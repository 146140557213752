import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ApiClient } from "../../../api-client/api.client";
import { Button, Card, Col, Form, Input, message, PageHeader, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";

interface IProps extends RouteComponentProps {}

interface IState {
  template: any;
  isLoading: boolean;
  mandrillTemplates: any[];
}

export class EditTemplateComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    mandrillTemplates: [],
    template: undefined,
    isLoading: true,
  };

  componentWillUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): void {
    if (nextProps.match.params.name !== this.props.match.params.name) {
      this.getTemplate(nextProps.match.params.name);
    }
  }

  public componentDidMount(): void {
    this.getTemplate();
    this.getMandrillTemplates();
  }

  public async getTemplate(_name?: string) {
    const { name } = this.props.match.params;
    const { data: template } = await ApiClient.getTemplate(_name || name);
    this.setState({ template });
  }

  public async getMandrillTemplates() {
    const { data: mandrillTemplates } = await ApiClient.getMandrillTemplates();
    this.setState({ mandrillTemplates, isLoading: false });
  }

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { template } = this.state;

      await ApiClient.updateTemplate(template.name, {
        ...template,
        content: values.content,
      });

      message.success("Template successfully saved!");
      this.getTemplate();
    } catch (e) {
      console.log(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public async activateTemplate() {
    const { name } = this.props.match.params;
    await ApiClient.activateTemplate(name);
    message.success("Template activated successfully!");
    this.getTemplate();
  }

  public async deactivateTemplate() {
    const { name } = this.props.match.params;
    await ApiClient.deactivateTemplate(name);
    message.success("Template deactivated successfully!");
    this.getTemplate();
  }

  public render() {
    const { template, isLoading, mandrillTemplates } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/communication-bus/templates")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit template"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Template"
              loading={isLoading}
              extra={
                <>
                  {!template?.isActive ? (
                    <Button onClick={() => this.activateTemplate()}>Activate</Button>
                  ) : (
                    <Button onClick={() => this.deactivateTemplate()}>Deactivate</Button>
                  )}
                </>
              }>
              {template ? (
                <Form key={template.name  } layout="vertical" ref={this.form} name="edit-template" onFinish={this.onFinish}>
                  <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={template?.name}>
                    <Input disabled />
                  </Form.Item>
                  {template.type === "email" ? (
                    <Form.Item
                      name="content"
                      label="Mandrill template"
                      rules={[{ required: true }]}
                      initialValue={template?.content}>
                      <Select placeholder="Select mandrill template" style={{ width: "100%" }}>
                        {mandrillTemplates.map((mandrillTemplate) => (
                          <Select.Option value={mandrillTemplate}>{mandrillTemplate}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="content"
                      label="Content"
                      rules={[{ required: true }]}
                      initialValue={template?.content}>
                      <Input.TextArea />
                    </Form.Item>
                  )}

                  <Form.Item name="variables" label="Variables">
                    <ul>
                      {template.variables.map((x) => (
                        <li>
                          <code>{x}</code>
                        </li>
                      ))}
                    </ul>
                  </Form.Item>
                  <Form.Item name="general_variables" label="General variables">
                    <ul>
                        <li>
                          <code>patientPortalSignUpUrl</code>
                        </li>
                        <li>
                          <code>patientPortalSignInUrl</code>
                        </li>
                    </ul>

                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button loading={isLoading} type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : null}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
