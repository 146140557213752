import React from "react";
import { Card } from "antd";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { MedicalHistory } from "./medical-history.component";

export interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  medicalHistory: IState["medicalHistory"];
}

export class QuestionnaireStep2Component extends React.Component<IProps> {
  render() {
    return (
      <Card title="Step 2">
        <MedicalHistory {...this.props} />
      </Card>
    );
  }
}
