import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { ResponsesListComponent } from "./components/responses-list.component";

interface IProps extends RouteComponentProps {}

export class NPS extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>NPS</Typography.Title>
        <Route path="/nps" exact component={ResponsesListComponent} />
      </>
    );
  }
}
