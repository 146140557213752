export const colors = {
  white: "#ffffff",
  black: "#000000",
  lightGrey: "#F8F8F8",
  grey: "#cccccc",
  blue: "#1890ff",
  darkerBlue: "#4a69bd",
  green: "#6ab04c",
  yellow: "#f9ca24",
  orange: "#f39c12",
  red: "#eb4d4b",
  redCell: "#eecdcd",
  yellowCell: "#fdf2d0",
  greenCell: "#dce9d5",
  greyCell: "#d9d9d9",
  yellowGreen: "#9acd32",
};
