import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { Typography } from "antd";
import { StatsListComponent } from "./components/stats-list.component";
import { StatsForVisitComponent } from "./components/stats-for-visit.component";

interface IProps extends RouteComponentProps {}

export class Stats extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Stats</Typography.Title>
        <Route path="/stats" component={StatsListComponent} exact />
        <Route path="/stats/visit/:id" component={StatsForVisitComponent} />
      </>
    );
  }
}
