import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import { Button, Layout, Result, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
// @ts-ignore
import logo from "./assets/images/2020-logo.svg";
import { AuthenticationGuard } from "./features/authentication-guard/authentication-guard.container";
import { Logout } from "./features/logout/logout.container";
import { Companies } from "./features/companies/companies.container";
import { MenuComponent } from "./ui/menu.component";
import styled from "styled-components";
import { Patients } from "./features/patients/patients.container";
import { Trucks } from "./features/trucks/trucks.container";
import { Visits } from "./features/visits/visits.container";
import { Insurances } from "./features/insurances/insurances.container";
import { Settings } from "./features/settings/settings.container";
import { NPS } from "./features/nps/nps.container";
import { Waitlist } from "./features/waitlist/waitlist.container";
import { CommunicationBus } from "./features/communication-bus/communication-bus.container";
import { Appointments } from "./features/appointments/appointments.container";
import { ApiClient } from "./api-client/api.client";
import { Stats } from "./features/stats/stats.container";
import { Home } from "./features/home/home.container";
import { PatientTracker } from "./features/patient-tracker/patient-tracker.container";
import { BookingBoard } from "./features/booking-board/booking-board.container";
import { BulkUpload } from "./features/bulk-upload/bulk-upload.container";
import { QueryClientProvider, QueryClient } from "react-query";

interface IState {
  menuCollapsed: boolean;
  user: any;
}
const queryClient = new QueryClient();

class App extends React.Component<IState> {
  public state: IState = {
    menuCollapsed: false,
    user: undefined,
  };

  public async componentDidMount() {
    const { data: user } = await ApiClient.getAccountsMe();
    this.setState({ user: user.data });
  }

  public collapseMenu = () => {
    const { menuCollapsed } = this.state;
    this.setState({ menuCollapsed: !menuCollapsed });
  };

  public getUserFirstName() {
    return this.state.user?.firstName || "-";
  }

  public render() {
    const { menuCollapsed } = this.state;
    return (
      <Container>
        <AuthenticationGuard>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Layout style={{ height: "100%" }}>
                <Layout.Sider
                  collapsible
                  collapsed={menuCollapsed}
                  onCollapse={this.collapseMenu}
                  style={{
                    backgroundColor: "white",
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    borderRight: "1px solid #eee",
                    left: 0,
                  }}>
                  <Logo>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </Logo>
                  <MenuComponent />
                </Layout.Sider>
                <Layout style={{ marginLeft: menuCollapsed ? 80 : 200 }} className="site-layout">
                  <StyledHeader>
                    <Typography.Text>
                      Howdy, <strong>{this.getUserFirstName()}</strong>! 👋
                    </Typography.Text>
                    <LogoutLink to="/logout">
                      Logout <LogoutOutlined />
                    </LogoutLink>
                  </StyledHeader>
                  <Layout.Content
                    className="site-layout-background"
                    style={{
                      padding: 30,
                      margin: 30,
                      minHeight: 280,
                    }}>
                    <Switch>
                      <Route path="/logout" component={Logout} />
                      <Route path="/companies" component={Companies} />
                      <Route path="/patients" component={Patients} />
                      <Route path="/visits" component={Visits} />
                      <Route path="/clinics" component={Trucks} />
                      <Route path="/insurances" component={Insurances} />
                      <Route path="/nps" component={NPS} />
                      <Route path="/waitlist" component={Waitlist} />
                      <Route path="/communication-bus" component={CommunicationBus} />
                      <Route path="/appointments" component={Appointments} />
                      <Route path="/patient-tracker" component={PatientTracker} />
                      <Route path="/stats" component={Stats} />
                      <Route path="/settings" component={Settings} />
                      <Route path="/booking-board" component={BookingBoard} />
                      <Route path="/bulk-upload" component={BulkUpload} />
                      <Route path="/" exact component={Home} />
                      <Route
                        path="*"
                        component={() => (
                          <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                            extra={
                              <Button type="primary">
                                <Link to={"/"}>Back to home</Link>
                              </Button>
                            }
                          />
                        )}
                      />
                    </Switch>
                  </Layout.Content>
                </Layout>
              </Layout>
            </Router>
          </QueryClientProvider>
        </AuthenticationGuard>
      </Container>
    );
  }
}

export default App;

const Container = styled.main`
  height: 100%;
`;

const Logo = styled.div`
  height: 63px;
  padding: 19px 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 24px;
    max-width: 100%;
  }
`;

const StyledHeader = styled(Layout.Header)`
  padding: 0 24px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LogoutLink = styled(Link)`
  margin-left: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;

  svg {
    margin-left: 5px;
    height: 17px;
    width: 17px;
  }
`;
