import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  id: string;
}

interface IState {}

export class TwoFactorAuthenticationSettingsComponent extends React.Component<IProps, IState> {
  public render() {
    return (
      <>
        <iframe
          src={`${process.env.AUTHENTICATION_APP_URL}/accounts/2fa/${this.props.id}`}
          style={{
            width: "100%",
            height: "90vh",
            border: "none",
            marginTop: 24,
          }}
        />
      </>
    );
  }
}
