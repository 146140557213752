import { IState } from "./questionnaire.interfaces";

export const questionnaireValidator = {
  isCovidScreeningSectionValid(payload: IState["covidScreening"]): boolean {
    return !!payload.covidPositive;
  },
  isCurrentConditionSectionValid(payload: IState["currentCondition"]): boolean {
    return [...Object.values(payload)].every((answer) => answer);
  },
  isMedicalHistorySectionValid(payload: IState["medicalHistory"]): boolean {
    return [...Object.values(payload)].every((answer) => answer.length);
  },
  isEyeHealthHistorySectionValid(payload: IState["eyeHealthHistory"]): boolean {
    return [...Object.values(payload)].every((answer) => answer.length);
  },
  isCurrentVisionConditionSectionValid(payload: IState["currentVisionCondition"]): boolean {
    return [...Object.values(payload)].every((answer) => answer);
  },
  isOtherSectionValid(payload: IState["other"]): boolean {
    const validationFields = {
      pregnantOrNursing: payload.pregnantOrNursing,
      tobacco: payload.tobacco,
      alcohol: payload.alcohol,
    };
    return [...Object.values(validationFields)].every((answer) => answer);
  },
  isMarketingConsentsSectionValid(payload: IState["marketingConsents"]): boolean {
    return [...Object.values(payload)].every((answer) => answer);
  },
};
