import React, { Component } from "react";
import { Tabs } from "antd";
import { RouteComponentProps } from "react-router-dom";

import { AppointmentsListComponent } from "./appointments-list.component";
import { AcuitasDocsChecker } from "./acuitas-docs-checker.component";

interface IProps extends RouteComponentProps {}

export class AppointmentsComponent extends Component<IProps> {
  render() {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Appointments" key="1">
          <AppointmentsListComponent {...this.props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Acuitas - Docs Checker" key="2">
          <AcuitasDocsChecker {...this.props} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}
