import React, { useEffect } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import {
  Form,
  Input,
  PageHeader,
  Card,
  Button,
  Table as AntdTable,
  Switch,
  message,
  Select,
  Spin,
  Modal,
  Space,
  Popconfirm,
} from "antd";
import { ApiClient } from "../../../api-client/api.client";
import moment from "moment-timezone";
import styled from "styled-components";
import { FormInstance } from "antd/lib/form";
import { Appointment, Slot, Template, Visit } from "./edit-timeslots.types";
import { useMutation, useQuery } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { useTimeslots } from "./useTimeslots";
import { AxiosError } from "axios";

interface IProps extends RouteComponentProps {}

const START_TIME = 0;
const END_TIME = 24 * 60;

export const EditTimeslotsComponent = (props: IProps) => {
  const { id } = useParams();
  const formForTemplateName = React.createRef<FormInstance>();
  const [baseSlots, setBaseSlots] = React.useState<Slot[]>([]);
  const [appointments, setAppointments] = React.useState<Appointment[]>([]);
  const [timeslotLength, setTimeslotLength] = React.useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState<Template>();
  const [matchedTemplates, setMatchedTemplates] = React.useState<Template[]>([]);
  const [modal, setModal] = React.useState<{ isVisible: boolean; slot?: Slot; appointment?: Appointment }>({
    isVisible: false,
    slot: undefined,
    appointment: undefined,
  });

  const {
    visit,
    getVisit,
    isVisitLoading,
    addSingleTimeslot,
    removeSingleTimeslot,
    activateSingleTimeslot,
    deactivateSingleTimeslot,
    cancelAppointment,
    rescheduleAppointment,
    patient,
    getPatient,
    parseError,
  } = useTimeslots(id);

  useEffect(() => {
    const f = async () => {
      await getVisit();
      await getTemplates();
    };
    f();
  }, []);

  useEffect(() => {
    if (visit && visit.timeslots && visit.timeslots.length > 0) {
      const slots = generateBaseSlots(visit);
      setBaseSlots(slots);
      setAppointments(parseAppointments(visit.timeslots, visit.timezone, slots));
    }
    if (visit && visit.timeslotLength) {
      setTimeslotLength(visit.timeslotLength);
    }
  }, [visit]);

  const parseAppointments = (timeslots: Visit["timeslots"], timezone: string, slots: Slot[]) => {
    const startOfVisitDayMoment = getStartOfVisitDayMoment();
    const appointments: Appointment[] = [];

    timeslots.forEach((timeslot) => {
      if (timeslot.appointments && timeslot.appointments.length > 0) {
        const startDate = moment.tz(timeslot.startDate, timezone).toISOString();
        const endDate = moment.tz(timeslot.endDate, timezone).toISOString();
        const availableTimeslot = slots.find(
          (slot) => startOfVisitDayMoment.clone().add(slot.offset, "minutes").toISOString() === startDate
        );

        timeslot.appointments.forEach((a) => {
          appointments.push({
            patientId: a.patientId,
            appointmentId: a.id || "",
            startDate,
            endDate,
            offset: availableTimeslot?.offset,
            status: a.status,
          });
        });
      }
    });

    return appointments;
  };

  const { data: templates, refetch: getTemplates, isLoading: isTemplatesLoading } = useQuery({
    queryKey: ["getTemplates"],
    queryFn: async () => {
      const { data: templates } = await ApiClient.getTimeslotTemplates();
      const startOfDayMoment = getStartOfVisitDayMoment();
      return (templates as Template[]).map((template) => ({
        ...template,
        slots: template.slots.map((slot) => ({
          ...slot,
          isDisabled: false,
          startDate: startOfDayMoment.clone().add(slot.offset, "minutes").toISOString(),
          endDate: startOfDayMoment
            .clone()
            .add(slot.offset + template?.interval! || 0, "minutes")
            .toISOString(),
        })),
      }));
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: () => message.error("Cannot pull the templates"),
  });

  useEffect(() => {
    setMatchedTemplates(templates && timeslotLength > 0 ? templates.filter((x) => x.interval === timeslotLength) : []);
    setSelectedTemplate(undefined);
  }, [templates, timeslotLength, visit]);

  const getTemplateName = () => {
    if (selectedTemplate?.name && selectedTemplate?.name === "custom") {
      return "custom";
    }

    const template = templates?.find((t) => t.id === selectedTemplate?.id);
    return template ? template.name : undefined;
  };

  const generateBaseSlots = (visit: Visit): Slot[] => {
    const slots = generateSlots(visit.timeslotLength);
    const startOfDayMoment = getStartOfVisitDayMoment();

    return slots
      .map((slot) => {
        const timeslot = visit.timeslots.find(
          ({ startDate }) => startOfDayMoment.clone().add(slot.offset, "minutes").toISOString() === startDate
        );
        return timeslot
          ? {
              id: timeslot.id,
              startDate: timeslot.startDate,
              endDate: timeslot.endDate,
              count: 1,
              offset: slot.offset,
              isDisabled: timeslot.isDisabled,
            }
          : slot;
      })
      .sort((a, b) => a.offset - b.offset);
  };

  const generateSlots = (setLength?: number): Slot[] => {
    const startOfDayMoment = getStartOfVisitDayMoment();
    const length = setLength || timeslotLength;
    const slots: Slot[] = [];
    for (let slotOffset = START_TIME; slotOffset <= END_TIME - length; slotOffset += length) {
      slots.push({
        count: 0,
        offset: slotOffset,
        isDisabled: false,
        startDate: startOfDayMoment.clone().add(slotOffset, "minutes").toISOString(),
        endDate: startOfDayMoment
          .clone()
          .add(slotOffset + length, "minutes")
          .toISOString(),
      });
    }
    return slots;
  };

  const handleSelectCustomTemplate = () => {
    const slots = generateSlots();
    setSelectedTemplate({ name: "custom", id: undefined, slots });
    changeTemplateName("custom");
  };

  const handleSelectExistingTemplate = (templateId: string) => {
    const template = templates?.find((x) => x.id === templateId);
    const sortedSlots = template?.slots.sort((a, b) => a.offset - b.offset) || [];
    const slots = mergeTemplateSlots(generateSlots(), sortedSlots);
    setSelectedTemplate({ name: template?.name, id: templateId, slots });
    changeTemplateName(templateId);
  };

  const mergeTemplateSlots = (...templateSlotsArrays: Slot[][]) => {
    let slots: Slot[] = [];

    templateSlotsArrays.forEach((slotsArray) => {
      slotsArray.forEach((slot) => {
        slots = slots.filter((x) => x.offset !== slot.offset);
        slots.push(slot);
      });
    });

    return slots.sort((a, b) => a.offset - b.offset);
  };

  const changeTemplateName = (templateId: string) => {
    if (templateId === "custom") {
      formForTemplateName.current?.setFieldsValue({ newTemplateName: "custom" });
    } else {
      const template = templates?.find((t) => t.id === templateId);
      if (template) formForTemplateName.current?.setFieldsValue({ newTemplateName: template.name });
    }
  };

  const getStartOfVisitDayMoment = () =>
    visit ? moment.tz(visit.date, "YYYY-MM-DD", visit.timezone).startOf("day") : moment().startOf("day");

  const handleToggleTemplateSlot = (slotToSelect: Slot) => {
    setSelectedTemplate((template) => ({
      ...template,
      slots: template
        ? template.slots.map((slot) =>
            slot.offset === slotToSelect.offset ? { ...slot, count: slot.count === 1 ? 0 : 1 } : slot
          )
        : [],
    }));
  };

  const generateTemplateSlotsFromVisitTimeslots = () => {
    const startOfDay = getStartOfVisitDayMoment();

    return visit?.timeslots
      ? visit?.timeslots.map((slot) => ({
          offset: moment(slot.startDate, visit.timezone).diff(startOfDay, "minutes"),
          count: 1,
        }))
      : [];
  };

  const { mutate: handleFinishSaveTemplate, isLoading: isSaveTemplateLoading } = useMutation(
    async (newTemplateName?: string) => {
      if (!newTemplateName) {
        message.error("Template name shouldn't be empty");
        return;
      }
      if (newTemplateName.toLowerCase() === "custom") {
        message.error(`Template name 'Custom' is not allowed`);
        return;
      }
      const slots =
        baseSlots.length > 0
          ? baseSlots.map((s) => {
              return { offset: s.offset, count: s.count };
            })
          : selectedTemplate?.slots.map((s) => {
              return { offset: s.offset, count: s.count };
            });
      if (selectedTemplate?.name === "custom") {
        await ApiClient.createTimeslotTemplate({
          name: newTemplateName,
          interval: timeslotLength,
          slots,
        });
      } else if (selectedTemplate?.id) {
        await ApiClient.updateTimeslotTemplate(selectedTemplate?.id, {
          name: newTemplateName,
          interval: timeslotLength,
          slots,
        });
      } else if (visit && visit.timeslots && visit.timeslots.length > 0) {
        await ApiClient.createTimeslotTemplate({
          name: newTemplateName,
          interval: timeslotLength,
          slots: generateTemplateSlotsFromVisitTimeslots(),
        });
      }
      message.success("Template saved successfully!");
      await getTemplates();
    },
    {
      onError: (e: AxiosError) => {
        message.error(parseError(e, "Cannot add template due to internal server error."));
      },
    }
  );

  const { refetch: addTimeslots, isLoading: isTimeslotsLoading } = useQuery({
    queryKey: ["addTimeslots"],
    queryFn: async () => {
      if (!visit) return;
      const startOfVisitDay = getStartOfVisitDayMoment();

      if (!selectedTemplate?.slots.length) {
        message.error("No timeslots selected!");
        return;
      }

      const timeslots = selectedTemplate.slots
        .filter((x) => x.count !== 0)
        .map(({ offset }) => ({
          startDate: startOfVisitDay.clone().add(offset, "minutes").toISOString(),
          endDate: startOfVisitDay
            .clone()
            .add(offset + timeslotLength, "minutes")
            .toISOString(),
        }));

      if (timeslots.length === 0) {
        message.warning("Visit should have one timeslot active!");
        return;
      }

      await ApiClient.changeTimeslotLength(visit.id, { timeslotLength });
      await ApiClient.addTimeslots(visit.id, { timeslots });
      message.success("Timeslots added successfully!");
      setSelectedTemplate(undefined);
      setMatchedTemplates([]);
      getVisit();
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: (e: AxiosError) => message.error(parseError(e, "Cannot add timeslots due to internal server error.")),
  });

  const getAppointmentByOffset = (offset: number) =>
    appointments.find((a) => a.offset === offset && a.status === "confirmed");

  const openModal = (slot: Slot, appointment: Appointment) => {
    getPatient(appointment.patientId);
    setModal({
      isVisible: true,
      slot,
      appointment,
    });
  };

  const closeModal = () => setModal((mod) => ({ ...mod, isVisible: false }));

  const onModalSubmit = () => {
    const currentTimeslotId = modal.slot?.id;
    const newTimeslotId = form.getFieldValue("timeslotId");
    if (currentTimeslotId === newTimeslotId || !currentTimeslotId || !newTimeslotId) {
      closeModal();
    } else {
      rescheduleAppointment.mutate(
        {
          timeslotId: currentTimeslotId!,
          targetTimeslotId: newTimeslotId,
          targetVisitId: id,
          appointmentId: modal.appointment!.appointmentId,
        },
        {
          onSuccess: () => {
            message.success("Appointment rescheduled successfully!");
            closeModal();
          },
          onError: (e) => {
            message.error(parseError(e as AxiosError, "Cannot reschedule appointment due to internal server error."));
          },
          onSettled: () => {
            getVisit();
          },
        }
      );
    }
  };

  const onCancelAppointment = () => {
    if (!modal.appointment?.appointmentId) return;
    cancelAppointment.mutate(modal.appointment!.appointmentId, {
      onSuccess: () => {
        message.success("Appointment cancelled successfully!");
        closeModal();
      },
      onError: (e) => {
        message.error(parseError(e as AxiosError, "Cannot cancel appointment due to internal server error."));
      },
      onSettled: () => {
        getVisit();
      },
    });
  };

  const startOfDayMoment = getStartOfVisitDayMoment();
  const templateName = getTemplateName();
  const hasTimeslotsAlreadyAdded = visit && visit.timeslots.length > 0;

  const [form] = Form.useForm();

  useEffect(() => {
    if (modal.slot) {
      form.setFieldsValue({ timeslotId: modal.slot.id });
    }
  }, [modal.slot]);

  return (
    <>
      <PageHeader
        onBack={() => props.history.push(`/visits/${id}/edit`)}
        ghost={false}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        title="Edit visit timeslots"
      />
      {isVisitLoading && (
        <Center>
          <Spin size="large" tip="Loading visit..." />
        </Center>
      )}
      {!isVisitLoading && !visit && (
        <Center>
          <h3>Visit not found</h3>
        </Center>
      )}
      {visit && (
        <>
          <Layout>
            {!hasTimeslotsAlreadyAdded && (
              <Card
                title="Options"
                style={{
                  marginBottom: 24,
                }}>
                <Form layout="vertical">
                  <Form.Item
                    name="timeslotLength"
                    label="Timeslot length"
                    rules={[{ required: true }]}
                    initialValue={timeslotLength || visit.timeslotLength}>
                    <Input
                      type="number"
                      value={timeslotLength}
                      onChange={(e) => setTimeslotLength(Number(e.target.value))}
                    />
                  </Form.Item>
                  <Form.Item label="Select Template">
                    <Button
                      type={selectedTemplate?.name === "custom" ? "primary" : "default"}
                      size="small"
                      htmlType="button"
                      disabled={timeslotLength === 0}
                      onClick={() => handleSelectCustomTemplate()}
                      style={{ marginRight: 10, marginBottom: 10 }}
                      icon={<PlusOutlined />}>
                      Custom
                    </Button>
                    {matchedTemplates.map((template) => (
                      <Button
                        type={selectedTemplate?.id === template.id ? "primary" : "default"}
                        key={template.id}
                        size="small"
                        htmlType="button"
                        disabled={timeslotLength === 0}
                        style={{ marginRight: 10, marginBottom: 10 }}
                        onClick={() => handleSelectExistingTemplate(template.id || "")}>
                        {template.name}
                      </Button>
                    ))}
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={() => addTimeslots()}
                    loading={isTimeslotsLoading}
                    disabled={!selectedTemplate?.slots}
                    style={{ marginTop: 20 }}>
                    Add selected timeslots
                  </Button>
                </Form>
              </Card>
            )}
            <Card
              title="Visit timeslots"
              loading={isVisitLoading}
              style={{
                marginBottom: 24,
              }}
              extra={
                <>
                  Timezone: <strong>{visit?.timezone}</strong>
                </>
              }>
              {hasTimeslotsAlreadyAdded ? (
                <Table
                  bordered
                  size="small"
                  pagination={false}
                  dataSource={baseSlots}
                  rowKey="offset"
                  rowClassName={(slot) => {
                    if ((slot as Slot).isDisabled) {
                      return "inactive";
                    } else if ((slot as Slot).id || (slot as Slot).count === 1) {
                      return "active";
                    }
                    return "";
                  }}
                  columns={[
                    {
                      title: "Start time",
                      key: "startTime",
                      render: (slot: Slot) => startOfDayMoment.clone().add(slot.offset, "minutes").format("LT"),
                    },
                    {
                      title: "End time",
                      key: "endTime",
                      render: (slot: Slot) =>
                        startOfDayMoment
                          .clone()
                          .add(slot.offset + timeslotLength, "minutes")
                          .format("LT"),
                    },
                    {
                      title: "Action",
                      key: "action",
                      width: "50%",
                      render: (slot: Slot) => {
                        if (!slot.id && slot.count === 0) {
                          return (
                            <Button
                              size="small"
                              style={{ marginRight: 6 }}
                              onClick={() =>
                                addSingleTimeslot.mutate({
                                  startDate: new Date(slot.startDate || ""),
                                  endDate: new Date(slot.endDate || ""),
                                })
                              }>
                              Add
                            </Button>
                          );
                        }

                        const appointmentForOffset = getAppointmentByOffset(slot.offset);

                        return appointmentForOffset ? (
                          <Button
                            size="small"
                            style={{ marginRight: 6 }}
                            onClick={() => openModal(slot, appointmentForOffset)}>
                            Manage appointment
                          </Button>
                        ) : slot.id ? (
                          <>
                            {slot.isDisabled ? (
                              <Button
                                size="small"
                                style={{ marginRight: 6 }}
                                onClick={() => activateSingleTimeslot.mutate(slot.id!)}>
                                Activate
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                style={{ marginRight: 6 }}
                                onClick={() => deactivateSingleTimeslot.mutate(slot.id!)}>
                                Deactivate
                              </Button>
                            )}
                            <Button
                              size="small"
                              style={{ marginRight: 6 }}
                              onClick={() => removeSingleTimeslot.mutate(slot.id!)}>
                              Remove
                            </Button>
                          </>
                        ) : null;
                      },
                    },
                  ]}
                />
              ) : selectedTemplate ? (
                <>
                  <Table
                    bordered
                    size="small"
                    pagination={false}
                    dataSource={selectedTemplate.slots}
                    rowKey="offset"
                    columns={[
                      {
                        title: "Start time",
                        key: "startTime",
                        render: (slot: Slot) => startOfDayMoment.clone().add(slot.offset, "minutes").format("LT"),
                      },
                      {
                        title: "End time",
                        key: "endTime",
                        render: (slot: Slot) =>
                          startOfDayMoment
                            .clone()
                            .add(slot.offset + timeslotLength, "minutes")
                            .format("LT"),
                      },
                      {
                        title: "Action",
                        key: "action",
                        width: "50%",
                        render: (slot: Slot) => (
                          <Switch checked={slot.count === 1} onChange={() => handleToggleTemplateSlot(slot)} />
                        ),
                      },
                    ]}
                  />
                  <Form
                    ref={formForTemplateName}
                    layout="inline"
                    onFinish={(e) => handleFinishSaveTemplate(e.newTemplateName)}
                    style={{ marginTop: 24 }}>
                    <Form.Item label="Template name" name="newTemplateName" initialValue={templateName}>
                      <Input />
                    </Form.Item>
                    <Button htmlType="submit" loading={isTemplatesLoading || isSaveTemplateLoading}>
                      Save template
                    </Button>
                  </Form>
                </>
              ) : (
                <Center direction="horizontal">
                  <h4>
                    Select existing template or click <b>+ Custom</b>
                  </h4>
                </Center>
              )}
            </Card>
          </Layout>
          <Modal
            title="Menage appointment"
            visible={modal.isVisible}
            onCancel={closeModal}
            onOk={onModalSubmit}
            okText="Save">
            <StyledForm layout="vertical" form={form} onFinish={onModalSubmit}>
              <Popconfirm
                title="Are you sure to cancel this appointment?"
                okText="Yes"
                cancelText="No"
                onConfirm={onCancelAppointment}>
                <Button danger>Cancel appointment</Button>
              </Popconfirm>
              <PatientDetails>
                <span>Patient:</span>
                <b>
                  {patient?.firstName} {patient?.lastName}
                </b>
                <span>Email:</span>
                <b>{patient?.email}</b>
                <span>Phone:</span>
                <b>{patient?.phoneNumber}</b>
              </PatientDetails>
              <Form.Item name="timeslotId" label="Choose timeslot" initialValue={modal.slot?.id}>
                <Select style={{ width: "100%" }}>
                  {visit.timeslots
                    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                    .map((timeslot) => {
                      const timeslotAppointments = timeslot.appointments.find((a) => a.status === "confirmed");
                      return (
                        <Select.Option
                          key={timeslot.id}
                          value={timeslot.id}
                          disabled={(timeslot.isDisabled || timeslotAppointments) && timeslot.id !== modal.slot?.id}>
                          {moment.tz(timeslot.startDate, visit.timezone).format("LT")}
                          {" - "}
                          {moment.tz(timeslot.endDate, visit.timezone).format("LT")}
                          {timeslot.id === modal.slot?.id
                            ? " (selected)"
                            : timeslot.isDisabled
                            ? " (disabled)"
                            : timeslotAppointments
                            ? " (booked)"
                            : ""}
                        </Select.Option>
                      );
                    })}
                  ))
                </Select>
              </Form.Item>
            </StyledForm>
          </Modal>
        </>
      )}
    </>
  );
};

const Layout = styled.section`
  display: flex;
  gap: 16px;

  & :first-child:not(:last-child) {
    max-width: 400px;
  }

  & :last-child {
    flex: 1;
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  gap: 20px;
`;

const PatientDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 5px;
  width: min-content;
`;

const Table = styled(AntdTable)`
  .active {
    background-color: #f6feed;
  }
  .inactive {
    background-color: #fffbe6;
  }

  .active,
  .inactive {
    > td {
      background: transparent !important;
    }
  }
`;

const Center = styled(Space)`
  width: 100%;
  justify-content: center;
`;
