import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, message, Row, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { dateFormatter } from "../../../helpers/date-formatter.helper";

import { sortInsurances } from "../../../helpers/appointment.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  insurances: any[];
}

export class InsurancesListComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    insurances: [],
  };

  public componentDidMount(): void {
    this.getInsurances();
  }

  public async getInsurances() {
    this.setState({ isLoading: true });

    try {
      const { data: insurances } = await ApiClient.findInsurances();

      this.setState({ insurances: insurances.sort(sortInsurances), isLoading: false });
    } catch (e) {
      message.error("Cannot fetch insurances data");
      this.setState({ isLoading: false });
    }
  }

  public render() {
    const { insurances, isLoading } = this.state;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Type",
        key: "type",
        render: ({ type }) => {
          return type === "medical" ? <Tag color="purple">medical</Tag> : <Tag color="blue">vision</Tag>;
        },
      },
      {
        title: "Status",
        key: "status",
        render: ({ isDisabled }) => {
          return isDisabled ? <Tag color="red">inactive</Tag> : <Tag color="green">active</Tag>;
        },
      },
      {
        title: "Visibility",
        key: "hidden",
        render: ({ isHidden }) => {
          return isHidden ? <Tag color="orange">hidden</Tag> : <Tag color="cyan">visible</Tag>;
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Link to={`/insurances/${id}/edit`}>
              <Button size={"small"}>Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ marginBottom: 24, textAlign: "right" }}>
            <Link to="/insurances/create">
              <Button type="primary">Create insurance</Button>
            </Link>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{insurances.length}</strong>
        </Typography.Text>
        <Table bordered loading={isLoading} size="small" dataSource={insurances} columns={columns} pagination={false} />
      </>
    );
  }
}
