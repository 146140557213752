import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space } from "antd";
import moment from "moment";
import styled from "styled-components";
import { FileExcelOutlined } from "@ant-design/icons/lib";
import { SearchOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";

import { ApiClient } from "../../../api-client/api.client";
import { dateFormatterWithoutTime } from "../../../helpers/date-formatter.helper";
import VirtualTable from "./virtual-table.component";
import { colors } from "../../../styles/colors";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  isLoadingCSV: boolean;
  currentPage: number;
  stats: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
}

export class StatsListComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    isLoadingCSV: false,
    currentPage: 1,
    stats: {
      total: 0,
      offset: 0,
      limit: 100000,
      data: [],
    },
  };

  private searchForm = React.createRef<FormInstance>();

  public onSearchFormFinish = () => {
    this.getStats();
  };

  public async getStats() {
    this.setState({ isLoading: true });
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();

      const { data: stats } = await ApiClient.getStatsVisits({
        offset: (this.state.currentPage - 1) * this.state.stats.limit,
        limit: this.state.stats.limit,
        companyName: searchForm.company || undefined,
        statuses: ["active_complete", "active_booked"],
        type: searchForm.type || undefined,
        startDate: searchForm.startDate ? moment(searchForm.startDate).toISOString() : undefined,
        endDate: searchForm.endDate ? moment(searchForm.endDate).add(1, "day").toISOString() : undefined,
      });

      this.setState({ stats, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch visits data");
      this.setState({ isLoading: false });
    }
  }

  public componentDidMount(): void {
    this.getStats();
  }

  async generateCSV() {
    const { stats } = this.state;
    try {
      this.setState({ isLoadingCSV: true });

      const data = await ApiClient.getCSVWithStatsForVisit(stats.data.map((s) => s.id));

      let csvContent = "data:text/csv;charset=utf-8," + data.data;
      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", `stats_${moment().format("l")}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.setState({ isLoadingCSV: false });
    } catch (e) {
      this.setState({ isLoadingCSV: false });
      message.error("Cannot generate CSV file.");
    }
  }
  public async getUnbounceStats(companySlug: string) {
    const { stats } = this.state;
    stats.data.forEach((s) => {
      if (s.company.slug === companySlug) {
        s.unbounceLoading = true;
      }
    });
    this.setState({ stats });
    try {
      const unbounceData = await ApiClient.getUnbounceStats(companySlug);
      stats.data.forEach((s) => {
        if (s.company.slug === companySlug) {
          s.unbounceLoading = false;
          if (unbounceData.data.id) {
            s.unbounce = unbounceData.data;
          } else {
            s.unbounceFails = true;
          }
        }
      });
      this.setState({ stats });
    } catch (e) {
      stats.data.forEach((s) => {
        if (s.company.slug === companySlug) {
          s.unbounceLoading = false;
        }
      });
      message.error("Cannot fetch unbounce data");
      this.setState({ stats });
    }
  }

  public clearForm = () => {
    this.searchForm.current!.resetFields();
  };

  public render() {
    const { isLoading, isLoadingCSV, stats } = this.state;

    const appointmentStatus = ["confirmed", "checked_in", "ready_for_exam", "exam", "checked_out"];
    const dataSource = stats.data.map((s, i) => {
      let isNewWeek = false;
      if (i > 0) {
        const previousRecord = stats.data[i - 1];
        isNewWeek = moment(previousRecord.date).week() !== moment(s.date).week();
      }
      return {
        ...s,
        isNewWeek,
      };
    });

    const columns = [
      {
        key: "date",
        header: "DATE",
        width: 120,
        content: ({ row }) => {
          return (
            <div
              style={{
                color: colors.black,
              }}>
              {dateFormatterWithoutTime(new Date(row.date), "UTC")}
            </div>
          );
        },
      },
      {
        key: "companyName",
        header: "COMPANY",
        width: 350,
        content: ({ row }) => (
          <div>
            <Link
              to={`/stats/visit/${row.id}`}
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                textDecoration: "underline",
                color: colors.black,
              }}>
              {row.company.name}
            </Link>
          </div>
        ),
      },
      {
        key: "currentCPV",
        header: "CURRENT CPV",
        width: 90,
        content: ({ row }) => (
          <div>
            <a
              style={{
                color: colors.black,
                textDecoration: "underline",
              }}
              href={`/appointments?companyId=${row.company.id}&statuses=${appointmentStatus.toString()}&visitId=${
                row.id
              }&startDate=${row.date}&endDate=${row.date}`}
              target="_blank">
              {row.currentCPV}
            </a>
          </div>
        ),
      },
      {
        key: "utilization",
        header: "APPT UTILIZATION",
        width: 100,
        content: ({ row }) => {
          let color;
          switch (true) {
            case row.utilization < 50:
              color = colors.red;
              break;
            case row.utilization < 70:
              color = colors.orange;
              break;
            case row.utilization < 100:
              color = colors.green;
              break;
            default:
              color = colors.black;
          }

          return <div style={{ color }}>{row.utilization.toFixed()}%</div>;
        },
      },
      {
        key: "targetCPV",
        header: "TARGET CPV",
        width: 90,
        content: ({ row }) => <div style={{ color: colors.black }}>{row.targetCPV}</div>,
      },
      {
        key: "checkedOutAppointments",
        header: "CHECKED OUTS",
        width: 90,
        content: ({ row }) => <div style={{ color: colors.black }}>{row.checkedOutAppointments}</div>,
      },
      {
        key: "lateCancelAppointments",
        header: "NO SHOWS",
        width: 90,
        content: ({ row }) => <div style={{ color: colors.black }}>{row.lateCancelAppointments}</div>,
      },
      {
        key: "waitlist",
        header: "WAITLIST",
        width: 90,
        content: ({ row }) => (
          <div>
            <a
              style={{
                color: colors.black,
                textDecoration: "underline",
              }}
              href={`/waitlist/subscriptions?locationId=${row.locationId}`}
              target="_blank">
              {row.waitlist}
            </a>
          </div>
        ),
      },
      {
        key: "unbounce1",
        header: "VISITS/CONV",
        width: 100,
        content: ({ row }) => {
          if (row.unbounce) {
            return (
              <UnbounceStats>
                {row.unbounce.visits}/{row.unbounce.conversions}
              </UnbounceStats>
            );
          } else if (row.unbounceLoading) {
            return <UnbounceStats>Loading...</UnbounceStats>;
          } else if (row.unbounceFails) {
            return <UnbounceStats>-/-</UnbounceStats>;
          } else {
            return (
              <UnbounceStats
                style={{
                  color: colors.black,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => this.getUnbounceStats(row.company.slug)}>
                Show
              </UnbounceStats>
            );
          }
        },
      },
      {
        key: "unbounce2",
        header: "CONV RATE",
        width: 90,
        content: ({ row }) => {
          if (row.unbounce) {
            return <UnbounceStats>{row.unbounce.conversionRate}</UnbounceStats>;
          } else if (row.unbounceLoading) {
            return <UnbounceStats>Loading...</UnbounceStats>;
          } else if (row.unbounceFails) {
            return <UnbounceStats>-</UnbounceStats>;
          } else {
            return (
              <UnbounceStats
                style={{
                  color: colors.black,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => this.getUnbounceStats(row.company.slug)}>
                Show
              </UnbounceStats>
            );
          }
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form-visit" onFinish={this.onSearchFormFinish}>
              <Form.Item name="company" label="Company" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="type" label="Type" initialValue={undefined} style={{ marginBottom: 16 }}>
                <Select placeholder="Select status" style={{ width: 150 }}>
                  <Select.Option value={undefined}>all</Select.Option>
                  <Select.Option value="ro_private">ro private</Select.Option>
                  <Select.Option value="ro_public">ro public</Select.Option>
                  <Select.Option value="telehealth">telehealth</Select.Option>
                  <Select.Option value="styling">styling</Select.Option>
                  <Select.Option value="life_sciences">life sciences</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="startDate"
                label="Start date"
                initialValue={moment().utc().startOf("day")}
                style={{ marginBottom: 16 }}>
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End date"
                initialValue={moment().utc().endOf("year")}
                style={{ marginBottom: 16 }}>
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>

              <Form.Item style={{ marginBottom: 16 }}>
                <Space>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                  <Button onClick={this.clearForm}>Clear</Button>
                </Space>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <ButtonCSV type="default" onClick={this.generateCSV.bind(this)} loading={isLoadingCSV}>
                  <FileExcelOutlined />
                  Generate CSV
                </ButtonCSV>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {isLoading === false && <VirtualTable columns={columns} dataSource={dataSource} />}
      </>
    );
  }
}
const UnbounceStats = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const ButtonCSV = styled(Button)`
  background-color: #ffcd00;
  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid transparent;
    background-color: #ffcd00;
    opacity: 0.8;
  }
`;
