import React from "react";
import { Modal, Button, Tag, Table, message, Row, Col, Select, Space, Typography, Divider } from "antd";
import { ITruck } from "../../../interfaces/truck.interface";
import { ApiClient } from "../../../api-client/api.client";

interface IProps {
  isVisible: boolean;
  truck: ITruck | undefined;
  selectedDay: string;
  onCancel: () => void;
  onFinish: () => void;
}

interface IState {
  selectedReason: IReason | undefined;
}

interface IReason {
  name: string;
  locationId: string;
}

class CreateOpsHoldVisitModal extends React.Component<IProps, IState> {
  private reasons: IReason[] = [
    {
      name: "Staff unavailable",
      locationId: "baf4b00c-f6f8-47be-a326-12929fa159c6",
    },
    {
      name: "Maintenance",
      locationId: "578dff6a-2d25-4044-8335-6f4d9fec90c3",
    },
    {
      name: "Event",
      locationId: "c53c8afd-0e7b-49d4-8bab-a18c7804fd58",
    },
  ];

  public state: IState = {
    selectedReason: this.reasons[0],
  };

  constructor(props: IProps) {
    super(props);
    this.saveVisit = this.saveVisit.bind(this);
  }

  public async saveVisit() {
    const { truck, selectedDay, onCancel, onFinish } = this.props;
    const { selectedReason } = this.state;
    try {
      if (!selectedReason) {
        throw new Error("Please specify the reason of creating inactive-Ops Hold visit");
      }
      await ApiClient.createVisit({
        name: selectedReason.name,
        date: selectedDay,
        truckId: truck?.id,
        locationId: selectedReason.locationId,
        timezone: "America/New_York",
        parkingLocationDetails: "29 Garden Street",
        appointmentType: "routine_optimetry",
        status: "inactive_ops_hold",
      });
      message.success("inactive-Ops Hold visit successfully saved!");
      onCancel();
      onFinish();
    } catch (error) {
      message.error(error.message);
    }
  }

  public render() {
    const { isVisible, onCancel } = this.props;

    return (
      <Modal
        title={"Create inactive-Ops Hold visit"}
        width={500}
        visible={isVisible}
        onCancel={onCancel}
        okText={"save"}
        onOk={this.saveVisit}>
        <Space direction="vertical">
          <Typography.Text>
            <b>Please select a reason: </b>
          </Typography.Text>
          <Select
            style={{ width: "15em" }}
            defaultValue={this.reasons[0].locationId}
            onChange={(value) =>
              this.setState({ selectedReason: this.reasons.find((reason) => reason.locationId === value) })
            }>
            {this.reasons.map((reason) => (
              <Select.Option value={reason.locationId} key={reason.locationId}>
                {reason.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Modal>
    );
  }
}

export default CreateOpsHoldVisitModal;
