import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { CreateCompanyComponent } from "./components/create-company.component";
import { EditCompanyComponent } from "./components/edit-company.component";
import { CompaniesListComponent } from "./components/companies-list.component";
import { Typography } from "antd";
import { CreateLocationComponent } from "./components/create-location.component";
import { EditLocationComponent } from "./components/edit-location.component";

interface IProps extends RouteComponentProps {}

export class Companies extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Companies</Typography.Title>
        <Route path="/companies/:id/locations/create" component={CreateLocationComponent} />
        <Route path="/companies/:id/locations/:locationId/edit" component={EditLocationComponent} />
        <Route path="/companies/create" component={CreateCompanyComponent} />
        <Route path="/companies/:id/edit" component={EditCompanyComponent} />
        <Route path="/companies/" exact component={CompaniesListComponent} />
      </>
    );
  }
}
