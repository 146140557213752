import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import * as React from 'react';
import classNames from 'classnames';
import Trigger from 'rc-trigger';
var BUILT_IN_PLACEMENTS = {
  bottomLeft: {
    points: ['tl', 'bl'],
    offset: [0, 4],
    overflow: {
      adjustX: 1,
      adjustY: 1
    }
  },
  bottomRight: {
    points: ['tr', 'br'],
    offset: [0, 4],
    overflow: {
      adjustX: 1,
      adjustY: 1
    }
  },
  topLeft: {
    points: ['bl', 'tl'],
    offset: [0, -4],
    overflow: {
      adjustX: 0,
      adjustY: 1
    }
  },
  topRight: {
    points: ['br', 'tr'],
    offset: [0, -4],
    overflow: {
      adjustX: 0,
      adjustY: 1
    }
  }
};

function PickerTrigger(_ref) {
  var _classNames;

  var prefixCls = _ref.prefixCls,
      popupElement = _ref.popupElement,
      popupStyle = _ref.popupStyle,
      visible = _ref.visible,
      dropdownClassName = _ref.dropdownClassName,
      dropdownAlign = _ref.dropdownAlign,
      transitionName = _ref.transitionName,
      getPopupContainer = _ref.getPopupContainer,
      children = _ref.children,
      range = _ref.range,
      popupPlacement = _ref.popupPlacement,
      direction = _ref.direction;
  var dropdownPrefixCls = "".concat(prefixCls, "-dropdown");

  var getPopupPlacement = function getPopupPlacement() {
    if (popupPlacement !== undefined) {
      return popupPlacement;
    }

    return direction === 'rtl' ? 'bottomRight' : 'bottomLeft';
  };

  return React.createElement(Trigger, {
    showAction: [],
    hideAction: [],
    popupPlacement: getPopupPlacement(),
    builtinPlacements: BUILT_IN_PLACEMENTS,
    prefixCls: dropdownPrefixCls,
    popupTransitionName: transitionName,
    popup: popupElement,
    popupAlign: dropdownAlign,
    popupVisible: visible,
    popupClassName: classNames(dropdownClassName, (_classNames = {}, _defineProperty(_classNames, "".concat(dropdownPrefixCls, "-range"), range), _defineProperty(_classNames, "".concat(dropdownPrefixCls, "-rtl"), direction === 'rtl'), _classNames)),
    popupStyle: popupStyle,
    getPopupContainer: getPopupContainer
  }, children);
}

export default PickerTrigger;