import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from 'react';
export default function useSyncState(filteredKeys) {
  var filteredKeysRef = React.useRef(filteredKeys);

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      forceUpdate = _React$useState2[1];

  return [function () {
    return filteredKeysRef.current;
  }, function (newValue) {
    filteredKeysRef.current = newValue; // re-render

    forceUpdate({});
  }];
}