import React from "react";
import { PageHeader, Button, Descriptions } from "antd";

type IProps = {
  numberOfRecordsReadyToSend: number;
  numberOfRecordsHaveErrors: number;
  onCleanUp: () => void;
  onUploadFile: () => void;
};

const StatisticsBar = ({ numberOfRecordsReadyToSend, numberOfRecordsHaveErrors, onCleanUp, onUploadFile }: IProps) => {
  const total = numberOfRecordsReadyToSend + numberOfRecordsHaveErrors;
  return (
    <PageHeader
      ghost={false}
      title="Read data from the CSV file"
      subTitle={null}
      extra={[
        <Button key="2" onClick={onCleanUp}>
          Clean up
        </Button>,
        <Button key="1" type="primary" onClick={onUploadFile} disabled={total === numberOfRecordsHaveErrors}>
          Upload now
        </Button>,
      ]}>
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Number of records that are to send">
          <div style={{ color: "#52c41a" }}>{numberOfRecordsReadyToSend}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Number of records that have errors">
          <div style={{ color: "#f5222d" }}>{numberOfRecordsHaveErrors}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Total">{total}</Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export default StatisticsBar;
