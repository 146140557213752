import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, message, Row, Alert } from "antd";

import { IQuestionnaire, IState, IUpdateQuestionnaireFieldPayload } from "./questionnaire.interfaces";
import { ApiClient } from "../../../../api-client/api.client";
import { QuestionnaireStep1Component } from "./step1/questionnaire-step-1.component";
import { QuestionnaireStep2Component } from "./step2/questionnaire-step-2.component";
import { QuestionnaireStep3Component } from "./step3/questionnaire-step-3.component";
import { QuestionnaireStep4Component } from "./step4/questionnaire-step-4.component";
import { questionnaireValidator } from "./questionnaire-validator";
import moment from "moment";

interface IProps extends RouteComponentProps {}

interface ILocalState {
  isLoading: boolean;
}

export class QuestionnaireComponent extends React.Component<IProps, IState & ILocalState> {
  public state: IState & ILocalState = {
    isLoading: false,
    // the question regarding "covidScreening" is hide, but to avoid problem with consistency to old data structure the "covidPositive" field is set to "No" as default
    covidScreening: {
      covidSymptoms: "",
      covidPositive: "No",
      eyeRedness: "",
    },
    currentCondition: {
      cardiovascular: "",
      endocrine: "",
      neurological: "",
      psychiatric: "",
      respiratory: "",
    },
    currentVisionCondition: {
      worseningHeadaches: "",
      eyePainOrItchingRedness: "",
      haveIssuesSeeingWithCurrentGlassesOrContacts: "",
      anyVisionDifficulties: "",
    },
    medicalHistory: {
      bloodPressureProblems: [],
      highCholesterol: [],
      diabetesOrHighBloodSugar: [],
      thyroidConditions: [],
      heartConditions: [],
      stroke: [],
      depression: [],
      cancer: [],
      asthmaOrCopd: [],
    },
    eyeHealthHistory: {
      cataracts: [],
      glaucoma: [],
      macularDegeneration: [],
      diabeticRetinopathy: [],
      recurrentEyeInfections: [],
      retinalDetachmentOrRetinalTear: [],
      dryEyes: [],
      lazyEyeOrCrossingEye: [],
      blindness: [],
      floatersOrSpotsOrLightFlashes: [],
      otherEyeProblems: [],
      eyeSurgeries: [],
    },
    other: {
      allergies: "",
      medications: "",
      pregnantOrNursing: "",
      tobacco: "",
      alcohol: "",
    },
    marketingConsents: {
      clinicalTrialNotifications: "",
    },
    acuitasSyncFailed: {
      date: "",
      message: "",
    },
  };

  public componentDidMount(): void {
    const { id } = this.props.match.params;
    this.getQuestionnaire(id);
  }

  public redirectOnSave() {
    const params = new URLSearchParams(this.props.location.search);
    const redirectTo = params.get("redirectTo");
    if (redirectTo === "patient-tracker") {
      this.props.history.push({
        pathname: `/${redirectTo}`,
        search: `?trackerId=${params.get("trackerId")}`,
      });
    }
  }

  public setQuestionnaireToState = (data: IQuestionnaire) => {
    const payloadForState = {
      covidScreening: {
        covidSymptoms: data.covidSymptoms,
        covidPositive: ['Yes', 'No'].includes(data.covidPositive) ? data.covidPositive : "No",
        eyeRedness: data.eyeRedness,
      },
      currentCondition: {
        cardiovascular: data.cardiovascular,
        endocrine: data.endocrine,
        neurological: data.neurological,
        psychiatric: data.psychiatric,
        respiratory: data.respiratory,
      },
      currentVisionCondition: {
        ...(data.worseningHeadaches ? { worseningHeadaches: "yes" } : { worseningHeadaches: "no" }),
        ...(data.eyePainOrItchingRedness ? { eyePainOrItchingRedness: "yes" } : { eyePainOrItchingRedness: "no" }),
        haveIssuesSeeingWithCurrentGlassesOrContacts: data.haveIssuesSeeingWithCurrentGlassesOrContacts,
        anyVisionDifficulties: data.anyVisionDifficulties,
      },
      medicalHistory: {
        bloodPressureProblems: data.bloodPressureProblems,
        highCholesterol: data.highCholesterol,
        diabetesOrHighBloodSugar: data.diabetesOrHighBloodSugar,
        thyroidConditions: data.thyroidConditions,
        heartConditions: data.heartConditions,
        stroke: data.stroke,
        depression: data.depression,
        cancer: data.cancer,
        asthmaOrCopd: data.asthmaOrCopd,
      },
      eyeHealthHistory: {
        cataracts: data.cataracts,
        glaucoma: data.glaucoma,
        macularDegeneration: data.macularDegeneration,
        diabeticRetinopathy: data.diabeticRetinopathy,
        recurrentEyeInfections: data.recurrentEyeInfections,
        retinalDetachmentOrRetinalTear: data.retinalDetachmentOrRetinalTear,
        dryEyes: data.dryEyes,
        lazyEyeOrCrossingEye: data.lazyEyeOrCrossingEye,
        blindness: data.blindness,
        floatersOrSpotsOrLightFlashes: data.floatersOrSpotsOrLightFlashes,
        otherEyeProblems: data.otherEyeProblems,
        eyeSurgeries: data.eyeSurgeries,
      },
      other: {
        allergies: data.allergies,
        medications: data.medications,
        ...(data.pregnantOrNursing ? { pregnantOrNursing: "yes" } : { pregnantOrNursing: "no" }),
        tobacco: data.tobacco,
        alcohol: data.alcohol,
      },
      marketingConsents: {
        clinicalTrialNotifications: data.clinicalTrialNotifications ? "yes" : "no",
      },
      acuitasSyncFailed: {
        date: data.acuitasSyncFailedAt,
        message: data.acuitasSyncMessage,
      },
    };
    this.setState(payloadForState);
  };

  public getQuestionnaire = async (patientId: string) => {
    try {
      this.setState({ isLoading: true });
      const questionnaireResponse: { data: IQuestionnaire } = await ApiClient.getQuestionnaire(patientId);
      this.setQuestionnaireToState(questionnaireResponse.data);
    } catch (e) {}
    this.setState({ isLoading: false });
  };

  public saveQuestionnaire = async () => {
    try {
      const { id } = this.props.match.params;
      this.setState({ isLoading: true });

      if (!this.checkQuestionnaireValidation()) {
        message.error("The form has not been fully completed");
        this.setState({ isLoading: false });
        return;
      }

      await ApiClient.createQuestionnaire(id, {
        ...this.state.covidScreening,
        ...this.state.currentCondition,
        ...this.state.medicalHistory,
        ...this.state.eyeHealthHistory,
        ...this.state.currentVisionCondition,
        ...this.state.other,
        pregnantOrNursing: this.state.other.pregnantOrNursing === "yes",
        worseningHeadaches: this.state.currentVisionCondition.worseningHeadaches === "yes",
        eyePainOrItchingRedness: this.state.currentVisionCondition.eyePainOrItchingRedness === "yes",
        clinicalTrialNotifications: this.state.marketingConsents.clinicalTrialNotifications === "yes",
      });
      message.success("Questionnaire successfully saved!");

      this.redirectOnSave();
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
    }
    this.setState({ isLoading: false });
  };

  public resyncQuestionnaire = async () => {
    try {
      const { id } = this.props.match.params;
      this.setState({ isLoading: true });

      await ApiClient.syncQuestionnaireToAcuitas(id);
      await this.getQuestionnaire(id);
      message.success("Questionnaire successfully synced!");

    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
    }
    this.setState({ isLoading: false });
  };

  public checkQuestionnaireValidation = () => {
    const {
      // covidScreening,
      currentCondition,
      medicalHistory,
      eyeHealthHistory,
      currentVisionCondition,
      other,
      marketingConsents,
    } = this.state;
    console.log('validation', questionnaireValidator.isCurrentConditionSectionValid(currentCondition),
    questionnaireValidator.isMedicalHistorySectionValid(medicalHistory),
    questionnaireValidator.isEyeHealthHistorySectionValid(eyeHealthHistory),
    questionnaireValidator.isCurrentVisionConditionSectionValid(currentVisionCondition),
    questionnaireValidator.isOtherSectionValid(other),
    questionnaireValidator.isMarketingConsentsSectionValid(marketingConsents));
    return (
      // questionnaireValidator.isCovidScreeningSectionValid(covidScreening) &&
      questionnaireValidator.isCurrentConditionSectionValid(currentCondition) &&
      questionnaireValidator.isMedicalHistorySectionValid(medicalHistory) &&
      questionnaireValidator.isEyeHealthHistorySectionValid(eyeHealthHistory) &&
      questionnaireValidator.isCurrentVisionConditionSectionValid(currentVisionCondition) &&
      questionnaireValidator.isOtherSectionValid(other) &&
      questionnaireValidator.isMarketingConsentsSectionValid(marketingConsents)
    );
  };

  public updateQuestionnaireField = (payload: IUpdateQuestionnaireFieldPayload) => {
    this.setState({
      [payload.section]: { ...this.state[payload.section], [payload.key]: payload.value },
    });
  };

  public render() {
    const {
      isLoading,
      covidScreening,
      currentCondition,
      medicalHistory,
      eyeHealthHistory,
      other,
      currentVisionCondition,
      marketingConsents,
      acuitasSyncFailed,
    } = this.state;

    return (
      <>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          {acuitasSyncFailed.date && (
            <Col xs={24} md={24} lg={18} style={{ marginBottom: 20 }}>
              <Alert
                message="Acuitas Sync Failed"
                description={
                  <div>
                    <div>
                      Questionnaire did not sync correctly with Acuitas. Click on the button below to resync.
                      <div>
                        Last failed sync:{" "}
                        {moment(acuitasSyncFailed.date)
                          .locale("en")
                          .format("MM/DD/YYYY LT")}
                      </div>
                    </div>
                    <div>
                      <Button style={{ marginTop: 10 }} loading={isLoading} onClick={this.resyncQuestionnaire}>Resync Questionnaire</Button>
                    </div>
                  </div>
                }
                type="warning"
                showIcon
              />
            </Col>
          )}

          <Col xs={24} md={24} lg={18}>
            <QuestionnaireStep1Component
              updateQuestionnaireField={this.updateQuestionnaireField}
              covidScreening={covidScreening}
              currentCondition={currentCondition}
            />
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} md={24} lg={18}>
            <QuestionnaireStep2Component
              updateQuestionnaireField={this.updateQuestionnaireField}
              medicalHistory={medicalHistory}
            />
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} md={24} lg={18}>
            <QuestionnaireStep3Component
              updateQuestionnaireField={this.updateQuestionnaireField}
              eyeHealthHistory={eyeHealthHistory}
            />
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} md={24} lg={18}>
            <QuestionnaireStep4Component
              updateQuestionnaireField={this.updateQuestionnaireField}
              other={other}
              marketingConsents={marketingConsents}
              currentVisionCondition={currentVisionCondition}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} lg={18}>
            <Button type="primary" style={{ float: "right" }} loading={isLoading} onClick={this.saveQuestionnaire}>
              Save
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
