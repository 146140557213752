import React from "react";
import moment, { Moment } from "moment";
import { orderBy } from "lodash";
import { RouteComponentProps } from "react-router-dom";
import { message, Button, Form, Input, PageHeader, Card, Row, Col, DatePicker, Select, Alert } from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import { validationPatterns } from "../../../helpers/validation.helper";
import { detectUrl } from "../../../helpers/regex.helper";
import { getVisitStatusesWhereTruckIsRequire, VisitStatuses } from "../../../helpers/visit.helper";
import { AxiosError } from "axios";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  companies: any[]; // FIXME:
  trucks: any[]; // FIXME:
  truckAvailabilities: any[]; // FIXME:
  excludedTrucks: string[];
  excludedDates: string[];
  locations: any[];
  parkingLocationConfig: {
    showAlert: boolean;
    link: string;
  };
  isTruckRequire: boolean;
}

export class CreateVisitComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    companies: [],
    trucks: [],
    locations: [],
    truckAvailabilities: [],
    excludedTrucks: [],
    excludedDates: [],
    parkingLocationConfig: {
      showAlert: false,
      link: "",
    },
    isTruckRequire: false,
  };

  private form = React.createRef<FormInstance>();

  public componentDidMount = async () => {
    await this.getCompanies();
    await this.getTrucks();
    await this.getExcludedDates(moment().format("YYYY-MM"));
    this.fillFormWithPassedParams();
  };

  public fillFormWithPassedParams = () => {
    const params = new URLSearchParams(location.search);

    if (params.has("date")) {
      const date = params.get("date");
      this.form.current!.setFieldsValue({
        date: moment(date),
      });
      this.getTruckAvailabilites(date as string);
    }

    if (params.has("truckId")) {
      const truckId = params.get("truckId");
      this.form.current!.setFieldsValue({
        truckId,
      });
    }
  };

  private async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({ offset: 0, limit: "all" });
    this.setState({ companies: companies.data });
  }

  private async getTrucks() {
    const { data: trucks } = await ApiClient.findTrucks();
    this.setState({ trucks });
  }

  private async getExcludedDates(month: string) {
    try {
      const response = await ApiClient.getExcludedDatesForSpecificMonth(month);
      this.setState({ excludedDates: response.data.dates });
    } catch (e) {}
  }

  private async getExcludedTrucks(date: string) {
    try {
      const response = await ApiClient.getExcludedTrucksForSpecificDate(date);
      this.setState({ excludedTrucks: response.data.truckIds });
    } catch (e) {}
  }

  private async getTruckAvailabilites(date: string) {
    try {
      const { data } = await ApiClient.getTruckAvailabilityForActiveTrucks({ date });
      this.setState({
        truckAvailabilities: data.map((truck: { id: any; isReserved: any }) => ({
          truckId: truck.id,
          isReserved: truck.isReserved,
          date,
        })),
      });
    } catch (error) {
        message.error(`Fetch details of Truck availability has been failed (for date: ${date}).`);
        const errorMessage = (error as AxiosError)?.response?.data?.message || error.message || "Unknown Error";
        console.log(`ERROR: getTruckAvailabilites for date: ${date} - ${errorMessage}`
    }
  }

  private onFinish = async (values: any) => {
    const { locations, isTruckRequire } = this.state;
    this.setState({ isLoading: true });
    try {
      const { data: response } = await ApiClient.createVisit({
        name: values.name,
        status: values.status,
        date: moment(values.date).format("YYYY-MM-DD"),
        locationId: values.locationId,
        timezone: locations.find((x) => x.id === values.locationId)?.timezone,
        parkingLocationDetails: values.parkingLocationDetails,
        appointmentType: values.appointmentType,
        SFVisitId: values.SFVisitId,
        ...(isTruckRequire ? { truckId: values.truckId } : {}),
      });

      message.success("Visit successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push(`/visits/${response.id}/edit`);
    } catch (e) {
      message.error((e as AxiosError)?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onChange = async (values: any) => {
    const { companies } = this.state;

    if (values.date) {
      const date = moment(values.date).format("YYYY-MM-DD");
      await this.getExcludedTrucks(date);

      this.getTruckAvailabilites(date);
      this.form.current?.resetFields(["truckId"]);
    }

    if (values.companyId) {
      const { locations } = companies.find((x) => x.id === values.companyId);

      if (!locations.length) {
        message.error("Chosen company has no locations assigned");
      }

      locations.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));

      this.setState({ locations });
      this.form.current?.resetFields(["locationId"]);
    }
  };

  private getTruckAvailabilityByTruckId = (truckId: string) => {
    return this.state.truckAvailabilities.find((x) => x.truckId === truckId);
  };

  private detectLinkInParkingLocationDetails = (parkingLocationDetails: string = "") => {
    const links = detectUrl(parkingLocationDetails);
    this.setState({
      parkingLocationConfig: { showAlert: !!links.length, link: links.length ? links[0] : "" },
    });
  };

  private onChangeVisitStatus = (status: string) => {
    const isTruckRequire = this.form.current ? getVisitStatusesWhereTruckIsRequire().includes(status) : false;
    this.setState({ isTruckRequire });
    if (!isTruckRequire) {
      this.form.current!.resetFields(["truckId"]);
    }
  };

  private onChangeCompany = (companyId: string) => {
    const { companies } = this.state;
    const company = companies.find((c) => c.id === companyId);
    if (company) {
      this.matchAppointmentStatusToCompanyType(company.type);
    }
  };

  private matchAppointmentStatusToCompanyType = (companyType: string) => {
    if (["ro_public", "ro_private", "life_sciences", "schools"].includes(companyType)) {
      this.form.current!.setFieldsValue({
        appointmentType: companyType,
      });
    }
  };

  public render() {
    const {
      isLoading,
      companies,
      locations,
      trucks,
      truckAvailabilities,
      parkingLocationConfig,
      excludedTrucks,
      excludedDates,
      isTruckRequire,
    } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/visits")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create visit"
        />
        <Row>
          <Col xs={24}>
            <Card title="Visit">
              <Form
                layout="vertical"
                ref={this.form}
                name="create-visit"
                onFinish={this.onFinish}
                onValuesChange={this.onChange}>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="status"
                      label="Status"
                      initialValue="inactive_internal_hold"
                      rules={[{ required: true }]}>
                      <Select placeholder="Select status" onChange={this.onChangeVisitStatus}>
                        {VisitStatuses.map((status) => (
                          <Select.Option key={status.value} value={status.value}>
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="companyId" label="Company" rules={[{ required: true }]}>
                      <Select
                        onChange={this.onChangeCompany}
                        showSearch
                        placeholder="Select company"
                        defaultActiveFirstOption={false}
                        filterOption={(input, option) =>
                          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {orderBy(companies, [(c) => c.name.toLowerCase()], "asc").map((company) => (
                          <Select.Option value={company.id} disabled={company.isDisabled}>
                            {company.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="appointmentType" label="Appointment type" rules={[{ required: true }]}>
                      <Select placeholder="Select appointment type">
                        <Select.Option value="ro_private">RO Private</Select.Option>
                        <Select.Option value="ro_public">RO Public</Select.Option>
                        <Select.Option value="telehealth">Telehealth</Select.Option>
                        <Select.Option value="styling">Styling</Select.Option>
                        <Select.Option value="life_sciences">Life Sciences</Select.Option>
                        <Select.Option value="schools">Schools</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="locationId" label="Location" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        placeholder="Select location"
                        disabled={!locations.length}
                        filterOption={(input: any, option: any) =>
                          option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {locations && locations.length
                          ? locations
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((location) => (
                                <Select.Option key={location.id} value={location.id}>
                                  {location.name} ({location.timezone})
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="SFVisitId"
                      label="SFVisitId"
                      rules={[
                        {
                          pattern: validationPatterns.salesforceVisitId,
                          message: "Invalid Salesforce Visit ID.  Please ensure to input the ID starting with a0D",
                        },
                      ]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                      <DatePicker
                        format="l"
                        disabledDate={(currentDate: Moment) => excludedDates.includes(currentDate.format("YYYY-MM-DD"))}
                        onPanelChange={(date: Moment) => this.getExcludedDates(date.format("YYYY-MM"))}
                      />
                    </Form.Item>
                    <Form.Item name="truckId" label="Clinic" rules={[{ required: isTruckRequire }]}>
                      <Select
                        placeholder="Select clinic"
                        disabled={!(trucks.length && truckAvailabilities.length) || !isTruckRequire}>
                        {orderBy(trucks, ["isDisabled", "name"], "asc").map((truck) => {
                          const truckAvailability = this.getTruckAvailabilityByTruckId(truck.id);

                          const isReserved = truckAvailability ? truckAvailability.isReserved : true;

                          return (
                            <Select.Option
                              disabled={isReserved || truck.isDisabled || excludedTrucks.includes(truck.id)}
                              value={truck.id}>
                              {truck.name}
                              {truck.isDisabled ? " (disabled)" : ""}
                              {isReserved ? " (reserved)" : ""}
                              {excludedTrucks.includes(truck.id) ? " (unavailable)" : ""}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {parkingLocationConfig.showAlert && (
                      <Form.Item>
                        <Alert
                          message="Link detected"
                          description={
                            <>
                              This link will be visible for user in the calendar location field: <br />
                              {parkingLocationConfig.link}
                            </>
                          }
                          type="info"
                          style={{ marginBottom: "20px" }}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="parkingLocationDetails"
                      label="Parking location details (Patient Facing)"
                      rules={[{ required: true }]}>
                      <Input.TextArea
                        placeholder="We will be parked right outside the office."
                        onChange={(e) => {
                          const { value } = e.target;
                          this.detectLinkInParkingLocationDetails(value);
                          this.form.current?.setFieldsValue({ parkingLocationDetails: value });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
