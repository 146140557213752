import React from "react";
import moment from "moment-timezone";
import { Button, Spin, Table } from "antd";
import { IAppointment, IFile } from "./documents.interface";
import { returnExamTypeName } from "../../../../helpers/appointment.helper";
import Column from "antd/lib/table/Column";

interface IProps {
  isDocumentLoading: boolean;
  previousAppointments: IAppointment[];
  files: { [key: string]: IFile[] };
  downloadDocument: (appointmentId: string, internalId: string) => void;
}

const PreviousAppointments = (props: IProps) => {
  const { isDocumentLoading, files, previousAppointments, downloadDocument } = props;

  const dataSource = previousAppointments.map((appointment: IAppointment, index) => {
    const { visit } = appointment.timeslot;
    const invoices = files[appointment.id] ? files[appointment.id].filter((f: IFile) => f.type === "invoice") : [];
    const prescriptions = files[appointment.id]
      ? files[appointment.id].filter((f: IFile) => f.type === "prescription")
      : [];

    return {
      key: index,
      date: moment(appointment.timeslot.startDate).tz(visit.timezone).format("LL"),
      type: returnExamTypeName(appointment.type),
      invoices: {
        appointmentId: appointment.id,
        data: invoices,
      },
      prescriptions: {
        appointmentId: appointment.id,
        data: prescriptions,
      },
    };
  });

  return (
    <Table bordered dataSource={dataSource} pagination={false}>
      <Column title="Date" dataIndex="date" key="date" width="25%" />
      <Column title="Type" dataIndex="type" key="type" width="25%" />
      <Column
        title="Prescriptions"
        dataIndex="prescriptions"
        key="prescriptions"
        width="25%"
        render={(prescriptions) => (
          <>
            {isDocumentLoading && <Spin />}
            {prescriptions.data.map((prescription, i) => (
              <Button
                type="primary"
                key={i}
                style={{ marginBottom: 5 }}
                block
                size="small"
                onClick={() => downloadDocument(prescriptions.appointmentId, prescription.internalId)}
                target="_blank">
                {prescription.documentName}
              </Button>
            ))}
          </>
        )}
      />
      <Column
        title="Invoices"
        dataIndex="invoices"
        key="invoices"
        width="25%"
        render={(invoices) => (
          <>
            {isDocumentLoading && <Spin />}
            {invoices.data.map((invoice, i) => (
              <Button
                type="primary"
                key={i}
                style={{ marginBottom: 5 }}
                block
                size="small"
                onClick={() => downloadDocument(invoices.appointmentId, invoice.internalId)}
                target="_blank">
                {invoice.documentName}
              </Button>
            ))}
          </>
        )}
      />
    </Table>
  );
};

export default PreviousAppointments;
