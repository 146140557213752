import React from "react";
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import { Col, Row, Button, message, Space, Descriptions } from "antd";
import { CSVReader } from "react-papaparse";
import { UploadOutlined } from "@ant-design/icons/lib";
import { CSVLink } from "react-csv";
import StepsBar from "./steps-bar.component";
import styled from "styled-components";
import { isUUID } from "../../../helpers/validation.helper";
import InteractiveTable from "./interactive-table.component";
import { ApiClient } from "../../../api-client/api.client";
import StatisticsBar from "./statistics-bar.component";
import { Parser } from "../helpers/parser";
import { Validator } from "../helpers/validator";

interface Data {
  visitId: string;
  date: string;
  time: string;
  timeZone: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  phoneNumber: string;
  dob: string;
  hippa: boolean;
  city: string;
  street: string;
  state: string;
  zipCode: string;
  wearGlasses: boolean;
  wearContactLenses: boolean;
  examType: string;
  medicalInsuranceProviderId: string;
  medicalSubscriberId: string;
  visionInsuranceProviderId: string;
  visionSubscriberId: string;
  errors: string[];

  //lisci patients
  caregiver?: string;
  caregiverRelationship?: string;
  qualified?: boolean;
  consent?: boolean;
  language?: string;
  smsSubscription: boolean;
}

interface IProps extends RouteComponentProps {}

interface IState {
  step: number;
  bar: {
    current: number;
    status: "wait" | "process" | "finish" | "error";
    steps: {
      title: string;
      description: string;
    }[];
  };
  data: Data[];
  visits: any[];
  insurances: any[];
  dataSuccessfullySent: Data[];
  dataNotSent: Data[];
}

export class UploadComponent extends React.Component<IProps, IState> {
  private uploadButtonRef = React.createRef<CSVReader>();

  public state: IState = {
    step: 0,
    bar: {
      current: 0,
      status: "wait",
      steps: [
        {
          title: "Upload file",
          description: "Upload  CSV file",
        },
        {
          title: "Data Validation",
          description: "Check errors",
        },
        {
          title: "In Process",
          description: "Uploading data to the system",
        },
        {
          title: "Finished",
          description: "Check results",
        },
      ],
    },
    data: [],
    visits: [],
    insurances: [],
    dataSuccessfullySent: [],
    dataNotSent: [],
  };

  // FETCHING METHODS
  private getVisits = async (visitsIds: string[]) => {
    const visits = [];
    for (const visitId of visitsIds) {
      const { data: visit } = await ApiClient.getVisit(visitId);
      visits.push(visit);
    }
    this.setState({ visits });
  };

  private getInsurances = async () => {
    const { data: insurances } = await ApiClient.findInsurances();
    this.setState({ insurances });
  };

  // FILE PROCESSING METHODS
  private handleOpenDialog = (e) => {
    if (this.uploadButtonRef.current) {
      this.uploadButtonRef.current.open(e);
    }
  };

  private isHeaderCorrect = (data: any[]) => {
    const even = (element: string) =>
      [
        "firstName",
        "lastName",
        "visitId",
        "date",
        "time",
        "timezone",
        "phoneNumber",
        "email",
        "gender",
        "dob",
        "street",
        "city",
        "state",
        "zipCode",
        "medicalSubscriberId",
      ].includes(element);
    if (data.length && data[0].data.length && data[0].data.some(even)) return true;
    return false;
  };

  private handleOnFileLoad = async (data: any[]) => {
    if (!data) {
      message.error("Critical error");
      return;
    }

    if (!data.length) {
      message.error("Empty file");
      return;
    }

    if (!this.isHeaderCorrect(data)) {
      message.error("Incorrect names of columns");
      return;
    }

    const header = data[0].data;
    const results = [];
    for (const row of data.slice(1)) {
      const parsedRow = {};
      if (row.errors && row.errors.length) continue;
      for (let i = 0; i < row.data.length; i++) {
        if (row.data[i] && row.data[i].length) {
          // @ts-ignore
          parsedRow[header[i]] = row.data[i];
        } else {
          // @ts-ignore
          parsedRow[header[i]] = undefined;
        }
      }
      results.push(parsedRow);
    }

    const visitsIds: string[] = [];
    results.forEach((r: any) => {
      if (r.visitId && isUUID(r.visitId, "4")) visitsIds.push(r.visitId);
    });

    if (visitsIds.length) {
      try {
        await this.getVisits([...new Set(visitsIds)]);
      } catch (e) {}
      try {
        await this.getInsurances();
      } catch (e) {
        message.error("Insurances cannot be collected");
      }
    }
    this.addData(results);
  };

  private handleOnFileError(err, file, inputElem, reason) {
    message.error("An error occurred with the file");
  }

  private handleOnRemoveFile = (data) => {};

  // DATA PROCESSING METHODS
  private addData(data: any[]) {
    const results = [];
    for (const [index, element] of data.entries()) {
      const row = this.prepareRow(element);
      const errors = this.validateRow(row);
      results.push({
        key: index,
        ...row,
        errors,
      });
    }
    this.setState({ data: results, bar: { ...this.state.bar, current: 1, status: "process" }, step: 1 });
  }

  private validateRow(row: any): string[] {
    const { visits, insurances } = this.state;
    const visit = visits.find((v) => v.id === row.visitId);
    const timeslot =
      visit && row.timeZone && row.time
        ? visit.timeslots.find((t) => moment(t.startDate).tz(row.timeZone).format("LT") === row.time)
        : undefined;

    const errors = [];

    for (const validator of [
      Validator.isDataValid(
        {
          // basic info
          firstName: row.firstName,
          lastName: row.lastName,
          email: row.email,
          gender: row.gender,
          dob: row.dob,
          phoneNumber: row.phoneNumber,
          wearGlasses: row.wearGlasses,
          wearContactLenses: row.wearContactLenses,
          // address
          city: row.city,
          street: row.street,
          state: row.state,
          zipCode: row.zipCode,
          // life sciences
          caregiver: row.caregiver,
          caregiverRelationship: row.caregiverRelationship,
          qualified: row.qualified,
          consent: row.consent,
          language: row.language,
          // visit
          visitId: row.visitId,
          examType: row.examType,
          timeZone: row.timeZone,
          hippa: row.hippa,
          time: row.time,
          date: row.date,
          smsSubscription: row.smsSubscription,
        },
        visit,
        timeslot
      ),
      Validator.isMedicalInsuranceProviderIdValid(row.medicalInsuranceProviderId, insurances),
      Validator.isVisionInsuranceProviderIdValid(row.visionInsuranceProviderId, insurances),
    ]) {
      if (!validator.isValid) errors.push(...validator.errors);
    }
    return errors;
  }

  private prepareRow(row: any) {
    return {
      visitId: Parser.parseVisitId(row),
      date: Parser.parseDate(row),
      time: Parser.parseTime(row),
      timeZone: Parser.parseTimeZone(row),
      firstName: Parser.parseFirstName(row),
      lastName: Parser.parseLastName(row),
      email: Parser.parseEmail(row),
      gender: Parser.parseGender(row),
      phoneNumber: Parser.parsePhoneNumber(row),
      dob: Parser.parseDob(row),
      hippa: Parser.parseHippa(row),
      city: Parser.parseCity(row),
      street: Parser.parseStreet(row),
      state: Parser.parseState(row),
      zipCode: Parser.parseZipCode(row),
      wearGlasses: Parser.parseWearGlasses(row),
      wearContactLenses: Parser.parseWearContactLenses(row),
      examType: Parser.parseExamType(row),
      medicalInsuranceProviderId: Parser.parseMedicalInsuranceProviderId(row),
      medicalSubscriberId: Parser.parseMedicalSubscriberId(row),
      visionInsuranceProviderId: Parser.parseVisionInsuranceProviderId(row),
      visionSubscriberId: Parser.parseVisionSubscriberId(row),
      caregiver: Parser.parseCaregiver(row),
      caregiverRelationship: Parser.parseCaregiverRelationship(row),
      qualified: Parser.parseQualified(row),
      consent: Parser.parseConsent(row),
      language: Parser.parseLanguage(row),
      smsSubscription: Parser.parseSmsSubscription(row),
    };
  }

  private handleOnCleanUp = () => {
    this.setState({
      step: 0,
      bar: {
        current: 0,
        status: "wait",
        steps: [
          {
            title: "Upload file",
            description: "Upload  CSV file",
          },

          {
            title: "Data Validation",
            description: "Check errors",
          },
          {
            title: "In Process",
            description: "Uploading data to the system",
          },
          {
            title: "Finished",
            description: "Check results",
          },
        ],
      },
      data: [],
      visits: [],
      insurances: [],
    });
  };

  private handleOnUploadNow = async () => {
    const { data, visits } = this.state;
    this.setState({ bar: { ...this.state.bar, current: 2, status: "process" }, step: 2 });

    const dataSuccessfullySent = [];
    const dataNotSent = [];
    const dataToCorrect = [];
    for (const row of data) {
      if (row.errors && !row.errors.length) {
        const visit = visits.find((v) => v.id === row.visitId);
        const timeslot = visit.timeslots.find((t) => moment(t.startDate).tz(row.timeZone).format("LT") === row.time);
        if (visit && timeslot) {
          try {
            const body = {
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
              gender: row.gender || "not_specified",
              birth: moment(row.dob, "MM/DD/YYYY").format("YYYY-MM-DD"),
              phoneNumber: row.phoneNumber || "12000000000",
              ...(row.city
                ? {
                    address: {
                      type: "billing",
                      city: row.city,
                      street: row.street,
                      zipCode: row.zipCode,
                      state: row.state,
                    },
                  }
                : {}),

              ...(visit.appointmentType === "life_sciences"
                ? {
                    caregiver: row.caregiver,
                    caregiverRelationship: row.caregiverRelationship,
                    qualified: row.qualified,
                    consent: row.consent,
                    language: row.language,
                  }
                : {}),
            };
            const patient = await ApiClient.createPatient(body);

            const { data: response } = await ApiClient.createAppointment(visit.id, {
              timeslotId: timeslot.id,
              patientId: patient.data.id,
              type: row.examType || "exam",
              survey: {
                isEyeExam: false,
                isContactRenewal: false,
                isFittedForContactLenses: false,
                wearGlasses: row.wearGlasses,
                wearContactLenses: row.wearContactLenses,
                applyInsuranceBenefitsForGlassPurchase: false,
                covidPositive: false,
                covidSymptoms: false,
                covidAntibodies: false,
                insuranceProvidedByCompany: false,
                insuranceReminder: true,
                marketingOptIn: false,
                hipaaAcknowledgement: row.hippa,
                noInsuranceCoverage: false,
                lastExam: undefined,
                primaryInsurance: {
                  insuranceId: row.medicalInsuranceProviderId,
                  secondaryInsured: false,
                  provider: undefined,
                  subscriberId: row.medicalSubscriberId,
                  extPolicyHolderDOB: undefined,
                  extPolicyHolderName: undefined,
                },
                secondaryInsurance: {
                  insuranceId: row.visionInsuranceProviderId,
                  secondaryInsured: false,
                  provider: undefined,
                  subscriberId: row.visionSubscriberId,
                  extPolicyHolderDOB: undefined,
                  extPolicyHolderName: undefined,
                },
              },
              smsSubscription: row.smsSubscription,
            });
            dataSuccessfullySent.push(row);
          } catch (e) {
            dataNotSent.push({
              ...row,
              errors: [e.response?.data?.message || e.message || "An error occurred while sending data"],
            });
          }
        }
      } else {
        dataToCorrect.push(row);
      }
    }

    this.setState({
      dataSuccessfullySent,
      dataNotSent,
      data: dataToCorrect,
      bar: { ...this.state.bar, current: 3, status: "process" },
      step: 3,
    });
  };

  private generateExampleDataForRoutineOptometryPatients() {
    return [
      {
        visitId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        date: "12/08/2021",
        time: "10:00 AM",
        timezone: "America/New_York",
        firstName: "John",
        lastName: "Wick",
        email: "john.wick@2020onsite.com",
        gender: "male",
        phoneNumber: "12085240099",
        dob: "4/18/1993",
        hippa: "true",
        city: "Somerville",
        street: "19 Calvin Street",
        state: "MA",
        zipCode: "02143",
        wearGlasses: "false",
        wearContactLenses: "false",
        examType: "exam",
        medicalInsuranceProviderId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        medicalSubscriberId: "12313141",
        visionInsuranceProviderId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        visionSubscriberId: "123123122313",
        smsSubscription: "true",
      },
    ];
  }
  private generateExampleDataForLifeSciencesPatients() {
    return [
      {
        visitId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        date: "12/08/2021",
        time: "10:00 AM",
        timezone: "America/New_York",
        firstName: "John",
        lastName: "Wick",
        email: "john.wick@2020onsite.com",
        gender: "male",
        phoneNumber: "12085240099",
        dob: "4/18/1993",
        hippa: "true",
        city: "Somerville",
        street: "19 Calvin Street",
        state: "MA",
        zipCode: "02143",
        wearGlasses: "false",
        wearContactLenses: "false",
        examType: "exam",
        medicalInsuranceProviderId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        medicalSubscriberId: "12313141",
        visionInsuranceProviderId: "44849203-53e2-4327-b8e4-5c4673395d8b",
        visionSubscriberId: "120000000000",
        caregiver: "James",
        caregiverRelationship: "parent",
        qualified: "false",
        consent: "true",
        language: "english",
        smsSubscription: "true",
      },
    ];
  }

  render(): React.ReactNode {
    const { bar, step, data, dataNotSent, dataSuccessfullySent } = this.state;

    const numberOfRecordsReadyToSend = data.reduce((total, d) => (d.errors && !d.errors.length ? total + 1 : total), 0);
    const numberOfRecordsHaveErrors = data.reduce((total, d) => (d.errors && d.errors.length ? total + 1 : total), 0);

    const numberOfSuccessfullySent = dataSuccessfullySent.length || 0;
    const numberOfDataNotSent = dataNotSent.length || 0;
    const numberOfDataToCorrect = data.reduce((total, d) => (d.errors && d.errors.length ? total + 1 : total), 0) || 0;

    return (
      <Row style={{ margin: "24px 0" }}>
        <Col span={24} style={{ margin: "12px 0" }}>
          <StepsBar current={bar.current} status={bar.status} steps={bar.steps} />
        </Col>
        {step === 0 && (
          <Col span={24} style={{ margin: "12px 0" }}>
            <CenterWrapper>
              <CSVReader
                ref={this.uploadButtonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnFileError}
                noClick
                noDrag
                progressBarColor="#1890ff">
                {({ file }) => (
                  <>
                    <SpaceWrapper direction="vertical" size="middle">
                      <div>
                        <h2>Pick action</h2>
                      </div>
                      <Button icon={<UploadOutlined />} type="primary" onClick={this.handleOpenDialog}>
                        Click to upload CSV
                      </Button>
                      {file && <b>{file.name}</b>}
                      {!file && (
                        <>
                          <CSVLink
                            data={this.generateExampleDataForRoutineOptometryPatients()}
                            filename="example_routine_optometry_patients.csv"
                            target="_blank"
                            className="ant-btn">
                            Download example CSV file for routine optometry patients
                          </CSVLink>
                          <CSVLink
                            data={this.generateExampleDataForLifeSciencesPatients()}
                            filename="example_life_sciences_patients.csv"
                            target="_blank"
                            className="ant-btn">
                            Download example CSV file for life sciences patients
                          </CSVLink>
                        </>
                      )}
                    </SpaceWrapper>
                  </>
                )}
              </CSVReader>
            </CenterWrapper>
          </Col>
        )}
        {step === 1 && (
          <>
            <Col span={24} style={{ margin: "12px 0" }}>
              <StatisticsBar
                numberOfRecordsReadyToSend={numberOfRecordsReadyToSend}
                numberOfRecordsHaveErrors={numberOfRecordsHaveErrors}
                onCleanUp={this.handleOnCleanUp}
                onUploadFile={this.handleOnUploadNow}
              />
            </Col>
            <Col span={24} style={{ margin: "12px 0" }}>
              <InteractiveTable data={data} />
            </Col>
          </>
        )}
        {step === 2 && (
          <Col style={{ margin: "24px 0" }}>
            <CenterWrapper>
              <h3>Please wait, uploading...</h3>
            </CenterWrapper>
          </Col>
        )}
        {step === 3 && (
          <Col style={{ margin: "24px 0" }}>
            <Descriptions title="Results of data sent:" bordered>
              <Descriptions.Item label="Number of correctly sent rows" span={3}>
                {numberOfSuccessfullySent}
              </Descriptions.Item>
              <Descriptions.Item label="Number of rows that has not been sent" span={3}>
                {numberOfDataNotSent}{" "}
                {numberOfDataNotSent > 0 && (
                  <CSVLink data={dataNotSent} filename={"data_not_sent.csv"} target="_blank">
                    Download data
                  </CSVLink>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Number of rows to correct" span={3}>
                {numberOfDataToCorrect}{" "}
                {numberOfDataToCorrect > 0 && (
                  <CSVLink data={data} filename={"rows_to_correct.csv"} target="_blank">
                    Download data
                  </CSVLink>
                )}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <Button onClick={this.handleOnCleanUp}>Back to first step</Button>
          </Col>
        )}
      </Row>
    );
  }
}

const CenterWrapper = styled.div`
  height: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceWrapper = styled(Space)`
  display: flex;
  align-items: center;
`;
