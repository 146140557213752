import { message } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { ApiClient } from "../../../api-client/api.client";
import { Visit } from "./edit-timeslots.types";

type RescheduleAppointmentPayload = {
  appointmentId: string;
  timeslotId: string;
  targetVisitId: string;
  targetTimeslotId: string;
};

export const useTimeslots = (visitId: string) => {
  const parseError = (e: AxiosError, fallbackMessage?: string) =>
    typeof e.response?.data?.message === "string" ? e.response?.data?.message : fallbackMessage;

  const { data: visit, refetch: getVisit, isLoading: isVisitLoading } = useQuery({
    queryKey: ["getVisit"],
    queryFn: async () => {
      const { data: visit } = await ApiClient.getVisit(visitId);
      return visit as Visit;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: () => message.error("Cannot pull the visit"),
  });

  const addSingleTimeslot = useMutation(
    (payload: { startDate: Date; endDate: Date }) => ApiClient.addSingleTimeslot(visitId, payload),
    {
      onError: (e: AxiosError) => {
        message.error(parseError(e, "Cannot add timeslot due to internal server error."));
      },
      onSettled: () => {
        getVisit();
      },
    }
  );

  const removeSingleTimeslot = useMutation(
    (timeslotId: string) => ApiClient.removeSingleTimeslot(visitId, timeslotId),
    {
      onError: (e: AxiosError) => {
        message.error(parseError(e, "Cannot remove timeslot due to internal server error."));
      },
      onSettled: () => {
        getVisit();
      },
    }
  );

  const activateSingleTimeslot = useMutation(
    (timeslotId: string) => ApiClient.activateSingleTimeslot(visitId, timeslotId),
    {
      onError: (e: AxiosError) => {
        message.error(parseError(e, "Cannot activate timeslot due to internal server error."));
      },
      onSettled: () => {
        getVisit();
      },
    }
  );

  const deactivateSingleTimeslot = useMutation(
    (timeslotId: string) => ApiClient.deactivateSingleTimeslot(visitId, timeslotId),
    {
      onError: (e: AxiosError) => {
        message.error(parseError(e, "Cannot deactivate timeslot due to internal server error."));
      },
      onSettled: () => {
        getVisit();
      },
    }
  );

  const cancelAppointment = useMutation((appointmentId: string) => ApiClient.cancelAppointment(visitId, appointmentId));

  const rescheduleAppointment = useMutation(({ appointmentId, ...rest }: RescheduleAppointmentPayload) =>
    ApiClient.rescheduleAppointment(visitId, appointmentId, rest)
  );

  // TODO: somehow move this to useQuery
  const { data: patient, mutate: getPatient } = useMutation(async (patientId: string) => {
    const { data } = await ApiClient.getPatient(patientId);
    return data;
  });

  return {
    parseError,
    visit,
    getVisit,
    isVisitLoading,
    addSingleTimeslot,
    removeSingleTimeslot,
    activateSingleTimeslot,
    deactivateSingleTimeslot,
    cancelAppointment,
    rescheduleAppointment,
    patient,
    getPatient,
  };
};
