import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Collapse, message, Spin } from "antd";
import moment from "moment";
import { orderBy } from "lodash";
import styled from "styled-components";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import { BookingBoardFilters } from "./filters.component";
import { ITruck } from "../../../interfaces/truck.interface";
import { BookingBoardCalendar } from "./calendar.component";
import {
  IBookingBoardMonthCalendarVisit,
  IBookingBoardMonthCalendarVisitWithoutRequireTruck,
  IVisitStaff,
} from "../../../interfaces/visit.interface";
import { VisitStatuses } from "../../../helpers/visit.helper";
import { IMonthlyMetrics } from "../booking-board.interfaces";
import { BookingBoardVisitsMetrics } from "./visits-metrics.component";

interface IProps extends RouteComponentProps {
}

interface IState {
  isLoading: {
    visits: boolean;
    trucks: boolean;
    employees: boolean;
    visitsWithoutRequireTruck: boolean;
  };
  filters: {
    selectedMonth: string;
    visitTypes: string[];
    visitStatuses: string[];
    trucks: string[];
  };
  isEditExcludedDatesModeOn: boolean;
  isEditExcludedTrucksModeOn: boolean;
  isMarkClinicUnavailableModeOn: boolean;
  excludedDates: string[];
  excludedTrucks: {
    [key: string]: string[];
  };
  trucks: ITruck[];
  visits: IBookingBoardMonthCalendarVisit[];
  visitsWithoutRequireTruck: IBookingBoardMonthCalendarVisitWithoutRequireTruck[];
  employees: IVisitStaff[];
  monthlyMetrics: IMonthlyMetrics;
}

export class BookingBoardComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  private initialMetrics: IMonthlyMetrics = {
    availableSlots: 0,
    bookedVisits: 0,
    completeVisits: 0,
    holdVisits: 0,
    openSlots: 0,
    utilization: 0,
  };

  public state: IState = {
    isLoading: {
      visits: false,
      trucks: false,
      employees: false,
      visitsWithoutRequireTruck: false,
    },
    filters: {
      selectedMonth: moment().format("YYYY-MM"),
      visitTypes: ["ro_private", "ro_public", "telehealth", "styling", "life_sciences", "schools"],
      visitStatuses: VisitStatuses.map((status) => status.value),
      trucks: [],
    },
    isEditExcludedDatesModeOn: false,
    isEditExcludedTrucksModeOn: false,
    isMarkClinicUnavailableModeOn: false,
    monthlyMetrics: this.initialMetrics,
    excludedDates: [],
    excludedTrucks: {},
    trucks: [],
    visits: [],
    employees: [],
  };

  public componentDidMount(): void {
    this.getExcludedDates();
    this.getExcludedTrucks();
    this.getTrucks();
    this.getEmployees();
  }

  public async componentDidUpdate(_: IProps, prevState: IState): Promise<void> {
    if (!prevState.trucks.length && this.state.trucks.length) {
      await this.getVisits();
      await this.getVisitsWithoutRequireTruck();
    }
  }

  public getVisits = async () => {
    const { isLoading, filters } = this.state;
    try {
      this.setState({ isLoading: { ...isLoading, visits: true } });
      const searchForm = this.searchForm.current!.getFieldsValue();
      const payload = {
        month: filters.selectedMonth,
        statuses: searchForm.visitStatuses.length ? searchForm.visitStatuses : undefined,
        appointmentTypes: searchForm.visitTypes.length ? searchForm.visitTypes : undefined,
        trucks: searchForm.trucks.length ? searchForm.trucks : filters.trucks,
        employees: searchForm.employees?.length ? searchForm.employees : undefined,
      };

      const sumObjectsByKey = (...objs) => {
        return objs.reduce((a, b) => {
          for (let k in b) {
            if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
          }
          return a;
        }, {});
      };

      const visits = [];
      const { data: visits1 } = await ApiClient.getBookingBoardVisitsForSpecificMonth(payload);
      const { data: metrics1 } = await ApiClient.getBookingBoardVisitsMetricsForSpecificMonth(payload);

      payload.month = moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM");
      const { data: visits2 } = await ApiClient.getBookingBoardVisitsForSpecificMonth(payload);
      const { data: metrics2 } = await ApiClient.getBookingBoardVisitsMetricsForSpecificMonth(payload);

      visits.push(...visits1, ...visits2);

      this.setState({
        visits,
        monthlyMetrics: sumObjectsByKey(this.initialMetrics, metrics1, metrics2),
        isLoading: { ...isLoading, visits: false },
      });
    } catch (e) {
      this.setState({ isLoading: { ...isLoading, visits: false } });
      message.error("Cannot fetch visits");
    }
  };

  public getVisitsWithoutRequireTruck = async () => {
    const { isLoading, filters } = this.state;
    try {
      this.setState({ isLoading: { ...isLoading, visitsWithoutRequireTruck: true } });
      const searchForm = this.searchForm.current!.getFieldsValue();

      const visitsWithoutRequireTruck = [];
      const payload = {
        month: filters.selectedMonth,
        statuses: searchForm.visitStatuses.length ? searchForm.visitStatuses : undefined,
        appointmentTypes: searchForm.visitTypes.length ? searchForm.visitTypes : undefined,
        employees: searchForm.employees?.length ? searchForm.employees : undefined,
      };

      const {
        data: visitsWithoutRequireTruck1,
      } = await ApiClient.getBookingBoardVisitsWithoutRequireTruckMetricsForSpecificMonth(payload);

      payload.month = moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM");

      const {
        data: visitsWithoutRequireTruck2,
      } = await ApiClient.getBookingBoardVisitsWithoutRequireTruckMetricsForSpecificMonth(payload);

      visitsWithoutRequireTruck.push(...visitsWithoutRequireTruck1, ...visitsWithoutRequireTruck2);
      this.setState({
        visitsWithoutRequireTruck,
        isLoading: { ...isLoading, visitsWithoutRequireTruck: false },
      });
    } catch (e) {
      this.setState({ isLoading: { ...isLoading, visitsWithoutRequireTruck: false } });
      message.error("Cannot fetch visits without require truck");
    }
  };

  public getVisitsWithoutRequireTruckInBackground = async () => {
    const { filters } = this.state;
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();

      const payload = {
        month: filters.selectedMonth,
        statuses: searchForm.visitStatuses.length ? searchForm.visitStatuses : undefined,
        appointmentTypes: searchForm.visitTypes.length ? searchForm.visitTypes : undefined,
      };

      const {
        data: visitsWithoutRequireTruckCurrentMonth,
      } = await ApiClient.getBookingBoardVisitsWithoutRequireTruckMetricsForSpecificMonth(payload);

      const {
        data: visitsWithoutRequireTruckNextMonth,
      } = await ApiClient.getBookingBoardVisitsWithoutRequireTruckMetricsForSpecificMonth({
        ...payload,
        month: moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM"),
      });

      this.setState({
        visitsWithoutRequireTruck: [...visitsWithoutRequireTruckCurrentMonth, ...visitsWithoutRequireTruckNextMonth],
      });
    } catch (e) {
    }
  };

  public getVisitsInBackground = async () => {
    const { filters } = this.state;
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();

      const payload = {
        month: filters.selectedMonth,
        statuses: searchForm.visitStatuses.length ? searchForm.visitStatuses : undefined,
        appointmentTypes: searchForm.visitTypes.length ? searchForm.visitTypes : undefined,
        trucks: searchForm.trucks.length ? searchForm.trucks : filters.trucks,
      };

      const nextMonthPayload = {
        ...payload,
        month: moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM"),
      };

      const { data: visits } = await ApiClient.getBookingBoardVisitsForSpecificMonth(payload);
      const { data: nextMonthVisits } = await ApiClient.getBookingBoardVisitsForSpecificMonth(nextMonthPayload);
      const { data: metrics } = await ApiClient.getBookingBoardVisitsMetricsForSpecificMonth(payload);
      const { data: nextMonthMetrics } = await ApiClient.getBookingBoardVisitsMetricsForSpecificMonth(nextMonthPayload);

      const sumMetrics = (...metrics: IMonthlyMetrics[]): IMonthlyMetrics => {
        return metrics.reduce((summary, currentMetric) => {
          const tmpSummary = { ...summary };

          Object.keys(currentMetric).forEach(key => {
            tmpSummary[key] = summary[key] + currentMetric[key];
          });

          return tmpSummary;
        }, this.initialMetrics);
      };


      this.setState({
        visits: [...visits, ...nextMonthVisits],
        monthlyMetrics: sumMetrics(metrics, nextMonthMetrics),
      });
    } catch (e) {
    }
  };

  public getTrucks = async () => {
    const { isLoading, filters } = this.state;
    try {
      this.setState({ isLoading: { ...isLoading, trucks: true } });
      const { data } = await ApiClient.findTrucks();
      const trucks = orderBy(data, "name", "asc").filter((t: ITruck) => !t.isDisabled);
      const truckIds = trucks.map((t: ITruck) => t.id);

      this.setState({
        trucks,
        isLoading: { ...isLoading, trucks: false },
        filters: { ...filters, trucks: truckIds },
      });
    } catch (e) {
      this.setState({ isLoading: { ...isLoading, trucks: false } });
      message.error("Cannot fetch trucks");
    }
  };

  public getExcludedDates = async () => {
    const { filters } = this.state;
    try {
      const selectedAdditionalMonth = moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM");
      const excludedDates = [];
      const { data: response1 } = await ApiClient.getExcludedDatesForSpecificMonth(filters.selectedMonth);
      const { data: response2 } = await ApiClient.getExcludedDatesForSpecificMonth(selectedAdditionalMonth);
      excludedDates.push(...response1.dates, ...response2.dates);
      this.setState({
        excludedDates,
      });
    } catch (e) {
      message.error("Cannot fetch excluded dates");
    }
  };

  public getExcludedTrucks = async () => {
    const { filters } = this.state;
    try {
      const selectedAdditionalMonth = moment(filters.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM");
      const { data: response1 } = await ApiClient.getExcludedTrucksForSpecificMonth(filters.selectedMonth);
      const { data: response2 } = await ApiClient.getExcludedTrucksForSpecificMonth(selectedAdditionalMonth);
      this.setState({
        excludedTrucks: {
          ...response1,
          ...response2,
        },
      });
    } catch (e) {
      message.error("Cannot fetch excluded trucks");
    }
  };

  public saveExcludedDates = async () => {
    const { filters, excludedDates } = this.state;
    try {
      const { data: response } = await ApiClient.updateExcludedDatesForSpecificMonth(
        filters.selectedMonth,
        excludedDates,
      );

      await this.getVisits();
      await this.getVisitsWithoutRequireTruck();
      this.setState({
        excludedDates: response.dates,
        isEditExcludedDatesModeOn: false,
      });
    } catch (e) {
      message.error("Cannot save excluded dates");
    }
  };

  public updateExcludedDates = async (date: string) => {
    const { excludedDates } = this.state;
    if (excludedDates.includes(date)) {
      this.setState({
        excludedDates: [...excludedDates.filter((d) => d !== date)],
      });
    } else {
      this.setState({
        excludedDates: [...excludedDates, date],
      });
    }
  };

  public updateExcludedTrucks = async (date: string, truckId: string) => {
    const { excludedTrucks } = this.state;
    const excludedTrucksThisDay = this.state.excludedTrucks[date] || [];

    if (excludedTrucksThisDay.includes(truckId)) {
      this.setState({
        excludedTrucks: {
          ...excludedTrucks,
          [date]: [...excludedTrucksThisDay.filter((t) => t !== truckId)],
        },
      });
    } else {
      this.setState({
        excludedTrucks: {
          ...excludedTrucks,
          [date]: [...excludedTrucksThisDay, truckId],
        },
      });
    }
  };

  public saveExcludedTrucks = async () => {
    const { excludedTrucks } = this.state;
    try {
      for (const date of Object.keys(excludedTrucks)) {
        const excludedTrucksThisDay = excludedTrucks[date] || [];
        await ApiClient.updateExcludedTrucks(date, excludedTrucksThisDay);
      }

      await this.getExcludedTrucks();
      await this.getVisits();
      await this.getVisitsWithoutRequireTruck();

      this.setState({
        isEditExcludedTrucksModeOn: false,
      });
    } catch (e) {
      message.error("Cannot save unavailable trucks");
    }
  };

  public onMonthChange = (month: string) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedMonth: month,
        },
      },
      async () => {
        await this.onSearchFormFinish();
      },
    );
  };

  public onSearchFormFinish = async () => {
    await this.getExcludedDates();
    await this.getVisits();
    await this.getVisitsWithoutRequireTruck();
  };

  public onFormReset = () => {
    const filters = {
      visitTypes: [],
      visitStatuses: [],
      trucks: [],
      employees: [],
    };
    this.searchForm.current!.setFieldsValue(filters);
  };

  public toggleEditExcludedDatesMode = async (value: boolean) => {
    this.setState({ isEditExcludedDatesModeOn: value });

    if (!value) {
      await this.getExcludedDates();
    }
  };

  public toggleEditExcludedTrucksMode = async (value: boolean) => {
    this.setState({ isEditExcludedTrucksModeOn: value });

    if (!value) {
      await this.getExcludedTrucks();
    }
  };

  public toggleMarkClinicUnavailableMode = (value: boolean) => {
    this.setState({ isMarkClinicUnavailableModeOn: value });
  };

  public getEmployees = async () => {
    const { isLoading } = this.state;
    try {
      this.setState({ isLoading: { ...isLoading, employees: true } });
      const { data } = await ApiClient.findAccountsByPositions({ positions: ["OD", "ASM", "SM", "CS"] });
      const employees = orderBy(data, "lastName", "asc");

      this.setState({
        employees,
        isLoading: { ...isLoading, employees: false },
      });
    } catch (e) {
      this.setState({ isLoading: { ...isLoading, employees: false } });
      message.error("Cannot fetch employees");
    }
  };

  public render() {
    const {
      filters,
      trucks,
      employees,
      visits,
      excludedDates,
      excludedTrucks,
      isEditExcludedDatesModeOn,
      isEditExcludedTrucksModeOn,
      isMarkClinicUnavailableModeOn,
      monthlyMetrics,
      visitsWithoutRequireTruck,
    } = this.state;
    const isLoading = Object.values(this.state.isLoading).some((property) => property);
    const selectedTrucks = this.searchForm.current?.getFieldValue("trucks");

    return (
      <Wrapper>
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Filters" key="1" style={{ padding: 0 }}>
            <BookingBoardFilters
              searchForm={this.searchForm}
              isLoading={isLoading}
              filters={filters}
              trucks={trucks}
              employees={employees}
              onMonthChange={this.onMonthChange}
              onSearchFormFinish={this.onSearchFormFinish}
              onFormReset={this.onFormReset}
            />
          </Collapse.Panel>
        </Collapse>
        {isLoading ? (
          <Spinner>
            <Spin />
          </Spinner>
        ) : (
          <>
            <div style={{ marginTop: 50 }}>
              <BookingBoardCalendar
                selectedMonth={filters.selectedMonth}
                selectedTrucks={selectedTrucks}
                visits={visits}
                trucks={trucks}
                excludedDates={excludedDates}
                excludedTrucks={excludedTrucks}
                isEditExcludedDatesModeOn={isEditExcludedDatesModeOn}
                isEditExcludedTrucksModeOn={isEditExcludedTrucksModeOn}
                isMarkClinicUnavailableModeOn={isMarkClinicUnavailableModeOn}
                visitsWithoutRequireTruck={visitsWithoutRequireTruck}
                refreshVisits={this.onSearchFormFinish}
                getVisitsInBackground={this.getVisitsInBackground}
                toggleEditExcludedDatesMode={this.toggleEditExcludedDatesMode}
                toggleEditExcludedTrucksMode={this.toggleEditExcludedTrucksMode}
                toggleMarkClinicUnavailableMode={this.toggleMarkClinicUnavailableMode}
                updateExcludedDates={this.updateExcludedDates}
                updateExcludedTrucks={this.updateExcludedTrucks}
                saveExcludedDates={this.saveExcludedDates}
                saveExcludedTrucks={this.saveExcludedTrucks}
                getVisitsWithoutRequireTruckInBackground={this.getVisitsWithoutRequireTruckInBackground}
              />
              <BookingBoardVisitsMetrics monthlyMetrics={monthlyMetrics} />
            </div>
          </>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
