import {
  isUUID,
  validationSchemaForBirth,
  validationSchemaForCurrentlyWearGlasses,
  validationSchemaForCurrentlyWearContactLens,
  validationSchemaForEmail,
  validationSchemaForFirstName,
  validationSchemaForGender,
  validationSchemaForLastName,
  validationSchemaForCaregiverName,
  validationSchemaForCaregiverRelationship,
  validationSchemaForQualified,
  validationSchemaForConsent,
  validationSchemaForPreferredLanguage,
  validationSchemaForVisitExamType,
  validationSchemaForVisitTimezone,
  validationSchemaForHipaaAcknowledgement,
  validationSchemaForCity,
  validationSchemaForStreet,
  validationSchemaForState,
  validationSchemaForZipCode,
  validationSchemaForVisit,
  validationSchemaForVisitTime,
  validationSchemaForVisitTimeslot,
  validationSchemaForVisitDate,
  validationSchemaForVisitId,
  validationSchemaForSmsSubscription,
} from "../../../helpers/validation.helper";
import { flatten } from "lodash";

enum ValidatorErrors {
  NO_VISIT_ID = "No visitId",
  VISIT_NOT_FOUND = "Visit not found",
  INCORRECT_VISIT_ID = "Incorrect visitId",
  NO_TIMEZONE = "No timezone",
  INCORRECT_TIMEZONE = "Incorrect timezone",
  NO_TIME = "No time",
  INCORRECT_TIME = "Incorrect time",
  NO_TIMESLOT = "Timeslot not found",
  TIMESLOT_HAS_APPOINTMENT = "Timeslot already has a booked appointment",
  TIMESLOT_IS_DISABLED = "Timeslot is not active",
  NO_DATE = "No date",
  INCORRECT_DATE = "Incorrect date",
  NO_FIRST_NAME = "No first name",
  INCORRECT_FIRST_NAME = "Incorrect first name",
  NO_LAST_NAME = "No last name",
  INCORRECT_LAST_NAME = "Incorrect last name",
  NO_EMAIL = "No email",
  INCORRECT_EMAIL = "Incorrect email",
  INCORRECT_GENDER = "Incorrect gender",
  INCORRECT_PHONE_NUMBER = "Incorrect phone number",
  NO_DOB = "No dob",
  INCORRECT_DOB = "Incorrect dob",
  INCORRECT_HIPPA = "Incorrect hippa",
  INCORRECT_STATE = "Incorrect state",
  INCORRECT_ZIP_CODE = "Incorrect zip code",
  NO_CITY = "No city",
  NO_STATE = "No state",
  NO_ZIP_CODE = "No zip code",
  NO_STREET = "No street",
  INCORRECT_EXAM_TYPE = "Incorrect exam type",
  NO_CAREGIVER = "No caregiver",
  NO_CAREGIVER_RELATIONSHIP = "No caregiver relationship",
  INCORRECT_CAREGIVER_RELATIONSHIP = "Incorrect caregiver relationship",
  INCORRECT_QUALIFIED = "Incorrect qualified",
  INCORRECT_CONSENT = "Incorrect consent",
  INCORRECT_LANGUAGE = "Incorrect language",
  INCORRECT_MEDICAL_INSURANCE_PROVIDER_ID = "Incorrect medical insurance provider id",
  INCORRECT_VISION_INSURANCE_PROVIDER_ID = "Incorrect vision insurance provider id",
  INCORRECT_WEAR_GLASSES = "Incorrect wear glasses",
  INCORRECT_WEAR_CONTACT_LENSES = "Incorrect wear contact lenses",
  NOT_FOUND_MEDICAL_INSURANCE_PROVIDER_ID = "Not found medical insurance provider id",
  NOT_FOUND_VISION_INSURANCE_PROVIDER_ID = "Not found vision insurance provider id",
}

type ValidatorResult = {
  isValid: boolean;
  errors: ValidatorErrors[];
};

type VisitData = {
  // basic info
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dob: string;
  phoneNumber: string;
  wearGlasses: boolean;
  wearContactLenses: boolean;
  // address
  city: string;
  street: string;
  state: string;
  zipCode: string;
  // life sciences
  caregiver: string;
  caregiverRelationship: string;
  qualified: boolean;
  consent: boolean;
  language: string;
  // visit
  visitId: string;
  examType: string;
  timeZone: string;
  hippa: boolean;
  time: string;
  date: string;
  smsSubscription: boolean;
};

export class Validator {
  static isDataValid(data: VisitData, visit: any, timeslot: any): ValidatorResult {
    const basicValidators = [
      { validator: validationSchemaForFirstName, value: data.firstName },
      { validator: validationSchemaForLastName, value: data.lastName },
      { validator: validationSchemaForEmail, value: data.email },
      { validator: validationSchemaForGender, value: data.gender },
      { validator: validationSchemaForBirth, value: data.dob },
      { validator: validationSchemaForCurrentlyWearGlasses, value: data.wearGlasses },
      { validator: validationSchemaForCurrentlyWearContactLens, value: data.wearContactLenses },
      // address
      { validator: validationSchemaForCity, value: data.city },
      { validator: validationSchemaForStreet, value: data.street },
      { validator: validationSchemaForState, value: data.state },
      { validator: validationSchemaForZipCode, value: data.zipCode },
      //visit
      { validator: validationSchemaForVisitExamType, value: data.examType },
      { validator: validationSchemaForVisitTimezone, value: data.timeZone },
      { validator: validationSchemaForHipaaAcknowledgement, value: data.hippa },
      { validator: validationSchemaForVisit, value: visit },
      { validator: validationSchemaForVisitTime, value: data.time },
      { validator: validationSchemaForVisitTimeslot, value: timeslot },
      { validator: validationSchemaForVisitDate, value: data.date },
      { validator: validationSchemaForVisitId, value: data.visitId },
      { validator: validationSchemaForSmsSubscription, value: data.smsSubscription },
    ];

    const lifeSciencesValidators =
      visit && visit.appointmentType === "life_sciences"
        ? [
            { validator: validationSchemaForCaregiverName, value: data.caregiver },
            { validator: validationSchemaForCaregiverRelationship, value: data.caregiverRelationship },
            { validator: validationSchemaForQualified, value: data.qualified },
            { validator: validationSchemaForConsent, value: data.consent },
            { validator: validationSchemaForPreferredLanguage, value: data.language },
          ]
        : [];

    const allValidators = [...basicValidators, ...lifeSciencesValidators];
    const errors = [];

    for (let { validator, value } of allValidators) {
      try {
        validator.validateSync(value);
      } catch (e: any) {
        e?.errors?.length && errors.push(e?.errors);
      }
    }

    return {
      isValid: errors.length === 0,
      errors: flatten(errors),
    };
  }

  // visit

  static isMedicalInsuranceProviderIdValid(value: string, insurances: any[]): ValidatorResult {
    const errors = [];
    if (value && !isUUID(value, 4)) errors.push(ValidatorErrors.INCORRECT_MEDICAL_INSURANCE_PROVIDER_ID);

    const isMedicalInsuranceProviderIdCorrect = value
      ? insurances.some((i) => i.id === value && i.type === "medical")
      : false;
    if (value && !isMedicalInsuranceProviderIdCorrect)
      errors.push(ValidatorErrors.NOT_FOUND_MEDICAL_INSURANCE_PROVIDER_ID);

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  static isVisionInsuranceProviderIdValid(value: string, insurances: any[]): ValidatorResult {
    const errors = [];
    if (value && !isUUID(value, 4)) errors.push(ValidatorErrors.INCORRECT_VISION_INSURANCE_PROVIDER_ID);

    const isVisionInsuranceProviderIdCorrect = value
      ? insurances.some((i) => i.id === value && i.type === "vision")
      : false;
    if (value && !isVisionInsuranceProviderIdCorrect)
      errors.push(ValidatorErrors.NOT_FOUND_VISION_INSURANCE_PROVIDER_ID);

    return {
      isValid: errors.length === 0,
      errors,
    };
  }
}
