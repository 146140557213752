import React from "react";
import { FormInstance } from "antd/es/form";
import { Card, Form, Button, notification, Input } from "antd";
import { ApiClient } from "../../../../api-client/api.client";

interface IProps {
  type: "primary" | "secondary";
  id: string;
  currentEmail?: string | undefined;
}

interface IState {
  isLoading: boolean;
}

export class ChangeEmailComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();
  state: IState = {
    isLoading: false,
  };

  private async onFinish(values: any) {
    const { type, id } = this.props;

    this.setState({ isLoading: true });

    await this.form.current!.validateFields();

    try {
      await ApiClient.requestChangePatientEmailWithoutPassword(id, { type, newEmail: values.newEmail });
      notification.success({
        message: "Success",
        description: `Successfully sent activation link to new email address`,
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.response?.data?.message || "Unknown Error",
      });
    }

    this.setState({ isLoading: false });
  }

  private getCardTitle(type: "primary" | "secondary") {
    if (type === "primary") return "Change primary email";
    else if (type === "secondary") return "Change secondary email";
    else return "";
  }

  render(): React.ReactNode {
    const { isLoading } = this.state;
    const { type, currentEmail } = this.props;

    return (
      <Card title={this.getCardTitle(type)}>
        <Form layout="vertical" ref={this.form} name="change-patient-email-form" onFinish={(e) => this.onFinish(e)}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item name="currentEmail" label="Current email" initialValue={currentEmail}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="newEmail" label="New email" rules={[{ required: true, type: "email" }]}>
              <Input />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Change email
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
