import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Tabs, Typography } from "antd";
import { UploadComponent } from "./components/upload.component";

interface IProps extends RouteComponentProps {}

interface IState {
  tab: string;
}

export class BulkUpload extends React.Component<IProps, IState> {
  state: IState = {
    tab: this.getMenuSelectedKey(),
  };

  componentDidMount = () => {
    const tabUrl = this.getMenuSelectedKey();
    if (tabUrl) {
      this.setState({ tab: tabUrl });
      this.props.history.push(tabUrl);
    }
  };

  private getMenuSelectedKey(): string {
    const path = this.props.location.pathname;
    return "/" + path.split("/").slice(1, 3).join("/");
  }

  render(): React.ReactNode {
    const { history } = this.props;
    const { tab } = this.state;
    return (
      <>
        <Typography.Title>Bulk Upload</Typography.Title>
        <Tabs
          defaultActiveKey={tab}
          onChange={(key) => {
            this.setState({ tab: key });
            history.push(key);
          }}>
          <Tabs.TabPane tab="Upload CSV file" key="/bulk-upload/upload">
            <Route path="/bulk-upload/upload" exact component={UploadComponent} />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}
