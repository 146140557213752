import React from "react";
import styled from "styled-components";
import { Row, Col, Radio } from "antd";

interface IProps {
  question: string;
  defaultValue: string;
  options: {
    yes: string;
    no: string;
  };
  onChange: any;
  labelColOptions?: { [key: string]: { span: number } };
  explanation?: string;
}

export const QuestionSelectYesOrNoOption = (props: IProps) => {
  const { question, options, defaultValue, onChange, explanation } = props;

  const labelColOptions = props.labelColOptions ? props.labelColOptions : { xs: { span: 24 }, md: { span: 12 } };

  return (
    <Row {...props}>
      <Col {...labelColOptions}>
        <div>{question}</div>
        {explanation && <ExplanationWrapper>{explanation}</ExplanationWrapper>}
      </Col>
      <Col span={12}>
        <WrapperRadioGroup>
          <Radio.Group defaultValue={defaultValue} value={defaultValue} onChange={onChange} buttonStyle="solid">
            <StyledRadioButton value={options.yes}>{options.yes}</StyledRadioButton>
            <StyledRadioButton value={options.no}>{options.no}</StyledRadioButton>
          </Radio.Group>
        </WrapperRadioGroup>
      </Col>
    </Row>
  );
};

const StyledRadioButton = styled(Radio.Button)`
  text-transform: capitalize;
`;

const WrapperRadioGroup = styled.div`
  margin-left: 7px;

  .ant-radio-button-wrapper-checked {
    font-weight: bold;
  }

  @media (max-width: 767px) {
    margin: 10px 0 0 0;
  }
`;

const ExplanationWrapper = styled.div`
  font-style: italic;
  font-size: 12px;
  margin-top: 5px;
`;
