import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { getStatusName, getTextColorForVisitStatus, VisitStatuses } from "../../../helpers/visit.helper";
import { FindVisits } from "../../../interfaces/visit.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  currentPage: number;
  companies: any[];
  visits: FindVisits;
}

export class VisitsListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    currentPage: 1,
    companies: [],
    visits: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
  };

  public componentDidMount(): void {
    this.searchForm.current!.setFieldsValue({
      startDate: moment().startOf("week"),
      endDate: moment().endOf("week"),
    });
    this.getCompanies();
  }

  public async getCompanies() {
    try {
      const { data: companies } = await ApiClient.findCompaniesWithLocations({ offset: 0, limit: "all" });
      this.setState({ companies: companies.data });
    } catch (e) {
      message.error("Cannot fetch companies data");
    }
  }

  public async getVisits() {
    const { companies } = this.state;
    this.setState({ isLoading: true });
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data: visits } = await ApiClient.findVisits({
        offset: (this.state.currentPage - 1) * this.state.visits.limit,
        limit: this.state.visits.limit,
        name: searchForm.name || undefined,
        appointmentType: searchForm.appointmentType || undefined,
        locationIds: searchForm.companyId
          ? companies.find((x) => x.id === searchForm.companyId).locations.map((x) => x.id)
          : [],
        statuses: searchForm.statuses || undefined,
        startDate: searchForm.startDate ? moment(searchForm.startDate).toISOString() : undefined,
        endDate: searchForm.endDate ? moment(searchForm.endDate).toISOString() : undefined,
      });

      this.setState({ visits, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch visits data");
      this.setState({ isLoading: false });
    }
  }

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getVisits();
    });
  };

  public onSearchFormFinish = () => {
    this.getVisits();
  };

  public onFormReset = () => {
    this.searchForm.current!.resetFields();
  };

  public generateDataTableFromVisitsAndCompanies = () => {
    const { visits, companies } = this.state;
    return visits.data.map((visit) => {
      const { id, date, appointmentType, status, locationId } = visit || {};
      const { name, slug } =
        companies.find(
          (company) => company.locations && company.locations.find((location) => location.id === locationId)
        ) || {};

      return {
        id,
        date,
        appointmentType,
        status,
        companyName: name,
        companySlug: slug,
      };
    });
  };

  public redirectToPatientTracker(visitId: string) {
    this.props.history.push({
      pathname: `/patient-tracker`,
      search: `?trackerId=${visitId}`,
    });
  }

  public redirectToSlugSite(slug: string) {
    window.open(`https://${slug}.2020onsite.com`, "_blank");
  }

  public render() {
    const { visits, currentPage, isLoading, companies } = this.state;

    const columns = [
      {
        title: "Company name",
        dataIndex: "companyName",
        key: "companyName",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Appointment type",
        dataIndex: "appointmentType",
        key: "appointmentType",
        render: (type) => {
          switch (type) {
            case "ro_private":
              return <Tag color="magenta">{type}</Tag>;
            case "ro_public":
              return <Tag color="orange">{type}</Tag>;
            case "telehealth":
              return <Tag color="geekblue">{type}</Tag>;
            case "styling":
              return <Tag color="purple">{type}</Tag>;
            case "life_sciences":
              return <Tag color="cyan">{type}</Tag>;
            case "schools":
              return <Tag color="gold">{type}</Tag>;
            default:
              return <Tag>{type}</Tag>;
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          return (
            <Tag color={getTextColorForVisitStatus(status)} closable={false} style={{ marginRight: 3 }}>
              {getStatusName(status)}
            </Tag>
          );
        },
      },
      {
        title: "Booking",
        key: "booking-operation",
        fixed: "right",
        width: 100,
        render: ({ companySlug }) => {
          return (
            <Button size="small" onClick={() => this.redirectToSlugSite(companySlug)}>
              Go to booking
            </Button>
          );
        },
      },
      {
        title: "Tracker",
        key: "tracker-operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Button size="small" onClick={() => this.redirectToPatientTracker(id)}>
              Go to Patient tracker
            </Button>
          );
        },
      },
      {
        title: "Admin",
        key: "admin-operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Link to={`/visits/${id}/edit`}>
              <Button size={"small"}>Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ marginBottom: 24, textAlign: "right" }}>
            <Link to="/visits/create">
              <Button type="primary">Create visit</Button>
            </Link>
          </Col>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form-visit" onFinish={this.onSearchFormFinish}>
              <Form.Item name="name" label="Name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="companyId" label="Company" initialValue={""} style={{ marginBottom: 16 }}>
                <Select
                  showSearch
                  placeholder="Select company"
                  style={{ width: 400 }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Select.Option value={""}>all</Select.Option>
                  {companies.map((company) => (
                    <Select.Option value={company.id}>{company.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="appointmentType" label="Appointment Type" initialValue={""} style={{ marginBottom: 16 }}>
                <Select placeholder="Select appointment type" style={{ width: 150 }}>
                  <Select.Option value="">all</Select.Option>
                  <Select.Option value="ro_private">RO Private</Select.Option>
                  <Select.Option value="ro_public">RO Public</Select.Option>
                  <Select.Option value="telehealth">Telehealth</Select.Option>
                  <Select.Option value="styling">Styling</Select.Option>
                  <Select.Option value="life_sciences">Life Sciences</Select.Option>
                  <Select.Option value="schools">Schools</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="startDate" label="Start date" style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item name="endDate" label="End date" style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item
                name="statuses"
                label="Statuses"
                initialValue={VisitStatuses.map((status) => status.value)}
                style={{ marginBottom: 16, width: 550 }}>
                <Select
                  mode="multiple"
                  showArrow
                  tagRender={(props: any) => {
                    const { label, value, closable, onClose } = props;
                    return (
                      <Tag
                        color={getTextColorForVisitStatus(value)}
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}>
                        {label}
                      </Tag>
                    );
                  }}
                  style={{ width: "100%" }}
                  options={VisitStatuses.map((status) => ({ label: status.shortName, value: status.value }))}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{visits.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={this.generateDataTableFromVisitsAndCompanies()}
          columns={columns}
          pagination={{
            pageSize: visits.limit,
            current: currentPage,
            total: visits.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}
