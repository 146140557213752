import { isAfter, isPast, isValid, parse } from "date-fns";
import * as Yup from "yup";
import { states } from "./states.helper";

export const validationPatterns = {
  patientName: /^[A-Za-z](?![A-Za-z\-\'\s]*([\-\'\s])\1{1,})[A-Za-z\-\'\s]{0,48}$/,
  email: /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
  phoneNumber: /^[0-9]{10}$/,
  phoneNumberWithCountryCode: /^[1][0-9]{10}$/,

  salesforceAccountId: /^001(?=(?:.{12}|.{15})$)[a-zA-Z0-9]*$/,
  salesforceVisitId: /^a0D(?=(?:.{12}|.{15})$)[a-zA-Z0-9]*$/s,

  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z]).{8,}$/,
  verificationCodeNumber: /^[a-zA-Z0-9]{1}$/,
  containsLowerCaseLetter: /\w*[a-z]\w*/,
  containsUpperCaseLetter: /\w*[A-Z]\w*/,
  containsNumberLetter: /\d/,
  containsSpecialLetter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
  twoFactorAuthentication: /^[0-9]{6,}$/,

  uuidV4: /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/,
  MMDDYYYY: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
  zipCodeWith5numbers: /^[0-9]{5}$/,
  zipCodeWith4numbers: /^[0-9]{4}$/,
  time: /^(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)$/,
  integer: /^[0-9]+$/,
};

export const isUUID = (str: string, version: 1 | 2 | 3 | 4 | 5 | "all") => {
  const uuid = {
    1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  };
  // @ts-ignore
  const pattern = uuid[version];
  return !!pattern && pattern.test(str);
};

const boolSchema = (label: string) =>
  Yup.bool().required(`${label} field is required`).typeError(`${label} field is invalid`);

const maxLengthSchema = (label: string) =>
  Yup.string().test(`${label} length`, `${label} is too long`, (val) => !!(val && val.length <= 255));

/***********************************************/
// Patient's personal details

export const validationSchemaForFirstName = Yup.string()
  .required("First name is required")
  .matches(
    validationPatterns.patientName,
    "First name can contain letters, hyphens, dots and whitespaces, but must start and end with a letter."
  )
  .concat(maxLengthSchema("First name"));

export const validationSchemaForLastName = Yup.string()
  .required("Last name is required")
  .matches(
    validationPatterns.patientName,
    "Last name can contain letters, hyphens, dots and whitespaces, but must start and end with a letter."
  )
  .concat(maxLengthSchema("Last name"));

export const validationSchemaForEmail = Yup.string()
  .required("Email is required")
  .matches(validationPatterns.email, "Email must be valid")
  .concat(maxLengthSchema("Email"));

export const validationSchemaForConfirmEmail = Yup.string()
  .required("Confirm Email is required")
  .when("email", {
    is: (val: string) => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref("email")], "Both emails need to be the same"),
  });

export const validationSchemaForGender = Yup.string()
  .required("Gender is required")
  .oneOf(["male", "female", "not_specified"], "Gender is invalid, allowed values are: male, female, not_specified")
  .concat(maxLengthSchema("Gender"));

export const validationSchemaForBirth = Yup.string()
  .required("Date of birth is required")
  .test("date format", "Date of birth should be in MM/DD/YYYY format", (val) => {
    if (!val) return false;
    const [MM, DD, YYYY] = val.split("/");
    return (
      validationPatterns.integer.test(MM) &&
      validationPatterns.integer.test(DD) &&
      validationPatterns.integer.test(YYYY) &&
      YYYY.length === 4 &&
      MM.length === 2 &&
      DD.length === 2
    );
  })
  .test("date range", "Date of birth should be earlier than today and later than 01/01/1900", (val) => {
    if (!val) return false;
    const parsedDate = parse(val, "MM/dd/yyyy", new Date());
    return isValid(parsedDate) && isPast(parsedDate) && isAfter(parsedDate, new Date(1900, 0, 1));
  });

export const validationSchemaForPhoneNumber = Yup.string()
  .required("Phone number is required")
  .matches(validationPatterns.phoneNumber, "Phone number must contain 10 digits");

export const validationSchemaForPassword = Yup.string()
  .required("Password is required")
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z]).{8,}$/,
    "Password is not strong"
  );

export const validationSchemaForConfirmPassword = Yup.string()
  .required("Confirm Password is required")
  .when("password", {
    is: (val: string) => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"),
  });

/***********************************************/
// Life Sciences personal details

export const validationSchemaForCaregiverName = Yup.string()
  .required("Caregiver name is required")
  .concat(maxLengthSchema("Caregiver name"));

export const validationSchemaForCaregiverRelationship = Yup.string()
  .required("Caregiver relationship name is required")
  .concat(maxLengthSchema("Caregiver relationship"));

export const validationSchemaForQualified = boolSchema("Qualified");

export const validationSchemaForConsent = boolSchema("Consent");

export const validationSchemaForPreferredLanguage = Yup.string()
  .oneOf(["english", "spanish"], "Must be English or Spanish")
  .required("Preferred language field is required");

/***********************************************/
// Patient's address

export const validationSchemaForCity = Yup.string().required("City is required").concat(maxLengthSchema("City"));

export const validationSchemaForStreet = Yup.string().required("Street is required").concat(maxLengthSchema("Street"));

export const validationSchemaForState = Yup.string()
  .required("State is required")
  .oneOf(states, "State is invalid")
  .concat(maxLengthSchema("State"));

export const validationSchemaForZipCode = Yup.string()
  .required("Zip code is required")
  .test("zip length", "Must contain 5 digits", (val) => val?.length === 5)
  .test(
    "zip range",
    "Must be between 00001 and 99950",
    (val) => !!(val && parseInt(val, 10) >= 1 && parseInt(val, 10) <= 99950)
  );

/***********************************************/
// Visit details

export const validationSchemaForMarketingOptIn = Yup.bool().typeError("Marketing opt-in field is invalid");

export const validationSchemaForHipaaAcknowledgement = Yup.bool()
  .oneOf([true], "Hipaa acknowledgement field must be checked")
  .concat(boolSchema("Hipaa acknowledgement"));

export const validationSchemaForUnderstandCancellationPolicy = Yup.bool()
  .oneOf([true], "Understand cancellation policy field must be checked")
  .concat(boolSchema("Understand cancellation policy"));

export const validationSchemaForSelectedVisitId = Yup.string().required("Visit is required");

export const validationSchemaForSelectedCompanyId = Yup.string().required("Company is required");

export const validationSchemaForSelectedTimeslotId = Yup.string().required("Timeslot is required");

export const validationSchemaForSelectedLocationId = Yup.string().required("Location is required");

export const validationSchemaForCurrentlyWearGlasses = boolSchema("Currently wear glasses");

export const validationSchemaForCurrentlyWearContactLens = boolSchema("Currently wear contact lens");

export const validationSchemaForLastEyeExam = Yup.string().required("Last eye exam field is required");

// Insurance
export const validationSchemaForInsuranceCoverage = boolSchema("Insurance coverage");

export const validationSchemaForInsuranceProvidedByCompany = boolSchema("Insurance provided by your employer");

export const validationSchemaForCompanyName = Yup.string()
  .required("Company name is required")
  .concat(maxLengthSchema("Company name"));

export const validationSchemaForSchoolName = Yup.string()
  .required("School name is required")
  .concat(maxLengthSchema("School name"));

export const validationSchemaForInsuranceProvider = Yup.string().required("Insurance provider is required");

export const validationSchemaForOtherInsuranceProvider = Yup.string().required("Other insurance provider is required");

export const validationSchemaForPrimaryPolicyHolder = boolSchema("Primary policy holder");

export const validationSchemaForSubscriberId = Yup.string().required("Subscriber id is required");

export const validationSchemaForLast4OfSsn = Yup.string()
  .required("Last 4 digits are required")
  .test("ssn type", "Must be an intiger", (val) => validationPatterns.integer.test(val || ""))
  .test("ssn length", "Must contain only 4 digits", (val) => val?.length === 4)
  .test(
    "ssn range",
    "Must be between 0001 and 9999",
    (val) => !!(val && parseInt(val, 10) >= 1 && parseInt(val, 10) <= 9999)
  );

export const validationSchemaForPrimaryPolicyHolderLast4OfSSN = validationSchemaForLast4OfSsn;

export const validationSchemaForPrimaryPolicyHolderSubscriberId = Yup.string().required(
  "Primary policy holder subscriber id is required"
);

export const validationSchemaForExtPolicyHolderName = Yup.string()
  .required("Policy holder name is required")
  .concat(maxLengthSchema("Policy holder name"));

export const validationSchemaForExtPolicyHolderDOB = Yup.date()
  .typeError("Policy holder DOB is invalid")
  .required("Policy holder DOB is required")
  .max(new Date(), `Date of birth should be earlier than ${new Date().toLocaleDateString("en-US")}`);

export const validationSchemaForRegistrationCodeOfCenter = Yup.string().required(
  "Registration code of center is required"
);

export const validationSchemaForLifeSciencesNoMedicalInsurance = boolSchema("Insurance coverage");

// Patient portal
export const validationSchemaForPasswordRequiredOnly = Yup.string().required("Password is required");

export const validationSchemaForCodeRequiredOnly = Yup.string().required("Code is required");

/***********************************************/
// Bulk upload

export const validationSchemaForVisit = Yup.object().required("Visit not found");

export const validationSchemaForVisitId = Yup.string()
  .required("Visit id is required")
  .test("visit id uuid", "Visit id is invalid", (val) => !!(val && isUUID(val, 4)));

export const validationSchemaForVisitExamType = Yup.string()
  .required("Visit exam type is required")
  .oneOf(
    ["exam", "cl_exam", "retail_only", "f/u"],
    "Visit exam type is invalid, allowed values are: exam, cl_exam, retail_only, f/u"
  );

export const validationSchemaForVisitTimezone = Yup.string()
  .required("Visit timezone is required")
  .oneOf(
    ["America/New_York", "America/Chicago", "America/Los_Angeles", "America/Denver"],
    "Visit timezone is invalid, allowed values are: America/New_York, America/Chicago, America/Los_Angeles, America/Denver"
  );

export const validationSchemaForVisitTime = Yup.string()
  .required("Visit time is required")
  .matches(validationPatterns.time, "Visit time format is incorrect, use HH:MM AM/PM");

export const validationSchemaForVisitTimeslot = Yup.object()
  .shape({
    isDisabled: Yup.bool(),
    appointments: Yup.array().of(
      Yup.object({
        status: Yup.string(),
      })
    ),
  })
  .default(undefined)
  .required("Visit timeslot not found")
  .test("timeslot disabled", "Visit timeslot is disabled", (val) => !val.isDisabled)
  .test(
    "timeslot booked",
    "Visit timeslot is already booked",
    (val) => !val.appointments?.some((a) => a.status === "confirmed")
  );

export const validationSchemaForVisitDate = Yup.string()
  .required("Visit date is required")
  .matches(validationPatterns.MMDDYYYY, "Visit date format is incorrect, use MM/DD/YYYY");

export const validationSchemaForSmsSubscription = boolSchema("Sms subscription");
