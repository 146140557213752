import React from "react";
import { Modal, Button, Tag, Table, message, Row, Col, Select, Space } from "antd";
import moment from "moment";
import momentTz from "moment-timezone";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { orderBy } from "lodash";

import { IBookingBoardMonthCalendarVisit, ITruckAvailabilityForSpecificDay } from "../../../interfaces/visit.interface";
import { IEmployee } from "../../../interfaces/booking-board.interface";
import { getAppointmentTypeColor, getStatusName, VisitStatuses } from "../../../helpers/visit.helper";
import { ApiClient } from "../../../api-client/api.client";
import { SchedulingNotes } from "./scheduling-notes.component";
import { Staff } from "./staff.component";
import styled from "styled-components";

interface IProps extends RouteComponentProps {
  isVisible: boolean;
  selectedDay: string;
  visits: IBookingBoardMonthCalendarVisit[];
  onCancel: () => void;
  getVisitsInBackground: () => void;
  getVisitsWithoutRequireTruckInBackground: () => void;
}

interface IState {
  truckAvailabilityForThatDay: ITruckAvailabilityForSpecificDay[];
  visitTimeRange: {
    [key: string]: {
      startDate: string;
      endDate: string;
    };
  };
  employees: IEmployee[];
}

class BookingBoardVisitsModal extends React.Component<IProps, IState> {
  state = {
    truckAvailabilityForThatDay: [],
    visitTimeRange: {},
    employees: [],
  };

  componentDidUpdate = async (prevProps: IProps) => {
    const { visits, isVisible, selectedDay } = this.props;
    if (prevProps.isVisible !== isVisible && isVisible) {
      const filteredVisits = visits.filter((visit) => visit.date === selectedDay);

      await this.getTruckAvailabilityForActiveTrucks();
      await this.getEmployees();

      for (const visit of filteredVisits) {
        await this.getTimeRanges(visit.id);
      }
    }
  };

  getTruckAvailabilityForActiveTrucks = async () => {
    try {
      const { selectedDay } = this.props;
      const response = await ApiClient.getTruckAvailabilityForActiveTrucks({ date: selectedDay });

      this.setState({
        truckAvailabilityForThatDay: response.data,
      });
    } catch (error) {}
  };

  getTimeRanges = async (visitId: string) => {
    try {
      const response = await ApiClient.getVisitTimeRange(visitId);
      this.setState({
        visitTimeRange: {
          ...this.state.visitTimeRange,
          [visitId]: response.data,
        },
      });
    } catch (error) {}
  };

  getEmployees = async () => {
    try {
      const response = await ApiClient.getEmployees();

      this.setState({
        employees: response.data,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  changeVisitTruck = async (oldTruckId: string, newTruckId: string, visitId: string) => {
    try {
      const { selectedDay } = this.props;

      if (oldTruckId) {
        await ApiClient.removeTruckReservation(oldTruckId, visitId);
      }

      await ApiClient.reserveTruck(newTruckId, visitId, selectedDay);
      await this.props.getVisitsInBackground();
      await this.props.getVisitsWithoutRequireTruckInBackground();
      await this.getTruckAvailabilityForActiveTrucks();
    } catch (error) {
      message.error(error.message);
    }
  };

  redirectToPatientTrackerBySelectedDay = (date: string) => {
    this.props.history.push({
      pathname: `/patient-tracker`,
      search: `?date=${date}`,
    });
  };

  public render() {
    const {
      isVisible,
      selectedDay,
      visits,
      onCancel,
      getVisitsInBackground,
      getVisitsWithoutRequireTruckInBackground,
    } = this.props;
    const { employees, truckAvailabilityForThatDay } = this.state;

    const filteredVisits = visits.filter((visit) => visit.date === selectedDay);

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => <span>{record.name}</span>,
      },
      {
        title: "Company",
        dataIndex: "companyName",
        key: "companyName",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => <span>{record.companyName}</span>,
      },
      {
        title: "Hours",
        dataIndex: "hours",
        key: "hours",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => {
          // @ts-ignore
          const timeRange = this.state.visitTimeRange[record.id];

          return timeRange ? (
            <Tag>
              {momentTz(timeRange.startDate).locale("en").tz(record.timezone).format("LT")} -{" "}
              {momentTz(timeRange.endDate).locale("en").tz(record.timezone).format("LT")}
            </Tag>
          ) : (
            "-"
          );
        },
      },
      {
        title: "Clinic",
        dataIndex: "truckName",
        key: "truckName",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => (
          <Select
            placeholder="Select clinic"
            style={{ width: 120 }}
            size="small"
            value={record.truckId}
            onChange={(value) => {
              const newTruck: any = truckAvailabilityForThatDay.find(
                (t: ITruckAvailabilityForSpecificDay) => t.id === value
              );

              Modal.confirm({
                icon: null,
                content: (
                  <div>
                    Do you want to change clinic to:{" "}
                    <div>
                      <strong>{newTruck ? newTruck.name : ""}</strong>?
                    </div>
                  </div>
                ),
                okText: "Yes",
                onOk: async () => {
                  await this.changeVisitTruck(record.truckId, value, record.id);
                },
              });
            }}>
            {orderBy(truckAvailabilityForThatDay, ["isReserved", "name"], "asc").map(
              (truck: ITruckAvailabilityForSpecificDay) => (
                <Select.Option key={truck.id} value={truck.id} disabled={truck.isReserved}>
                  {truck.name}
                </Select.Option>
              )
            )}
          </Select>
        ),
      },
      {
        title: "Type",
        dataIndex: "appointmentType",
        key: "appointmentType",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => (
          <Tag color={getAppointmentTypeColor(record.appointmentType)}>{record.appointmentType}</Tag>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => (
          <Select
            placeholder="Select status"
            style={{ width: 230 }}
            size="small"
            value={record.status}
            onChange={(value) => {
              Modal.confirm({
                icon: null,
                content: (
                  <div>
                    Do you want to change visit status to:{" "}
                    <div>
                      <strong>{getStatusName(value)}</strong>?
                    </div>
                  </div>
                ),
                okText: "Yes",
                onOk: async () => {
                  try {
                    await ApiClient.changeVisitStatus(record.id, value);
                  } catch (e) {
                    message.error(e.response?.data?.message || e.message || "Unknown error");
                  }

                  await getVisitsInBackground();
                  await getVisitsWithoutRequireTruckInBackground();
                },
              });
            }}>
            {VisitStatuses.map((status) => (
              <Select.Option key={status.value} value={status.value}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (_text: string, record: IBookingBoardMonthCalendarVisit) => (
          <Button type="link" target="_blank" href={`/visits/${record.id}/edit`}>
            Edit
          </Button>
        ),
      },
    ];

    return (
      <Modal
        width={1200}
        visible={isVisible}
        title={
          <HeaderWrapper>
            <Row justify="space-between">
              <>{moment(selectedDay).format("LL")}</>
              <Button
                type="primary"
                size="small"
                onClick={() => this.redirectToPatientTrackerBySelectedDay(selectedDay)}>
                Go to Tracker
              </Button>
            </Row>
          </HeaderWrapper>
        }
        onCancel={onCancel}
        footer={[
          <Button key="add-visit" type="primary" target="_blank" href={`/visits/create?date=${selectedDay}`}>
            Add visit
          </Button>,
        ]}>
        <Table
          columns={columns}
          dataSource={orderBy(filteredVisits, "truckName", "asc").map((visit) => ({ ...visit, key: visit.id }))}
          pagination={false}
          expandable={{
            expandedRowRender: (record: IBookingBoardMonthCalendarVisit) => {
              return (
                <Row gutter={32} key={record.id}>
                  <Col span={14}>
                    <SchedulingNotes
                      visitId={record.id}
                      getVisitsInBackground={getVisitsInBackground}
                      getVisitsWithoutRequireTruckInBackground={getVisitsWithoutRequireTruckInBackground}
                    />
                  </Col>
                  <Col span={10}>
                    <Staff visitId={record.id} employees={employees} getVisitsInBackground={getVisitsInBackground} />
                  </Col>
                </Row>
              );
            },
          }}
        />
      </Modal>
    );
  }
}

export default withRouter(BookingBoardVisitsModal);

const HeaderWrapper = styled.div`
  width: 96%;
`;
