import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { notification, Space, Spin, Tabs, Typography } from "antd";
import { ChangePasswordSettings } from "./components/tabs/change-password-settings.component";
import { AccountsSettingsComponent } from "./components/tabs/accounts-settings.component";
import { TwoFactorAuthenticationSettingsComponent } from "./components/tabs/two-factor-authentication-settings.component";
import { ApiClient } from "../../api-client/api.client";
import { defaultUserDataValue, IUserData } from "../../interfaces/userData.interface";
import { EditAccountComponent } from "./components/edit-patient-account/edit-account.component";
import { PatientAccountsSettingsComponent } from "./components/tabs/patient-accounts-settings.component";

const DEFAULT_TAB = 'change-password'

interface IProps extends RouteComponentProps {}

interface IState {
  tab: string;
  userData: IUserData;
  isLoading: boolean;
}

export class Settings extends React.Component<IProps, IState> {
  state: IState = {
    tab: DEFAULT_TAB,
    isLoading: false,
    userData: defaultUserDataValue,
  };

  componentDidMount = () => {
    this.getUserData();
  };

  private async getUserData(): any {
    this.setState({ isLoading: true });
    try {
      const {
        data: { data: userData },
      } = await ApiClient.getAccountsMe();
      this.setState({ userData, isLoading: false });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Check authorization token!",
      });
    }
  }

  render(): React.ReactNode {
    const { tab, userData, isLoading } = this.state;
    return (
      <>
        <Typography.Title>Settings</Typography.Title>
        <Tabs
          defaultActiveKey={tab}
          onChange={(key) => {
            this.setState({ tab: key });
          }}>
          {!isLoading && userData ? (
            <>
              <Tabs.TabPane tab="Change password" key="change-password">
                <ChangePasswordSettings />
              </Tabs.TabPane>
              <Tabs.TabPane tab="2FA" key="2fa">
                  <TwoFactorAuthenticationSettingsComponent id={userData.id} />
              </Tabs.TabPane>
              {userData.role === "admin" && (
                <Tabs.TabPane tab="2020on-site Accounts" key="accounts">
                    <AccountsSettingsComponent />
                </Tabs.TabPane>
              )}
              <Tabs.TabPane tab="Patient Accounts" key="patient-accounts">

                  <PatientAccountsSettingsComponent userData={userData} />
              </Tabs.TabPane>
            </>
          ) : (
            <Space
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              align="center"
              size="middle">
              <Spin size="large" tip="Loading..." />
            </Space>
          )}
        </Tabs>
        <Route path="/settings/patient-accounts/:id/edit" exact component={EditAccountComponent} />
      </>
    );
  }
}
