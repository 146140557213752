import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Button, Col, Form, Input, message, PageHeader, Row } from "antd";
import { validationPatterns } from "../../../../helpers/validation.helper";
import { ApiClient } from "../../../../api-client/api.client";
import { AuthenticationGuard } from "../../../authentication-guard/authentication-guard.container";
import PasswordPolicyMessage from "../../../../ui/password/PasswordPolicyMessage.component";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  isFormValidated: boolean;
  timer: any;
}

export class ChangePasswordSettings extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    isFormValidated: false,
    timer: null,
  };

  public async componentWillUnmount() {
    const { timer } = this.state;
    if (timer) clearTimeout(timer);
  }

  private getFieldValue(fieldName: string): string {
    if (!this.form.current) {
      return "";
    }
    return this.form.current!.getFieldValue(fieldName);
  }

  private isFieldTouched(fieldName: string): boolean {
    if (!this.form.current) {
      return false;
    }
    return this.form.current!.isFieldTouched(fieldName);
  }

  private verifyPasswordFieldValidationStatus() {
    const checkTouched = this.isFieldTouched("password");
    const value = this.getFieldValue("password");
    const isValidPattern = validationPatterns.password.test(value);

    if (!checkTouched) {
      return false;
    } else if ((checkTouched && value === "") || !isValidPattern) {
      return "error";
    } else {
      return "success";
    }
  }
  private verifyPasswordConfirmationFieldValidationStatus = () => {
    const passsword = this.getFieldValue("password");
    const passwordConfirmation = this.getFieldValue("passwordConfirmation");
    const isPasswordValid = validationPatterns.password.test(passsword);
    const isPasswordConfirmationTouched = this.isFieldTouched("passwordConfirmation");
    const isPasswordConfirmationValid = passsword === passwordConfirmation && isPasswordValid;
    if (!isPasswordConfirmationTouched) {
      return "";
    } else if (
      (isPasswordConfirmationTouched && passwordConfirmation === "") ||
      !(isPasswordValid && isPasswordConfirmationValid)
    ) {
      return "error";
    } else {
      return "success";
    }
  };

  private validateForm = () => {
    this.setState({ isFormValidated: false });
    const passsword = this.getFieldValue("password");
    const passwordConfirmation = this.getFieldValue("passwordConfirmation");
    const isPasswordValid = validationPatterns.password.test(passsword);
    const isPasswordConfirmationValid = passsword === passwordConfirmation && isPasswordValid;

    if (isPasswordValid && isPasswordConfirmationValid) {
      this.setState({ isFormValidated: true });
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      await this.form.current!.validateFields();

      await ApiClient.changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.password,
      });

      message.success("Password changed successfully. In a moment you will be taken to the login page.", 5);
      this.form.current!.resetFields();
      this.setState({ isLoading: false });
      const timer = setTimeout(() => {
        AuthenticationGuard.removeAuthorizationToken();
        AuthenticationGuard.redirectToAuthenticationApp();
      }, 5000);
      this.setState({ timer });
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading } = this.state;
    return (
      <>
        <PageHeader
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Change current password"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Form
              ref={this.form}
              layout="vertical"
              onChange={this.validateForm}
              onFinish={this.onFinish}
              validateTrigger="onChange">
              <Form.Item
                name="currentPassword"
                label="Current password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Current password is required",
                  },
                ]}
                style={{ marginBottom: 5 }}>
                <Input placeholder="******" type="password" width="100%" />
              </Form.Item>
              <Form.Item
                name="password"
                label="New password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    pattern: validationPatterns.password,
                    message: "New password is not valid",
                  },
                ]}
                validateStatus={this.verifyPasswordFieldValidationStatus()}
                style={{ marginBottom: 5 }}>
                <Input placeholder="******" type="password" width="100%" />
              </Form.Item>
              <Form.Item
                name="passwordConfirmation"
                label="Confirm new password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords that you entered do not match!");
                    },
                  }),
                ]}
                validateStatus={this.verifyPasswordConfirmationFieldValidationStatus()}>
                <Input placeholder="******" type="password" width="100%" />
              </Form.Item>
              <Form.Item>
                <PasswordPolicyMessage password={this.getFieldValue("password")} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    Change password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}
