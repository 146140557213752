import React from "react";
import { PageHeader, Tabs } from "antd";
import { RouteComponentProps } from "react-router-dom";

import { EditPatientFormComponent } from "./edit-patient-form.component";
import { QuestionnaireComponent } from "./questionnaire/questionnaire.component";
import { DocumentsComponent } from "./documents/documents.component";

interface IProps extends RouteComponentProps {}

interface IState {
  activeKeyForTabs: string;
}

export class EditPatientComponent extends React.Component<IProps, IState> {
  public state: IState = {
    activeKeyForTabs: "1",
  };

  public componentDidMount(): void {
    this.generateDefaultActiveKeyForTab();
  }

  private generateDefaultActiveKeyForTab = (): void => {
    let activeKeyForTabs;
    const params = new URLSearchParams(this.props.location.search);
    if ("questionnaire" === params.get("tab")) activeKeyForTabs = "2";
    else activeKeyForTabs = "1";
    this.setState({ activeKeyForTabs });
  };

  private onTabChange = (activeKeyForTabs: string) => {
    this.setState({ activeKeyForTabs });
  };

  public render() {
    const { activeKeyForTabs } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push(`/patients`)}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit patient"
        />
        <Tabs activeKey={activeKeyForTabs} onChange={this.onTabChange}>
          <Tabs.TabPane tab="Patient" key="1">
            <EditPatientFormComponent {...this.props} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Questionnaire" key="2">
            <QuestionnaireComponent {...this.props} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Documents" key="3">
            <DocumentsComponent {...this.props} />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}
