export var WEEK_DAY_COUNT = 7;
export function isNullEqual(value1, value2) {
  if (!value1 && !value2) {
    return true;
  }

  if (!value1 || !value2) {
    return false;
  }

  return undefined;
}
export function isSameDecade(generateConfig, decade1, decade2) {
  var equal = isNullEqual(decade1, decade2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  var num1 = Math.floor(generateConfig.getYear(decade1) / 10);
  var num2 = Math.floor(generateConfig.getYear(decade2) / 10);
  return num1 === num2;
}
export function isSameYear(generateConfig, year1, year2) {
  var equal = isNullEqual(year1, year2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return generateConfig.getYear(year1) === generateConfig.getYear(year2);
}
export function getQuarter(generateConfig, date) {
  var quota = Math.floor(generateConfig.getMonth(date) / 3);
  return quota + 1;
}
export function isSameQuarter(generateConfig, quarter1, quarter2) {
  var equal = isNullEqual(quarter1, quarter2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return isSameYear(generateConfig, quarter1, quarter2) && getQuarter(generateConfig, quarter1) === getQuarter(generateConfig, quarter2);
}
export function isSameMonth(generateConfig, month1, month2) {
  var equal = isNullEqual(month1, month2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return isSameYear(generateConfig, month1, month2) && generateConfig.getMonth(month1) === generateConfig.getMonth(month2);
}
export function isSameDate(generateConfig, date1, date2) {
  var equal = isNullEqual(date1, date2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return generateConfig.getYear(date1) === generateConfig.getYear(date2) && generateConfig.getMonth(date1) === generateConfig.getMonth(date2) && generateConfig.getDate(date1) === generateConfig.getDate(date2);
}
export function isSameTime(generateConfig, time1, time2) {
  var equal = isNullEqual(time1, time2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return generateConfig.getHour(time1) === generateConfig.getHour(time2) && generateConfig.getMinute(time1) === generateConfig.getMinute(time2) && generateConfig.getSecond(time1) === generateConfig.getSecond(time2);
}
export function isSameWeek(generateConfig, locale, date1, date2) {
  var equal = isNullEqual(date1, date2);

  if (typeof equal === 'boolean') {
    return equal;
  }

  return generateConfig.locale.getWeek(locale, date1) === generateConfig.locale.getWeek(locale, date2);
}
export function isEqual(generateConfig, value1, value2) {
  return isSameDate(generateConfig, value1, value2) && isSameTime(generateConfig, value1, value2);
}
/** Between in date but not equal of date */

export function isInRange(generateConfig, startDate, endDate, current) {
  if (!startDate || !endDate || !current) {
    return false;
  }

  return !isSameDate(generateConfig, startDate, current) && !isSameDate(generateConfig, endDate, current) && generateConfig.isAfter(current, startDate) && generateConfig.isAfter(endDate, current);
}
export function getWeekStartDate(locale, generateConfig, value) {
  var weekFirstDay = generateConfig.locale.getWeekFirstDay(locale);
  var monthStartDate = generateConfig.setDate(value, 1);
  var startDateWeekDay = generateConfig.getWeekDay(monthStartDate);
  var alignStartDate = generateConfig.addDate(monthStartDate, weekFirstDay - startDateWeekDay);

  if (generateConfig.getMonth(alignStartDate) === generateConfig.getMonth(value) && generateConfig.getDate(alignStartDate) > 1) {
    alignStartDate = generateConfig.addDate(alignStartDate, -7);
  }

  return alignStartDate;
}
export function getClosingViewDate(viewDate, picker, generateConfig) {
  var offset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;

  switch (picker) {
    case 'year':
      return generateConfig.addYear(viewDate, offset * 10);

    case 'quarter':
    case 'month':
      return generateConfig.addYear(viewDate, offset);

    default:
      return generateConfig.addMonth(viewDate, offset);
  }
}