import _typeof from "@babel/runtime/helpers/esm/typeof";
import warning from "rc-util/es/warning";
export function getMotion(_ref, _ref2, menuMode) {
  var prefixCls = _ref.prefixCls,
      motion = _ref.motion,
      _ref$defaultMotions = _ref.defaultMotions,
      defaultMotions = _ref$defaultMotions === void 0 ? {} : _ref$defaultMotions,
      openAnimation = _ref.openAnimation,
      openTransitionName = _ref.openTransitionName;
  var switchingModeFromInline = _ref2.switchingModeFromInline;

  if (motion) {
    return motion;
  }

  if (_typeof(openAnimation) === 'object' && openAnimation) {
    warning(false, 'Object type of `openAnimation` is removed. Please use `motion` instead.');
  } else if (typeof openAnimation === 'string') {
    return {
      motionName: "".concat(prefixCls, "-open-").concat(openAnimation)
    };
  }

  if (openTransitionName) {
    return {
      motionName: openTransitionName
    };
  } // Default logic


  var defaultMotion = defaultMotions[menuMode];

  if (defaultMotion) {
    return defaultMotion;
  } // When mode switch from inline
  // submenu should hide without animation


  return switchingModeFromInline ? null : defaultMotions.other;
}