import React from "react";
import styled from "styled-components";
import { Row, Col, Input } from "antd";

interface IProps {
  label: string;
  defaultValue: string;
  value: string;
  onChange: () => void;
  margin?: string;
  labelColOptions?: { [key: string]: { span: number } };
}

export const QuestionTextArea = (props: IProps) => {
  const { label, defaultValue, value, onChange } = props;

  const labelColOptions = props.labelColOptions ? props.labelColOptions : { xs: { span: 24 }, md: { span: 20 } };

  return (
    <StyledRow {...props}>
      <Col {...labelColOptions}>
        <Label>{label}</Label>
        <div>
          <Input.TextArea defaultValue={defaultValue} value={value} rows={3} onChange={onChange} />
        </div>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: ${(props: IProps) => (props.margin ? props.margin : "20px 0")};
`;

const Label = styled.div`
  margin-bottom: 5px;
`;
