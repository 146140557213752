import React from "react";
import { v4 as uuid } from "uuid";
import { orderBy } from "lodash";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import moment from "moment-timezone";
import { FileExcelOutlined } from "@ant-design/icons/lib";
import styled from "styled-components";
import { VisitData } from "../../../interfaces/visit.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  isCSVLoading: boolean;
  currentPage: number;
  appointments: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  patients: any[];
  companies: any[];
  visits: {
    data: VisitData[];
    currentPage: number;
    total: number;
    isLoading: boolean;
  };
  selectedCompany: any | undefined;
}

export class AppointmentsListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    isCSVLoading: false,
    currentPage: 1,
    appointments: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
    patients: [],
    companies: [],
    visits: {
      data: [],
      currentPage: 1,
      total: 0,
      isLoading: false,
    },
    selectedCompany: undefined,
  };

  public async componentDidMount(): void {
    const params = this.getUrlQueryParams();
    await this.getCompanies();
    const { companies } = this.state;
    if (params.visitId) {
      this.searchForm.current!.setFieldsValue({
        visitId: params.visitId,
      });
    }

    if (params.companyId) {
      this.searchForm.current!.setFieldsValue({
        companyId: params.companyId,
      });
      const company = companies.find((x) => x.id === params.companyId);
      this.setState({ selectedCompany: company }, async () => await this.getVisits());
    }

    if (params.startDate) {
      this.searchForm.current!.setFieldsValue({
        startDate: undefined,
      });
    }

    if (params.endDate) {
      this.searchForm.current!.setFieldsValue({
        endDate: undefined,
      });
    }

    if (params.statuses) {
      this.searchForm.current!.setFieldsValue({
        status: params.statuses,
      });
    }

    if (params.email) {
      this.searchForm.current!.setFieldsValue({
        email: params.email,
      });
    }

    if (params.amount === "all") {
      this.searchForm.current?.setFieldsValue({
        startDate: undefined,
        endDate: undefined,
      });
    }

    if (!this.isObjectEmpty(params)) {
      await this.onSearchFormFinish(this.searchForm.current?.getFieldsValue());
    } else {
      this.searchForm.current?.setFieldsValue({
        startDate: moment().utc().startOf("week"),
        endDate: moment().utc().endOf("week"),
      });
    }
  }

  public getUrlQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const visitId = params.get("visitId");
    const companyId = params.get("companyId");
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    const statuses = params.get("statuses")?.split(",");
    const email = params.get("email");
    const amount = params.get("amount");
    return {
      visitId,
      statuses,
      companyId,
      startDate,
      endDate,
      email,
      amount,
    };
  };

  public getAppointments = async (payload?: any) => {
    this.setState({ isLoading: true });

    try {
      const { data: appointments } = await ApiClient.findAppointments({
        offset: (this.state.currentPage - 1) * this.state.appointments.limit,
        limit: this.state.appointments.limit,
        ...(payload ? payload : {}),
      });

      this.setState({ appointments, isLoading: false }, async () => {
        await this.getPatients();
      });
    } catch (e) {
      message.error("Cannot fetch appointments data");
      this.setState({ isLoading: false });
    }
  };

  public async getPatients() {
    const { appointments } = this.state;

    const patientIds = [...new Set(appointments.data.map((x) => x.patientId))];

    if (!patientIds || !patientIds.length) {
      return;
    }

    const { data: patients } = await ApiClient.findPatients({
      offset: 0,
      limit: 50,
      ids: patientIds,
    });

    this.setState({ patients: patients.data });
  }

  public async findPatientIdsByQuery(payload: any) {
    const { data: patients } = await ApiClient.findPatients({
      offset: 0,
      limit: 50,
      firstName: payload.firstName || undefined,
      lastName: payload.lastName || undefined,
      email: payload.email || undefined,
    });

    const patientIds = patients.data.map((x) => x.id);

    // random uuid is intentional. It's used for clearing results
    return patientIds.length ? patientIds : [uuid()];
  }

  // TODO improve this
  public async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({
      offset: 0,
      limit: "all"
    });

    this.setState({ companies: companies.data });
  }

  public async getVisits(page: number = 1) {
    const { selectedCompany, visits } = this.state;
    this.setState({ visits: { ...visits, isLoading: true } });
    const locationIds = selectedCompany?.locations.map((l: any) => l.id);

    const { data } = await ApiClient.findVisits({
      offset: (page - 1) * 50,
      limit: 50,
      ...(locationIds ? { locationIds } : {}),
    });

    this.setState({
      visits: {
        data: this.removeDuplicates([...visits.data, ...data.data]),
        currentPage: page,
        total: data.total,
        isLoading: false,
      },
    });
  }

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, async () => {
      const { selectedCompany } = this.state;
      const values = this.searchForm.current?.getFieldsValue()!;

      const payload: any = {
        withSurveys: true,
      };

      this.setState({ isLoading: true });

      if (values.companyId && values.visitId) {
        payload.visitIds = [values.visitId];
      }

      if (values.companyId && !values.visitId && selectedCompany && selectedCompany.locations) {
        payload.locationIds = selectedCompany.locations.map((l) => l.id);
      }

      if (values.firstName || values.email || values.lastName) {
        const patientIds = await this.findPatientIdsByQuery(values);

        payload.patientIds = patientIds;
      }

      if (values.status) {
        payload.statuses = values.status;
      }

      if (values.examType) {
        payload.types = [values.examType];
      }

      if (values.appointmentType) {
        payload.appointmentTypes = [values.appointmentType];
      }

      if (values.startDate) {
        payload.startDate = values.startDate.toDate();
      }

      if (values.endDate) {
        payload.endDate = values.endDate.toDate();
      }

      if (values.marketingOptIn !== undefined) {
        payload.marketingOptIn = values.marketingOptIn;
      }

      await this.getAppointments(payload);
    });
  };

  public onSearchFormFinish = async (values: any) => {
    this.resetPagination();
    const { selectedCompany } = this.state;
    const payload: any = {
      withSurveys: true,
    };

    this.setState({ isLoading: true });

    if (values.companyId && values.visitId) {
      payload.visitIds = [values.visitId];
    }

    if (values.companyId && !values.visitId && selectedCompany && selectedCompany.locations) {
      payload.locationIds = selectedCompany.locations.map((l) => l.id);
    }

    if (values.firstName || values.email || values.lastName) {
      const patientIds = await this.findPatientIdsByQuery(values);
      payload.patientIds = patientIds;
    }

    if (values.status) {
      payload.statuses = values.status;
    }

    if (values.examType) {
      payload.types = [values.examType];
    }

    if (values.appointmentType) {
      payload.appointmentTypes = [values.appointmentType];
    }

    if (values.startDate) {
      payload.startDate = values.startDate.toDate();
    }

    if (values.endDate) {
      payload.endDate = values.endDate.toDate();
    }

    if (values.marketingOptIn !== undefined) {
      payload.marketingOptIn = values.marketingOptIn;
    }
    await this.getAppointments(payload);
  };

  public generateCSVFile = async () => {
    const { selectedCompany } = this.state;
    const values = this.searchForm.current?.getFieldsValue()!;
    const payload: any = {};

    this.setState({ isCSVLoading: true });

    if (values.companyId && values.visitId) {
      payload.visitIds = [values.visitId];
    }

    if (values.companyId && !values.visitId && selectedCompany && selectedCompany.locations) {
      payload.locationIds = selectedCompany.locations.map((l) => l.id);
    }

    if (values.firstName || values.email || values.lastName) {
      const patientIds = await this.findPatientIdsByQuery(values);

      payload.patientIds = patientIds;
    }

    if (values.status) {
      payload.statuses = values.status;
    }

    if (values.examType) {
      payload.types = [values.examType];
    }

    if (values.startDate) {
      payload.startDate = values.startDate.toDate();
    }

    if (values.endDate) {
      payload.endDate = values.endDate.toDate();
    }

    if (values.marketingOptIn !== undefined) {
      payload.marketingOptIn = values.marketingOptIn;
    }

    const csv = await ApiClient.generateCSVFileWithAppointments(payload);

    const csvContent = "data:text/csv;charset=utf-8," + csv.data;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", `appointments_${moment().valueOf()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    this.setState({ isCSVLoading: false });
  };

  public onFormValuesChange = (values: any) => {
    const { companies } = this.state;
    if (values.companyId !== undefined) {
      this.searchForm.current?.resetFields(["visitId"]);

      const company = companies.find((x) => x.id === values.companyId) || undefined;

      if (!company) {
        this.setState({ visits: { data: [], currentPage: 1, total: 0, isLoading: false }, selectedCompany: company });
        return;
      }

      this.setState(
        { visits: { data: [], currentPage: 1, total: 0, isLoading: false }, selectedCompany: company },
        async () => await this.getVisits()
      );
    }
  };

  private redirectToPatientTracker(appointmentId: string) {
    const { appointments } = this.state;
    const appointment = appointments.data.find((a) => a.id === appointmentId);
    if (appointment) {
      this.props.history.push({
        pathname: `/patient-tracker`,
        search: `?trackerId=${appointment.timeslot.visit.id}`,
      });
    }
  }

  public onFormReset = () => {
    this.resetPagination();
    this.resetStateOnFormReset();
    this.searchForm.current!.resetFields();
  };

  private resetPagination = () => {
    this.setState({ currentPage: 1 });
  };

  private resetStateOnFormReset = () => {
    this.setState({ visits: { data: [], currentPage: 1, total: 0, isLoading: false }, selectedCompany: undefined });
  };

  private onVisitScroll = async (e) => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const { data, currentPage, total, isLoading } = this.state.visits;
      if (!isLoading && data.length !== total) {
        await this.getVisits(currentPage + 1); //  Call api method
      }
    }
  };

  private removeDuplicates(arr: any[]) {
    return arr.filter((val: any, idx: number, array: any[]) => array.findIndex((t) => t.id === val.id) === idx);
  }

  private isObjectEmpty = (obj: Object) => Object.values(obj).every((val) => val == null);

  private renderPatientType = (patientType: string, visitAppointmentType: string) => {
    if ((patientType === "life-sciences" || visitAppointmentType === "life_sciences") && patientType !== visitAppointmentType) {
    return (<Tag color="red" style={{display: "block"}}>type: {patientType}</Tag>);
    }

    return (<small style={{display:"block"}}>type: {patientType}</span>);
  }

  public render() {
    const { appointments, currentPage, isLoading, isCSVLoading, companies, patients, visits } = this.state;

    const columns = [
      {
        title: "Patient name",
        key: "patientName",
        render: ({ patientId, timeslot }) => {
          const patient = patients.find((x) => x.id === patientId);

          if (!patient) {
            return "-";
          }

          return (
            <>
              <Link to={`/patients/${patient.id}/edit`}>
                {patient.firstName} {patient.lastName}
              </Link>
              {this.renderPatientType(patient.type, timeslot?.visit?.appointmentType)}
            </>
          );
        },
      },
      {
        title: "Visit name",
        key: "visitName",
        render: ({ timeslot }) => {
          return (
            <>
              <Link to={`/visits/${timeslot?.visit?.id}/edit`}>{timeslot?.visit?.name || "-"}</Link>
              <small style={{display:"block"}}>type: {timeslot?.visit?.appointmentType}</small>
            </>
          );
        },
      },
      {
        title: "Visit date",
        key: "visitDate",
        render: ({ timeslot }) => {
          return <>{moment.tz(timeslot?.visit?.date, "YYYY-MM-DD", timeslot?.visit?.timezone).format("l") || "-"}</>;
        },
      },
      {
        title: "Timeslot",
        key: "timeslot",
        render: ({ timeslot }) => {
          const { timezone } = timeslot?.visit;
          return (
            <>
              {moment.tz(timeslot.startDate, timezone).format("LT")}
              {" - "}
              {moment.tz(timeslot.endDate, timezone).format("LT z")}
            </>
          );
        },
      },
      {
        title: "Status",
        key: "status",
        render: ({ status }) => {
          switch (true) {
            case status === "checked_out":
              return <Tag color="green">{status}</Tag>;
            case status.indexOf("cancelled") > -1:
              return <Tag color="red">{status}</Tag>;
            default:
              return <Tag color="blue">{status}</Tag>;
          }
        },
      },
      {
        title: "Marketing Opt In",
        key: "marketingOptIn",
        render: ({ survey }) => {
          switch (true) {
            case survey.marketingOptIn === true:
              return <Tag color="green">{survey.marketingOptIn ? "Yes" : "No"}</Tag>;
            case survey.marketingOptIn === false:
              return <Tag color="red">{survey.marketingOptIn ? "Yes" : "No"}</Tag>;
            default:
              return <Tag>{survey.marketingOptIn ? "Yes" : "No"}</Tag>;
          }
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Space>
              <Link to={`/appointments/${id}/edit`}>
                <Button size="small">Show</Button>
              </Link>
              <Button type="primary" size="small" onClick={() => this.redirectToPatientTracker(id)}>
                Go to Tracker
              </Button>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form
              layout="inline"
              ref={this.searchForm}
              name="search-form-appointments"
              onFinish={this.onSearchFormFinish}
              onValuesChange={this.onFormValuesChange}>
              <Form.Item name="firstName" label="First name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label="Last name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="companyId" label="Company" initialValue={""} style={{ marginBottom: 16 }}>
                <Select
                  showSearch
                  placeholder="Select company"
                  style={{ width: 400 }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Select.Option value={""}>all</Select.Option>
                  {orderBy(companies, [(c) => c.name.toLowerCase()], "asc").map((company) => (
                    <Select.Option value={company.id}>{company.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="visitId" label="Visit" initialValue={""}>
                <Select
                  showSearch
                  disabled={!visits.data.length}
                  placeholder="Select visit"
                  style={{ width: 150 }}
                  onPopupScroll={this.onVisitScroll}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  notFoundContent={"no visits"}>
                  <Select.Option value={""}>-</Select.Option>
                  {visits.data.map((visit) => (
                    <Select.Option value={visit.id}>{visit.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status" label="Status" initialValue={""} style={{ marginBottom: 16 }}>
                <Select mode="multiple" placeholder="Select appointment status" style={{ width: 200 }}>
                  <Select.Option value="">all</Select.Option>
                  <Select.Option value="confirmed">confirmed</Select.Option>
                  <Select.Option value="checked_in">checked in</Select.Option>
                  <Select.Option value="ready_for_exam">ready for exam</Select.Option>
                  <Select.Option value="exam">exam</Select.Option>
                  <Select.Option value="checked_out">checked out</Select.Option>
                  <Select.Option value="cancelled_not_eligible">cancelled not eligible</Select.Option>
                  <Select.Option value="cancelled_refused_exam">cancelled refused exam</Select.Option>
                  <Select.Option value="cancelled_day_of_issue">cancelled day of issue</Select.Option>
                  <Select.Option value="cancelled_no_show">cancelled no show</Select.Option>
                  <Select.Option value="cancelled_late_cancel">cancelled late cancel</Select.Option>
                  <Select.Option value="cancelled_patient_late">cancelled patient late</Select.Option>
                  <Select.Option value="cancelled_admin_cancel">cancelled admin cancel</Select.Option>
                  <Select.Option value="cancelled_early_cancel">cancelled early cancel</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="examType" label="Exam type" initialValue={undefined} style={{ marginBottom: 16 }}>
                <Select placeholder="Select exam type" style={{ width: 200 }}>
                  <Select.Option value={undefined}>all</Select.Option>
                  <Select.Option value="exam">exam</Select.Option>
                  <Select.Option value="cl_exam">cl exam</Select.Option>
                  <Select.Option value="retail_only">retail only</Select.Option>
                  <Select.Option value="f/u">f/u</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="appointmentType"
                label="Appointment type"
                initialValue={undefined}
                style={{ marginBottom: 16 }}>
                <Select placeholder="Select appointment type" style={{ width: 200 }}>
                  <Select.Option value={undefined}>all</Select.Option>
                  <Select.Option value="ro_private">ro private</Select.Option>
                  <Select.Option value="ro_public">ro public</Select.Option>
                  <Select.Option value="telehealth">telehealth</Select.Option>
                  <Select.Option value="styling">styling</Select.Option>
                  <Select.Option value="school">school</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="startDate" label="Start date" style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item name="endDate" label="End date" style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item
                name="marketingOptIn"
                label="Marketing Opt In"
                initialValue={undefined}
                style={{ marginBottom: 16 }}>
                <Select placeholder="Select filter" style={{ width: 120 }}>
                  <Select.Option value={undefined}>all</Select.Option>
                  <Select.Option value={true}>yes</Select.Option>
                  <Select.Option value={false}>no</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
              <Form.Item>
                <ButtonCSV type="default" loading={isCSVLoading} onClick={this.generateCSVFile}>
                  <FileExcelOutlined />
                  Generate CSV
                </ButtonCSV>
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} style={{ marginTop: 24, textAlign: "right" }}>
            <Link to="/appointments/create">
              <Button type="primary">Create appointment</Button>
            </Link>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{appointments.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={appointments.data}
          columns={columns}
          pagination={{
            pageSize: appointments.limit,
            current: currentPage,
            total: appointments.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}

const ButtonCSV = styled(Button)`
  background-color: #ffcd00;
  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid transparent;
    background-color: #ffcd00;
    opacity: 0.8;
  }
`;
