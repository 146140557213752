import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Table, Typography, Space } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import moment from "moment";
import { FileExcelOutlined } from "@ant-design/icons/lib";
import styled from "styled-components";
import { VisitData } from "../../../interfaces/visit.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  isCSVLoading: boolean;
  currentPage: number;
  visits: VisitData[];
  responses: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
    summary: number;
  };
}

export class ResponsesListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    isCSVLoading: false,
    currentPage: 1,
    visits: [],
    responses: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
      summary: 0
    },
  };

  public componentDidMount(): void {
    this.getResponses();
    this.getVisits();
  }

  public async getResponses() {
    this.setState({ isLoading: true });

    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data: responses } = await ApiClient.findNPSResponses({
        offset: (this.state.currentPage - 1) * this.state.responses.limit,
        limit: this.state.responses.limit,
        minRating: Number(searchForm.minRating),
        maxRating: Number(searchForm.maxRating),
        startDate: searchForm.startDate.toISOString(),
        endDate: searchForm.endDate.toISOString(),
        visitIds: searchForm.visitId ? [searchForm.visitId] : [],
        comment: searchForm.comment || undefined,
      });

      this.setState({ responses, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch responses data");
      this.setState({ isLoading: false });
    }
  }

  public generateCSV = async () => {
    this.setState({ isCSVLoading: true });
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const csv = await ApiClient.generateNPSResponsesCSVFile({
        offset: 0,
        limit: "all",
        minRating: Number(searchForm.minRating),
        maxRating: Number(searchForm.maxRating),
        startDate: searchForm.startDate.toISOString(),
        endDate: searchForm.endDate.toISOString(),
        visitIds: searchForm.visitId ? [searchForm.visitId] : [],
        comment: searchForm.comment || undefined,
      });

      const csvContent = "data:text/csv;charset=utf-8," + csv.data;
      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", `nps_${moment().valueOf()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.setState({ isCSVLoading: false });
    } catch (e) {
      message.error("Cannot generate CSV file");
      this.setState({ isCSVLoading: false });
    }
  };

  public async getVisits() {
    const { data: visits } = await ApiClient.findVisits({ limit: "all", offset: 0 });
    this.setState({ visits: visits.data });
  }

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getResponses();
    });
  };

  public onSearchFormFinish = () => {
    this.getResponses();
  };

  public clearForm = () => {
    this.searchForm.current!.resetFields();
  };

  public render() {
    const { responses, currentPage, isLoading, isCSVLoading, visits } = this.state;

    const columns = [
      {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
      },
      {
        title: "Phone number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Visit",
        key: "visit",
        render: ({ visitId }) => {
          const visit = visits.find((x) => x.id === visitId);

          if (!visit) {
            return "-";
          }

          return <Link to={`/visits/${visitId}/edit`}>{visit.name}</Link>;
        },
      },
      {
        title: "Appointment",
        key: "appointment",
        render: ({ appointmentId }) => {
          if (!appointmentId) {
            return "-";
          }

          return <Link to={`/appointments/${appointmentId}/edit`}>Show appointment</Link>;
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form-response" onFinish={this.onSearchFormFinish}>
              <Form.Item
                name="minRating"
                label="Min rating"
                rules={[{ required: true }]}
                style={{ marginBottom: 16 }}
                initialValue={"1"}>
                <Select style={{ width: 100 }}>
                  {[...new Array(10)].map((_, i) => (
                    <Select.Option value={i + 1}>{i + 1}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="maxRating"
                label="Max rating"
                rules={[{ required: true }]}
                style={{ marginBottom: 16 }}
                initialValue={"10"}>
                <Select style={{ width: 100 }}>
                  {[...new Array(10)].map((_, i) => (
                    <Select.Option value={i + 1}>{i + 1}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="comment" label="Comment">
                <Input />
              </Form.Item>
              <Form.Item name="visitId" label="Visit" style={{ marginBottom: 16 }} initialValue={""}>
                <Select
                  style={{ width: 200 }}
                  showSearch
                  placeholder="Select a visit"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Select.Option value="">all</Select.Option>
                  {visits.map((visit) => (
                    <Select.Option value={visit.id}>{visit.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true }]}
                initialValue={moment().startOf("week")}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End date"
                rules={[{ required: true }]}
                initialValue={moment().endOf("week")}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Space>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                  <Button onClick={this.clearForm}>Clear</Button>
                </Space>
              </Form.Item>
              <Form.Item>
                <ButtonCSV type="default" loading={isCSVLoading} onClick={this.generateCSV}>
                  <FileExcelOutlined />
                  Generate CSV
                </ButtonCSV>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Responses: <strong>{responses.total}</strong>
        </Typography.Text>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          NPS: <strong>{responses.summary ? `${responses.summary}%` : '-'}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={responses.data}
          columns={columns}
          pagination={{
            pageSize: responses.limit,
            current: currentPage,
            total: responses.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}

const ButtonCSV = styled(Button)`
  background-color: #ffcd00;
  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid transparent;
    background-color: #ffcd00;
    opacity: 0.8;
  }
`;
