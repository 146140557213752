export interface IUserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isActive: boolean;
  isPasswordSet: boolean;
  position: string | null;
  twoFactorAuthentication: any;
  failedLoginAttempts: string[];
  createdAt?: string;
  permissions: string[];
}
export const defaultUserDataValue: IUserData = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  role: "",
  isActive: false,
  isPasswordSet: false,
  position: null,
  twoFactorAuthentication: null,
  failedLoginAttempts: [],
  permissions: [],
};
