import React from "react";
import { Table } from "react-fluid-table";
import styled from "styled-components";

function VirtualTable(props) {
  const { columns, dataSource } = props;

  const rowStyle = (index) => ({
    borderTop: dataSource[index].isNewWeek ? "1px solid black" : "none",
    backgroundColor: index % 2 === 0 ? "#fff" : "#ebebeb",
    fontSize: "12px",
    fontWeight: "500",
  });

  return (
    <>
      <Table
        data={dataSource}
        columns={columns}
        rowStyle={rowStyle}
        tableHeight={600}
        rowRenderer={HoverRow}
        headerStyle={{
          color: "rgba(0,0,0,.85)",
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #f0f0f0",
          fontSize: "12px",
          marginBottom: "20px",
        }}
      />
    </>
  );
}

export default VirtualTable;

const HoverRow = styled.div`
  :hover {
    background-color: #ebebeb !important;
  }
`;
