export class Parser {
  static parseVisitId(row: any): string | undefined {
    const value = row.visitId || row.visitid;
    return value ? value.trim() : undefined;
  }

  static parseDate(row: any): string | undefined {
    const value = row.date;
    return value ? value.trim() : undefined;
  }

  static parseTime(row: any): string | undefined {
    const value = row.time;
    return value ? value.trim().toUpperCase() : undefined;
  }

  static parseTimeZone(row: any): string | undefined {
    const value = row.timezone || row.timeZone;
    return value ? value.trim() : undefined;
  }

  static parseFirstName(row: any): string | undefined {
    const value = row.firstName || row.firstname;
    return value ? value.trim() : undefined;
  }

  static parseLastName(row: any): string | undefined {
    const value = row.lastName || row.lastname;
    return value ? value.trim() : undefined;
  }

  static parseEmail(row: any): string | undefined {
    const value = row.email;
    return value ? value.trim().toLowerCase() : undefined;
  }

  static parseGender(row: any): string {
    const value = row.gender;
    return value ? value.trim().toLowerCase() : undefined;
  }

  static parsePhoneNumber(row: any): string | undefined {
    let value = row.phonenumber || row.phoneNumber || row.phone;
    value = value ? value.trim().replaceAll("-", "") : undefined;
    if (value && value.length === 10) return `1${value}`;
    return value;
  }

  static parseDob(row: any): string | undefined {
    const value = row.dob;
    return value ? value.trim().toLowerCase() : undefined;
  }

  static parseHippa(row: any): boolean | undefined {
    let value = row.hippa;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseCity(row: any): string | undefined {
    const value = row.city;
    return value ? value.trim() : undefined;
  }

  static parseStreet(row: any): string | undefined {
    const value = row.street;
    return value ? value.trim() : undefined;
  }

  static parseState(row: any): string | undefined {
    const value = row.state;
    return value ? value.trim().toUpperCase() : undefined;
  }

  static parseZipCode(row: any): string | undefined {
    let value = row.zipcode || row.zipCode;
    value = value ? value.trim() : undefined;
    return value;
  }

  static parseSmsSubscription(row: any): boolean | undefined {
    let value = row.smssubscription || row.smsSubscription;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseWearGlasses(row: any): boolean | undefined {
    let value = row.WearGlasses || row.wearGlasses || row.Wearglasses || row.wearglasses;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseWearContactLenses(row: any): boolean | undefined {
    let value = row.WearContactLenses || row.wearcontactLenses || row.wearcontactlenses || row.wearContactLenses;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseExamType(row: any): string {
    const value = row.ExamType || row.examtype || row.examType;
    return value ? value.trim().toLowerCase() : "exam";
  }

  static parseMedicalInsuranceProviderId(row: any): string | undefined {
    const value = row.MedicalInsuranceProviderId || row.medicalinsuranceproviderid || row.medicalInsuranceProviderId;
    return value ? value.trim() : undefined;
  }

  static parseVisionInsuranceProviderId(row: any): string | undefined {
    const value = row.VisionInsuranceProviderId || row.visioninsuranceproviderid || row.visionInsuranceProviderId;
    return value ? value.trim() : undefined;
  }

  static parseMedicalSubscriberId(row: any): string | undefined {
    const value = row.MedicalSubscriberId || row.medicalsubscriberid || row.medicalSubscriberId;
    return value ? value.trim() : undefined;
  }

  static parseVisionSubscriberId(row: any): string | undefined {
    const value = row.VisionSubscriberId || row.visionsubscriberid || row.visionSubscriberId;
    return value ? value.trim() : undefined;
  }

  static parseCaregiver(row: any): string | undefined {
    const value = row.caregiver;
    return value ? value.trim() : undefined;
  }

  static parseCaregiverRelationship(row: any): string | undefined {
    const value = row.caregiverRelationship;
    return value ? value.trim().toLowerCase() : undefined;
  }

  static parseQualified(row: any): boolean | undefined {
    let value = row.qualified;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseConsent(row: any): boolean | undefined {
    let value = row.consent;
    value = value ? value.trim().toLowerCase() : undefined;
    return value;
  }

  static parseLanguage(row: any): string | undefined {
    const value = row.language;
    return value ? value.trim().toLowerCase() : undefined;
  }
}
