import React from "react";
import { Divider, Table, Typography, Tooltip } from "antd";
import { IMonthlyMetrics } from "../booking-board.interfaces";

interface IProps {
  monthlyMetrics: IMonthlyMetrics;
}

export class BookingBoardVisitsMetrics extends React.Component<IProps> {
  public render() {
    const { monthlyMetrics } = this.props;

    const columns = [
      {
        title: (
          <Tooltip title="count of completed visits this month">
            <span>Complete</span>
          </Tooltip>
        ),
        dataIndex: "completeVisits",
        key: "completeVisits",
        render: (value: number) => <span>{value}</span>,
      },
      {
        title: (
          <Tooltip title="count of (booked + confirmed) visits this month">
            <span>Booked</span>
          </Tooltip>
        ),
        dataIndex: "bookedVisits",
        key: "bookedVisits",
        render: (value: number) => <span>{value}</span>,
      },
      {
        title: (
          <Tooltip title="count of (internal hold + ops hold + cs hold date offered) visits this month">
            <span>Hold</span>
          </Tooltip>
        ),
        dataIndex: "holdVisits",
        key: "holdVisits",
        render: (value: number) => <span>{value}</span>,
      },
      {
        title: (
          <Tooltip title="count of available, unbooked clinics this month">
            <span>Open</span>
          </Tooltip>
        ),
        dataIndex: "openSlots",
        key: "openSlots",
        render: (value: number) => <span>{value}</span>,
      },
      {
        title: (
          <Tooltip title="count of all the clinics on all bookable days this month">
            <span>Available</span>
          </Tooltip>
        ),
        dataIndex: "availableSlots",
        key: "availableSlots",
        render: (value: number) => <span>{value}</span>,
      },
      {
        title: (
          <Tooltip title="(complete + booked) / available">
            <span>Utilization</span>
          </Tooltip>
        ),
        dataIndex: "utilization",
        key: "utilization",
        render: (value: number) => <span>{value.toFixed(2)}%</span>,
      },
    ];

    return (
      <div style={{ marginTop: 20 }}>
        <Typography.Title level={3}>Monthly metrics</Typography.Title>
        <Divider />
        <Table columns={columns} dataSource={[monthlyMetrics]} pagination={false} />
      </div>
    );
  }
}
