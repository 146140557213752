import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import {
  message,
  Button,
  Form,
  Input,
  PageHeader,
  Card,
  Row,
  Col,
  DatePicker,
  Select,
  Table,
  Tag,
  Alert,
  Modal,
} from "antd";
import { FormInstance } from "antd/es/form";
import { orderBy } from "lodash";
import moment from "moment-timezone";
import { Moment } from "moment";
import delay from "delay";

import { ApiClient } from "../../../api-client/api.client";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import { validationPatterns } from "../../../helpers/validation.helper";
import { detectUrl } from "../../../helpers/regex.helper";
import { getVisitStatusesWhereTruckIsRequire, VisitStatuses } from "../../../helpers/visit.helper";
import { ITruck } from "../../../interfaces/truck.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  visit: any;
  companies: any[];
  company: any; // FIXME:
  trucks: any[]; // FIXME:
  truckAvailabilities: any[]; // FIXME:
  excludedTrucks: string[];
  excludedDates: string[];
  parkingLocationConfig: {
    showAlert: boolean;
    link: string;
  };
  isTruckRequire: boolean;
}

export class EditVisitComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    visit: undefined,
    companies: [],
    company: undefined,
    trucks: [],
    truckAvailabilities: [],
    excludedTrucks: [],
    excludedDates: [],
    parkingLocationConfig: {
      showAlert: false,
      link: "",
    },
    isTruckRequire: false,
  };

  private form = React.createRef<FormInstance>();

  public componentDidMount(): void {
    this.getVisit();
    this.getCompanies();
  }

  private async getVisit() {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    const { data: visit } = await ApiClient.getVisit(id);
    const isTruckRequire = getVisitStatusesWhereTruckIsRequire().includes(visit.status);
    this.setState({ visit, isTruckRequire }, () => {
      this.getCompany();
      this.getExcludedTrucks(visit.date);
      this.getExcludedDates(moment(visit.date).format("YYYY-MM"));
    });
  }

  private async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({ offset: 0, limit: "all" });
    this.setState({ companies: companies.data });
  }

  private async getCompany() {
    const { visit } = this.state;
    const { data: company } = await ApiClient.getCompanyByLocationId(visit.locationId);

    company.locations?.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ company }, () => {
      this.getTrucks();
    });
  }

  private async getTrucks() {
    const { data: trucks } = await ApiClient.findTrucks();
    this.setState({ isLoading: false });

    this.setState({ trucks }, () => {
      this.getTruckAvailabilites(
        trucks.filter((truck: ITruck) => !truck.isDisabled).map((x) => x.id),
        moment(this.state.visit.date).format("YYYY-MM-DD"),
        () => {
          this.setInitialValues();
        }
      );
    });
  }

  private async getTruckAvailabilites(truckIds: string[], date: string, callback?: any) {
    const truckAvailabilitiesToUpdate = [];

    for (const truckId of truckIds) {
      const { data } = await ApiClient.getTruckAvailability(truckId, { dates: [date] });

      truckAvailabilitiesToUpdate.push({
        truckId,
        date,
        isReserved: data[0].isReserved,
      });
    }

    this.setState({ truckAvailabilities: truckAvailabilitiesToUpdate }, () => {
      if (callback) {
        callback();
      }
    });
  }

  private async getExcludedTrucks(date: string) {
    try {
      const response = await ApiClient.getExcludedTrucksForSpecificDate(date);
      this.setState({ excludedTrucks: response.data.truckIds });
    } catch (e) {}
  }

  private async getExcludedDates(month: string) {
    try {
      const response = await ApiClient.getExcludedDatesForSpecificMonth(month);
      this.setState({ excludedDates: response.data.dates });
    } catch (e) {}
  }

  private onFinish = async (values: any) => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    const { isTruckRequire } = this.state;

    try {
      if (values.truckId) {
        const oldTruckId = this.getVisitTruckId();
        if (oldTruckId) {
          await ApiClient.removeTruckReservation(oldTruckId, id);
        }

        if (isTruckRequire) {
          await ApiClient.reserveTruck(values.truckId, id, moment(values.date).format("YYYY-MM-DD"));
        }
      }

      await ApiClient.updateVisit(id, {
        name: values.name,
        status: values.status,
        date: moment(values.date).format("YYYY-MM-DD"),
        locationId: values.locationId,
        timezone: values.timezone,
        parkingLocationDetails: values.parkingLocationDetails,
        SFVisitId: values.SFVisitId || undefined,
        notes: values.notes || undefined,
        trackingNumber: values.trackingNumber || undefined,
        issueNotes: values.issueNotes || undefined,
      });

      message.success("Visit successfully updated!");

      if (this.state.visit.locationId !== values.locationId) {
        this.showOnLocationChangeModal(id);
      }
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    } finally {
      await this.getVisit();
    }
  };

  private onChange = async (values: any) => {
    const { trucks } = this.state;

    if (values.date) {
      const date = moment(values.date).format("YYYY-MM-DD");
      await this.getExcludedTrucks(date);

      this.getTruckAvailabilites(
        trucks.map((x) => x.id),
        date
      );
      this.form.current?.resetFields(["truckId"]);
    }

    if (values.companyId) {
      this.setState({});
      this.form.current?.resetFields(["locationId"]);
    }
  };

  private getTruckAvailabilityByTruckId = (truckId: string) => {
    return this.state.truckAvailabilities.find((x) => x.truckId === truckId);
  };

  private getVisitTruckId = () => {
    const { visit, trucks } = this.state;

    if (!visit || !trucks.length) {
      return undefined;
    }

    return trucks.find((x) => !!x.truckReservations.find((tr) => tr.visitId === visit.id))?.id;
  };

  private async setInitialValues() {
    if (!this.form.current) {
      await delay(50);
      await this.setInitialValues();
      return;
    }

    const { visit, company } = this.state;
    const truckId = this.getVisitTruckId();

    this.detectLinkInParkingLocationDetails(visit.parkingLocationDetails);
    this.form.current!.setFieldsValue({
      name: visit.name,
      status: visit.status,
      appointmentType: visit.appointmentType,
      companyId: company.id,
      locationId: visit.locationId,
      SFVisitId: visit.SFVisitId,
      date: moment(visit.date, "YYYY-MM-DD"),
      truckId,
      parkingLocationDetails: visit.parkingLocationDetails,
      issueNotes: visit.issueNotes,
      trackingNumber: visit.trackingNumber,
      timezone: visit.timezone,
    });
  }

  private synchronizeSalesforceVisit = async () => {
    const { id } = this.props.match.params;
    await ApiClient.synchronizeSalesforceVisit(id);
    this.getVisit();
    message.success("Visit synchronized!");
  };

  private detectLinkInParkingLocationDetails = (parkingLocationDetails: string) => {
    const links = detectUrl(parkingLocationDetails);
    this.setState({
      parkingLocationConfig: { showAlert: !!links.length, link: links.length ? links[0] : "" },
    });
  };

  private showOnLocationChangeModal(visitId: string) {
    Modal.confirm({
      icon: null,
      content: <div>Do you want to notify all the signed up patients about visit's location change?</div>,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await ApiClient.sendUpdateLocationEmail(visitId);
          message.success("Sign ups have been notified about the change");
        } catch (error) {
          message.error("Couldn't notify sign ups about the change");
        }
      },
    });
  }

  private onChangeVisitStatus = (status: string) => {
    const isTruckRequire = this.form.current ? getVisitStatusesWhereTruckIsRequire().includes(status) : false;
    this.setState({ isTruckRequire });
    if (!isTruckRequire) {
      this.form.current!.resetFields(["truckId"]);
    }
  };

  public render() {
    const { id } = this.props.match.params;
    const {
      company,
      companies,
      trucks,
      truckAvailabilities,
      isLoading,
      visit,
      parkingLocationConfig,
      excludedTrucks,
      excludedDates,
      isTruckRequire,
    } = this.state;

    const truckId = this.getVisitTruckId();
    const hasAnyAppointment = visit ? visit.timeslots.find((timeslot) => timeslot.appointments.length) : false;
    const selectedCompany = this.form.current
      ? companies.find((c) => c.id === this.form.current!.getFieldValue("companyId"))
      : undefined;

    const shouldRenderAppointments = (appointments: typeof visit.timeslots) =>
      Array.isArray(appointments) && appointments.length > 0;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/visits")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit visit"
        />
        <Row>
          {visit?.status.includes("inactive") && (
            <Col span={24}>
              <Alert
                message="Inactive visit"
                description={
                  <>
                    <strong>{visit.name}</strong> is currently <strong>inactive</strong>. When you are ready to add
                    appointments to this visit, please change the status to <strong>active</strong>.
                  </>
                }
                type="warning"
                style={{
                  marginBottom: 20,
                }}
              />
            </Col>
          )}
          {visit?.status.includes("cancelled") && (
            <Col span={24}>
              <Alert
                message="Cancelled visit"
                description={
                  <>
                    <strong>{visit.name}</strong> is <strong>cancelled</strong>.
                  </>
                }
                type="error"
                style={{
                  marginBottom: 20,
                }}
              />
            </Col>
          )}
          <Col xs={24}>
            <Card
              title="Visit"
              loading={isLoading}
              style={{
                marginBottom: 24,
              }}>
              {visit ? (
                <Form
                  layout="vertical"
                  ref={this.form}
                  name="edit-visit"
                  onFinish={this.onFinish}
                  onValuesChange={this.onChange}>
                  <Row gutter={24}>
                    <Col xs={24} lg={12}>
                      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                        <Select placeholder="Select status" onChange={this.onChangeVisitStatus}>
                          {VisitStatuses.map((status) => (
                            <Select.Option key={status.value} value={status.value}>
                              {status.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name="appointmentType" label="Appointment type" rules={[{ required: true }]}>
                        <Input disabled />
                      </Form.Item>
                      {company ? (
                        <>
                          <Form.Item name="companyId" label="Company" rules={[{ required: true }]}>
                            <Select
                              disabled={hasAnyAppointment}
                              showSearch
                              placeholder="Select company"
                              defaultActiveFirstOption={false}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }>
                              {orderBy(companies, [(c) => c.name.toLowerCase()], "asc").map((c) => (
                                <Select.Option value={c.id} disabled={c.isDisabled}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item name="locationId" label="Location" rules={[{ required: true }]}>
                            <Select
                              showSearch
                              placeholder="Select location"
                              defaultActiveFirstOption={false}
                              filterOption={(input: any, option: any) =>
                                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={selectedCompany ? !selectedCompany.locations.length : true}>
                              {selectedCompany &&
                                selectedCompany.locations
                                  .sort((a: any, b: any) => a.name.localeCompare(b.name))
                                  .map((location: any) => (
                                    <Select.Option value={location.id}>
                                      {location.name} ({location.timezone})
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Form.Item>
                        </>
                      ) : null}
                      <Row gutter={16}>
                        <Col xs={12}>
                          <Form.Item
                            name="SFVisitId"
                            label="SFVisitId"
                            style={{ marginBottom: 0 }}
                            extra={
                              visit.SFVisitSyncAt ? (
                                <div>
                                  Last sync at: {dateFormatter(visit.SFVisitSyncAt)}
                                  <br />
                                  <a href={`${process.env.SALESFORCE_URL}/${visit.SFVisitId}`} target="_blank">
                                    Open in Salesforce
                                  </a>
                                </div>
                              ) : undefined
                            }
                            rules={[
                              {
                                pattern: validationPatterns.salesforceVisitId,
                                message:
                                  "Invalid Salesforce Visit ID.  Please ensure to input the ID starting with a0D",
                              },
                            ]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Button onClick={this.synchronizeSalesforceVisit} htmlType="button" style={{ marginTop: 30 }}>
                            Synchronize Salesforce Visit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                        <DatePicker
                          format="l"
                          allowClear={false}
                          disabledDate={(currentDate: Moment) =>
                            excludedDates.includes(currentDate.format("YYYY-MM-DD"))
                          }
                          onPanelChange={(date: Moment) => this.getExcludedDates(date.format("YYYY-MM"))}
                        />
                      </Form.Item>
                      {visit && trucks.length && truckAvailabilities.length ? (
                        <Form.Item name="truckId" label="Clinic" rules={[{ required: isTruckRequire }]}>
                          <Select
                            placeholder="Select clinic"
                            disabled={!(trucks.length && truckAvailabilities.length) || !isTruckRequire}>
                            {orderBy(trucks, ["isDisabled", "name"], "asc").map((truck) => {
                              const truckAvailability = this.getTruckAvailabilityByTruckId(truck.id);

                              let isReserved = truckAvailability ? truckAvailability.isReserved : false;

                              if (truckId === truck.id) {
                                isReserved = false;
                              }

                              return (
                                <Select.Option
                                  disabled={isReserved || truck.isDisabled || excludedTrucks.includes(truck.id)}
                                  value={truck.id}>
                                  {truck.name}
                                  {truck.isDisabled ? " (disabled)" : ""}
                                  {isReserved ? " (reserved)" : ""}
                                  {excludedTrucks.includes(truck.id) ? " (unavailable)" : ""}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : null}
                      <Form.Item name="timezone" label="Timezone" rules={[{ required: true }]}>
                        <Select showSearch placeholder="Select timezone">
                          <Select.Option value="America/New_York">America/New_York (Eastern)</Select.Option>
                          <Select.Option value="America/Chicago">America/Chicago (Central)</Select.Option>
                          <Select.Option value="America/Los_Angeles">America/Los_Angeles (Pacific)</Select.Option>
                          <Select.Option value="America/Denver">America/Denver (Mountain)</Select.Option>
                        </Select>
                      </Form.Item>
                      {parkingLocationConfig.showAlert && (
                        <Form.Item>
                          <Alert
                            message="Link detected"
                            description={
                              <>
                                This link will be visible for user in the calendar location field: <br />
                                {parkingLocationConfig.link}
                              </>
                            }
                            type="info"
                            style={{ marginBottom: "20px" }}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="parkingLocationDetails"
                        label="Parking location details"
                        rules={[{ required: true }]}>
                        <Input.TextArea
                          onChange={(e) => {
                            const { value } = e.target;
                            this.detectLinkInParkingLocationDetails(value);
                            this.form.current.setFieldsValue({ parkingLocationDetails: value });
                          }}
                        />
                      </Form.Item>
                      <Form.Item name="trackingNumber" label="Tracking number">
                        <Input />
                      </Form.Item>
                      <Form.Item name="issueNotes" label="Issue notes">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item style={{ marginBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button loading={isLoading} type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : null}
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              title="Visit timeslots"
              loading={isLoading}
              extra={
                <>
                  <Link to={`/visits/${visit?.id}/timeslots/edit`}>
                    <Button htmlType="button" type="primary">
                      Edit timeslots
                    </Button>
                  </Link>
                </>
              }>
              <Table
                bordered
                size="small"
                pagination={false}
                scroll={{ y: 400 }}
                dataSource={visit?.timeslots.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)) || []}
                columns={[
                  {
                    title: "Start time",
                    key: "startTime",
                    width: 250,
                    render: ({ startDate }) => <>{moment.tz(startDate, visit.timezone).format("LT")}</>,
                  },
                  {
                    title: "End time",
                    key: "endTime",
                    width: 250,
                    render: ({ endDate }) => <>{moment.tz(endDate, visit.timezone).format("LT")}</>,
                  },
                  {
                    title: "SMS subscription",
                    key: "smsSubscription",
                    width: 200,
                    render: ({ appointments }) =>
                      shouldRenderAppointments(appointments) &&
                      appointments.map((appointment: any) =>
                        appointment.smsSubscription ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
                      ),
                  },
                  {
                    title: "Appointments",
                    key: "appointments",
                    render: ({ appointments }) =>
                      shouldRenderAppointments(appointments) &&
                      appointments.map((appointment: any) => (
                        <Tag color="green">
                          <Link to={`/appointments/${appointment.id}/edit`}>{appointment.type === 'retail_only' ? 'RetailOnly' : appointment.status}</Link>
                        </Tag>
                      )),
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
