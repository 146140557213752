import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Form, Input, message, PageHeader, Row, Table } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  truck: any; // FIXME:
}

export class EditTruckComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    truck: undefined,
  };

  public componentDidMount(): void {
    this.getTruck();
  }

  public getTruck = async () => {
    try {
      this.setState({ isLoading: true });
      const id = this.props.match.params?.id;
      const { data: truck } = await ApiClient.getTruck(id);
      this.setState({ truck, isLoading: false });
    } catch {
      message.error("Clinic cannot be fetched!");
    }
  };

  public deactivate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.deactivateTruck(id);
      message.success("Clinic deactivated!");
      await this.getTruck();
    } catch {
      message.error("Clinic cannot be deactivated!");
    }
  };

  public activate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.activateTruck(id);
      message.success("Clinic activated!");
      await this.getTruck();
    } catch {
      message.error("Clinic cannot be activated!");
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { truck } = this.state;

      await ApiClient.updateTruck(truck.id, {
        name: values.name,
      });

      message.success("Clinic successfully saved!");
      this.getTruck();
    } catch (e) {
      console.log(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading, truck } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/clinics")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit clinic"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Clinic"
              loading={isLoading}
              extra={
                <>
                  {truck?.isDisabled ? (
                    <Button onClick={this.activate}>Activate</Button>
                  ) : (
                    <Button onClick={this.deactivate}>Deactivate</Button>
                  )}
                </>
              }>
              <Form layout="vertical" ref={this.form} name="control-hooks" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={truck?.name}>
                  <Input />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
