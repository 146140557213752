import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Col, message, notification, PageHeader, Row, Space, Spin } from "antd";
import { defaultUserDataValue, IUserData } from "../../../../interfaces/userData.interface";
import { ApiClient } from "../../../../api-client/api.client";
import { ResetPasswordComponent } from "./reset-password.component";
import { ChangeEmailComponent } from "./change-email.component";
import { ActivatePatientAccount } from "./activate-patient-account";

interface IProps extends RouteComponentProps {}

interface IState {
  userData: IUserData;
  account: any;
  isLoading: {
    account: boolean;
    userData: boolean;
  };
}

export class EditAccountComponent extends React.Component<IProps, IState> {
  state: IState = {
    isLoading: {
      account: false,
      userData: false,
    },
    userData: defaultUserDataValue,
    account: undefined,
  };
  componentDidMount = () => {
    this.getUserData();
    this.getPatientAccount();
  };

  public async getPatientAccount() {
    try {
      this.setState({
        isLoading: { ...this.state.isLoading, account: true },
      });
      const { id } = this.props.match.params;
      const { data: account } = await ApiClient.getAccount(id);
      this.setState({
        account,
      });
    } catch {
      message.error("Patient account cannot be fetched!");
    } finally {
      this.setState({
        isLoading: { ...this.state.isLoading, account: false },
      });
    }
  }

  public getEmails(account: any): string[] {
    const emails = [];
    if (!account) return [];
    if (account.email) emails.push(account.email);
    if (account.secondaryEmail) emails.push(account.secondaryEmail);
    return emails;
  }

  render(): React.ReactNode {
    const { userData, account, isLoading } = this.state;
    const { id } = this.props.match.params;

    const hasAccessToResetPassword = userData.permissions.includes("RESET_PATIENT_PASSWORD");
    const hasAccessToChangeEmail = userData.permissions.includes("CHANGE_PATIENT_EMAIL");
    const hasAccessToActivatePatientAccount = userData.permissions.includes("ACTIVATE_PATIENT_ACCOUNT");

    if (isLoading.userData || isLoading.account) {
      return (
        <Space
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          align="center"
          size="middle">
          <Spin size="large" tip="Loading..." />
        </Space>
      );
    }
    const emails = this.getEmails(account);
    const accountId = account?.id;
    const activeAccount = account?.isActive ?? false;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/settings/patient-accounts")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit patient account"
        />
        <Row gutter={[24, 24]}>
          {hasAccessToChangeEmail && (
            <>
              <Col xs={24} md={24} lg={12}>
                <ChangeEmailComponent type="primary" id={id} currentEmail={account.email} />
              </Col>
              <Col xs={24} md={24} lg={12}>
                <ChangeEmailComponent type="secondary" id={id} currentEmail={account.secondaryEmail} />
              </Col>
            </>
          )}
          {hasAccessToResetPassword && (
            <Col xs={24} md={24} lg={12}>
              <ResetPasswordComponent emails={emails} />
            </Col>
          )}
          {hasAccessToActivatePatientAccount && accountId && (
            <Col xs={24} md={24} lg={12}>
              <ActivatePatientAccount accountId={accountId} emails={emails} active={activeAccount} />
            </Col>
          )}
        </Row>
      </>
    );
  }

  private async getUserData(): any {
    this.setState({
      isLoading: { ...this.state.isLoading, userData: true },
    });

    try {
      const {
        data: { data: userData },
      } = await ApiClient.getAccountsMe();
      this.setState({ userData });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Check authorization token!",
      });
    } finally {
      this.setState({
        isLoading: { ...this.state.isLoading, userData: false },
      });
    }
  }
}
