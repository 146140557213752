import React from "react";
import { Steps } from "antd";

type IProps = {
  current: number;
  status: "wait" | "process" | "finish" | "error";
  steps: {
    title: string;
    description: string;
  }[];
};

const StepsBar = ({ current, status, steps }: IProps) => {
  if (!steps || (steps && !steps.length)) return null;

  return (
    <Steps current={current} status={status}>
      {steps.map((step, i) => {
        return <Steps.Step key={i} title={step.title} description={step.description} />;
      })}
    </Steps>
  );
};

export default StepsBar;
