"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var React = _interopRequireWildcard(require("react"));

var _KeyOutlined = _interopRequireDefault(require("@ant-design/icons-svg/lib/asn/KeyOutlined"));

var _AntdIcon = _interopRequireDefault(require("../components/AntdIcon"));

// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
var KeyOutlined = function KeyOutlined(props, ref) {
  return React.createElement(_AntdIcon.default, Object.assign({}, props, {
    ref: ref,
    icon: _KeyOutlined.default
  }));
};

KeyOutlined.displayName = 'KeyOutlined';

var _default = React.forwardRef(KeyOutlined);

exports.default = _default;