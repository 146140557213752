import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, Form, Input, message, Row, Select, Table, Tag, Typography, AutoComplete } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  currentPage: number;
  companies: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  autoCompleteLocations: {
    data: any[];
    total: number;
    offset: number;
    limit: number;
  };
  isAutoCompleteLocationsLoading: boolean;
}

export class CompaniesListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  private autoCompleteLocationsDefaults = {
    data: [],
    total: 0,
    offset: 0,
    limit: 30,
  };

  public state: IState = {
    isLoading: false,
    currentPage: 1,
    companies: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
    autoCompleteLocations: {
      data: [],
      total: 0,
      offset: 0,
      limit: 30,
    },
    isAutoCompleteLocationsLoading: false,
  };

  public componentDidMount(): void {
    this.getCompanies();
    this.getAutoCompleteLocations();
  }

  public async getCompanies() {
    this.setState({ isLoading: true });

    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data: companies } = await ApiClient.findCompanies({
        offset: (this.state.currentPage - 1) * this.state.companies.limit,
        limit: this.state.companies.limit,
        name: searchForm.name,
        slug: searchForm.slug,
        locationName: searchForm.locationName,
        ...(searchForm.type === "all" ? {} : { type: searchForm.type }),
        ...(searchForm.isDisabled === undefined ? {} : { isDisabled: searchForm.isDisabled }),
      });

      this.setState({ companies, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch companies data");
      this.setState({ isLoading: false });
    }
  }

  public async getAutoCompleteLocations(offset: number = 0, limit: number = 30) {
    try {
      this.setState({ isAutoCompleteLocationsLoading: true });

      const name = this.searchForm.current!.getFieldValue("locationName") || undefined;

      const { data: autoCompleteLocations } = await ApiClient.getLocations({
        name,
        offset,
        limit,
      });

      if (!autoCompleteLocations) {
        throw new Error("couldn't fetch locations for auto complete");
      }

      this.setState({
        autoCompleteLocations: {
          data: this.removeDuplicates([...this.state.autoCompleteLocations.data, ...autoCompleteLocations.data]),
          offset: autoCompleteLocations.offset,
          limit: autoCompleteLocations.limit,
          total: autoCompleteLocations.total,
        },
        isAutoCompleteLocationsLoading: false,
      });
    } catch (e) {
      this.setState({
        isAutoCompleteLocationsLoading: false,
        autoCompleteLocations: this.autoCompleteLocationsDefaults,
      });
    }
  }

  private removeDuplicates(arr: any[]) {
    return arr.filter(
      (val: any, idx: number, array: any[]) => array.findIndex((t) => t.id === val.id && t.name === val.name) === idx
    );
  }

  public async handleScroll(event: any) {
    event.stopPropagation();
    const target = event.target;

    const { isAutoCompleteLocationsLoading, autoCompleteLocations } = this.state;

    if (!isAutoCompleteLocationsLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (autoCompleteLocations && autoCompleteLocations.total !== autoCompleteLocations.data.length) {
        this.getAutoCompleteLocations(autoCompleteLocations.offset + autoCompleteLocations.limit || 0);
      }
    }
  }

  public onLocationChange = (value) => {
    if ((value === "" || value.length % 3 === 0) && !this.state.isAutoCompleteLocationsLoading) {
      this.setState(
        {
          autoCompleteLocations: this.autoCompleteLocationsDefaults,
        },
        () => {
          this.getAutoCompleteLocations();
        }
      );
    }
  };

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getCompanies();
    });
  };

  public onSearchFormFinish = () => {
    this.getCompanies();
  };

  public onFormReset = () => {
    this.searchForm.current!.resetFields();
  };

  public render() {
    const { companies, autoCompleteLocations, currentPage, isLoading } = this.state;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Slug",
        dataIndex: "slug",
        key: "slug",
      },
      {
        title: "SFAccountId",
        dataIndex: "SFAccountId",
        key: "SFAccountId",
      },
      {
        title: "Status",
        key: "status",
        render: ({ isDisabled }) => {
          return isDisabled ? <Tag color="red">inactive</Tag> : <Tag color="green">active</Tag>;
        },
      },
      {
        title: "Locations",
        key: "locations",
        render: ({ locations }) => {
          return <Tag>{locations.length}</Tag>;
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Link to={`/companies/${id}/edit`}>
              <Button size={"small"}>Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ marginBottom: 24, textAlign: "right" }}>
            <Link to="/companies/create">
              <Button type="primary">Create company</Button>
            </Link>
          </Col>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
              <Form.Item name="name" label="Name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="slug" label="Slug" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="locationName" label="Location name" style={{ marginBottom: 16 }}>
                <AutoComplete
                  showSearch
                  style={{ width: "15em" }}
                  onChange={this.onLocationChange}
                  filterOption={(input: any, option: any) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onPopupScroll={(event: any) => this.handleScroll(event)}>
                  {autoCompleteLocations.data.length &&
                    autoCompleteLocations.data.map((location) => {
                      return (
                        <AutoComplete.Option key={location.id} value={location.name}>
                          {location.name}
                        </AutoComplete.Option>
                      );
                    })}
                </AutoComplete>
              </Form.Item>
              <Form.Item name="isDisabled" label="Status" initialValue={false} style={{ marginBottom: 16 }}>
                <Select placeholder="Select status" style={{ width: 150 }}>
                  <Select.Option value={false}>active</Select.Option>
                  <Select.Option value={true}>inactive</Select.Option>
                  <Select.Option value={undefined}>all</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="type" label="Type" initialValue="all" style={{ marginBottom: 16 }}>
                <Select placeholder="Select type" style={{ width: 150 }}>
                  <Select.Option value="ro_public">RO Public</Select.Option>
                  <Select.Option value="ro_private">RO Private</Select.Option>
                  <Select.Option value="life_sciences">Life Sciences</Select.Option>
                  <Select.Option value="schools">Schools</Select.Option>
                  <Select.Option value="all">All</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{companies.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={companies.data}
          columns={columns}
          pagination={{
            pageSize: companies.limit,
            current: currentPage,
            total: companies.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}
