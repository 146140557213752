import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Table, Tooltip, Typography, Drawer } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import moment from "moment";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  currentPage: number;
  templates: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  messages: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  messageEventLogsModalOpen: boolean;
  messageEventLogsContent: { event_name: string; ts: number; metadata: Object }[];
}

export class MessageQueueBrowserComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    currentPage: 1,
    templates: {
      total: 0,
      offset: 0,
      limit: 25,
      data: [],
    },
    messages: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
    messageEventLogsModalOpen: false,
    messageEventLogsContent: [],
  };

  public componentDidMount(): void {
    this.getMessages();
    this.getTemplates();
  }

  public async getMessages() {
    this.setState({ isLoading: true });

    try {
      const searchForm = this.searchForm.current!.getFieldsValue();

      const { data: messages } = await ApiClient.findMessages({
        offset: (this.state.currentPage - 1) * this.state.messages.limit,
        limit: this.state.messages.limit,
        text: searchForm.recipient || undefined,
        templateName: searchForm.templateName || undefined,
        fromDate: searchForm.fromDate ? searchForm.fromDate.toISOString() : undefined,
        toDate: searchForm.toDate ? searchForm.toDate.toISOString() : undefined,
        ...(![undefined, "", null].includes(searchForm.status) ? { status: searchForm.status } : {}),
      });

      this.setState({ messages, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch messages data");
      this.setState({ isLoading: false });
    }
  }

  public async getTemplates() {
    const { data: templates } = await ApiClient.getTemplates({
      limit: "all",
      offset: 0,
    });
    this.setState({ templates });
  }

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getMessages();
    });
  };

  public onSearchFormFinish = () => {
    this.getMessages();
  };

  public async cancelMessage(id: string) {
    try {
      await ApiClient.cancelMessage(id);
      message.error("Message successfully cancelled!");
      this.getMessages();
    } catch (e) {
      message.error("Cannot cancel message");
    }
  }

  public async forceSendMessage(id: string) {
    try {
      await ApiClient.forceSendMessage(id);
      message.success("Message successfully added to send queue!");
      this.getMessages();
    } catch (e) {
      message.error(e?.response?.data?.message || "Cannot sent message");
    }
  }

  public onFormReset = () => {
    this.searchForm.current!.resetFields();
  };

  public showEventLogsModal = (content: unknown[]) => {
    this.setState({ messageEventLogsModalOpen: true, messageEventLogsContent: content as any });
  };

  public closeEventLogsModal = () => {
    this.setState({ messageEventLogsModalOpen: false });
  };

  public render() {
    const { messages, currentPage, isLoading, templates } = this.state;

    const columns = [
      {
        title: "Message",
        key: "message",
        render: (record) => {
          return record.template.name;
        },
      },
      {
        title: "Recipient",
        key: "recipient",
        render: (record) => {
          return record.recipient;
        },
      },
      {
        title: "Send time",
        dataIndex: "sendTime",
        key: "sendTime",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Status",
        key: "status",
        render: (record) => {
          if (record.status === "failed" || (record.status === "cancelled" && record.errorMessage)) {
            return (
              <>
                {record.status}&nbsp;
                <Tooltip title={record.errorMessage}>
                  <InfoCircleFilled />
                </Tooltip>
              </>
            );
          }
          return record.status;
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id, status, providerEventsLog }) => {
          const hasEventLogs = Array.isArray(providerEventsLog) && providerEventsLog.length > 1;
          return (
            <>
              {status === "waiting" && (
                <Button size={"small"} onClick={() => this.cancelMessage(id)}>
                  Cancel
                </Button>
              )}
              {["cancelled", "failed"].includes(status) && (
                <Button size={"small"} onClick={() => this.forceSendMessage(id)}>
                  Force send
                </Button>
              )}
              {hasEventLogs && (
                <Button
                  size={"small"}
                  onClick={() =>
                    this.showEventLogsModal(
                      providerEventsLog.sort((a, b) => {
                        return (b.ts < 10000000000 ? b.ts * 1000 : b.ts) - (a.ts < 10000000000 ? a.ts * 1000 : a.ts);
                      })
                    )
                  }>
                  Show event logs
                </Button>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
              <Form.Item name="recipient" label="Recipient" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="templateName" label="Template" style={{ marginBottom: 16 }}>
                <Select placeholder="Select template" style={{ width: 300 }}>
                  <Select.Option value={""}>all</Select.Option>
                  {templates.data.map((template) => (
                    <Select.Option key={template.name} value={template.name}>
                      {template.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status" label="Status" style={{ marginBottom: 16 }}>
                <Select placeholder="Select status" style={{ width: 150 }}>
                  <Select.Option value={""}>all</Select.Option>
                  <Select.Option value={"waiting"}>{"waiting"}</Select.Option>
                  <Select.Option value={"sent"}>{"sent"}</Select.Option>
                  <Select.Option value={"cancelled"}>{"cancelled"}</Select.Option>
                  <Select.Option value={"failed"}>{"failed"}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="fromDate"
                label="Start date"
                initialValue={moment().startOf("week")}
                style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item
                name="toDate"
                label="End date"
                initialValue={moment().endOf("week")}
                style={{ marginBottom: 16 }}>
                <DatePicker format="l" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{messages.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={messages.data.map((d) => ({ ...d, key: d.id }))}
          columns={columns}
          pagination={{
            pageSize: messages.limit,
            current: currentPage,
            total: messages.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
        <Drawer
          title="Event logs"
          width={"75%"}
          onClose={this.closeEventLogsModal}
          visible={this.state.messageEventLogsModalOpen}>
          <Table
            dataSource={this.state.messageEventLogsContent}
            columns={[
              {
                title: "Event name",
                dataIndex: "event_name",
                key: "event_name",
              },
              {
                title: "Timestamp",
                dataIndex: "ts",
                key: "ts",
                render: (ts) => dateFormatter(new Date(ts < 10000000000 ? ts * 1000 : ts)),
              },
              {
                title: "Metadata",
                dataIndex: "metadata",
                key: "metadata",
                render: (metadata) => (
                  <small>
                    <pre>{JSON.stringify(metadata, null, 2)}</pre>
                  </small>
                ),
              },
            ]}
          />
        </Drawer>
      </>
    );
  }
}
