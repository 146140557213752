import React from "react";
import { Col, message, Row, Spin } from "antd";
import { orderBy } from "lodash";
import { RouteComponentProps } from "react-router-dom";

import { IAppointment, IDocument, IFile } from "./documents.interface";
import { ApiClient } from "../../../../api-client/api.client";
import PreviousAppointments from "./previous-appointments.component";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: {
    page: boolean;
    documents: boolean;
  };
  previousAppointments: IAppointment[];
  files: { [key: string]: IFile[] };
}

export class DocumentsComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: {
      page: false,
      documents: false,
    },
    previousAppointments: [],
    files: {},
  };

  public componentDidMount = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: { ...this.state.isLoading, page: true } });
    await this.getPreviousAppointments(id);
    this.setState({ isLoading: { ...this.state.isLoading, page: false } });
  };

  public getPreviousAppointments = async (patientId: string) => {
    try {
      const { data } = await ApiClient.getPreviousAppointments(patientId);
      const previousAppointments = orderBy(data.data, "timeslot[startDate]", "desc");
      this.setState({
        previousAppointments,
      });

      if (previousAppointments.length) {
        this.getAppointmentsDocuments(patientId);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  public getAppointmentsDocuments = async (patientId: string) => {
    this.setState({ isLoading: { ...this.state.isLoading, documents: true } });
    try {
      const documents: IDocument[] = (await ApiClient.getDocuments(patientId)).data;
      const upriseDocuments = documents.filter((document) => document.storage === "uprise");
      const acuitasDocuments = documents.filter((document) => document.storage === "acuitas");

      this.state.previousAppointments.forEach((a: IAppointment) => {
        const filteredAcuitasDocuments = acuitasDocuments.filter((document) => document.appointmentId === a.id);
        const filteredUpriseDocuments = upriseDocuments.filter((document) => document.appointmentId === a.id);

        const allDocuments = filteredAcuitasDocuments.concat(filteredUpriseDocuments);
        allDocuments.forEach((file, index) => (file.internalId = index));

        this.setState({
          files: {
            ...this.state.files,
            [a.id]: allDocuments,
          },
        });
      });
    } catch (e) {
      message.error("We can't pull documents list");
    }

    this.setState({ isLoading: { ...this.state.isLoading, documents: false } });
  };

  public downloadDocument = (appointmentId: string, internalId: string) => {
    const files = this.state.files[appointmentId];
    if (files.length) {
      const file = files.find((f: IFile) => f.internalId === internalId);
      if (file && file.storage === "uprise") {
        window.open(`${process.env.API_URL}/storage/files/${file.id}`, "_blank");
      } else if (file && file.storage === "acuitas" && file.type === "invoice") {
        window.open(
          `${process.env.API_URL}/booking/documents/patients/${file.patientId}/appointments/${appointmentId}/receipt/${file.id}`,
          "_blank"
        );
      } else if (file && file.storage === "acuitas" && file.type === "prescription") {
        window.open(
          `${process.env.API_URL}/booking/documents/patients/${file.patientId}/appointments/${appointmentId}/prescriptions/${file.id}/type/${file.prescriptionType}`,
          "_blank"
        );
      }
    }
  };

  public render() {
    const { isLoading, previousAppointments, files } = this.state;

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          {isLoading.page && <Spin />}

          {!isLoading.page && (
            <PreviousAppointments
              isDocumentLoading={isLoading.documents}
              files={files}
              previousAppointments={previousAppointments}
              downloadDocument={this.downloadDocument}
            />
          )}
        </Col>
      </Row>
    );
  }
}
