import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { orderBy } from "lodash";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Table,
  Tag,
  Space,
} from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import moment from "moment";
import { AppointmentData, VisitData } from "../../../interfaces/visit.interface";
import { sortInsurances } from "../../../helpers/appointment.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  appointment: AppointmentData | undefined;
  patient: any; // FIXME:
  companies: any[];
  visits: VisitData[];
  files: any[];
  timeslots: any[];
  insurances: any[];
  companyId?: string;
  visitId?: string;
  primaryInsuranceSecondaryInsured: boolean;
  secondaryInsuranceSecondaryInsured: boolean;
  subMenuKey: string;
  selectedFileList: any[];
  selectedFile: any;
  filesToSend: string[];
}

export class EditAppointmentComponent extends React.Component<IProps, IState> {
  private mainForm = React.createRef<FormInstance>();
  private surveyForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: true,
    appointment: undefined,
    patient: undefined,
    companies: [],
    visits: [],
    files: [],
    timeslots: [],
    insurances: [],
    companyId: undefined,
    visitId: undefined,
    primaryInsuranceSecondaryInsured: false,
    secondaryInsuranceSecondaryInsured: false,
    subMenuKey: "details",
    selectedFileList: [],
    selectedFile: undefined,
    filesToSend: [],
  };

  public async componentDidMount() {
    await this.getCompanies();
    await this.getAppointment();
    await this.getInsurances();
    await this.getAppointmentDocuments();
  }

  public getAppointment = async () => {
    try {
      this.setState({ isLoading: true });
      const { id } = this.props.match.params;
      const { data: appointment } = await ApiClient.getAppointment(id);
      this.setState(
        {
          appointment,
          primaryInsuranceSecondaryInsured: appointment.survey.primaryInsurance.secondaryInsured,
          secondaryInsuranceSecondaryInsured: appointment.survey.secondaryInsurance.secondaryInsured,
          isLoading: false,
        },
        () => {
          this.getPatient();
        }
      );

      const { companies } = this.state;

      const company = companies.find((company) =>
        company.locations.find((x) => x.id === appointment.timeslot.visit.locationId)
      );

      this.getVisits(company.id);
      this.getTimeslots(appointment.timeslot.visit.id);

      this.mainForm.current?.setFieldsValue({
        companyId: company.id,
        visitId: appointment.timeslot.visit.id,
        timeslotId: appointment.timeslot.id,
      });

      this.setState({
        companyId: company.id,
        visitId: appointment.timeslot.visit.id,
      });
    } catch {
      message.error("Appointment cannot be fetched!");
    }
  };

  public getAppointmentDocuments = async () => {
    const { appointment } = this.state;

    const { data: files } = await ApiClient.getFiles({
      appointmentId: appointment!.id,
    });

    this.setState({ files });
  };

  public getPatient = async () => {
    try {
      const { appointment } = this.state;
      const { data: patient } = await ApiClient.getPatient(appointment!.patientId);
      this.setState({ patient });
    } catch {
      message.error("Patient cannot be fetched!");
    }
  };

  private onMainFormFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { appointment } = this.state;

      if (!appointment) {
        throw new Error("No appointment found");
      }

      const oldTimeslotId = appointment.timeslot.id;
      const newTimeslotId = values.timeslotId;

      await ApiClient.updateAppointment(appointment.timeslot.visit.id, appointment.id, {
        type: values.type,
        status: values.status,
        dilationType: values.dilationType || null,
        payment: values.payment || null,
        materialsResponsibility: values.materialsResponsibility || 0,
        examResponsibility: values.examResponsibility || 0,
        examInsurance: values.examInsurance || null,
        notes: values.notes || null,
        survey: appointment.survey,
        smsSubscription: values.smsSubscription,
      });

      if (newTimeslotId !== oldTimeslotId) {
        await ApiClient.rescheduleAppointment(appointment.timeslot.visit.id, appointment.id, {
          timeslotId: oldTimeslotId,
          targetVisitId: values.visitId,
          targetTimeslotId: newTimeslotId,
        });
      }

      message.success("Appointment successfully saved!");
      this.getAppointment();
    } catch (e) {
      console.error(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.getAppointment();
    }
  };

  private onSurveyFormFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { appointment } = this.state;

      if (!appointment) {
        throw new Error("No appointment found");
      }

      await ApiClient.updateAppointment(appointment.timeslot.visit.id, appointment.id, {
        type: appointment.type,
        status: appointment.status,
        dilationType: appointment.dilationType || null,
        payment: appointment.payment || null,
        materialsResponsibility: appointment.materialsResponsibility || 0,
        examResponsibility: appointment.examResponsibility || 0,
        examInsurance: appointment.examInsurance || null,
        notes: appointment.notes || null,
        smsSubscription: appointment.smsSubscription,
        survey: {
          isEyeExam: values.isEyeExam || false,
          isContactRenewal: values.isContactRenewal || false,
          isFittedForContactLenses: values.isFittedForContactLenses || false,
          wearGlasses: values.wearGlasses || false,
          wearContactLenses: values.wearContactLenses || false,
          applyInsuranceBenefitsForGlassPurchase: values.applyInsuranceBenefitsForGlassPurchase || false,
          covidPositive: values.covidPositive || false,
          covidSymptoms: values.covidSymptoms || false,
          covidAntibodies: values.covidAntibodies || false,
          insuranceProvidedByCompany: values.insuranceProvidedByCompany || false,
          insuranceReminder: values.insuranceReminder || false,
          marketingOptIn: values.marketingOptIn || false,
          hipaaAcknowledgement: values.hipaaAcknowledgement || false,
          noInsuranceCoverage: values.noInsuranceCoverage || false,
          lastExam: values.lastExam || null,
          primaryInsurance: {
            insuranceId: values.primaryInsuranceId || null,
            secondaryInsured: values.primaryInsuranceSecondaryInsured || false,
            provider: values.primaryInsuranceProvider || null,
            subscriberId: values.primaryInsuranceSubscriberId || null,
            extPolicyHolderDOB: values.primaryInsuranceExtPolicyHolderDOB || null,
            extPolicyHolderName: values.primaryInsuranceExtPolicyHolderName || null,
            noInsurance: values.noMedicalInsurance || false,
            companySponsoredInsurancePlan: values.companySponsoredMedicalInsurancePlan || undefined,
          },
          secondaryInsurance: {
            insuranceId: values.secondaryInsuranceId || null,
            secondaryInsured: values.secondaryInsuranceSecondaryInsured || false,
            provider: values.secondaryInsuranceProvider || null,
            subscriberId: values.secondaryInsuranceSubscriberId || null,
            extPolicyHolderDOB: values.secondaryInsuranceExtPolicyHolderDOB || null,
            extPolicyHolderName: values.secondaryInsuranceExtPolicyHolderName || null,
            noInsurance: values.noVisionInsurance || false,
            companySponsoredInsurancePlan: values.companySponsoredVisionInsurancePlan || undefined,
          },
        },
      });

      message.success("Appointment successfully saved!");
      this.getAppointment();
    } catch (e) {
      console.error(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.getAppointment();
    }
  };

  private getCompanies = async (companyName?: string) => {
    const { data: companies } = await ApiClient.findCompanies({
      offset: 0,
      limit: 1500,
      name: companyName || undefined,
    });
    this.setState({ companies: companies.data });
  };

  private getTimeslots = async (visitId: string) => {
    const { data: timeslots } = await ApiClient.getVisitTimeslots(visitId);
    this.setState({ timeslots });
  };

  private getVisits = async (companyId: string) => {
    const { companies } = this.state;

    const company = companies.find((x) => x.id === companyId);

    if (!company) {
      this.setState({ visits: [] });
    }

    const locationIds = company.locations.map((x) => x.id);

    const { data: visits } = await ApiClient.findVisits({
      offset: 0,
      limit: 100,
      locationIds,
    });

    this.setState({ visits: visits.data });
  };

  private getInsurances = async () => {
    const { data: insurances } = await ApiClient.findInsurances();
    this.setState({ insurances: insurances.sort(sortInsurances) });
  };

  private onMainFormValuesChange = (values: any) => {
    if (values.companyId && !values.visitId) {
      this.mainForm.current?.resetFields(["visitId", "timeslotId"]);
      this.getVisits(values.companyId);
    }

    if (values.visitId && !values.timeslotId) {
      this.mainForm.current?.resetFields(["timeslotId"]);
      this.getTimeslots(values.visitId);
    }
  };

  private onSurveyFormValuesChange = (values: any) => {
    if (typeof values.primaryInsuranceSecondaryInsured === "boolean") {
      this.setState({
        primaryInsuranceSecondaryInsured: values.primaryInsuranceSecondaryInsured,
      });
      this.surveyForm.current?.resetFields([
        "primaryInsuranceExtPolicyHolderName",
        "primaryInsuranceExtPolicyHolderDOB",
      ]);
    }

    if (typeof values.secondaryInsuranceSecondaryInsured === "boolean") {
      this.setState({
        secondaryInsuranceSecondaryInsured: values.secondaryInsuranceSecondaryInsured,
      });
      this.surveyForm.current?.resetFields([
        "secondaryInsuranceExtPolicyHolderName",
        "secondaryInsuranceExtPolicyHolderDOB",
      ]);
    }
  };

  private removeProduct = async (productId: string) => {
    try {
      const { appointment } = this.state;

      if (!appointment) {
        throw new Error("No appointment found");
      }

      await ApiClient.removeProduct(appointment.timeslot.visit.id, appointment.id, productId);

      message.success("Product successfully removed!");
      this.getAppointment();
    } catch (e) {
      console.error(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.getAppointment();
    }
  };

  public render() {
    const {
      isLoading,
      appointment,
      patient,
      companies,
      visits,
      timeslots,
      insurances,
      primaryInsuranceSecondaryInsured,
      secondaryInsuranceSecondaryInsured,
      subMenuKey,
      companyId,
      visitId,
    } = this.state;

    const visit = appointment?.timeslot?.visit;

    const selectedVisitId = this.mainForm.current?.getFieldValue("visitId");
    const selectedVisit = visits && selectedVisitId ? visits.find((x) => x.id === selectedVisitId) : undefined;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/appointments")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit appointment"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Appointment"
              loading={isLoading}
              style={{ marginBottom: 24 }}
              tabList={[
                {
                  key: "details",
                  tab: "Details",
                },
                {
                  key: "survey",
                  tab: "Survey",
                },
                {
                  key: "products",
                  tab: "Products",
                },
                // {
                //   key: "documents",
                //   tab: "Documents",
                // },
              ]}
              activeTabKey={this.state.subMenuKey}
              onTabChange={(subMenuKey) => {
                this.setState({ subMenuKey });
              }}>
              {subMenuKey === "details" ? (
                <Form
                  layout="vertical"
                  ref={this.mainForm}
                  name="edit-appointment"
                  onFinish={this.onMainFormFinish}
                  onValuesChange={this.onMainFormValuesChange}>
                  <Form.Item name="companyId" label="Company" rules={[{ required: true }]} initialValue={companyId}>
                    <Select
                      showSearch
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      placeholder="Select company"
                      onSearch={this.getCompanies}
                      notFoundContent={"no companies"}>
                      {orderBy(companies, [(c) => c.name.toLowerCase()], "asc").map((company) => (
                        <Select.Option value={company.id}>{company.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="visitId" label="Visit" rules={[{ required: true }]} initialValue={visitId}>
                    <Select placeholder="Select visit" notFoundContent={"no visits"}>
                      {visits.map((visit) => (
                        <Select.Option value={visit.id}>{visit.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="timeslotId"
                    label="Timeslot"
                    rules={[{ required: true }]}
                    initialValue={appointment?.timeslot.id}>
                    <Select placeholder="Select timeslot" notFoundContent={"no timeslots"}>
                      {timeslots
                        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.endDate).getTime())
                        .map((timeslot) => (
                          <Select.Option
                            value={timeslot.id}
                            disabled={
                              (timeslot.isBooked || timeslot.isDisabled || timeslot.isTemporaryLocked) &&
                              timeslot.id !== appointment?.timeslot.id
                            }>
                            {moment.tz(timeslot.startDate, selectedVisit?.timezone).format("LT")}
                            {" - "}
                            {moment.tz(timeslot.endDate, selectedVisit?.timezone).format("LT")}
                            {timeslot.id !== appointment?.timeslot.id ? (
                              <>
                                {timeslot.isBooked ? " (booked)" : ""}
                                {timeslot.isDisabled ? " (disabled)" : ""}
                                {timeslot.isTemporaryLocked ? " (temporary locked)" : ""}
                              </>
                            ) : (
                              <>{timeslot.id === appointment?.timeslot.id ? " (selected)" : ""}</>
                            )}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="status"
                    label="Status"
                    initialValue={appointment?.status}
                    rules={[{ required: true }]}>
                    <Select placeholder="Select appointment status">
                      <Select.Option value="confirmed">confirmed</Select.Option>
                      <Select.Option value="checked_in">checked_in</Select.Option>
                      <Select.Option value="ready_for_exam">ready_for_exam</Select.Option>
                      <Select.Option value="exam">exam</Select.Option>
                      <Select.Option value="checked_out">checked_out</Select.Option>
                      <Select.Option value="cancelled_not_eligible">cancelled_not_eligible</Select.Option>
                      <Select.Option value="cancelled_refused_exam">cancelled_refused_exam</Select.Option>
                      <Select.Option value="cancelled_day_of_issue">cancelled_day_of_issue</Select.Option>
                      <Select.Option value="cancelled_no_show">cancelled_no_show</Select.Option>
                      <Select.Option value="cancelled_late_cancel">cancelled_late_cancel</Select.Option>
                      <Select.Option value="cancelled_patient_late">cancelled_patient_late</Select.Option>
                      <Select.Option value="cancelled_admin_cancel">cancelled_admin_cancel</Select.Option>
                      <Select.Option value="cancelled_early_cancel">cancelled_early_cancel</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={appointment?.type}>
                    <Select placeholder="Select appointment type">
                      <Select.Option value={"exam"}>exam</Select.Option>
                      <Select.Option value={"cl_exam"}>cl_exam</Select.Option>
                      <Select.Option value={"retail_only"}>retail_only</Select.Option>
                      <Select.Option value={"f/u"}>f/u</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="dilationType" label="Dilation type" initialValue={appointment?.dilationType || ""}>
                    <Select placeholder="Select dilation type">
                      <Select.Option value={""}>-</Select.Option>
                      <Select.Option value={"hdri"}>hdri</Select.Option>
                      <Select.Option value={"full"}>full</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="payment" label="Payment" initialValue={appointment?.payment || ""}>
                    <Select placeholder="Select payment">
                      <Select.Option value={""}>-</Select.Option>
                      <Select.Option value={"visa"}>visa</Select.Option>
                      <Select.Option value={"master_card"}>master_card</Select.Option>
                      <Select.Option value={"amex"}>amex</Select.Option>
                      <Select.Option value={"discover"}>discover</Select.Option>
                      <Select.Option value={"multiple"}>multiple</Select.Option>
                      <Select.Option value={"other"}>other</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="examResponsibility"
                    label="Exam responsibility"
                    initialValue={appointment?.examResponsibility}>
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    name="materialsResponsibility"
                    label="Materials responsibility"
                    initialValue={appointment?.materialsResponsibility}>
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item name="examInsurance" label="Exam insurance" initialValue={appointment?.examInsurance}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="notes" label="Notes" initialValue={appointment?.notes}>
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item
                    name="smsSubscription"
                    valuePropName="checked"
                    initialValue={appointment?.smsSubscription}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>SMS texts for the appointment</Checkbox>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button loading={isLoading} type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : null}
              {subMenuKey === "survey" && appointment?.survey ? (
                <Form
                  layout="vertical"
                  ref={this.surveyForm}
                  name="edit-appointment-survey"
                  onFinish={this.onSurveyFormFinish}
                  onValuesChange={this.onSurveyFormValuesChange}>
                  <div className="ant-col ant-form-item-label">
                    <label>Survey</label>
                  </div>
                  <style>
                    {`
                          .ant-checkbox-wrapper { display: flex; }
                          .ant-checkbox { margin-top: 3px; }
                        `}
                  </style>
                  <Form.Item
                    name="wearGlasses"
                    valuePropName="checked"
                    initialValue={appointment.survey.wearGlasses}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Do you currently wear glasses?</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="wearContactLenses"
                    valuePropName="checked"
                    initialValue={appointment.survey.wearContactLenses}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Do you currently wear contact lenses?</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="isContactRenewal"
                    valuePropName="checked"
                    initialValue={appointment.survey.isContactRenewal}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Do you need to renew your contact lens prescription?</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="isFittedForContactLenses"
                    valuePropName="checked"
                    initialValue={appointment.survey.isFittedForContactLenses}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Do you want to be fitted for contact lenses?</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="applyInsuranceBenefitsForGlassPurchase"
                    valuePropName="checked"
                    initialValue={appointment.survey.applyInsuranceBenefitsForGlassPurchase}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      Would you like apply existing insurance benefits for a potential glasses purchase?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="insuranceProvidedByCompany"
                    valuePropName="checked"
                    initialValue={appointment.survey.insuranceProvidedByCompany}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Is your insurance provided by company?</Checkbox>
                  </Form.Item>
                  <Divider />
                  {visit?.appointmentType === "ro_public" && (
                    <Form.Item
                      name="noMedicalInsurance"
                      valuePropName="checked"
                      initialValue={appointment.survey.primaryInsurance.noInsurance}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>I don't have medical insurance</Checkbox>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="primaryInsuranceId"
                    label="Primary Insurance"
                    initialValue={appointment.survey.primaryInsurance.insuranceId || ""}>
                    <Select placeholder="Select primary insurance">
                      <Select.Option value={""}>none</Select.Option>
                      {insurances.map((insurance) => (
                        <Select.Option value={insurance.id}>{insurance.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="primaryInsuranceProvider"
                    label="Primary Insurance - provider (other)"
                    initialValue={appointment.survey.primaryInsurance.provider}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="primaryInsuranceSubscriberId"
                    label="Primary Insurance - subscriber ID"
                    initialValue={appointment.survey.primaryInsurance.subscriberId}>
                    <Input />
                  </Form.Item>
                  {visit?.appointmentType === "ro_public" && (
                    <Form.Item
                      name="companySponsoredMedicalInsurancePlan"
                      label="Insured under a company-sponsored insurance plan"
                      initialValue={appointment.survey.primaryInsurance.companySponsoredInsurancePlan}>
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="primaryInsuranceSecondaryInsured"
                    label="Primary Insurance - secondary insured"
                    valuePropName="checked"
                    initialValue={appointment.survey.primaryInsurance.secondaryInsured}>
                    <Checkbox />
                  </Form.Item>
                  {primaryInsuranceSecondaryInsured ? (
                    <>
                      <Form.Item
                        name="primaryInsuranceExtPolicyHolderName"
                        label="Primary Insurance - Ext Policy Holder Name"
                        initialValue={appointment.survey.primaryInsurance.extPolicyHolderName}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="primaryInsuranceExtPolicyHolderDOB"
                        label="Primary Insurance - Ext Policy Holder DOB"
                        initialValue={appointment.survey.primaryInsurance.extPolicyHolderDOB}>
                        <Input />
                      </Form.Item>
                    </>
                  ) : null}
                  <Divider />

                  {visit?.appointmentType === "ro_public" && (
                    <Form.Item
                      name="noVisionInsurance"
                      valuePropName="checked"
                      initialValue={appointment.survey.secondaryInsurance.noInsurance}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>I don't have vision insurance</Checkbox>
                    </Form.Item>
                  )}

                  <Form.Item
                    name="secondaryInsuranceId"
                    label="Secondary Insurance"
                    initialValue={appointment.survey.secondaryInsurance.insuranceId || ""}>
                    <Select placeholder="Select secondary insurance">
                      <Select.Option value={""}>none</Select.Option>
                      {insurances.map((insurance) => (
                        <Select.Option value={insurance.id}>{insurance.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="secondaryInsuranceProvider"
                    label="Secondary Insurance - provider (other)"
                    initialValue={appointment.survey.secondaryInsurance.provider}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="secondaryInsuranceSubscriberId"
                    label="Secondary Insurance - subscriber ID"
                    initialValue={appointment.survey.secondaryInsurance.subscriberId}>
                    <Input />
                  </Form.Item>
                  {visit?.appointmentType === "ro_public" && (
                    <Form.Item
                      name="companySponsoredVisionInsurancePlan"
                      label="Insured under a company-sponsored insurance plan"
                      initialValue={appointment.survey.secondaryInsurance.companySponsoredInsurancePlan}>
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="secondaryInsuranceSecondaryInsured"
                    label="Secondary Insurance - secondary insured"
                    valuePropName="checked"
                    initialValue={appointment.survey.secondaryInsurance.secondaryInsured}>
                    <Checkbox />
                  </Form.Item>

                  {secondaryInsuranceSecondaryInsured ? (
                    <>
                      <Form.Item
                        name="secondaryInsuranceExtPolicyHolderName"
                        label="Secondary Insurance - Ext Policy Holder Name"
                        initialValue={appointment.survey.secondaryInsurance.extPolicyHolderName}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="secondaryInsuranceExtPolicyHolderDOB"
                        label="Secondary Insurance - Ext Policy Holder DOB"
                        initialValue={appointment.survey.secondaryInsurance.extPolicyHolderDOB}>
                        <Input />
                      </Form.Item>
                    </>
                  ) : null}
                  <Divider />
                  <Form.Item
                    name="insuranceReminder"
                    valuePropName="checked"
                    initialValue={appointment.survey.insuranceReminder}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      I don't have my insurance ID cards right now. Email me a reminder to complete this information
                      when I have my ID card.
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="noInsuranceCoverage"
                    valuePropName="checked"
                    initialValue={appointment.survey.noInsuranceCoverage}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>I don’t have insurance coverage.</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="marketingOptIn"
                    valuePropName="checked"
                    initialValue={appointment.survey.marketingOptIn}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      I would like to stay in the loop about 2020 On-site promotions, news, and events.
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="hipaaAcknowledgement"
                    valuePropName="checked"
                    initialValue={appointment.survey.hipaaAcknowledgement}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      I acknowledge that I have reviewed and agree to the{" "}
                      <a href="https://www.2020onsite.com/privacypolicy" target="_blank">
                        <u>20/20 Onsite HIPAA compliance and financial responsibilities policies</u>
                      </a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="covidPositive"
                    valuePropName="checked"
                    initialValue={appointment.survey.covidPositive}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      Have you tested positive for COVID-19 in the past 14 days, or been in contact with anyone who has
                      tested positive for COVID-19 in the past 14 days?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="covidSymptoms"
                    valuePropName="checked"
                    initialValue={appointment.survey.covidSymptoms}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>
                      Are you currently experiencing any signs of acute respiratory illness (cough, fever, or difficulty
                      breathing)?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="covidAntibodies"
                    valuePropName="checked"
                    initialValue={appointment.survey.covidAntibodies}
                    style={{ marginBottom: 5 }}>
                    <Checkbox>Have you been tested for COVID-19 antibodies?</Checkbox>
                  </Form.Item>

                  <Form.Item style={{ marginBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button loading={isLoading} type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : null}
              {subMenuKey === "products" ? (
                <>
                  <div
                    style={{
                      marginBottom: 24,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                    <Link to={`/appointments/${appointment?.id}/products/add`}>
                      <Button type="primary">Add product</Button>
                    </Link>
                  </div>
                  <Table
                    dataSource={appointment ? appointment.products : []}
                    columns={[
                      {
                        title: "Type",
                        key: "type",
                        render: ({ type }) => <strong>{type}</strong>,
                      },
                      {
                        title: "Status",
                        key: "status",
                        render: ({ status }) => <Tag>{status}</Tag>,
                      },
                      {
                        title: "Notes",
                        key: "notes",
                        render: ({ notes }) => notes || "-",
                      },
                      {
                        title: "Action",
                        key: "action",
                        render: ({ id }) => (
                          <>
                            <Link to={`/appointments/${appointment!.id}/products/${id}/edit`}>
                              <Button size={"small"}>Edit</Button>
                            </Link>
                            <Button size={"small"} style={{ marginLeft: 6 }} onClick={() => this.removeProduct(id)}>
                              Remove
                            </Button>
                          </>
                        ),
                      },
                    ]}
                    bordered
                    size="small"
                    pagination={false}
                  />
                </>
              ) : null}
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Visit"
              loading={isLoading}
              extra={
                <Space>
                  <Button type="primary">
                    <Link to={`/visits/${visit?.id}/edit`}>Visit Setup</Link>
                  </Button>
                  <Button type="primary">
                    <Link
                      to={{
                        pathname: `/patient-tracker`,
                        search: `?trackerId=${visit?.id}`,
                      }}>
                      Go to Tracker
                    </Link>
                  </Button>
                </Space>
              }
              style={{ marginBottom: 24 }}>
              <Descriptions bordered>
                <Descriptions.Item label="Name" span={12}>
                  {visit?.name || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Date" span={12}>
                  {visit?.date ? moment(visit?.date, "YYYY-MM-DD").format("l") : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Appointment type" span={12}>
                  {visit?.appointmentType || "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card
              title="Patient"
              loading={!patient}
              extra={
                <Button type="primary">
                  <Link to={`/patients/${patient?.id}/edit`}>Go to patient</Link>
                </Button>
              }
              style={{ marginBottom: 24 }}>
              <Descriptions bordered>
                <Descriptions.Item label="First name" span={12}>
                  {patient?.firstName || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Last name" span={12}>
                  {patient?.lastName || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Phone number" span={12}>
                  {patient?.phoneNumber || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={12}>
                  {patient?.email || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Gender" span={12}>
                  {patient?.gender || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Birth" span={12}>
                  {patient?.birth ? moment(patient?.birth, "YYYY-MM-DD").format("l") : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Notes" span={12}>
                  {patient?.notes || "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
