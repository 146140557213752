import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Checkbox, Col, Form, Input, message, PageHeader, Row, Select, TreeSelect } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  companies: any[];
  subscription: any; // FIXME:
  locations: any[];
  companyId: string | undefined;
}

export class EditSubscriptionComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    companies: [],
    isLoading: false,
    subscription: undefined,
    locations: [],
    companyId: undefined,
  };

  public async componentDidMount(): Promise<void> {
    await this.getSubscription();
    await this.getCompanies();
    await this.matchLocations();
  }

  public async matchLocations() {
    const { companies, subscription } = this.state;
    let locations = [];
    let companyId;

    companies.forEach((company) => {
      const locationFound = company.locations.find((location) => location.id === subscription.locationId);
      if (locationFound) {
        locations = company.locations;
        companyId = company.id;
      }
    });

    this.setState({ locations, companyId });
  }

  public async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({ offset: 0, limit: "all" });
    this.setState({ companies: companies.data });
  }

  public getSubscription = async () => {
    try {
      this.setState({ isLoading: true });
      const id = this.props.match.params?.id;
      const { data: subscription } = await ApiClient.getWaitlistSubscription(id);

      this.setState({ subscription, isLoading: false });
    } catch {
      message.error("Subscription cannot be fetched!");
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { subscription } = this.state;

      await ApiClient.updateWaitlistSubscription(subscription.id, {
        email: values.email,
        phoneNumber: values.phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        timezone: values.timezone,
        timePreference: {
          morning: values.timePreference.includes("morning"),
          afternoon: values.timePreference.includes("afternoon"),
        },
        locationId: values.locationId,
        status: values.status,
      });

      message.success("Subscription successfully saved!");
      this.getSubscription();
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public onCompanyChange = (value: string) => {
    let locations = [];

    const company = this.state.companies.find((company) => company.id === value);
    if (company && company.locations.length) locations = company.locations;

    this.setState({ locations, companyId: company.id });

    this.form.current!.setFieldsValue({
      locationId: undefined,
    });
  };

  public render() {
    const { isLoading, subscription, companies, companyId, locations } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/waitlist/subscriptions")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit subscription"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card title="Subscription" loading={isLoading}>
              {subscription ? (
                <Form layout="vertical" ref={this.form} name="edit-waitlist-subscription" onFinish={this.onFinish}>
                  <Form.Item name="email" label="Email" rules={[{ required: true }]} initialValue={subscription.email}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    extra="Country code + 10 digits"
                    name="phoneNumber"
                    label="Phone number"
                    rules={[{ required: true }, { min: 11, max: 11, message: "Phone number must have 11 digits." }]}
                    initialValue={subscription.phoneNumber}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    label="First name"
                    rules={[{ required: true }]}
                    initialValue={subscription.firstName}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last name"
                    rules={[{ required: true }]}
                    initialValue={subscription.lastName}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="timezone"
                    label="Timezone"
                    rules={[{ required: true }]}
                    initialValue={subscription.timezone}>
                    <Select showSearch placeholder="Select timezone">
                      <Select.Option value="America/New_York">America/New_York (Eastern)</Select.Option>
                      <Select.Option value="America/Chicago">America/Chicago (Central)</Select.Option>
                      <Select.Option value="America/Los_Angeles">America/Los_Angeles (Pacific)</Select.Option>
                      <Select.Option value="America/Denver">America/Denver (Mountain)</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true }]}
                    initialValue={subscription.status}>
                    <Select placeholder="Select status">
                      <Select.Option value="active">active</Select.Option>
                      <Select.Option value="removed">removed</Select.Option>
                      <Select.Option value="booked">booked</Select.Option>
                      <Select.Option value="early_access">early_access</Select.Option>
                    </Select>
                  </Form.Item>

                  {companyId && (
                    <>
                      <Form.Item
                        name="companyName"
                        label="Company"
                        style={{ marginBottom: 16 }}
                        rules={[{ required: true }]}
                        initialValue={companyId}>
                        <Select
                          showSearch
                          onChange={this.onCompanyChange}
                          placeholder="Please select company"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {companies
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter((x) => x)
                            .filter((value, index, array) => {
                              return index === 0 || value.name !== array[index - 1].name;
                            })
                            .map((company) => (
                              <Select.Option key={company.id} value={company.id}>
                                {company.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="locationId"
                        label="Location"
                        style={{ marginBottom: 16 }}
                        rules={[{ required: true }]}
                        initialValue={subscription.locationId}>
                        <Select
                          showSearch
                          placeholder="Please select location"
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {locations
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((location) => (
                              <Select.Option key={location.id} value={location.id}>
                                {location.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="timePreference"
                    label="Time preference"
                    rules={[{ required: true }]}
                    initialValue={[
                      ...(subscription.timePreference.morning ? ["morning"] : []),
                      ...(subscription.timePreference.afternoon ? ["afternoon"] : []),
                    ]}>
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row>
                        <Col span={8}>
                          <Checkbox value={"morning"} style={{ lineHeight: "32px" }}>
                            morning
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value={"afternoon"} style={{ lineHeight: "32px" }}>
                            afternoon
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button loading={isLoading} type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : null}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
