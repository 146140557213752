import { colors } from "../styles/colors";

export const VisitStatuses = [
  { name: "Active - Complete", shortName: "Complete", value: "active_complete", color: colors.darkerBlue },
  { name: "Active - Booked", shortName: "Booked", value: "active_booked", color: colors.green },
  { name: "Inactive - Confirmed", shortName: "Confirmed", value: "inactive_confirmed", color: colors.yellowGreen },
  {
    name: "Inactive - Internal Hold",
    shortName: "Internal Hold",
    value: "inactive_internal_hold",
    color: colors.yellow,
  },
  { name: "Inactive - Ops Hold", shortName: "Ops Hold", value: "inactive_ops_hold", color: colors.yellow },
  {
    name: "Inactive - CS Hold Date Offered",
    shortName: "CS Hold Date Offered",
    value: "inactive_cs_hold_date_offered",
    color: colors.yellow,
  },
  { name: "Cancelled - Incomplete", shortName: "Incomplete", value: "cancelled_incomplete", color: colors.red },
  { name: "Cancelled - Client", shortName: "Client", value: "cancelled_client", color: colors.red },
  { name: "Cancelled - 2020", shortName: "2020", value: "cancelled_2020", color: colors.red },
  { name: "Cancelled - Late Cancel", shortName: "Late Cancel", value: "cancelled_late_cancel", color: colors.red },
  {
    name: "Cancelled - Client Declined",
    shortName: "Client Declined",
    value: "cancelled_client_declined",
    color: colors.red,
  },
];

export const getAppointmentTypeColor = (appointmentType: string) => {
  switch (appointmentType) {
    case "ro_private":
      return "magenta";
    case "ro_public":
      return "orange";
    case "telehealth":
      return "geekblue";
    case "styling":
      return "purple";
    case "life_sciences":
      return "cyan";
    case "schools":
      return "gold";
    default:
      return "";
  }
};

export const getTextColorForVisitStatus = (status: string) => {
  switch (status) {
    case "active_complete":
      return colors.darkerBlue;
    case "active_booked":
      return colors.green;
    case "inactive_confirmed":
      return colors.yellowGreen;
    case "inactive_ops_hold":
      return colors.red;
    case "inactive_internal_hold":
    case "inactive_cs_hold_date_offered":
      return colors.yellow;
    case "cancelled_incomplete":
    case "cancelled_client":
    case "cancelled_2020":
    case "cancelled_late_cancel":
    case "cancelled_client_declined":
      return colors.black;
    default:
      return colors.black;
  }
};

export const getVisitStatusColor = (status: string) => {
  switch (status) {
    case "active_complete":
      return colors.darkerBlue;
    case "active_booked":
      return colors.green;
    case "inactive_confirmed":
      return colors.yellowGreen;
    case "inactive_internal_hold":
    case "inactive_cs_hold_date_offered":
      return colors.yellow;
    case "inactive_ops_hold":
      return colors.red;
    case "cancelled_incomplete":
    case "cancelled_client":
    case "cancelled_2020":
    case "cancelled_late_cancel":
    case "cancelled_client_declined":
      return colors.black;
    default:
      return colors.black;
  }
};

export const getPositionTagColor = (position: string) => {
  switch (position.toLowerCase()) {
    case "cs":
      return "blue";
    case "od":
      return "cyan";
    case "sm":
      return "purple";
    case "asm":
      return "magenta";
    case "extra":
      return "geekblue";
    default:
      return "";
  }
};

export const getStatusName = (value: string) => {
  const status = VisitStatuses.find((s) => s.value === value);
  return status ? status.name : "";
};

export const getVisitStatusesWhereTruckIsRequire = (): string[] => {
  return ["active_booked", "active_complete", "inactive_ops_hold", "inactive_confirmed"];
};
