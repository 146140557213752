import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { message, Button, Form, Input, PageHeader, Card, Row, Col } from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
}

export class CreateTruckComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  private form = React.createRef<FormInstance>();

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      await ApiClient.createTruck({
        name: values.name,
      });

      message.success("Clinic successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push("/clinics");
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/clinics")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Add clinic"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Clinic">
              <Form layout="vertical" ref={this.form} name="create-clinic" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
