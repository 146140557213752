import { Form, Input } from "antd";
import Multiselect from "multiselect-react-dropdown";
import React, { ReactElement, useEffect } from "react";
import styled, { css } from "styled-components";

const getEthnicityOptions = (): {
  value: string;
  label: string;
  extraText: string;
  selected?: boolean;
}[] => [
  {
    value: "americanIndianOrAlaskaNative",
    label: "American Indian or Alaska Native",
    extraText: "e.g., Navajo Nation, Blackfeet Tribe, Inupiat Traditional Gov't., etc.",
  },
  {
    value: "asianOrAsianAmerican",
    label: "Asian or Asian American",
    extraText: "e.g., Chinese, Japanese, Filipino, Korean, South Asian, Vietnamese, etc.",
  },
  {
    value: "blackOrAfricanAmerican",
    label: "Black or African American",
    extraText: "e.g., Jamaican, Nigerian, Haitian, Ethiopian, etc.",
  },
  {
    value: "hispanicOrLatino",
    label: "Hispanic or Latino/a",
    extraText: "e.g., Puerto Rican, Mexican, Cuban, Salvadoran, Colombian, etc.",
  },
  {
    value: "middleEasternOrNorthAfrican",
    label: "Middle Eastern or North African",
    extraText: "e.g., Lebanese, Iranian, Egyptian, Moroccan, Israeli, Palestinian, etc.",
  },
  {
    value: "nativeHawaiianOrPacificIslander",
    label: "Native Hawai'ian or Pacific Islander",
    extraText: "e.g., Samoan, Guamanian, Chamorro, Tongan, etc.",
  },
  {
    value: "whiteOrEuropean",
    label: "White or European",
    extraText: "e.g., German, Irish, English, Italian, Polish, French, etc.",
  },
  { value: "other", label: "Other", extraText: "" },
  { value: "preferNotToSay", label: "Prefer not to say", extraText: "" },
];

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export const EthnicityField = ({ value, onChange }: Props): React.ReactElement | null => {
  const otherSelected = React.useRef(false);
  const fieldValue = React.useRef("");
  // const [otherInputValue, setOtherInputValue] = React.useState("");
  const otherInputValue = React.useRef("");
  const multiselectRef = React.useRef<Multiselect>(null);

  useEffect(() => {
    const element = document.getElementsByName("search_name_input")[0];
    element.addEventListener("blur", handleBlur);
    return () => {
      element.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleBlur = (e: any) => {
    e.target.name = "ethnicity";
    e.target.value = fieldValue.current;
    // onBlur(e);
  };

  const handleOnChangeProps = (value: string) => {
    fieldValue.current = value;
    onChange?.(value);
  };

  let otherValue = "";
  const initialValues = getEthnicityOptions();
  String(value || "")
    .split("|")
    .forEach((v) => {
      if (v.startsWith("other(")) {
        otherValue = RegExp(/other\((.*)\)/).exec(v)![1];
        if (otherValue || v === "other()") {
          initialValues.forEach((e) => {
            if (e.value === "other") {
              e.selected = true;
              otherSelected.current = true;
              otherInputValue.current = otherValue;
            }
          });
        }
      } else {
        initialValues.forEach((e) => {
          if (e.value === v) {
            e.selected = true;
          }
        });
      }
    });

  const options = initialValues.map((e) => ({ value: e.value, key: e.label, sub: e.extraText }));
  const selectedValues = initialValues.filter((e) => e.selected).map((e) => ({ value: e.value, key: e.label }));

  const handleOnChange = (action: "select" | "remove") => (selectedList: any, selectedItem: any) => {
    if (action === "select" && selectedItem.value === "other") {
      otherSelected.current = true;
      handleOnChangeProps(
        selectedList
          .filter((el: any) => el.value !== "preferNotToSay")
          .map((el: any) => el.value)
          .join("|")
      );
    } else if (action === "remove" && selectedItem.value === "other") {
      otherSelected.current = false;
      handleOnChangeProps(selectedList.map((el: any) => el.value).join("|"));
    } else if (action === "select" && selectedItem.value === "preferNotToSay") {
      otherSelected.current = false;
      handleOnChangeProps("preferNotToSay");
    } else {
      handleOnChangeProps(
        selectedList
          .filter((el: any) => el.value !== "preferNotToSay")
          .map((el: any) => el.value)
          .join("|")
      );
    }
  };

  const handleOnChangeOtherInput = (event: any) => {
    const newValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    if (newValue !== otherInputValue) {
      const selected = (multiselectRef.current?.getSelectedItems() || [])
        .filter((el: any) => el.value !== "other")
        .filter((el: any) => el.value !== "preferNotToSay")
        .map((el: any) => el.value);
      onChange?.([...selected, `other(${newValue})`].join("|"));
    }
  };

  return (
    <React.Fragment>
      <InputWrapper>
        <Multiselect
          ref={multiselectRef}
          options={options}
          selectedValues={selectedValues}
          displayValue="key"
          optionValueDecorator={(val, options) => {
            return (
              <span>
                {options.key}
                {options.sub && <span>({options.sub})</span>}
              </span>
            );
          }}
          avoidHighlightFirstOption={true}
          customCloseIcon={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <line
                x1="7.06066"
                y1="7"
                x2="17.3137"
                y2="17.253"
                stroke="#637074"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <line
                x1="7"
                y1="17.2528"
                x2="17.253"
                y2="6.99977"
                stroke="#637074"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          }
          showCheckbox={true}
          closeIcon="cancel"
          onSelect={handleOnChange("select")}
          onRemove={handleOnChange("remove")}
        />
      </InputWrapper>
      <BottomInputWrapper>
        {/* <BottomAdditionalText hasErrorMessage={hasErrorMessage}>{bottomAdditionalText}</BottomAdditionalText> */}
      </BottomInputWrapper>
      {otherSelected?.current && (
        <Form.Item label={`If "Other", please specify:`}>
          <Input
            id="ethnicity-other"
            name="ethnicity-other"
            type="text"
            size="large"
            placeholder="Describe your ethnicity"
            onChange={handleOnChangeOtherInput}
            value={otherInputValue.current}
          />
        </Form.Item>
      )}
    </React.Fragment>
  );
};

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const BottomInputWrapper = styled("div")<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
