import React from "react";
import { Button, Col, Form, Row, Select, Tag, Calendar, Popover, Space } from "antd";
import { SearchOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ITruck } from "../../../interfaces/truck.interface";
import moment from "moment";
import { colors } from "../../../styles/colors";
import { employeePositionColors } from "../../../helpers/employee-position-colors.helper";
import {
  getAppointmentTypeColor,
  getTextColorForVisitStatus,
  getVisitStatusesWhereTruckIsRequire,
  VisitStatuses,
} from "../../../helpers/visit.helper";
import { IVisitStaff } from "../../../interfaces/visit.interface";
import { ApiClient } from "../../../api-client/api.client";

interface IProps {
  searchForm: any;
  isLoading: boolean;
  filters: {
    selectedMonth: string;
    visitTypes: string[];
    visitStatuses: string[];
    trucks: string[];
  };
  trucks: ITruck[];
  employees: IVisitStaff[];
  onMonthChange: (month: string) => void;
  onSearchFormFinish: () => void;
  onFormReset: () => void;
}

interface IState {
  isCalendarVisible: boolean;
}

export class BookingBoardFilters extends React.Component<IProps, IState> {
  state: IState = {
    isCalendarVisible: false,
  };

  public getQuickFiltersTrucks = (truckList: Array<string>): string[] => {
    const { trucks } = this.props;
    return trucks.filter((truck) => truckList.includes(truck.name)).map((truck) => truck.id);
  };

  public getUser = async (): Promise<string[]> => {
    const { employees } = this.props;
    try {
      const { data } = await ApiClient.getAccountsMe();
      const employee = employees.find((employee) => employee.email === data.data.email);
      return employee ? [employee.id] : [];
    } catch (e) {
      return [];
    }
  };

  public onQuickFiltersChange = async (option: string) => {
    const { searchForm, onSearchFormFinish } = this.props;

    searchForm.current.setFieldsValue({
      visitTypes: [],
      visitStatuses: [],
      trucks: [],
      employees: [],
    });

    switch (option) {
      case "cs":
        searchForm.current.setFieldsValue({
          visitTypes: ["ro_private", "ro_public"],
          visitStatuses: [
            "active_complete",
            "active_booked",
            "inactive_confirmed",
            "inactive_cs_hold_date_offered",
            "inactive_internal_hold",
            "inactive_ops_hold",
          ],
          trucks: this.getQuickFiltersTrucks(["a. MVC1", "a. MVC2", "a. MVC5", "b. Alternate #1", "b. Alternate #2", "a. MVC4"]),
        });
        break;
      case "ect":
        searchForm.current.setFieldsValue({
          trucks: this.getQuickFiltersTrucks(["a. MVC1", "a. MVC2", "a. MVC3"]),
          visitStatuses: getVisitStatusesWhereTruckIsRequire(),
        });
        break;
      case "lisci":
        searchForm.current.setFieldsValue({
          visitTypes: ["life_sciences"],
        });
        break;
      case "me":
        searchForm.current.setFieldsValue({
          employees: await this.getUser(),
        });
        break;
      default:
        break;
    }
    onSearchFormFinish();
  };

  public render() {
    const { isCalendarVisible } = this.state;
    const { isLoading, filters, trucks, employees, searchForm, onMonthChange, onSearchFormFinish, onFormReset } =
      this.props;
    return (
      <Form layout="vertical" ref={searchForm} name="search-form">
        <Row style={{ margin: 0 }}>
          <Col span={12} style={{ backgroundColor: colors.lightGrey, padding: 10 }}>
            <Form.Item name="selectedDate" label="Month" style={{ marginBottom: 16 }}>
              <Space>
                <Button
                  onClick={() => {
                    onMonthChange(moment(filters.selectedMonth).subtract(1, "month").format("YYYY-MM"));
                  }}>
                  <LeftOutlined />
                </Button>
                <Popover
                  placement="rightTop"
                  visible={isCalendarVisible}
                  content={
                    <StyledCalendar
                      fullscreen={false}
                      mode="year"
                      value={moment(filters.selectedMonth).add(1, "d")}
                      onSelect={(date) => {
                        onMonthChange(date.format("YYYY-MM"));
                        this.setState({ isCalendarVisible: false });
                      }}
                    />
                  }
                  trigger="click">
                  <Button onClick={() => this.setState({ isCalendarVisible: true })}>{filters.selectedMonth}</Button>
                </Popover>
                <Button
                  onClick={() => {
                    onMonthChange(moment(filters.selectedMonth).add(1, "month").format("YYYY-MM"));
                  }}>
                  <RightOutlined />
                </Button>
              </Space>
            </Form.Item>
          </Col>

          <Col span={12} style={{ backgroundColor: colors.lightGrey, padding: 10 }}>
            <Form.Item label="Quick Filters" style={{ marginBottom: 16 }}>
              <Select
                defaultValue={"all"}
                style={{ width: "50%" }}
                onChange={this.onQuickFiltersChange}
                options={[
                  { label: "All", value: "all" },
                  { label: "CS", value: "cs" },
                  { label: "ECT", value: "ect" },
                  { label: "LiSci", value: "lisci" },
                  { label: "Me", value: "me" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24} style={{ backgroundColor: colors.lightGrey, padding: 10 }}>
            <Form.Item name="visitTypes" label="Visit types" initialValue={[]} style={{ marginBottom: 16 }}>
              <Select
                mode="multiple"
                showArrow
                tagRender={(props: any) => {
                  const { label, value, closable, onClose } = props;
                  return (
                    <Tag
                      color={getAppointmentTypeColor(value)}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}>
                      {label}
                    </Tag>
                  );
                }}
                style={{ width: "100%" }}
                options={[
                  { label: "Ro Private", value: "ro_private" },
                  { label: "Ro Public", value: "ro_public" },
                  { label: "Telehealth", value: "telehealth" },
                  { label: "Styling", value: "styling" },
                  { label: "Life Sciences", value: "life_sciences" },
                  { label: "Schools", value: "schools" },
                ]}
              />
            </Form.Item>
            <Form.Item name="visitStatuses" label="Visit statuses" initialValue={[]} style={{ marginBottom: 16 }}>
              <Select
                mode="multiple"
                showArrow
                tagRender={(props: any) => {
                  const { label, value, closable, onClose } = props;
                  return (
                    <Tag
                      color={getTextColorForVisitStatus(value)}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}>
                      {label}
                    </Tag>
                  );
                }}
                style={{ width: "100%" }}
                options={VisitStatuses.map((status) => ({ label: status.shortName, value: status.value }))}
              />
            </Form.Item>
            <Form.Item name="trucks" label="Clinics" initialValue={[]} style={{ marginBottom: 16 }}>
              <Select
                mode="multiple"
                showArrow
                tagRender={(props: any) => {
                  const { label, closable, onClose } = props;
                  return (
                    <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                      {label}
                    </Tag>
                  );
                }}
                style={{ width: "100%" }}
                options={trucks.map((truck) => ({
                  label: `${truck.name}`,
                  value: truck.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="employees" label="Employees" initialValue={[]} style={{ marginBottom: 16 }}>
              <Select
                style={{ width: "100%" }}
                mode={"multiple"}
                showSearch
                tagRender={(props: any) => {
                  const { label, closable, onClose } = props;
                  const position: string = label?.split("[")[1]?.replace("]", "") || "STANDARD";
                  return (
                    <Tag
                      // @ts-ignore
                      color={employeePositionColors[position]}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}>
                      {label}
                    </Tag>
                  );
                }}
                disabled={isLoading}
                filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {employees.map((employee) => (
                  <Select.Option key={employee.id} value={employee.id}>
                    {`${employee.firstName} ${employee.lastName} [${employee.position}]`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
              <ButtonsWrapper>
                <div style={{ marginRight: 15 }}>
                  <Button type="primary" loading={isLoading} onClick={onSearchFormFinish} icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
                <div>
                  <Button htmlType="button" onClick={onFormReset}>
                    Clear
                  </Button>
                </div>
              </ButtonsWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledCalendar = styled(Calendar)`
  width: 250px;
  div.ant-radio-group.ant-radio-group-outline.ant-radio-group-small.ant-picker-calendar-mode-switch {
    display: none;
  }
`;
