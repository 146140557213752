import React from "react";
import { Button, Card, notification } from "antd";
import { ApiClient } from "../../../../api-client/api.client";

type ActivatePatientAccountProps = {
  accountId: string;
  emails: string[];
  active: boolean;
};

export const ActivatePatientAccount = ({ accountId, emails, active }: ActivatePatientAccountProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [primaryEmail, secondaryEmail] = emails;

  const activateAccountHandler = async (accountId: string) => {
    try {
      setLoading(true);
      await ApiClient.activatePatientAccount(accountId);
      notification.success({
        message: "Success",
        description: `Account with email address ${primaryEmail} has been activated successfully.`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `There was a problem with activating account with ${primaryEmail} email. Please, contact support team.`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="Activate patient account">
      {primaryEmail}
      {secondaryEmail && <div>Secondary email address: {secondaryEmail}</div>}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          loading={loading}
          disabled={active}
          type="primary"
          htmlType="button"
          onClick={() => activateAccountHandler(accountId)}>
          {active ? "Account is already activated" : "Activate"}
        </Button>
      </div>
    </Card>
  );
};
