import React from "react";
import styled from "styled-components";
import { GoogleMap, Marker, useJsApiLoader, InfoWindow, Circle } from "@react-google-maps/api";

import { Spin } from "antd";

import { MAP_SETTINGS } from "./Map.constants";

interface IMapContainerProps {
  markers?: {
    color: "blue" | "red";
    coordinates: {
      lat: number;
      lng: number;
    };
    popup: any;
  }[];
  circle?: {
    center: {
      lat: number;
      lng: number;
    };
    radius: number; //in meters
  };
  styles?: any;
  draggableMarker?: {
    onDragEnd: (e: any) => void;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
}

const createBoundsForMarkers = (map, markers: any[]) => {
  const bounds = new window.google.maps.LatLngBounds();
  if (markers && markers.length) {
    markers.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.coordinates.lat, marker.coordinates.lng));
    });
  } else {
    bounds.extend(new window.google.maps.LatLng(MAP_SETTINGS.DEFAULT_CENTER.lat, MAP_SETTINGS.DEFAULT_CENTER.lng));
  }
  map.fitBounds(bounds);
};

const createBoundsForDraggableMarker = (map, draggableMarker) => {
  const bounds = new window.google.maps.LatLngBounds();
  bounds.extend(new window.google.maps.LatLng(draggableMarker.coordinates.lat, draggableMarker.coordinates.lng));
  map.fitBounds(bounds);
};

const createZoomAfterFitBounds = (map: any) => {
  const listener = google.maps.event.addListener(map, "idle", function () {
    if (map.getZoom() > MAP_SETTINGS.DEFAULT_ZOOM) map.setZoom(MAP_SETTINGS.DEFAULT_ZOOM);
    google.maps.event.removeListener(listener);
  });
};

function MapContainer(props: IMapContainerProps) {
  const { markers, circle, styles, draggableMarker } = props;
  const { isLoaded, loadError } = useJsApiLoader({
    id: MAP_SETTINGS.ID_SCRIPT,
    googleMapsApiKey: MAP_SETTINGS.API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [markersWithPopupInfo, setMarkersWithPopupInfo] = React.useState([]);

  const onLoad = React.useCallback(function callback(map) {
    if (draggableMarker) {
      createBoundsForDraggableMarker(map, draggableMarker);
    } else {
      createBoundsForMarkers(map, markers);
    }

    createZoomAfterFitBounds(map);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (draggableMarker && map)
      map.panTo(new window.google.maps.LatLng(draggableMarker.coordinates.lat, draggableMarker.coordinates.lng));
  });

  React.useEffect(() => {
    if (circle && map) map.panTo(new window.google.maps.LatLng(circle.center.lat, circle.center.lng));
  }, [circle]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={styles ? { ...MAP_SETTINGS.STYLES, ...styles } : MAP_SETTINGS.STYLES}
        zoom={MAP_SETTINGS.DEFAULT_ZOOM}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        {markers && (
          <>
            {markers.map((marker, id) => (
              <Marker
                key={id}
                animation={window.google.maps.Animation.DROP}
                position={marker.coordinates}
                icon={{
                  url: marker.color === "blue" ? MAP_SETTINGS.BLUE_ICON.ICON_URL : MAP_SETTINGS.RED_ICON.ICON_URL,
                  scaledSize: new window.google.maps.Size(MAP_SETTINGS.MARKER_SIZE[0], MAP_SETTINGS.MARKER_SIZE[1]),
                }}
                onClick={() => {
                  if (!markersWithPopupInfo.includes(id)) setMarkersWithPopupInfo([...markersWithPopupInfo, id]);
                }}>
                {markersWithPopupInfo.includes(id) && (
                  <InfoWindow
                    onCloseClick={() => {
                      setMarkersWithPopupInfo([...markersWithPopupInfo].filter((el) => el !== id));
                    }}>
                    <div>{marker.popup}</div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </>
        )}
        {circle && <Circle center={circle.center} radius={circle.radius} options={MAP_SETTINGS.CIRCLE_OPTIONS} />}
        {draggableMarker && (
          <Marker
            key="draggable-marker"
            animation={window.google.maps.Animation.DROP}
            position={draggableMarker.coordinates}
            draggable
            onDragEnd={(e) => {
              draggableMarker.onDragEnd(e);
            }}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
}

export default React.memo(MapContainer);

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
