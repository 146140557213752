export interface GeocodeAddress {
  formattedAddress: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

export const geocode = async (address: string): Promise<GeocodeAddress[]> => {
  const apiKey = process.env.GOOGLE_MAPS_API_KEY;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}&components=country:US`
  );
  const jsonResponse = await response.json();
  if (jsonResponse && jsonResponse.results && jsonResponse.results.length) {
    return jsonResponse.results.map((r: any) => {
      return {
        formattedAddress: r.formatted_address,
        coordinates: r.geometry.location,
      };
    });
  }
  return [];
};

export const reverseGeocode = async (coordinates: { lng: number; lat: number }): Promise<GeocodeAddress[]> => {
  const apiKey = process.env.GOOGLE_MAPS_API_KEY;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${apiKey}`
  );
  const jsonResponse = await response.json();
  if (jsonResponse && jsonResponse.results && jsonResponse.results.length) {
    return jsonResponse.results.map((r: any) => {
      return {
        formattedAddress: r.formatted_address,
        coordinates: r.geometry.location,
      };
    });
  }
  return [];
};
