import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { TrucksListComponent } from "./components/trucks-list.component";
import { CreateTruckComponent } from "./components/create-truck.component";
import { EditTruckComponent } from "./components/edit-truck.component";

interface IProps extends RouteComponentProps {}

export class Trucks extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Clinics</Typography.Title>
        <Route path="/clinics/create" component={CreateTruckComponent} />
        <Route path="/clinics/:id/edit" component={EditTruckComponent} />
        <Route path="/clinics/" exact component={TrucksListComponent} />
      </>
    );
  }
}
