import React from "react";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { marketingConsents as marketingConsentsQuestions } from "../questions";
import { QuestionSelectYesOrNoOption } from "../inputs/question-select-yes-or-no-option.component";

interface IProps {
  marketingConsents: IState["marketingConsents"];
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
}

export const MarketingConsents = (props: IProps) => {
  const { marketingConsents, updateQuestionnaireField } = props;
  return (
    <>
      {marketingConsentsQuestions.map((q) => (
        <QuestionSelectYesOrNoOption
          key={q.key}
          question={q.question}
          explanation={q.explanation}
          options={q.options}
          defaultValue={marketingConsents[q.key]}
          onChange={(e: any) => {
            updateQuestionnaireField({
              section: "marketingConsents",
              key: q.key,
              value: e.target.value,
            });
          }}
          labelColOptions={{ xs: { span: 24 }, md: { span: 12 } }}
        />
      ))}
    </>
  );
};
