import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, message, Row, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { dateFormatter } from "../../../helpers/date-formatter.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  trucks: [];
}

export class TrucksListComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    trucks: [],
  };

  public componentDidMount(): void {
    this.getTrucks();
  }

  public async getTrucks() {
    this.setState({ isLoading: true });

    try {
      const { data: trucks } = await ApiClient.findTrucks();

      this.setState({ trucks, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch clinics data");
      this.setState({ isLoading: false });
    }
  }

  public render() {
    const { trucks, isLoading } = this.state;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        key: "status",
        render: ({ isDisabled }) => {
          return isDisabled ? <Tag color="red">inactive</Tag> : <Tag color="green">active</Tag>;
        },
      },
      {
        title: "Assigned visits",
        key: "assignedVisits",
        render: ({ truckReservations }) => <Tag color="blue">{truckReservations.length}</Tag>,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Link to={`/clinics/${id}/edit`}>
              <Button size={"small"}>Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ marginBottom: 24, textAlign: "right" }}>
            <Link to="/clinics/create">
              <Button type="primary">Add clinic</Button>
            </Link>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{trucks.length}</strong>
        </Typography.Text>
        <Table bordered loading={isLoading} size="small" dataSource={trucks} columns={columns} pagination={false} />
      </>
    );
  }
}
