// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import * as React from 'react';
import FolderOpenOutlinedSvg from "@ant-design/icons-svg/es/asn/FolderOpenOutlined";
import AntdIcon from '../components/AntdIcon';

var FolderOpenOutlined = function FolderOpenOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: FolderOpenOutlinedSvg
  }));
};

FolderOpenOutlined.displayName = 'FolderOpenOutlined';
export default React.forwardRef(FolderOpenOutlined);