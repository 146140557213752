import React from "react";
import { Button, message, Input } from "antd";
import styled from "styled-components";

import { ApiClient } from "../../../api-client/api.client";
import { colors } from "../../../styles/colors";

interface IProps {
  visitId: string;
  getVisitsInBackground: () => void;
  getVisitsWithoutRequireTruckInBackground: () => void;
}

interface IState {
  schedulingNotes: string;
  isLoading: boolean;
}

export class SchedulingNotes extends React.Component<IProps, IState> {
  state = {
    schedulingNotes: "",
    isLoading: false,
  };

  componentDidMount = () => {
    this.getSchedulingNotes();
  };

  getSchedulingNotes = async () => {
    try {
      const { visitId } = this.props;
      const response = await ApiClient.getVisitSchedulingNotes(visitId);
      this.setState({
        schedulingNotes: response.data.schedulingNotes,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  updateSchedulingNotes = async () => {
    try {
      const { visitId, getVisitsInBackground, getVisitsWithoutRequireTruckInBackground } = this.props;
      const { schedulingNotes } = this.state;
      this.setState({ isLoading: true });
      await ApiClient.updateVisitSchedulingNotes(visitId, { schedulingNotes });
      await this.getSchedulingNotes();
      getVisitsInBackground();
      getVisitsWithoutRequireTruckInBackground();
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      message.error(error.message);
    }
  };

  onTextAreaChange = (value: string) => {
    this.setState({
      schedulingNotes: value,
    });
  };

  public render() {
    const { schedulingNotes, isLoading } = this.state;

    return (
      <>
        <div>
          <strong>Scheduling notes:</strong>
        </div>
        <Input.TextArea
          rows={4}
          value={schedulingNotes}
          maxLength={280}
          onChange={(e) => this.onTextAreaChange(e.target.value)}
        />
        <LettersCounter color={schedulingNotes && schedulingNotes.length > 250 ? colors.red : colors.black}>
          {schedulingNotes ? schedulingNotes.length : 0}/280
        </LettersCounter>
        <Button loading={isLoading} onClick={this.updateSchedulingNotes}>
          Save
        </Button>
      </>
    );
  }
}

const LettersCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props: { color?: string }) => (props.color ? props.color : colors.black)};
`;
