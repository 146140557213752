import React from "react";
import { ApiClient } from "../../api-client/api.client";
import { Space, Spin } from "antd";

interface IProps {
  children: any;
}

interface IState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

export class AuthenticationGuard extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    isAuthenticated: false,
  };

  public async componentDidMount(): Promise<void> {
    try {
      const { data } = await ApiClient.getAccountsMe();
      const userData = data.data;
      if (!userData || (userData && !["admin", "user"].includes(userData.role))) {
        await ApiClient.removeAuthorizationAccessToken()
        this.setState({ isLoading: false, isAuthenticated: false });
      } else {
        this.setState({ isLoading: false, isAuthenticated: true});
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  public render() {
    const { isAuthenticated, isLoading } = this.state;

    if (isLoading) {
      return (
        <Space
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          align="center"
          size="middle">
          <Spin size="large" tip="Loading..." />
        </Space>
      );
    }

    if (!isAuthenticated) {
      AuthenticationGuard.redirectToAuthenticationApp();
      return null;
    }

    return <>{this.props.children}</>;
  }

  public static redirectToAuthenticationApp() {
    window.location.href = `${process.env.AUTHENTICATION_APP_URL}?target=${window.location.href.replace(
      "/logout",
      ""
    )}`;
  }

  public static async removeAuthorizationToken() {
    await ApiClient.removeAuthorizationAccessToken();
  }
}
