import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Typography, Row, Col } from "antd";

import video from "./../../assets/videos/shesback.mp4";

interface IProps extends RouteComponentProps {}

const helpfulLinks = [
  [
    { name: " Slack", link: "https://2020visionaries.slack.com" },
    {
      name: "Avochato",
      link: "http://avochato.com",
    },
    {
      name: "Airbase",
      link: "https://dashboard.airbase.io/home",
    },
  ],
  [
    {
      name: "ADP",
      link: "https://workforcenow.adp.com",
    },
    {
      name: "Harvard Pilgrim",
      link: "https://hphconnect.harvardpilgrim.org/",
    },
    {
      name: "Optum Benefits Mall",
      link: "https://healthsafeid.optum.com/",
    },
  ],
  [
    {
      name: "Helpscout",
      link: "https://secure.helpscout.net/members/login/",
    },
    {
      name: "Salesforce",
      link: "https://2020onsite.lightning.force.com/lightning/page/home",
    },
    {
      name: "Unbounce",
      link: "https://app.unbounce.com/users/sign_in",
    },
  ],
];

export class Home extends React.Component<IProps> {
  public state = {
    played: false,
  };

  public playVideo = () => {
    window.document.getElementById("video").play();
    this.setState({ played: true });
  };

  render(): React.ReactNode {
    const { played } = this.state;
    return (
      <>
        <BottomSectionWrapper>
          <Typography.Title level={2} style={{ marginBottom: "30px" }}>
            Quick Links
          </Typography.Title>
          <HelpfulLinksContainer>
            {helpfulLinks.map((linksGroup, idx) => (
              <div key={idx}>
                <ul>
                  {linksGroup.map((linkItem) => (
                    <li>
                      <HelpfulLink target={"_blank"} href={linkItem.link}>
                        {linkItem.name}
                      </HelpfulLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div>
              Acuitas Customer Support
              <br />
              1-
              <HelpfulLink target={"_blank"} href={"tel:8009870543"}>
                800-987-0543
              </HelpfulLink>
            </div>
            <div>
              <HelpfulLink
                target={"_blank"}
                href={"https://nachat.myconnectwise.net/customer/partnerId/5a32861826a7be7541efd652?n=1&q=Help%20Desk"}>
                August E-tech Support
              </HelpfulLink>
              <br />
              1-
              <HelpfulLink target={"_blank"} href={"tel:6092500522"}>
                609-250-0522
              </HelpfulLink>
            </div>
          </HelpfulLinksContainer>
        </BottomSectionWrapper>
        <SectionDivider />
        <VideoWrapper>
          <Video id="video" loop>
            <source src={video} type="video/mp4" />
          </Video>
          {!played ? (
            <>
              <Overlay />
              <PlayButton onClick={this.playVideo}>
                <PlayCircleOutlined />
              </PlayButton>
            </>
          ) : null}
        </VideoWrapper>
      </>
    );
  }
}

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  max-width: 1316px;
  width: 100%;
  background-color: #eee;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  max-width: 1316px;
  width: 100%;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  svg {
    min-height: 100px;
    min-width: 100px;
    fill: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    svg {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
`;

const BottomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  ul,
  li {
    padding: 0;
    list-style-type: none;
  }
`;

const HelpfulLinksContainer = styled(Row)`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 0 20px;

  @media (min-width: 768px) {
    padding-left: 75px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const HelpfulLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const SectionDivider = styled.div`
  width: auto;
  height: 40px;
  background-color: #f0f2f5;
  margin: 30px -30px;
  padding: -30px;
`;
