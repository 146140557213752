import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, Col, Descriptions, message, Row, Table, Spin, PageHeader } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { dateFormatterWithoutTime } from "../../../helpers/date-formatter.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  data: {
    date: string;
    company: { id: string; name: string; slug: string };
    reservationName: string;
    cpvAmount: { cpvName: string; amount: number }[];
    appointmentsStatusAmount: { statusName: string; amount: number }[];
    currentCPV: number;
    waitlist: number;
    targetCPV: number;
    conversion: { id: string; step: number; type: string }[];
  };
}

export class StatsForVisitComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    data: {
      date: "",
      company: { id: "", name: "", slug: "" },
      reservationName: "",
      cpvAmount: [],
      appointmentsStatusAmount: [],
      currentCPV: 0,
      waitlist: 0,
      targetCPV: 0,
      conversion: [],
    },
  };

  public componentDidMount(): void {
    this.getVisit();
  }

  public async getVisit() {
    const visitId = this.props.match.params.id;
    try {
      this.setState({ isLoading: true });
      const stats = await ApiClient.getVisitStats(visitId);
      const conversion = await ApiClient.getVisitConversion(visitId);
      this.setState({
        data: {
          ...stats.data,
          conversion: conversion.data,
        },
        isLoading: false,
      });
    } catch (e) {
      message.error("Cannot fetch stats for visit data");
      this.setState({ isLoading: false });
    }
  }

  public countVisits = (step: number) => {
    const { data } = this.state;
    return data.conversion.filter((c) => c.step === step && c.type === "visit").length;
  };

  public countConversions = (step: number) => {
    const { data } = this.state;
    return data.conversion.filter((c) => c.step === step && c.type === "action").length;
  };

  public countConversionRate = (visits: number, actions: number) => {
    if (!visits || !actions) {
      return "0.00 %";
    }

    return ((actions / visits) * 100).toFixed(2) + " %";
  };

  public render() {
    const { isLoading, data } = this.state;

    const appointmentStatus = ["confirmed", "checked_in", "ready_for_exam", "exam", "checked_out"];

    const mapOfCpv = new Map(data.cpvAmount.map((i) => [i.cpvName, i.amount]));
    const mapOfAppointmentsStatusAmount = new Map(data.appointmentsStatusAmount.map((i) => [i.statusName, i.amount]));

    const conversionColumns = [
      {
        title: "Step",
        dataIndex: "step",
        key: "step",
        render: (text) => text,
      },
      {
        title: "Visits",
        dataIndex: "visits",
        key: "visits",
        render: (text) => text,
      },
      {
        title: "Conversions",
        dataIndex: "conversions",
        key: "conversions",
        render: (text) => text,
      },
      {
        title: "Conversion Rate",
        dataIndex: "rate",
        key: "rate",
        render: (text) => text,
      },
    ];

    const step1Visits = this.countVisits(1);
    const step1Actions = this.countConversions(1);
    const step1ConversionRate = this.countConversionRate(step1Visits, step1Actions);

    const step2Visits = this.countVisits(2);
    const step2Actions = this.countConversions(2);
    const step2ConversionRate = this.countConversionRate(step2Visits, step2Actions);

    const step3Visits = this.countVisits(3);
    const step3Actions = this.countConversions(3);
    const step3ConversionRate = this.countConversionRate(step3Visits, step3Actions);

    const step4Visits = this.countVisits(4);
    const step4Actions = this.countConversions(4);
    const step4ConversionRate = this.countConversionRate(step4Visits, step4Actions);

    const conversionData = [
      {
        key: "1",
        step: "1",
        visits: step1Visits,
        conversions: step1Actions,
        rate: step1ConversionRate,
      },
      {
        key: "2",
        step: "2",
        visits: step2Visits,
        conversions: step2Actions,
        rate: step2ConversionRate,
      },
      {
        key: "3",
        step: "3",
        visits: step3Visits,
        conversions: step3Actions,
        rate: step3ConversionRate,
      },
      {
        key: "4",
        step: "4",
        visits: step4Visits,
        conversions: step4Actions,
        rate: step4ConversionRate,
      },
    ];

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/stats")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Back to stats"
        />
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Card title="Reservation details" bordered={true} loading={isLoading}>
              <Descriptions layout="vertical" bordered>
                <Descriptions.Item label="Date">
                  {dateFormatterWithoutTime(new Date(data.date), "UTC")}
                </Descriptions.Item>
                <Descriptions.Item label="Company name">{data.company.name}</Descriptions.Item>
                <Descriptions.Item label="Reservation name">{data.reservationName}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card title="CPV" bordered={true} loading={isLoading}>
              <Descriptions layout="vertical" bordered column={{ xs: 8, sm: 16, md: 24 }}>
                <Descriptions.Item label="V28">{mapOfCpv.get("V28")}</Descriptions.Item>
                <Descriptions.Item label="V21">{mapOfCpv.get("V21")}</Descriptions.Item>
                <Descriptions.Item label="V14">{mapOfCpv.get("V14")}</Descriptions.Item>
                <Descriptions.Item label="V7">{mapOfCpv.get("V7")}</Descriptions.Item>
                <Descriptions.Item label="V1">{mapOfCpv.get("V1")}</Descriptions.Item>
                <Descriptions.Item label="Current CPV">
                  <a
                    style={{ color: "#000", textDecoration: "underline" }}
                    href={`/appointments?companyId=${
                      data.company.id
                    }&statuses=${appointmentStatus.toString()}&visitId=${this.props.match.params.id}&startDate=${
                      data.date
                    }&endDate=${data.date}`}
                    target="_blank">
                    {data.currentCPV}
                  </a>
                </Descriptions.Item>
                <Descriptions.Item label="Target CPV">{data.targetCPV}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 50 }}>
          <Col className="gutter-row" span={12}>
            <Card title="Appointments & Waitlist" bordered={true} loading={isLoading}>
              <Row>
                <Col span={24}>
                  <Descriptions layout="horizontal" bordered column={{ xs: 1, sm: 1, md: 1 }}>
                    <Descriptions.Item label="Confirmed">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=confirmed&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("confirmed")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Checked in">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=checked_in&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("checked_in")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Exam">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=exam&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("exam")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Checked out">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=checked_out&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("checked_out")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Not eligible">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_not_eligible&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_not_eligible")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Refused exam">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_refused_exam&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_refused_exam")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Day-of Issue">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_day_of_issue&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_day_of_issue")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - No show">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_no_show&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_no_show")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Patient late">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_patient_late&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_patient_late")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Admin cancel">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_admin_cancel&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_admin_cancel")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cancelled - Early cancel">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/appointments?companyId=${data.company.id}&statuses=cancelled_early_cancel&visitId=${this.props.match.params.id}&startDate=${data.date}&endDate=${data.date}`}
                        target="_blank">
                        {mapOfAppointmentsStatusAmount.get("cancelled_early_cancel")}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Waitlist">
                      <a
                        style={{ color: "#000", textDecoration: "underline" }}
                        href={`/waitlist/subscriptions?locationId=${data.locationId}`}
                        target="_blank">
                        {data.waitlist}
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card title="Conversion" bordered={true} loading={isLoading}>
              <Table columns={conversionColumns} dataSource={conversionData} pagination={false} />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
