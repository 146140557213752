import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { CreatePatientComponent } from "./components/create-patient.component";
import { PatientsListComponent } from "./components/patients-list.component";
import { EditPatientComponent } from "./components/edit-patient.component";

interface IProps extends RouteComponentProps {}

export class Patients extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Patients</Typography.Title>
        <Route path="/patients/create" component={CreatePatientComponent} />
        <Route path="/patients/:id/edit" component={EditPatientComponent} />
        <Route path="/patients/" exact component={PatientsListComponent} />
      </>
    );
  }
}
