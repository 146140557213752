import React from "react";
import { Tag, message, Select, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { IVisitStaff } from "../../../interfaces/visit.interface";
import { IEmployee } from "../../../interfaces/booking-board.interface";
import { getPositionTagColor } from "../../../helpers/visit.helper";
import { ApiClient } from "../../../api-client/api.client";
import { ISelectedPerson } from "../../../interfaces/booking-board.interface";

interface IProps {
  visitId: string;
  employees: IEmployee[];
  getVisitsInBackground: () => void;
}

interface IState {
  positions: { i: number; name: string }[];
  assignedStaff: IVisitStaff[];
  selectedStaff: ISelectedPerson[];
}

export class Staff extends React.Component<IProps, IState> {
  state = {
    positions: [
      { i: 0, name: "CS" },
      { i: 0, name: "OD" },
      { i: 0, name: "SM" },
      { i: 0, name: "ASM" },
      { i: 0, name: "EXTRA" },
      { i: 1, name: "EXTRA" },
      { i: 2, name: "EXTRA" },
      { i: 3, name: "EXTRA" },
    ],
    assignedStaff: [],
    selectedStaff: [],
  };

  componentDidMount = () => {
    this.getVisitStaff();
  };

  private getVisitStaff = async () => {
    try {
      const { visitId } = this.props;
      const response = await ApiClient.getVisitStaff(visitId);
      this.setState({
        assignedStaff: response.data.staff,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  private getReadableUser = (userId: string) => {
    const user = this.props.employees.find((e) => e.id === userId);
    if (!user) return "-";
    return `${user.firstName} ${user.lastName} ${user.position ? `[${user.position}]` : ""}`;
  };

  private onPersonSelect = (selectedPerson: ISelectedPerson) => {
    const { selectedStaff, positions } = this.state;
    const position = positions.filter((p) => p.name === selectedPerson.positionName);
    const currentPositionCount = selectedStaff.filter(
      (p: ISelectedPerson) => p.positionName === selectedPerson.positionName
    ).length;

    if (position.length > currentPositionCount) {
      this.setState({
        selectedStaff: [...selectedStaff, selectedPerson],
      });
    } else if (position.length === currentPositionCount) {
      const existingPerson: ISelectedPerson | undefined = selectedStaff.find(
        (p: ISelectedPerson) =>
          p.positionName === selectedPerson.positionName && p.positionIndex === selectedPerson.positionIndex
      );

      if (existingPerson) {
        this.setState({
          selectedStaff: [
            ...selectedStaff.filter((p: ISelectedPerson) => p.employeeId !== existingPerson!.employeeId),
            selectedPerson,
          ],
        });
      }
    }
  };

  private saveSelectedStaff = async () => {
    const { visitId } = this.props;
    const selectedStaff: IState["selectedStaff"] = this.state.selectedStaff;

    try {
      for (const person of selectedStaff) {
        await ApiClient.addEmployeeToVisit(visitId, { id: person.employeeId, position: person.positionName });
      }
      await this.getVisitStaff();
      this.setState({ selectedStaff: [] });
    } catch (error) {
      message.error(error.message);
    }
  };

  private removeEmployeeFromVisit = async (userId: string) => {
    try {
      const { visitId } = this.props;
      await ApiClient.removeEmployeeFromVisit(visitId, userId);
      await this.getVisitStaff();
    } catch (error) {
      message.error(error.message);
    }
  };

  public render() {
    const { employees } = this.props;
    const { positions, assignedStaff, selectedStaff } = this.state;

    return (
      <>
        <div>
          <strong>Staff:</strong>
        </div>

        <TableStyled>
          <tbody>
            {positions.map((position) => {
              const assignedEmployee: IVisitStaff = assignedStaff.filter(
                (e: IVisitStaff) => e.position === position.name
              )[position.i];

              return (
                <tr>
                  <td className="label" style={{ verticalAlign: "baseline" }}>
                    <TagStyled color={getPositionTagColor(position.name)}>{position.name}</TagStyled>
                  </td>
                  <td>
                    {assignedEmployee ? (
                      <>
                        <EmployeeWrapper>
                          <strong>{this.getReadableUser(assignedEmployee.id)}</strong>
                          <Button
                            danger
                            type="primary"
                            size="small"
                            icon={<MinusOutlined />}
                            onClick={() => this.removeEmployeeFromVisit(assignedEmployee.id)}
                          />
                        </EmployeeWrapper>
                      </>
                    ) : (
                      <EmployeeWrapper>
                        <SelectStyled
                          placeholder="Select a user"
                          size="small"
                          onChange={(value: string) => {
                            this.onPersonSelect({
                              employeeId: value,
                              positionName: position.name,
                              positionIndex: position.i,
                            });
                          }}>
                          {employees
                            .filter((user) => {
                              if (position.name === "EXTRA" && user.position) {
                                return true;
                              }
                              return user.position === position.name;
                            })
                            .map((user) => (
                              <Select.Option key={user.id} value={user.id}>
                                {this.getReadableUser(user.id)}
                              </Select.Option>
                            ))}
                        </SelectStyled>
                      </EmployeeWrapper>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableStyled>
        <ButtonWrapper>
          <Button type="default" onClick={this.saveSelectedStaff} disabled={!selectedStaff.length}>
            Save
          </Button>
        </ButtonWrapper>
      </>
    );
  }
}

const TableStyled = styled.table`
  width: 100%;
  tr {
    border-bottom: 1px solid #eeeeee;
  }
  td {
    padding: 7px;
  }
  .label {
    width: 60px;
    font-weight: bold;
    text-align: center;
  }
`;

export const TagStyled = styled(Tag)`
  width: 60px;
`;

const SelectStyled = styled(Select)`
  width: 100%;
`;

export const EmployeeWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
  }

  .ant-btn {
    padding: 0 4px !important;
    margin-left: 10px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 5px 0 0 8px;
`;
