import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { Typography } from "antd";
import { AppointmentsComponent } from "./components/appointments.component";
import { CreateAppointmentComponent } from "./components/create-appointment.component";
import { EditAppointmentComponent } from "./components/edit-appointment.component";
import { EditProductComponent } from "./components/edit-product.component";
import { AddProductComponent } from "./components/add-product.component";
import { AddDocumentComponent } from "./components/add-document.component";

interface IProps extends RouteComponentProps {}

export class Appointments extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Appointments</Typography.Title>
        <Route path="/appointments/:id/products/:productId/edit" component={EditProductComponent} />
        <Route path="/appointments/:id/products/add" component={AddProductComponent} />
        <Route path="/appointments/:id/documents/add" component={AddDocumentComponent} />
        <Route path="/appointments/:id/edit" component={EditAppointmentComponent} />
        <Route
          path="/appointments/create_retail"
          component={(props: any) => <CreateAppointmentComponent {...props} isRetailOnly />}
        />
        <Route path="/appointments/create" component={CreateAppointmentComponent} />
        <Route path="/appointments/" exact component={AppointmentsComponent} />
      </>
    );
  }
}
