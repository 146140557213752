import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {}

interface IState {}

export class AccountsSettingsComponent extends React.Component<IProps, IState> {
  public render() {
    return (
      <>
        <iframe
          src={`${process.env.AUTHENTICATION_APP_URL}/accounts`}
          style={{
            width: "100%",
            height: "90vh",
            border: "none",
            marginTop: 24,
          }}
        />
      </>
    );
  }
}
