import React from "react";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { QuestionSelectOption } from "../inputs/question-select-option.component";
import { SectionHeader } from "../section-header.component";
import { currentVisionConditionSelectQuestions, currentVisionConditionYesNoQuestions } from "../questions";
import { QuestionSelectYesOrNoOption } from "../inputs/question-select-yes-or-no-option.component";

interface IProps {
  currentVisionCondition: IState["currentVisionCondition"];
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
}

export const CurrentVisionCondition = (props: IProps) => {
  const { currentVisionCondition, updateQuestionnaireField } = props;
  return (
    <>
      <SectionHeader margin="50px 0px 20px 0" header="Current vision condition" />
      {currentVisionConditionYesNoQuestions.map((q) => (
        <div key={q.key} style={{ marginBottom: 10 }}>
          <QuestionSelectYesOrNoOption
            key={q.key}
            question={q.question}
            options={q.options}
            defaultValue={currentVisionCondition[q.key]}
            onChange={(e: any) => {
              updateQuestionnaireField({
                section: "currentVisionCondition",
                key: q.key,
                value: e.target.value,
              });
            }}
            labelColOptions={{ xs: { span: 24 }, md: { span: 12 } }}
          />
        </div>
      ))}

      {currentVisionConditionSelectQuestions.map((q) => (
        <QuestionSelectOption
          key={q.question}
          question={q.question}
          options={q.options}
          defaultValue={currentVisionCondition[q.key]}
          onChange={(value: string) => {
            updateQuestionnaireField({
              section: "currentVisionCondition",
              key: q.key,
              value,
            });
          }}
          labelColOptions={{ xs: { span: 24 }, md: { span: 12 } }}
        />
      ))}
    </>
  );
};
