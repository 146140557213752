import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { SubscriptionsListComponent } from "./components/subscriptions-list.component";
import { CreateSubscriptionComponent } from "./components/create-subscription.component";
import { EditSubscriptionComponent } from "./components/edit-subscription.component";

interface IProps extends RouteComponentProps {}

export class Waitlist extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Waitlist</Typography.Title>
        <Route path="/waitlist/subscriptions/create" component={CreateSubscriptionComponent} />
        <Route path="/waitlist/subscriptions/:id/edit" component={EditSubscriptionComponent} />
        <Route path="/waitlist/subscriptions" exact component={SubscriptionsListComponent} />
      </>
    );
  }
}
