import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { CreateInsuranceComponent } from "./components/create-insurance.component";
import { InsurancesListComponent } from "./components/insurances-list.component";
import { EditInsuranceComponent } from "./components/edit-insurance.component";

interface IProps extends RouteComponentProps {}

export class Insurances extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Insurances</Typography.Title>
        <Route path="/insurances/create" component={CreateInsuranceComponent} />
        <Route path="/insurances/:id/edit" component={EditInsuranceComponent} />
        <Route path="/insurances/" exact component={InsurancesListComponent} />
      </>
    );
  }
}
