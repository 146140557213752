import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { message, Button, Form, Input, PageHeader, Card, Row, Col, Checkbox, Radio } from "antd";
import { FormInstance } from "antd/es/form";
import slugify from "slugify";
import { ApiClient } from "../../../api-client/api.client";
import { validationPatterns } from "../../../helpers/validation.helper";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
}

export class CreateCompanyComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  private form = React.createRef<FormInstance>();

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      await this.form.current!.validateFields();

      const { data: response } = await ApiClient.createCompany({
        name: values.name,
        slug: values.slug,
        SFAccountId: values.SFAccountId,
        type: values.type,
      });

      message.success("Company successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push(`/companies/${response.id}/edit`);
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onChange = (values: any) => {
    if (values.name) {
      this.form.current!.setFieldsValue({
        slug: slugify(values.name, { lower: true, strict: true }).trim(),
      });
    }
  };

  public render() {
    const { isLoading } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/companies")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create company"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Company">
              <Form
                layout="vertical"
                ref={this.form}
                name="create-company"
                onFinish={this.onFinish}
                onValuesChange={this.onChange}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="slug" label="Slug" rules={[{ required: true }]}>
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="SFAccountId"
                  label="SFAccountId"
                  rules={[
                    {
                      pattern: validationPatterns.salesforceAccountId,
                      message: "Invalid Salesforce Account ID.  Please ensure to input the ID starting with 001",
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: "Please pick type!" }]}
                  initialValue="routine_optometry">
                  <Radio.Group>
                    <Radio value="ro_public">RO Public</Radio>
                    <Radio value="ro_private">RO Private</Radio>
                    <Radio value="life_sciences">Life Sciences</Radio>
                    <Radio value="schools">Schools</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
