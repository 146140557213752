// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import * as React from 'react';
import CarryOutFilledSvg from "@ant-design/icons-svg/es/asn/CarryOutFilled";
import AntdIcon from '../components/AntdIcon';

var CarryOutFilled = function CarryOutFilled(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: CarryOutFilledSvg
  }));
};

CarryOutFilled.displayName = 'CarryOutFilled';
export default React.forwardRef(CarryOutFilled);