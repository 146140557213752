import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { useState } from 'react';
export var DEFAULT_PAGE_SIZE = 10;
export function getPaginationParam(pagination, mergedPagination) {
  var param = {
    current: mergedPagination.current,
    pageSize: mergedPagination.pageSize
  };
  var paginationObj = pagination && _typeof(pagination) === 'object' ? pagination : {};
  Object.keys(paginationObj).forEach(function (pageProp) {
    var value = mergedPagination[pageProp];

    if (typeof value !== 'function') {
      param[pageProp] = value;
    }
  });
  return param;
}

function extendsObject() {
  var result = {};

  for (var _len = arguments.length, list = new Array(_len), _key = 0; _key < _len; _key++) {
    list[_key] = arguments[_key];
  }

  list.forEach(function (obj) {
    if (obj) {
      Object.keys(obj).forEach(function (key) {
        var val = obj[key];

        if (val !== undefined) {
          result[key] = val;
        }
      });
    }
  });
  return result;
}

export default function usePagination(total, pagination, onChange) {
  var _a = pagination && _typeof(pagination) === 'object' ? pagination : {},
      _a$total = _a.total,
      paginationTotal = _a$total === void 0 ? 0 : _a$total,
      paginationObj = __rest(_a, ["total"]);

  var _useState = useState(function () {
    return {
      current: 'defaultCurrent' in paginationObj ? paginationObj.defaultCurrent : 1,
      pageSize: 'defaultPageSize' in paginationObj ? paginationObj.defaultPageSize : DEFAULT_PAGE_SIZE
    };
  }),
      _useState2 = _slicedToArray(_useState, 2),
      innerPagination = _useState2[0],
      setInnerPagination = _useState2[1]; // ============ Basic Pagination Config ============


  var mergedPagination = extendsObject(innerPagination, paginationObj, {
    total: paginationTotal > 0 ? paginationTotal : total
  });

  if (!paginationTotal) {
    // Reset `current` if data length changed. Only reset when paginationObj do not have total
    var maxPage = Math.ceil(total / mergedPagination.pageSize);

    if (maxPage < mergedPagination.current) {
      mergedPagination.current = 1;
    }
  }

  var refreshPagination = function refreshPagination() {
    var current = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    var pageSize = arguments.length > 1 ? arguments[1] : undefined;
    setInnerPagination(_extends(_extends({}, mergedPagination), {
      current: current,
      pageSize: pageSize || mergedPagination.pageSize
    }));
  };

  var onInternalChange = function onInternalChange(current, pageSize) {
    var paginationPageSize = mergedPagination === null || mergedPagination === void 0 ? void 0 : mergedPagination.pageSize;

    if (pageSize && pageSize !== paginationPageSize) {
      current = 1;
      if (pagination && pagination.onShowSizeChange) pagination.onShowSizeChange(current, pageSize);
    }

    if (pagination && pagination.onChange) pagination.onChange(current, pageSize);
    refreshPagination(current, pageSize);
    onChange(current, pageSize || paginationPageSize);
  };

  if (pagination === false) {
    return [{}, function () {}];
  }

  return [_extends(_extends({}, mergedPagination), {
    onChange: onInternalChange
  }), refreshPagination];
}