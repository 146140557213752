import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

interface IProps {
  header: string;
  description?: string;
  margin?: string;
}

export const SectionHeader = (props: IProps) => {
  const { header, description } = props;
  return (
    <Wrapper {...props}>
      <Header>{header}</Header>
      {description && (
        <Row>
          <Col xs={24} lg={12}>
            <Description>{description}</Description>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: ${(props: IProps) => props.margin};
`;

const Header = styled.div`
  font-size: 18px;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
`;
