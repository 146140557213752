export const MAP_SETTINGS = {
  ID_SCRIPT: "google-map-script",
  API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lng: -71.05828, lat: 42.35614 },
  DEFAULT_ZOOM: 10,
  MARKER_SIZE: [25, 41],
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  RED_ICON: {
    ICON_URL: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    SHADOW_ICON_URL:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  },
  BLUE_ICON: {
    ICON_URL: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    SHADOW_ICON_URL: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  },
  STYLES: {
    width: "100%",
    height: "500px",
  },
  CIRCLE_OPTIONS: {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  },
};
