import React, { Component } from "react";
import { Tabs } from "antd";
import { RouteComponentProps } from "react-router-dom";

import { BookingBoardComponent } from "./booking-board.component";
import { MapComponent } from "./map.component";

interface IProps extends RouteComponentProps {}

export class BoardComponent extends Component<IProps> {
  render() {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Calendar" key="1">
          <BookingBoardComponent {...this.props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Map" key="2">
          <MapComponent {...this.props} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}
