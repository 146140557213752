import React from "react";
import { Card, Divider } from "antd";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { OtherSection } from "./other-section.component";
import { CurrentVisionCondition } from "./current-vision-condition.component";
import { MarketingConsents } from "./marketing-consents.component";

export interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  other: IState["other"];
  marketingConsents: IState["marketingConsents"];
  currentVisionCondition: IState["currentVisionCondition"];
}

export class QuestionnaireStep4Component extends React.Component<IProps> {
  render() {
    const { updateQuestionnaireField, other, marketingConsents, currentVisionCondition } = this.props;
    return (
      <Card title="Step 4">
        <OtherSection updateQuestionnaireField={updateQuestionnaireField} other={other} />
        <CurrentVisionCondition
          updateQuestionnaireField={updateQuestionnaireField}
          currentVisionCondition={currentVisionCondition}
        />
        <Divider style={{ margin: "50px 0 40px 0" }} />
        <MarketingConsents marketingConsents={marketingConsents} updateQuestionnaireField={updateQuestionnaireField} />
      </Card>
    );
  }
}
