import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {}

export const PatientTracker = (props: IProps) => {
  const onMessageReceived = (event: any) => {
    const data = JSON.parse(event.data);
    if (data.action == "redirectToEditPatient") {
      props.history.push(
        `/patients/${data.payload.patientId}/edit?redirectTo=patient-tracker&trackerId=${data.payload.trackerId}`
      );
    } else if (data.action == "redirectToEditQuestionnaire") {
      props.history.push(
        `/patients/${data.payload.patientId}/edit?tab=questionnaire&redirectTo=patient-tracker&trackerId=${data.payload.trackerId}`
      );
    }
  };

  const params = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    window.addEventListener("message", onMessageReceived, false);
    window.history.replaceState("", "", "/patient-tracker");

    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  }, []);

  let iframeSrc = process.env.PATIENT_TRACKER_URL;

  const trackerId = params.get("trackerId");
  const date = params.get("date");

  if (trackerId) {
    iframeSrc += `?trackerId=${trackerId}`;
  }

  if (date) {
    iframeSrc += `?date=${date}`;
  }

  return (
    <>
      <iframe
        src={iframeSrc}
        style={{
          width: "100%",
          minHeight: "300vh",
          border: "none",
        }}
      />
    </>
  );
};
