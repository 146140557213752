import React from "react";
import styled from "styled-components";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons/lib";
import { colors } from "../../styles/colors";
import { validationPatterns } from "../../helpers/validation.helper";

const PasswordPolicyMessage = (props: { password?: string }) => {
  const passwordText = props.password || "";
  const successIcon = <CheckCircleTwoTone twoToneColor={colors.green} style={{ marginRight: 3 }} />;
  const failIcon = <CloseCircleTwoTone twoToneColor={colors.orange} style={{ marginRight: 3 }} />;

  const containsLowerCaseLetter = validationPatterns.containsLowerCaseLetter.test(passwordText);
  const containsUpperCaseLetter = validationPatterns.containsUpperCaseLetter.test(passwordText);
  const containsNumberLetter = validationPatterns.containsNumberLetter.test(passwordText);
  const containsSpecialLetter = validationPatterns.containsSpecialLetter.test(passwordText);
  const is8Characters = passwordText.length >= 8;
  return (
    <Container>
      <p>Password must contain:</p>
      <ul>
        <li>
          {is8Characters ? successIcon : failIcon} <span>at least 8 characters</span>
        </li>
        <li>
          {containsLowerCaseLetter ? successIcon : failIcon} <span>a minimum of 1 lower case letter [a-z]</span>
        </li>
        <li>
          {containsUpperCaseLetter ? successIcon : failIcon} <span>a minimum of 1 upper case letter [A-Z]</span>
        </li>
        <li>
          {containsNumberLetter ? successIcon : failIcon} <span>a minimum of 1 numeric character [0-9]</span>
        </li>
        <li>
          {containsSpecialLetter ? successIcon : failIcon} <span>a minimum of 1 special character</span>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: left;
  border: 1px solid #f3f3f3;
  padding: 10px;
  border-radius: 8px;
  p {
    display: block;
    font-weight: bold;
    margin-bottom: 15px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      padding: 0;
      span {
        display: block;
        margin-left: 4px;
        line-height: 2;
      }
    }
  }
`;

export default PasswordPolicyMessage;
