import React from "react";
import { AuthenticationGuard } from "../authentication-guard/authentication-guard.container";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {}

interface IState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

export class Logout extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    isAuthenticated: false,
  };

  public async componentDidMount(): Promise<void> {
    await AuthenticationGuard.removeAuthorizationToken();
    AuthenticationGuard.redirectToAuthenticationApp();
  }

  public render() {
    return null;
  }
}
