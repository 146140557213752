import React from "react";
import { Card } from "antd";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { EyeHealthHistory } from "./eye-health-history.component";

export interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  eyeHealthHistory: IState["eyeHealthHistory"];
}

export class QuestionnaireStep3Component extends React.Component<IProps> {
  render() {
    return (
      <Card title="Step 3">
        <EyeHealthHistory {...this.props} />
      </Card>
    );
  }
}