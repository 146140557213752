import React from "react";
import { List } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";

import { colors } from "../../../styles/colors";

interface IProps {
  visits: any[];
  onVisitClick: (visitId: string) => void;
}

export class ListOfVisitFromMap extends React.Component<IProps> {
  public render() {
    const { visits } = this.props;
    return (
      <InfiniteScroll initialLoad={false} pageStart={0} useWindow={false}>
        <List
          dataSource={visits}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={<b>{item.companyName}</b>}
                description={
                  <>
                    {item.address}
                    <br />
                    {item.distance.toFixed(2)} miles away
                    <br />
                    <VisitsTags>
                      {item.visits.map((visit, i) => {
                        return (
                          <VisitTag key={i} onClick={() => this.props.onVisitClick(visit.id)}>
                            {visit.odName && <div>dr. {visit.odName}</div>}
                            <div>{visit.date}</div>
                            <div>({visit.openTimeslotsCount})</div>
                          </VisitTag>
                        );
                      })}
                    </VisitsTags>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    );
  }
}

const VisitTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.yellow};
  color: ${colors.black};
  border-radius: 15px;
  margin: 5px;
  padding: 2px;
  border: 3px solid ${colors.yellow};
  cursor: pointer;
  &:nth-child(1) {
    margin-left: 0px;
  }
  &:hover {
    background-color: ${colors.white};
    border: 3px solid ${colors.yellow};
  }
`;

const VisitsTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
