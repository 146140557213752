import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";
import React from "react";
import {
  HomeOutlined,
  CalendarOutlined,
  SettingOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  FieldTimeOutlined,
  TableOutlined,
  MailOutlined,
  MessageOutlined,
  BarChartOutlined,
  FileDoneOutlined,
  ShopOutlined,
  CarOutlined,
  FileAddOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";

export const MenuComponent = withRouter((props) => {
  const pathname = "/" + props.location.pathname.split("/")[1];
  return (
    <Menu
      mode="inline"
      selectedKeys={[pathname]}
      style={{ height: "auto", border: "none", borderTop: "1px solid #eee", paddingTop: 4 }}>
      <Menu.Item key="/" icon={<HomeOutlined />}>
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="/booking-board" icon={<ScheduleOutlined />}>
        <Link to="/booking-board">Booking Board</Link>
      </Menu.Item>
      <Menu.Item key="/appointments" icon={<CalendarOutlined />}>
        <Link to="/appointments">Appointments</Link>
      </Menu.Item>
      <Menu.Item key="/visits" icon={<EnvironmentOutlined />}>
        <Link to="/visits">Visits</Link>
      </Menu.Item>
      <Menu.Item key="/patient-tracker" icon={<TableOutlined />}>
        <Link to="/patient-tracker">Patient Tracker</Link>
      </Menu.Item>
      <Menu.Item key="/patients" icon={<TeamOutlined />}>
        <Link to="/patients">Patients</Link>
      </Menu.Item>
      <Menu.Item key="/companies" icon={<ShopOutlined />}>
        <Link to="/companies">Companies</Link>
      </Menu.Item>
      <Menu.Item key="/waitlist" icon={<FieldTimeOutlined />}>
        <Link to="/waitlist/subscriptions">Waitlist</Link>
      </Menu.Item>
      <Menu.Item key="/nps" icon={<MessageOutlined />}>
        <Link to="/nps">NPS</Link>
      </Menu.Item>
      <Menu.Item key="/stats" icon={<BarChartOutlined />}>
        <Link to="/stats">Stats</Link>
      </Menu.Item>
      <Menu.Item key="/insurances" icon={<FileDoneOutlined />}>
        <Link to="/insurances">Insurances</Link>
      </Menu.Item>
      <Menu.Item key="/clinics" icon={<CarOutlined />}>
        <Link to="/clinics">Clinics</Link>
      </Menu.Item>
      <Menu.Item key="/communication-bus" icon={<MailOutlined />}>
        <Link to="/communication-bus">Communication</Link>
      </Menu.Item>
      <Menu.Item key="/bulk-upload" icon={<FileAddOutlined />}>
        <Link to="/bulk-upload/upload">Bulk Upload</Link>
      </Menu.Item>
      <Menu.Item key="/settings" icon={<SettingOutlined />}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>
    </Menu>
  );
});
