import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
  TreeSelect,
  Typography,
  Alert,
  Space,
  AutoComplete,
} from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import { removeEmptyKeys } from "../../../helpers/utils";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  currentPage: number;
  companies: any[];
  subscriptions: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  locations: any[];
  isLocationAlertShown: boolean;
}

export class SubscriptionsListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    currentPage: 1,
    companies: [],
    subscriptions: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
    locations: [],
    isLocationAlertShown: false,
  };

  public componentDidMount(): void {
    const params = this.getUrlQueryParams();
    if (params.locationId) {
      this.searchForm.current!.setFieldsValue({
        locationId: params.locationId,
      });
    }

    this.getSubscriptions();
    this.getCompanies();
  }

  public onCompanyChange = (value: string) => {
    let locations = [];

    if (value !== "all") {
      const company = this.state.companies.find((company) => company.id === value);
      if (company && company.locations) locations = company.locations;
    }

    this.setState({ locations, isLocationAlertShown: !locations.length && value !== "all" });
    this.searchForm.current!.setFieldsValue({
      locationId: undefined,
    });
  };

  public getUrlQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const locationId = params.get("locationId");

    return {
      locationId,
    };
  };

  public async getSubscriptions() {
    this.setState({ isLoading: true });
    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data: subscriptions } = await ApiClient.findWaitlistSubscriptions(
        removeEmptyKeys({
          offset: (this.state.currentPage - 1) * this.state.subscriptions.limit,
          limit: this.state.subscriptions.limit,
          sortingBy: "createdAt",
          sortingType: "DESC",
          firstName: searchForm.firstName,
          lastName: searchForm.lastName,
          email: searchForm.email,
          phoneNumber: searchForm.phoneNumber,
          statuses: searchForm.status ? [searchForm.status] : undefined,
          timePreference: searchForm.timePreference,
          locationIds: this.getLocationIds(searchForm.locationId),
        })
      );

      this.setState({ subscriptions, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch subscriptions data");
      this.setState({ isLoading: false });
    }
  }

  public async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({
      offset: 0,
      limit: "all",
    });
    this.setState({ companies: companies.data });
  }

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getSubscriptions();
    });
  };

  public onSearchFormFinish = () => {
    this.getSubscriptions();
  };

  public clearForm = () => {
    this.searchForm.current!.resetFields();
  };

  private getLocationIds = (locationId?: string) => {
    if (locationId && locationId !== "all") {
      return [locationId];
    }
    return this.state.locations?.length ? this.state.locations.map((location) => location.id) : undefined;
  };

  public render() {
    const { subscriptions, currentPage, isLoading, companies, isLocationAlertShown, locations } = this.state;

    const locationsCompaniesDict = companies.reduce((acc, company) => {
      company.locations.forEach((location) => {
        acc[location.id] = company.id;
      });
      return acc;
    }, {});

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ marginBottom: 24, textAlign: "right" }}>
            <Link to="/waitlist/subscriptions/create">
              <Button type="primary">Create subscription</Button>
            </Link>
          </Col>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
              <Form.Item name="firstName" label="First name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label="Last name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="phoneNumber" label="Phone number" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="status" label="Status" style={{ marginBottom: 16 }}>
                <Select style={{ width: 150 }} placeholder="Select status">
                  <Select.Option value="">all</Select.Option>
                  <Select.Option value="active">active</Select.Option>
                  <Select.Option value="removed">removed</Select.Option>
                  <Select.Option value="booked">booked</Select.Option>
                  <Select.Option value="early_access">early access</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="timePreference" label="Time preference" style={{ marginBottom: 16 }}>
                <Select style={{ width: 150 }} placeholder="Select time preference">
                  <Select.Option value="">all</Select.Option>
                  <Select.Option value="morning">morning</Select.Option>
                  <Select.Option value="afternoon">afternoon</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="companyName" label="Company" style={{ marginBottom: 16 }}>
                <Select
                  showSearch
                  onChange={this.onCompanyChange}
                  style={{ width: 300 }}
                  placeholder="Please select company"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  <Select.Option value="all"> all </Select.Option>
                  {companies
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((x) => x)
                    .filter((value, index, array) => {
                      return index === 0 || value.name !== array[index - 1].name;
                    })
                    .map((company) => (
                      <Select.Option key={company.id} value={company.id}>
                        {company.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              {locations?.length > 0 ? (
                <Form.Item name="locationId" label="Location" style={{ marginBottom: 16 }}>
                  <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder="Please select location"
                    optionFilterProp="children"
                    initialValue={"all"}
                    filterOption={(input: any, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    <Select.Option value="all">all</Select.Option>
                    {locations
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((location) => (
                        <Select.Option key={location.id} value={location.id}>
                          {location.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : null}

              <Form.Item style={{ marginBottom: 16 }}>
                <Space>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                  <Button onClick={this.clearForm}>Clear</Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        {isLocationAlertShown && <Alert message="The company does not have locations assigned." type="warning" />}

        <Typography.Text style={{ marginTop: 10, marginBottom: 10, display: "block" }}>
          Total: <strong>{subscriptions.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={subscriptions.data}
          columns={[
            {
              title: "Name",
              key: "name",
              render: ({ firstName, lastName }) => {
                return `${firstName} ${lastName}`;
              },
            },
            {
              title: "Phone number",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Status",
              key: "status",
              render: ({ status }) => {
                switch (status) {
                  case "active":
                    return <Tag color="green">{status}</Tag>;
                  case "removed":
                    return <Tag color="red">{status}</Tag>;
                  case "booked":
                    return <Tag color="green">{status}</Tag>;
                  case "early_access":
                    return <Tag color="yellow">{status}</Tag>;
                  default:
                    return "-";
                }
              },
            },
            {
              title: "Location",
              key: "location",
              render: ({ locationId }) => {
                if (!companies.length) {
                  return "-";
                }

                const company = companies.find((x) => x.id === locationsCompaniesDict[locationId]);
                if (!company) {
                  return "-";
                }

                const location = company.locations.find((x) => x.id === locationId);
                return (
                  <>
                    {company.name} <br />
                    <small>({location.name})</small>
                  </>
                );
              },
            },
            {
              title: "Time preference",
              key: "timePreference",
              render: ({ timePreference }) => (
                <>
                  {timePreference.morning ? <Tag>morning</Tag> : null}
                  {timePreference.afternoon ? <Tag>afternoon</Tag> : null}
                </>
              ),
            },
            {
              title: "Created At",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (date) => dateFormatter(new Date(date)),
            },
            {
              title: "Action",
              key: "operation",
              fixed: "right",
              width: 100,
              render: ({ id }) => {
                return (
                  <Link to={`/waitlist/subscriptions/${id}/edit`}>
                    <Button size={"small"}>Show</Button>
                  </Link>
                );
              },
            },
          ]}
          pagination={{
            pageSize: subscriptions.limit,
            current: currentPage,
            total: subscriptions.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}
