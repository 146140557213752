import React from "react";
import { Row, Col, Checkbox } from "antd";
import styled from "styled-components";

interface IProps {
  isRowOdd: boolean;
  question: string;
  options: string[];
  defaultValue: string[];
  onChange: any;
}

export const QuestionCheckbox = (props: IProps) => {
  const { isRowOdd, question, options, defaultValue, onChange } = props;
  return (
    <StyledRow gutter={16} isRowOdd={isRowOdd}>
      <QuestionCol span={12}>{question}</QuestionCol>
      <CenteredCol span={2}>
        <Checkbox value={options[0]} checked={defaultValue.includes(options[0])} onClick={(e) => onChange(e)} />
      </CenteredCol>
      <CenteredCol span={2}>
        <Checkbox value={options[1]} checked={defaultValue.includes(options[1])} onClick={(e) => onChange(e)} />
      </CenteredCol>
      <CenteredCol span={2}>
        <Checkbox value={options[2]} checked={defaultValue.includes(options[2])} onClick={(e) => onChange(e)} />
      </CenteredCol>
      <CenteredCol span={2}>
        <Checkbox value={options[3]} checked={defaultValue.includes(options[3])} onClick={(e) => onChange(e)} />
      </CenteredCol>
      <CenteredCol span={2}>
        <Checkbox value={options[4]} checked={defaultValue.includes(options[4])} onClick={(e) => onChange(e)} />
      </CenteredCol>
      <CenteredCol span={2}>
        <Checkbox value={options[5]} checked={defaultValue.includes(options[5])} onClick={(e) => onChange(e)} />
      </CenteredCol>
    </StyledRow>
  );
};

const CenteredCol = styled(Col)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionCol = styled(Col)`
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const StyledRow = styled(Row)`
  margin: 0;
  padding: 10px 0;

  background-color: ${(props: { isRowOdd: boolean }) => (props.isRowOdd ? "#fff" : "#e6f6ff")};
`;
