interface Position {
  longitude: number;
  latitude: number;
}
// The simplest method of calculating distance relies on some advanced-looking math.
// Known as the Haversine formula, it uses spherical trigonometry to determine the great circle distance between two points.
// Function return result in miles
export const calcHaversineFormula = (mk1: Position, mk2: Position) => {
  // 3958.8 - Radius of the Earth in miles
  const rLat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
  const rLat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
  const diffLat = rLat2 - rLat1; // Radian difference (latitudes)
  const diffLon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

  return (
    2 *
    3958.8 *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(rLat1) * Math.cos(rLat2) * Math.sin(diffLon / 2) * Math.sin(diffLon / 2)
      )
    )
  );
};
export const milesToMetersFormula = (miles: number) => miles * 1609.34;
