import React from "react";
import moment from "moment";

import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, Form, Input, message, Row, Table, Typography, Tag, Checkbox, Dropdown, Menu, Icon } from "antd";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";

import { ApiClient } from "../../../../api-client/api.client";
import { IUserData } from "../../../../interfaces/userData.interface";

interface IProps extends RouteComponentProps {
  userData: IUserData;
}

interface IState {
  isLoading: boolean;
  currentPage: number;
  accounts: {
    total: number;
    offset: number;
    limit: number;
    data: any[];
  };
}

export class PatientAccountsSettingsComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    currentPage: 1,
    accounts: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
  };
  private searchForm = React.createRef<FormInstance>();

  public componentDidMount(): void {
    this.getPatientAccounts();
  }

  public onSearchFormFinish = () => {
    this.getPatientAccounts();
  };

  public async getPatientAccounts() {
    this.setState({ isLoading: true });

    try {
      const { currentPage, accounts } = this.state;
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data } = await ApiClient.findAccounts({
        offset: (currentPage - 1) * accounts.limit,
        limit: accounts.limit,
        ...(searchForm.firstName?.length && { firstName: searchForm.firstName }),
        ...(searchForm.lastName?.length && { lastName: searchForm.lastName }),
        ...(searchForm.email?.length && { email: searchForm.email }),
        roles: ["patient"],
        ...(searchForm.isBlocked ? { isBlocked: searchForm.isBlocked } : {}),
      });

      this.setState({ accounts: data, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch accounts data");
      this.setState({ isLoading: false });
    }
  }

  public resetFailedLoginAttempts = async (accountId: string) => {
    try {
      await ApiClient.resetFailedLoginAttempts(accountId);
      this.getPatientAccounts();
      message.success("Failed login attempts successfully reseted");
    } catch (e) {
      message.error("Cannot reset failed login attempts");
    }
  };

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getPatientAccounts();
    });
  };

  public render() {
    const { accounts, currentPage, isLoading } = this.state;
    const { userData } = this.props;

    const hasAccessToResetPatientPassword =
      Array.isArray(userData.permissions) && userData.permissions.includes("RESET_PATIENT_PASSWORD");
    const hasAccessToChangePatientEmail =
      Array.isArray(userData.permissions) && userData.permissions.includes("CHANGE_PATIENT_EMAIL");
    const hasAccessToActivatePatientAccount =
      Array.isArray(userData.permissions) && userData.permissions.includes("ACTIVATE_PATIENT_ACCOUNT");

    const canEditAccount =
      hasAccessToResetPatientPassword || hasAccessToChangePatientEmail || hasAccessToActivatePatientAccount;

    const columns = [
      {
        title: "First name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Is active?",
        dataIndex: "isActive",
        key: "isActive",
        render: (isActive: boolean) => {
          return isActive ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>;
        },
      },
      {
        title: "Is closed?",
        dataIndex: "isClosed",
        key: "isClosed",
        render: (isClosed: boolean) => {
          return isClosed ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>;
        },
      },
      {
        title: "Is blocked?",
        dataIndex: "isBlocked",
        key: "isBlocked",
        render: (isBlocked: boolean) => {
          return isBlocked ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>;
        },
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => {
          return moment(createdAt).format("YYYY-MM-DD");
        },
      },
      {
        title: "Actions",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id, isBlocked }) => {
          const menu = (
            <Menu>
              {isBlocked ? (
                <Menu.Item>
                  <div onClick={() => this.resetFailedLoginAttempts(id)}>Unblock account</div>
                </Menu.Item>
              ) : null}
              {canEditAccount ? (
                <Menu.Item>
                  <Link to={`/settings/patient-accounts/${id}/edit`}>Edit account</Link>
                </Menu.Item>
              ) : null}
            </Menu>
          );
          return (
            <>
              {canEditAccount || isBlocked ? (
                <Dropdown overlay={menu}>
                  <Button>Actions</Button>
                </Dropdown>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "10px 0 24px" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
              <Form.Item name="firstName" label="First name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label="Last name" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="isBlocked" valuePropName="checked">
                <Checkbox>Show blocked accounts</Checkbox>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{accounts.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading}
          size="small"
          dataSource={accounts.data}
          columns={columns}
          pagination={{
            pageSize: accounts.limit,
            current: currentPage,
            total: accounts.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}
