import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { AutoComplete, Button, Card, Col, Form, Input, message, PageHeader, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import { geocode, GeocodeAddress, reverseGeocode } from "../../../helpers/geocode-service.helper";
import GoogleMap from "../../../ui/map/GoogleMap.container";

interface IProps extends RouteComponentProps {}

const DEFAULT_COORDINATES = {
  LNG: -71.05828,
  LAT: 42.35614,
};

interface IState {
  isLoading: boolean;
  coordinates: {
    lng: number;
    lat: number;
  };
  addressResults: GeocodeAddress[];
}

export class CreateLocationComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    coordinates: {
      lng: DEFAULT_COORDINATES.LNG,
      lat: DEFAULT_COORDINATES.LAT,
    },
    addressResults: [],
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    const { coordinates } = this.state;
    try {
      await ApiClient.createLocation(this.props.match.params!.id, {
        name: values.name,
        address: values.address,
        timezone: values.timezone,
        ...(DEFAULT_COORDINATES.LAT != coordinates.lat && DEFAULT_COORDINATES.LNG != coordinates.lng
          ? {
              geoLocation: {
                x: coordinates.lng,
                y: coordinates.lat,
              },
            }
          : {}),
      });

      message.success("Location successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push(`/companies/${this.props.match.params!.id}/edit`);
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onSearchAddress = async (address: string) => {
    this.setState({ addressResults: [] });
    if (address.length > 3) {
      const addresses = await geocode(address);
      this.setState({ addressResults: addresses });
    }
  };

  private onBlurAddress = async (e: any) => {
    const address = e.target.value;
    if (address) {
      const addresses = await geocode(address);

      if (addresses && addresses.length) {
        const { coordinates } = addresses[0];
        this.setState({ coordinates });
        this.form.current!.setFieldsValue({
          coordinates,
        });
      }
    }
    this.setState({ addressResults: [] });
  };

  private onDragEndDraggableMarker = async (e: any) => {
    const coordinates = {
      lng: e.latLng.lng(),
      lat: e.latLng.lat(),
    };

    this.setState({ coordinates });

    this.form.current!.setFieldsValue({
      coordinates,
    });

    const addresses = await reverseGeocode(coordinates);
    if (addresses && addresses.length)
      this.form.current!.setFieldsValue({
        address: addresses[0].formattedAddress,
      });
  };

  public render() {
    const { isLoading, coordinates, addressResults } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push(`/companies/${this.props.match.params!.id}/edit`)}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create location"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Location">
              <Form layout="vertical" ref={this.form} name="create-location" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  style={{ marginBottom: 16 }}
                  rules={[{ required: true, message: "Please enter address!" }]}>
                  <AutoComplete onSearch={this.onSearchAddress} placeholder="input here" onBlur={this.onBlurAddress}>
                    {addressResults.map((result, index) => (
                      <AutoComplete.Option key={`auto-complete-optin-${index}`} value={result.formattedAddress}>
                        {result.formattedAddress}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>

                <Form.Item name="coordinates" label="Coordinates" rules={[{ required: true }]}>
                  <GoogleMap
                    styles={{
                      height: "400px",
                    }}
                    draggableMarker={{
                      coordinates,
                      onDragEnd: this.onDragEndDraggableMarker,
                    }}
                  />
                  <span style={{ float: "right" }}>
                    Lng: {coordinates.lng.toFixed(5)} Lat: {coordinates.lat.toFixed(5)}
                  </span>
                </Form.Item>
                <Form.Item name="timezone" label="Timezone" rules={[{ required: true }]}>
                  <Select showSearch placeholder="Select timezone">
                    <Select.Option value="America/New_York">America/New_York (Eastern)</Select.Option>
                    <Select.Option value="America/Chicago">America/Chicago (Central)</Select.Option>
                    <Select.Option value="America/Los_Angeles">America/Los_Angeles (Pacific)</Select.Option>
                    <Select.Option value="America/Denver">America/Denver (Mountain)</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
