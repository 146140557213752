import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Tabs, Typography } from "antd";
import { MessageQueueBrowserComponent } from "./components/message-queue-browser.component";
import { TemplatesListComponent } from "./components/templates-list.component";
import { EditTemplateComponent } from "./components/edit-template.component";

const DEFAULT_TAB = 'messages'

interface IProps extends RouteComponentProps {}

interface IState {
  tab: string;
}

export class CommunicationBus extends React.Component<IProps, IState> {
  state: IState = {
    tab: DEFAULT_TAB,
  };

  render(): React.ReactNode {
    const { tab } = this.state;
    return (
      <>
        <Typography.Title>Communication bus</Typography.Title>
        <Tabs
          defaultActiveKey={tab}
          onChange={(key) => {
            this.setState({ tab: key });
          }}>
          <Tabs.TabPane tab="Messages" key="messages">
            <MessageQueueBrowserComponent />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Templates" key="templates">
            <TemplatesListComponent />
          </Tabs.TabPane>
        </Tabs>

        <Route path="/communication-bus/templates/:name/edit" exact component={EditTemplateComponent} />
      </>
    );
  }
}
