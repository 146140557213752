import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { Alert, AutoComplete, Button, Card, Col, Form, Input, message, PageHeader, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import { geocode, GeocodeAddress, reverseGeocode } from "../../../helpers/geocode-service.helper";
import GoogleMap from "../../../ui/map/GoogleMap.container";

interface IProps extends RouteComponentProps {}

const DEFAULT_COORDINATES = {
  LNG: -71.05828,
  LAT: 42.35614,
};

interface IState {
  isLoading: boolean;
  location: any;
  coordinates: {
    lng: number;
    lat: number;
  };
  addressResults: GeocodeAddress[];
}

export class EditLocationComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    location: undefined,
    coordinates: {
      lng: DEFAULT_COORDINATES.LNG,
      lat: DEFAULT_COORDINATES.LAT,
    },
    addressResults: [],
  };

  public async componentDidMount(): Promise<void> {
    await this.getLocation();
  }

  public async getLocation() {
    try {
      this.setState({ isLoading: true });
      const { id, locationId } = this.props.match.params;
      const { data: company } = await ApiClient.getCompany(id);

      const location = company.locations.find((x) => x.id === locationId);
      if (location?.geoLocation) {
        this.setState({ coordinates: { lng: location.geoLocation.x, lat: location.geoLocation.y } });
      }
      this.setState({ location, isLoading: false });
    } catch (e) {
      console.log(e);
      message.error("Location cannot be fetched!");
    }
  }

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { coordinates } = this.state;
      const { id, locationId } = this.props.match.params;

      await ApiClient.updateLocation(id, locationId, {
        name: values.name,
        address: values.address,
        timezone: values.timezone,
        notes: values.notes,
        ...(DEFAULT_COORDINATES.LAT != coordinates.lat && DEFAULT_COORDINATES.LNG != coordinates.lng
          ? {
              geoLocation: {
                x: coordinates.lng,
                y: coordinates.lat,
              },
            }
          : {}),
      });

      message.success("Location successfully saved!");
      this.getLocation();
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onSearchAddress = async (address: string) => {
    this.setState({ addressResults: [] });
    if (address.length > 3) {
      const addresses = await geocode(address);
      this.setState({ addressResults: addresses });
    }
  };

  private onBlurAddress = async (e: any) => {
    const address = e.target.value;
    if (address) {
      const addresses = await geocode(address);

      if (addresses && addresses.length) {
        const { coordinates } = addresses[0];
        this.setState({ coordinates });
        this.form.current!.setFieldsValue({
          coordinates,
        });
      }
    }
    this.setState({ addressResults: [] });
  };

  private onDragEndDraggableMarker = async (e: any) => {
    const coordinates = {
      lng: e.latLng.lng(),
      lat: e.latLng.lat(),
    };

    this.setState({ coordinates });

    this.form.current!.setFieldsValue({
      coordinates,
    });

    const addresses = await reverseGeocode(coordinates);
    if (addresses && addresses.length)
      this.form.current!.setFieldsValue({
        address: addresses[0].formattedAddress,
      });
  };

  public render() {
    const { isLoading, location, coordinates, addressResults } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push(`/companies/${this.props.match.params?.id}/edit`)}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit location"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card title="Location" loading={isLoading}>
              <Form layout="vertical" ref={this.form} name="control-hooks" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={location?.name}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  style={{ marginBottom: 16 }}
                  rules={[{ required: true }]}
                  initialValue={location?.address}>
                  <AutoComplete onSearch={this.onSearchAddress} placeholder="input here" onBlur={this.onBlurAddress}>
                    {addressResults.map((result, index) => (
                      <AutoComplete.Option key={`auto-complete-optin-${index}`} value={result.formattedAddress}>
                        {result.formattedAddress}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
                <Form.Item
                  name="coordinates"
                  label="Coordinates"
                  rules={[{ required: true }]}
                  initialValue={
                    location && location.geoLocation && location.geoLocation.x !== 0 && location.geoLocation.y !== 0
                      ? {
                          lng: location?.geoLocation.x,
                          lat: location?.geoLocation.y,
                        }
                      : undefined
                  }>
                  <GoogleMap
                    styles={{
                      height: "400px",
                    }}
                    draggableMarker={{
                      coordinates,
                      onDragEnd: this.onDragEndDraggableMarker,
                    }}
                  />
                  <>
                    {(!location?.geoLocation ||
                      (location?.geoLocation && location?.geoLocation.x == 0 && location?.geoLocation.y == 0)) &&
                    DEFAULT_COORDINATES.LAT == coordinates.lat &&
                    DEFAULT_COORDINATES.LNG == coordinates.lng ? (
                      <Alert
                        message="No coordinates"
                        description="You have to choose coordinates for this location."
                        type="error"
                      />
                    ) : (
                      <span style={{ float: "right" }}>
                        Lng: {coordinates.lng.toFixed(5)} Lat: {coordinates.lat.toFixed(5)}
                      </span>
                    )}
                  </>
                </Form.Item>
                <Form.Item
                  name="timezone"
                  label="Timezone"
                  rules={[{ required: true }]}
                  initialValue={location?.timezone}
                  extra="Timezone will be updated in every associated visit">
                  <Select showSearch placeholder="Select timezone">
                    <Select.Option value="America/New_York">America/New_York (Eastern)</Select.Option>
                    <Select.Option value="America/Chicago">America/Chicago (Central)</Select.Option>
                    <Select.Option value="America/Los_Angeles">America/Los_Angeles (Pacific)</Select.Option>
                    <Select.Option value="America/Denver">America/Denver (Mountain)</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="notes" label="Notes" initialValue={location?.notes}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
