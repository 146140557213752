// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import * as React from 'react';
import MonitorOutlinedSvg from "@ant-design/icons-svg/es/asn/MonitorOutlined";
import AntdIcon from '../components/AntdIcon';

var MonitorOutlined = function MonitorOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: MonitorOutlinedSvg
  }));
};

MonitorOutlined.displayName = 'MonitorOutlined';
export default React.forwardRef(MonitorOutlined);