import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, Form, Input, message, notification, Row, Select, Space, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { IDocumentsInfo } from "../../../interfaces/appointment.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: {
    [key: string]: boolean;
    data: boolean;
  };
  currentPage: number;
  documentsInfo: {
    total: number;
    offset: number;
    limit: number;
    data: IDocumentsInfo[];
  };
}

export class AcuitasDocsChecker extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: {
      data: false,
    },
    currentPage: 1,
    documentsInfo: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
  };

  public async componentDidMount() {
    await this.getDocumentsInfo({ status: "not_found" });
  }

  public getDocumentsInfo = async (payload?: {
    appointmentId?: string;
    patientId?: string;
    status?: "found" | "not_found";
  }) => {
    try {
      this.setState({ isLoading: { ...this.state.isLoading, data: true } });
      const { currentPage, documentsInfo } = this.state;
      const { data: info } = await ApiClient.findDocumentsInfo({
        offset: (currentPage - 1) * documentsInfo.limit,
        limit: documentsInfo.limit,
        ...(payload ? payload : {}),
      });

      this.setState({ documentsInfo: info, isLoading: { ...this.state.isLoading, data: false } });
    } catch (e) {
      message.error(e.response.data.message);
      this.setState({ isLoading: { ...this.state.isLoading, data: false } });
    }
  };

  public onPaginationChange = async (page: number) => {
    this.setState({ currentPage: page }, async () => {
      const values = this.searchForm.current?.getFieldsValue()!;
      const payload: any = {};

      this.setState({ isLoading: { ...this.state.isLoading, data: true } });

      if (values.status) {
        payload.status = values.status;
      }

      if (values.appointmentId) {
        payload.appointmentId = values.appointmentId;
      }

      if (values.patientId) {
        payload.patientId = values.patientId;
      }

      await this.getDocumentsInfo(payload);
    });
  };

  public onSearchFormFinish = async (values: any) => {
    const payload: any = {};

    this.setState({ isLoading: { ...this.state.isLoading, data: true } });

    if (values.status) {
      payload.status = values.status;
    }

    if (values.appointmentId) {
      payload.appointmentId = values.appointmentId;
    }

    if (values.patientId) {
      payload.patientId = values.patientId;
    }

    await this.getDocumentsInfo(payload);
  };

  public recheckDocumentsInfo = async (id: string) => {
    try {
      this.setState({ isLoading: { ...this.state.isLoading, [id]: true } });
      await ApiClient.recheckAcuitasDocuments(id);
      this.setState({ isLoading: { ...this.state.isLoading, [id]: false } });

      const formValues = this.searchForm.current?.getFieldsValue()!;
      await this.getDocumentsInfo({
        status: formValues.status,
        appointmentId: formValues.appointmentId,
        patientId: formValues.patientId,
      });
    } catch (e) {
      this.setState({ isLoading: { ...this.state.isLoading, [id]: false } });
      notification.error({ message: e.response.data.message });
    }
  };

  public onFormReset = () => {
    this.searchForm.current!.resetFields();
  };

  public render() {
    const { documentsInfo, currentPage, isLoading } = this.state;

    const columns = [
      {
        title: "Status",
        key: "status",
        align: "center",
        render: (_text: string, info: IDocumentsInfo) => (
          <Tag color={info.status === "found" ? "green" : "red"}>{info.status}</Tag>
        ),
      },
      {
        title: "Invoices",
        key: "invoices",
        align: "center",
        render: (_text: string, info: IDocumentsInfo) => <Tag>{info.invoices}</Tag>,
      },
      {
        title: "Glasses RX",
        key: "glassesRx",
        align: "center",
        render: (_text: string, info: IDocumentsInfo) => <Tag>{info.glassesRx}</Tag>,
      },
      {
        title: "CL RX",
        key: "clRx",
        align: "center",
        render: (_text: string, info: IDocumentsInfo) => <Tag>{info.clRx}</Tag>,
      },
      {
        title: "Message",
        key: "message",
        render: (_text: string, info: IDocumentsInfo) => info.message,
      },
      {
        title: "Appointment",
        key: "appointmentId",
        render: (_text: string, info: IDocumentsInfo) => (
          <a href={`/appointments/${info.appointmentId}/edit`} target="_blank">
            {info.appointment.date}
          </a>
        ),
      },
      {
        title: "Patient",
        key: "patientId",
        render: (_text: string, info: IDocumentsInfo) => (
          <a href={`/patients/${info.patientId}/edit`} target="_blank">
            {info.patient.lastName}, {info.patient.firstName}
          </a>
        ),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        align: "center",
        render: (_text: string, info: IDocumentsInfo) => {
          return (
            <Space>
              <Button
                type="primary"
                size="small"
                loading={isLoading[info.id]}
                onClick={() => this.recheckDocumentsInfo(info.id)}>
                Recheck
              </Button>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form
              layout="inline"
              ref={this.searchForm}
              name="search-form-docs-checker"
              onFinish={this.onSearchFormFinish}>
              <Form.Item name="appointmentId" label="Appointment ID" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="patientId" label="Patient ID" style={{ marginBottom: 16 }}>
                <Input />
              </Form.Item>
              <Form.Item name="status" label="Status" initialValue="not_found" style={{ marginBottom: 16 }}>
                <Select placeholder="Select status" style={{ width: "120px" }}>
                  <Select.Option value="found">found</Select.Option>
                  <Select.Option value="not_found">not found</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item style={{ marginBottom: 16 }}>
                <div>
                  <Button type="primary" loading={isLoading.data} htmlType="submit" icon={<SearchOutlined />}>
                    Search
                  </Button>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{documentsInfo.total}</strong>
        </Typography.Text>
        <Table
          bordered
          loading={isLoading.data}
          size="small"
          dataSource={documentsInfo.data}
          columns={columns}
          pagination={{
            pageSize: documentsInfo.limit,
            current: currentPage,
            total: documentsInfo.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}
