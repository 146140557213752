import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

import { SectionHeader } from "../section-header.component";
import { currentConditionQuestions } from "../questions";
import { QuestionRadio } from "../inputs/question-radio.component";
import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";

export interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  currentCondition: IState["currentCondition"];
}

export const CurrentCondition = (props: IProps) => {
  return (
    <>
      <SectionHeader margin="50px 0px 20px 0" header="Current Condition" />
      <Row gutter={16}>
        <CenteredCol span={4} offset={12}>
          <Option>Yes, currently being treated</Option>
        </CenteredCol>
        <CenteredCol span={4}>
          <Option>Yes, but not being treated</Option>
        </CenteredCol>
        <CenteredCol span={4}>
          <Option>No difficulties</Option>
        </CenteredCol>
      </Row>
      {currentConditionQuestions.map((q) => (
        <QuestionRadio
          key={q.question}
          question={q.question}
          options={q.options}
          defaultValue={props.currentCondition[q.key]}
          onChange={(e: any) => {
            props.updateQuestionnaireField({
              section: "currentCondition",
              key: q.key,
              value: e.target.value,
            });
          }}
        />
      ))}
    </>
  );
};

const Option = styled.div`
  font-size: 13px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const CenteredCol = styled(Col)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
