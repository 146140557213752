// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import * as React from 'react';
import NodeExpandOutlinedSvg from "@ant-design/icons-svg/es/asn/NodeExpandOutlined";
import AntdIcon from '../components/AntdIcon';

var NodeExpandOutlined = function NodeExpandOutlined(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: NodeExpandOutlinedSvg
  }));
};

NodeExpandOutlined.displayName = 'NodeExpandOutlined';
export default React.forwardRef(NodeExpandOutlined);