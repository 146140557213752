// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY
import * as React from 'react';
import AccountBookFilledSvg from "@ant-design/icons-svg/es/asn/AccountBookFilled";
import AntdIcon from '../components/AntdIcon';

var AccountBookFilled = function AccountBookFilled(props, ref) {
  return React.createElement(AntdIcon, Object.assign({}, props, {
    ref: ref,
    icon: AccountBookFilledSvg
  }));
};

AccountBookFilled.displayName = 'AccountBookFilled';
export default React.forwardRef(AccountBookFilled);