import React from "react";
import { Row, Col, Select } from "antd";
import styled from "styled-components";

interface IProps {
  question: string;
  options: {
    label: string;
    value: string;
  }[];
  defaultValue: string;
  onChange: any;
  labelColOptions?: { [key: string]: { span: number } };
}

export const QuestionSelectOption = (props: IProps) => {
  const { question, options, defaultValue, onChange } = props;

  const labelColOptions = props.labelColOptions ? props.labelColOptions : { xs: { span: 24 }, md: { span: 16 } };

  return (
    <Wrapper>
      <Row gutter={16} align="middle" style={{ marginTop: 20 }}>
        <Col {...labelColOptions}>{question}</Col>
        <StyledOptionsCol xs={{ span: 24 }} md={{ span: 8 }}>
          <Select defaultValue={defaultValue} value={defaultValue} style={{ width: "100%" }} onChange={onChange}>
            <Select.Option value="" disabled>
              Select answer
            </Select.Option>
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </StyledOptionsCol>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-select-selection--single {
    height: 40px;
  }

  .ant-select-selection__rendered {
    line-height: 40px;
    font-size: 13px;
  }
`;

const StyledOptionsCol = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;
