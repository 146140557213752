import React from "react";
import { FormInstance } from "antd/es/form";
import { Card, Form, Checkbox, Button, message, notification } from "antd";
import { ApiClient } from "../../../../api-client/api.client";

const CheckboxGroup = Checkbox.Group;

interface IProps {
  emails: string[];
}

interface IState {
  isLoading: boolean;
}

export class ResetPasswordComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();
  state: IState = {
    isLoading: false,
  };

  private async onFinish(values: any) {
    this.setState({ isLoading: true });
    if (!Array.isArray(values.emails)) {
      message.error("Unknown Error");
      return;
    }

    await this.form.current!.validateFields();

    for (const email of values.emails) {
      try {
        await ApiClient.sendInformationAboutWhereToChangePassword(email);
        notification.success({
          message: "Success",
          description: `Successfully sent an email with reset password link to ${email}`,
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: `There was a problem sending messages to ${email}`,
        });
      }
    }

    this.setState({ isLoading: false });
  }

  render(): React.ReactNode {
    const { isLoading } = this.state;
    const { emails } = this.props;

    return (
      <Card title="Reset patient password">
        <Form layout="vertical" ref={this.form} name="reset-patient-password-form" onFinish={(e) => this.onFinish(e)}>
          <Form.Item name="emails" label="Emails" rules={[{ required: true }]}>
            <CheckboxGroup options={emails} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Send reset password email
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
