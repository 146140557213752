import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { Button, Table, Tag, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
interface IProps extends RouteComponentProps {}

interface IState {
  templates: {
    total: number;
    offset: number;
    limit: number;
    data: any[];
  };
}

export class TemplatesListComponent extends React.Component<IProps, IState> {
  public state: IState = {
    templates: {
      total: 0,
      offset: 0,
      limit: 25,
      data: [],
    },
  };

  public componentDidMount(): void {
    this.getTemplates();
  }

  public async getTemplates() {
    const { data: templates } = await ApiClient.getTemplates({
      limit: "all",
      offset: 0,
    });
    this.setState({ templates });
  }

  public render() {
    const { templates } = this.state;

    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Template Slug",
        key: "template-slug",
        render: (row: any) => {
          return row.type === 'email'  ? <span>{row.content}</span> : null;
        },

      },
      {
        title: "Status",
        key: "isActive",
        render: (row: any) => {
          return !row.isActive ? <Tag color="red">inactive</Tag> : <Tag color="green">active</Tag>;
        },
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ name }) => {
          return (
            <Link to={`/communication-bus/templates/${name}/edit`}>
              <Button size="small">Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: <strong>{templates.total}</strong>
        </Typography.Text>
        <Table
          bordered
          size="small"
          dataSource={templates.data.map((t) => ({ ...t, key: t.name }))}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}
