import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { Typography } from "antd";

import { BoardComponent } from "./components/board.component";

interface IProps extends RouteComponentProps {}

export class BookingBoard extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Booking Board</Typography.Title>
        <Route path="/booking-board" exact component={BoardComponent} />
      </>
    );
  }
}
