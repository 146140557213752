export const returnExamTypeName = (type: "exam" | "cl_exam" | "retail_only" | "f/u") => {
  switch (type) {
    case "exam":
      return "Exam";
    case "cl_exam":
      return "CL Exam";
    case "retail_only":
      return "Retail Only";
    case "f/u":
      return "F/U";
    default:
      return "";
  }
};

export const sortInsurances = (a: any, b: any) => {
  if (a?.name?.toLocaleLowerCase() > b?.name?.toLocaleLowerCase()) {
    return 1;
  }

  if (a?.name?.toLocaleLowerCase() < b?.name?.toLocaleLowerCase()) {
    return -1;
  }

  return 0;
};
