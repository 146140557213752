type ObjectWithoutEmptyKeys<T> = {
  [K in keyof T]: T[K] extends undefined | "" ? never : T[K];
};

export const removeEmptyKeys = <T>(input: T): ObjectWithoutEmptyKeys<T> => {
  const result: Partial<T> = {};

  for (const key in input) {
    if (input[key] !== undefined && input[key] !== "") {
      result[key] = input[key];
    }
  }

  return result as ObjectWithoutEmptyKeys<T>;
};
