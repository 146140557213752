// STEP1
// ----- COVID SCREENING
export const covidScreeningQuestions = [
  {
    key: "covidPositive",
    question:
      "Have you tested positive or been in contact with someone who has tested positive for COVID-19 in the past 2 weeks?",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
  },
];

// ----- CURRENT CONDITION
export const currentConditionQuestions = [
  {
    key: "cardiovascular",
    question: "Cardiovascular (chest pain, heart palpitations?",
    options: ["Yes, currently being treated", "Yes, but not being treated", "No difficulties"],
  },
  {
    key: "endocrine",
    question: "Endocrine (heat/cold intolerance, frequent urination, thirst, appetite changes)?",
    options: ["Yes, currently being treated", "Yes, but not being treated", "No difficulties"],
  },
  {
    key: "neurological",
    question: "Neurological (dizziness, fainting, seizures, weakness)?",
    options: ["Yes, currently being treated", "Yes, but not being treated", "No difficulties"],
  },
  {
    key: "psychiatric",
    question: "Psychiatric (nervousness, depression, memory loss, stress)?",
    options: ["Yes, currently being treated", "Yes, but not being treated", "No difficulties"],
  },
  {
    key: "respiratory",
    question: "Respiratory (difficulty breathing)?",
    options: ["Yes, currently being treated", "Yes, but not being treated", "No difficulties"],
  },
];

// ---------------------------------------------------------------------------------------------------------

// STEP2
// ----- MEDICAL HISTORY
export const medicalHistoryQuestions = [
  {
    key: "bloodPressureProblems",
    question: "Blood Pressure Problems",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "highCholesterol",
    question: "High Cholesterol",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "diabetesOrHighBloodSugar",
    question: "Diabetes/High Blood Sugar",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "thyroidConditions",
    question: "Thyroid Conditions",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "heartConditions",
    question: "Heart Conditions",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "stroke",
    question: "Stroke",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "depression",
    question: "Depression",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "cancer",
    question: "Cancer",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "asthmaOrCopd",
    question: "Asthma/COPD",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
];

// ---------------------------------------------------------------------------------------------------------

// STEP3
// ----- EYE HEALTH HISTORY
export const eyeHealthHistoryQuestions = [
  {
    key: "cataracts",
    question: "Cataracts",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "glaucoma",
    question: "Glaucoma",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "macularDegeneration",
    question: "Macular degeneration",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "diabeticRetinopathy",
    question: "Diabetic retinopathy",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "recurrentEyeInfections",
    question: "Recurrent eye infections",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "retinalDetachmentOrRetinalTear",
    question: "Retinal detachment/Retinal tear",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "dryEyes",
    question: "Dry eyes",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "lazyEyeOrCrossingEye",
    question: "Lazy eye/Crossing eye",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "blindness",
    question: "Blindness",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "floatersOrSpotsOrLightFlashes",
    question: "Floaters/Spots/Light flashes",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "otherEyeProblems",
    question: "Other eye problems",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
  {
    key: "eyeSurgeries",
    question: "Eye surgeries",
    options: ["you", "mother", "father", "sister", "brother", "no"],
  },
];

// STEP4
// ----- OTHER SECTION
export const otherSectionTextAreaQuestions = [
  {
    key: "allergies",
    question: "Please list any known allergies to medicine",
  },
  {
    key: "medications",
    question: "Please list any medications and/or eye drops you are currently taking",
  },
];

export const otherSectionYesNoQuestions = [
  {
    key: "pregnantOrNursing",
    question: "Are you currently pregnant or nursing?",
    options: {
      yes: "yes",
      no: "no",
    },
  },
];

export const otherSectionSelectQuestions = [
  {
    key: "tobacco",
    question: "Do you use tobacco?",
    options: [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
      { label: "Socially", value: "socially" },
      { label: "Never", value: "never" },
    ],
  },
  {
    key: "alcohol",
    question: "Do you drink alcohol?",
    options: [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
      { label: "Socially", value: "socially" },
      { label: "Never", value: "never" },
    ],
  },
];

// ----- CURRENT VISION CONDITION
export const currentVisionConditionYesNoQuestions = [
  {
    key: "worseningHeadaches",
    question: "Do you have frequent or worsening headaches?",
    options: {
      yes: "yes",
      no: "no",
    },
  },
  {
    key: "eyePainOrItchingRedness",
    question: "Do you have frequent eye pain, itching, or redness?",
    options: {
      yes: "yes",
      no: "no",
    },
  },
];

export const currentVisionConditionSelectQuestions = [
  {
    key: "haveIssuesSeeingWithCurrentGlassesOrContacts",
    question: "Do you currently have issues seeing with your current glasses or contacts?",
    options: [
      { label: "At distance", value: "at distance" },
      { label: "At near", value: "at near" },
      { label: "Both near and far", value: "both near and far" },
      { label: "No current issues", value: "no current issues" },
    ],
  },
  {
    key: "anyVisionDifficulties",
    question: "If you don't have vision correction, are you having any vision difficulties?",
    options: [
      { label: "At distance", value: "at distance" },
      { label: "At near", value: "at near" },
      { label: "Both near and far", value: "both near and far" },
      { label: "No current issues", value: "no current issues" },
    ],
  },
];

export const marketingConsents = [
  {
    key: "clinicalTrialNotifications",
    question:
      "Our mission is to broaden access to vision care, including relevant clinical trials. \n" +
      "May we contact you in the future about a clinical trial?",
    explanation:
      "We will never sell your information to a third party; 2020 On-site will contact you directly if you may be eligible to participate in a clinical trial.",
    options: {
      yes: "yes",
      no: "no",
    },
  },
];
