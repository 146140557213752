import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { message, Button, Form, Input, PageHeader, Card, Row, Col, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
}

export class CreateInsuranceComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  private form = React.createRef<FormInstance>();

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      await ApiClient.createInsurance({
        name: values.name,
        type: values.type,
      });

      message.success("Insurance successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push("/insurances");
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/insurances")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create insurance"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Insurance">
              <Form layout="vertical" ref={this.form} name="create-insurance" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                  <Select placeholder="Select type">
                    <Select.Option value={"medical"}>Medical</Select.Option>
                    <Select.Option value={"vision"}>Vision</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
