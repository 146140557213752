import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Card, Checkbox, Col, Form, Input, message, PageHeader, Radio, Row, Table, Select } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { validationPatterns } from "../../../helpers/validation.helper";
import GoogleMap from "../../../ui/map/GoogleMap.container";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  company: any; // FIXME:
  locations: [];
}

export class EditCompanyComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    company: undefined,
    locations: [],
  };

  public componentDidMount(): void {
    this.getCompany();
  }

  public getCompany = async () => {
    try {
      this.setState({ isLoading: true });
      const id = this.props.match.params?.id;
      const { data: company } = await ApiClient.getCompany(id);
      this.setState({ company, isLoading: false, locations: company?.locations || [] });
    } catch {
      message.error("Company cannot be fetched!");
    }
  };

  public deactivate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.deactivateCompany(id);
      message.success("Company deactivated!");
      await this.getCompany();
    } catch {
      message.error("Company cannot be deactivated!");
    }
  };

  public activate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.activateCompany(id);
      message.success("Company activated!");
      await this.getCompany();
    } catch {
      message.error("Company cannot be activated!");
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { company } = this.state;

      await ApiClient.updateCompany(company.id, {
        name: values.name,
        slug: values.slug,
        SFAccountId: values.SFAccountId,
        type: values.type,
      });

      message.success("Company successfully saved!");
      this.getCompany();
    } catch (e) {
      console.log(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public onLocationSearch = (values: any) => {
    let { locations } = this.state.company || {};
    if (values.length && !values.includes("all") && locations) {
      locations = locations.filter((location: any) => values.includes(location.name));
    }
    this.setState({ locations: locations || [] });
  };

  public render() {
    const { isLoading, company, locations } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/companies")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit company"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Company"
              loading={isLoading}
              extra={
                <>
                  {company?.isDisabled ? (
                    <Button onClick={this.activate}>Activate</Button>
                  ) : (
                    <Button onClick={this.deactivate}>Deactivate</Button>
                  )}
                </>
              }>
              <Form layout="vertical" ref={this.form} name="control-hooks" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={company?.name}>
                  <Input />
                </Form.Item>
                <Form.Item name="slug" label="Slug" rules={[{ required: true }]} initialValue={company?.slug}>
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="SFAccountId"
                  label="SFAccountId"
                  style={{ marginBottom: 0 }}
                  initialValue={company?.SFAccountId}
                  rules={[
                    {
                      pattern: validationPatterns.salesforceAccountId,
                      message: "Invalid Salesforce Account ID.  Please ensure to input the ID starting with 001",
                    },
                  ]}>
                  <Input />
                </Form.Item>
                {company?.SFAccountId && (
                  <Form.Item name="salesforceLink">
                    <a href={`${process.env.SALESFORCE_URL}/${company?.SFAccountId}`} target="_blank">
                      Open in Salesforce
                    </a>
                  </Form.Item>
                )}
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: "Please pick type!" }]}
                  initialValue={company?.type}>
                  <Radio.Group>
                    <Radio value="ro_public">RO Public</Radio>
                    <Radio value="ro_private">RO Private</Radio>
                    <Radio value="life_sciences">Life Sciences</Radio>
                    <Radio value="schools">Schools</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Locations"
              extra={
                <>
                  <Link to={`/companies/${company?.id}/locations/create`}>
                    <Button type="primary">Create location</Button>
                  </Link>
                </>
              }>
              <>
                {company?.locations?.length ? (
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="search location"
                    optionFilterProp="children"
                    onChange={this.onLocationSearch}
                    style={{ width: "100%", marginBottom: "20px" }}
                    filterOption={(input: any, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    <Select.Option value="all">all</Select.Option>
                    {company.locations
                      .sort((a: any, b: any) => a.name.localeCompare(b.name))
                      .map((location: any) => (
                        <Select.Option key={location.id} value={location.name}>
                          {location.name}
                        </Select.Option>
                      ))}
                  </Select>
                ) : null}
                <Table
                  bordered
                  showHeader={false}
                  loading={isLoading}
                  size="small"
                  dataSource={locations}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Address",
                      dataIndex: "address",
                      key: "address",
                    },
                    {
                      title: "Timezone",
                      dataIndex: "timezone",
                      key: "timezone",
                    },
                    {
                      title: "Action",
                      key: "operation",
                      fixed: "right",
                      width: 100,
                      render: ({ id: locationId }) => {
                        return (
                          <Link to={`/companies/${company?.id}/locations/${locationId}/edit`}>
                            <Button size={"small"}>Show</Button>
                          </Link>
                        );
                      },
                    },
                  ]}
                  pagination={false}
                />
              </>
            </Card>
          </Col>
        </Row>
        {company && company.locations.length > 0 && (
          <Row gutter={24} style={{ marginTop: 20 }}>
            <Col span={24}>
              <GoogleMap
                markers={company.locations.map((l) => ({
                  coordinates: l.geoLocation
                    ? {
                        lng: l.geoLocation.x,
                        lat: l.geoLocation.y,
                      }
                    : {
                        lng: 0,
                        lat: 0,
                      },
                  popup: l.name,
                }))}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}
