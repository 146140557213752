import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Form, Input, message, PageHeader, Row, Select, Space, Alert } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  insurance: any; // FIXME:
}

export class EditInsuranceComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    insurance: undefined,
  };

  public componentDidMount(): void {
    this.getInsurance();
  }

  public getInsurance = async () => {
    try {
      this.setState({ isLoading: true });
      const id = this.props.match.params?.id;
      const { data: insurance } = await ApiClient.getInsurance(id);
      this.setState({ insurance, isLoading: false });
    } catch {
      message.error("Insurance cannot be fetched!");
    }
  };

  public deactivate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.deactivateInsurance(id);
      message.success("Insurance deactivated!");
      await this.getInsurance();
    } catch {
      message.error("Insurance cannot be deactivated!");
    }
  };

  public activate = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.activateInsurance(id);
      message.success("Insurance activated!");
      await this.getInsurance();
    } catch {
      message.error("Insurance cannot be activated!");
    }
  };

  public setHidden = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.setInsuranceHidden(id);
      message.success("Insurance provider successfully hidden");
      await this.getInsurance();
    } catch {
      message.error("Something went wrong, please try again in a moment");
    }
  };

  public unsetHidden = async () => {
    try {
      const { id } = this.props.match.params;
      await ApiClient.unsetInsuranceHidden(id);
      message.success("Insurance provider is visible in booking app");
      await this.getInsurance();
    } catch {
      message.error("Something went wrong, please try again in a moment");
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { insurance } = this.state;

      await ApiClient.updateInsurance(insurance.id, {
        name: values.name,
        type: values.type,
      });

      message.success("Insurance successfully saved!");
      this.getInsurance();
    } catch (e) {
      console.log(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading, insurance } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/insurances")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit insurance"
        />
        <Row gutter={24}>
          {insurance?.isHidden && (
            <Col span={24}>
              <Alert
                message="Hidden insurance provider"
                description="Insurance provider is not visible in booking app."
                type="warning"
                style={{
                  marginBottom: 20,
                }}
              />
            </Col>
          )}
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Insurance"
              loading={isLoading}
              extra={
                <Space size="small">
                  {insurance?.isDisabled ? (
                    <Button onClick={this.activate}>Activate</Button>
                  ) : (
                    <Button onClick={this.deactivate}>Deactivate</Button>
                  )}

                  {insurance?.isHidden ? (
                    <Button onClick={this.unsetHidden}>Unset hidden</Button>
                  ) : (
                    <Button type="primary" onClick={this.setHidden}>
                      Set hidden
                    </Button>
                  )}
                </Space>
              }>
              <Form layout="vertical" ref={this.form} name="edit-insurance" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={insurance?.name}>
                  <Input />
                </Form.Item>
                <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={insurance?.type}>
                  <Select placeholder="Select type">
                    <Select.Option value={"medical"}>Medical</Select.Option>
                    <Select.Option value={"vision"}>Vision</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
