import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  message,
  Button,
  Form,
  Input,
  PageHeader,
  Card,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Radio,
  Space,
} from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import moment from "moment";
import { states } from "../../../helpers/states.helper";
import { RadioChangeEvent } from "antd/lib/radio";
import styled from "styled-components";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  addAddressDetails: boolean;
  patientType: "routine_optometry" | "life_sciences" | "schools";
}

export class CreatePatientComponent extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    addAddressDetails: true,
    patientType: "routine_optometry",
  };

  private form = React.createRef<FormInstance>();

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    const { patientType } = this.state;

    try {
      const body = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
        gender: values.gender,
        birth: moment(values.birth).format("YYYY-MM-DD"),
        phoneNumber: "1" + values.phoneNumber, // country code
        address: {
          type: "billing",
          city: values.addressCity,
          street: values.addressStreet,
          state: values.addressState,
          zipCode: values.addressZipCode,
        },
        ...(patientType === "life_sciences"
          ? {
              caregiver: values.caregiver,
              caregiverRelationship: values.caregiverRelationship,
              qualified: values.qualified === "true",
              consent: values.consent === "true",
              language: values.language,
            }
          : { notes: values.notes, ehrId: values.ehrId }),
      };

      if (!this.state.addAddressDetails) {
        delete body.address;
      }

      if (patientType === "life_sciences") {
        await ApiClient.createLifeSciencePatient(body);
      } else if (patientType === "schools") {
        await ApiClient.createSchoolPatient(body);
      } else {
        await ApiClient.createPatient(body);
      }

      message.success("Patient successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push("/patients");
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onChangePatientType = (e: RadioChangeEvent) => {
    this.setState({ patientType: e.target.value });
  };

  public render() {
    const { isLoading, addAddressDetails, patientType } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/patients")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create patient"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Patient">
              <Form layout="vertical" ref={this.form} name="create-patient" onFinish={this.onFinish}>
                <Form.Item
                  name="patientType"
                  label="Patient type"
                  rules={[{ required: true, message: "Please pick type!" }]}
                  initialValue="routine_optometry">
                  <Radio.Group onChange={this.onChangePatientType}>
                    <Radio value="routine_optometry">Routine Optometry</Radio>
                    <Radio value="life_sciences">Life Sciences</Radio>
                    <Radio value="schools">Schools</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="firstName" label="First name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="lastName" label="Last name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                  <Select placeholder="Select gender">
                    <Select.Option value={"male"}>Male</Select.Option>
                    <Select.Option value={"female"}>Female</Select.Option>
                    <Select.Option value={"not_specified"}>Not specified</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="birth" label="Birth" rules={[{ required: true }]}>
                  <DatePicker format="l" />
                </Form.Item>
                <Form.Item
                  extra="10 digits"
                  name="phoneNumber"
                  label="Phone number"
                  rules={[
                    { required: true },
                    {
                      min: 10,
                      max: 10,
                      message: "Phone number must have 10 digits.",
                    },
                  ]}>
                  <Input />
                </Form.Item>

                {patientType === "routine_optometry" && (
                  <>
                    <Form.Item name="notes" label="Notes">
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="ehrId" label="EHR ID">
                      <Input />
                    </Form.Item>
                  </>
                )}

                {patientType === "life_sciences" && (
                  <>
                    <Form.Item name="caregiver" label="Caregiver" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item name="caregiverRelationship" label="Caregiver Relationship" rules={[{ required: true }]}>
                      <Select placeholder="Select gender">
                        <Select.Option value="parent">Parent</Select.Option>
                        <Select.Option value="guardian">Guardian</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                      <Select placeholder="Select gender">
                        <Select.Option value="english">English</Select.Option>
                        <Select.Option value="spanish">Español</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="consent"
                      label="I/MY CHILD HAS BEEN PRESCRIBED ONE OF THE FOLLOWING MEDICATIONS: KALYDECO®, ORKAMBI®, SYMDEKO®, OR TRIKAFTA®"
                      rules={[{ required: true, message: "Please pick consent!" }]}>
                      <Space direction={"vertical"}>
                        The patient is currently prescribed one of these medications in accordance with the prescribed
                        medicine's age and prescription label diagnosis requirements
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      name="qualified"
                      label="Is caregiver qualified?"
                      rules={[{ required: true, message: "The person needs to be qualified!" }]}>
                      <RadioOptions>
                        <RadioOption value={true}>
                          I certify that I am 18 years of age or older and have legal authorization as parent/guardian
                          of the above named patient, and give consent for them to be examined by 2020 On-site. I
                          understand that if additional testing, invasive or interventional procedures are recommended,
                          I will be asked to read and sign additional consent forms prior to the test(s) or
                          procedure(s).
                        </RadioOption>
                      </RadioOptions>
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name="addAddressDetails"
                  valuePropName="checked"
                  initialValue={addAddressDetails}
                  style={{ marginBottom: 5 }}>
                  <Checkbox onChange={(e) => this.setState({ addAddressDetails: e.target.checked })}>
                    Add address details
                  </Checkbox>
                </Form.Item>

                {addAddressDetails && (
                  <>
                    <Form.Item
                      name="addressType"
                      label="Address type"
                      initialValue={"billing"}
                      rules={[{ required: true }]}>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="addressStreet" label="Address street" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="addressCity" label="Address city" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="addressState" label="Address state" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select state"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {states.map((state) => (
                          <Select.Option key={state} value={state}>
                            {state}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="addressZipCode"
                      label="Address zip code"
                      rules={[
                        { required: true },
                        {
                          min: 5,
                          max: 5,
                          message: "Zip code must have 5 digits.",
                        },
                      ]}>
                      <Input />
                    </Form.Item>
                  </>
                )}
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const RadioOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioOption = styled(Radio)`
  white-space: pre-wrap;
`;
