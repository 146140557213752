import React from "react";
import { Card } from "antd";

import { CovidScreening } from "./covid-screening.component";
import { CurrentCondition } from "./current-condition.component";
import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";

export interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  covidScreening: IState["covidScreening"];
  currentCondition: IState["currentCondition"];
}

export class QuestionnaireStep1Component extends React.Component<IProps> {
  render() {
    const { updateQuestionnaireField, covidScreening, currentCondition } = this.props;
    return (
      <Card title="Step 1">
        {/* <CovidScreening updateQuestionnaireField={updateQuestionnaireField} covidScreening={covidScreening} /> */}
        <CurrentCondition updateQuestionnaireField={updateQuestionnaireField} currentCondition={currentCondition} />
      </Card>
    );
  }
}
