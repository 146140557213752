import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  message,
  Button,
  Form,
  Input,
  PageHeader,
  Card,
  Row,
  Col,
  Select,
  Checkbox,
  TreeSelect,
  Radio,
  Typography,
  Divider,
} from "antd";
import { FormInstance } from "antd/es/form";

import { PhoneOutlined } from "@ant-design/icons";
import { ApiClient } from "../../../api-client/api.client";
import moment from "moment-timezone";
import styled from "styled-components";
import { IPatient } from "../../../interfaces/patient.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  companies: any[];
  selectedCompanyId: any;
  isNewPatient: boolean;
  patients: IPatient[];
  patientId: string | undefined;
  locations: any[];
}

export class CreateSubscriptionComponent extends React.Component<IProps, IState> {
  public state: IState = {
    companies: [],
    isLoading: false,
    selectedCompanyId: undefined,
    isNewPatient: true,
    patients: [],
    patientId: undefined,
    locations: [],
  };

  private form = React.createRef<FormInstance>();

  public componentDidMount() {
    this.getCompanies();
  }

  public async getCompanies() {
    const { data: companies } = await ApiClient.findCompanies({ offset: 0, limit: "all" });
    this.setState({ companies: companies.data });
  }

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      await this.form.current!.validateFields();

      await ApiClient.createWaitlistSubscription({
        email: values.email,
        phoneNumber: values.phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        timezone: values.timezone,
        timePreference: {
          morning: values.timePreference.includes("morning"),
          afternoon: values.timePreference.includes("afternoon"),
        },
        locationId: values.locationId,
      });

      message.success("Subscription successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push("/waitlist/subscriptions");
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onChange = (values: any) => {
    if (values.companyId) {
      this.form.current!.resetFields(["locationId"]);
      this.setState({ selectedCompanyId: values.companyId });
    }
  };

  private getPatients = async () => {
    const { finderFirstName, finderLastName, finderEmail } = this.form.current!.getFieldsValue();

    if (finderFirstName || finderLastName || finderEmail) {
      const { data: patients } = await ApiClient.findPatients({
        offset: 0,
        limit: 500,
        firstName: finderFirstName || undefined,
        lastName: finderLastName || undefined,
        email: finderEmail || undefined,
      });
      this.setState({ patients: patients.data });
    }
  };

  private selectPatient = async (patient: IPatient) => {
    await this.form.current!.setFieldsValue({
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      phoneNumber: patient.phoneNumber,
    });
    this.setState({ patientId: patient.id });
  };

  public onCompanyChange = (value: string) => {
    let locations = [];

    const company = this.state.companies.find((company) => company.id === value);
    if (company && company.locations.length) locations = company.locations;

    this.setState({ locations });
    this.form.current!.setFieldsValue({
      locationId: undefined,
    });
  };

  public render() {
    const { isLoading, companies, isNewPatient, patients, patientId } = this.state;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/waitlist/subscriptions")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create subscription"
        />
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Card title="Subscription">
              <Form
                layout="vertical"
                ref={this.form}
                name="create-subscription"
                onFinish={this.onFinish}
                onValuesChange={this.onChange}>
                <Form.Item name="isNewPatient" rules={[{ required: false }]}>
                  <Radio.Group
                    defaultValue={isNewPatient}
                    buttonStyle="solid"
                    onChange={(e) => {
                      this.form.current!.resetFields();
                      this.setState({ isNewPatient: e.target.value, patients: [] });
                    }}>
                    <Radio.Button value={true}>New patient</Radio.Button>
                    <Radio.Button value={false}>Existing patient</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {!isNewPatient && (
                  <>
                    <div style={{ backgroundColor: "#F8F8F8", padding: 10 }}>
                      <Typography.Title level={4}>Finder</Typography.Title>
                      <Form.Item name="finderFirstName" label="First name" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="finderLastName" label="Last name" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="finderEmail"
                        label="Email"
                        rules={[{ required: false }]}
                        style={{ marginBottom: 15 }}>
                        <Input />
                      </Form.Item>
                      <Form.Item>
                        <Button onClick={this.getPatients}>Search</Button>
                      </Form.Item>

                      <PatientsList>
                        {patients.map((patient) => {
                          return (
                            <PatientsListItem
                              key={patient.id}
                              isSelected={patientId === patient.id}
                              onClick={() => this.selectPatient(patient)}>
                              <div>
                                <strong>
                                  {patient.firstName} {patient.lastName}
                                </strong>
                              </div>
                              <div>
                                <i>{patient.email}</i>
                              </div>
                              <div>
                                <i>
                                  <PhoneOutlined /> {patient.phoneNumber}
                                </i>
                              </div>
                              <div>
                                <i>{moment(patient.birth).format("MM/DD/YYYY")}</i>
                              </div>
                            </PatientsListItem>
                          );
                        })}
                      </PatientsList>
                    </div>
                    <Divider />
                  </>
                )}

                <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                  <Input disabled={!isNewPatient} />
                </Form.Item>
                <Form.Item
                  extra="Country code + 10 digits"
                  name="phoneNumber"
                  label="Phone number"
                  rules={[{ required: true }, { min: 11, max: 11, message: "Phone number must have 11 digits." }]}>
                  <Input disabled={!isNewPatient} />
                </Form.Item>
                <Form.Item name="firstName" label="First name" rules={[{ required: true }]}>
                  <Input disabled={!isNewPatient} />
                </Form.Item>
                <Form.Item name="lastName" label="Last name" rules={[{ required: true }]}>
                  <Input disabled={!isNewPatient} />
                </Form.Item>
                <Form.Item name="timezone" label="Timezone" rules={[{ required: true }]}>
                  <Select showSearch placeholder="Select timezone">
                    <Select.Option value="America/New_York">America/New_York (Eastern)</Select.Option>
                    <Select.Option value="America/Chicago">America/Chicago (Central)</Select.Option>
                    <Select.Option value="America/Los_Angeles">America/Los_Angeles (Pacific)</Select.Option>
                    <Select.Option value="America/Denver">America/Denver (Mountain)</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="companyName" label="Company" style={{ marginBottom: 16 }} rules={[{ required: true }]}>
                  <Select
                    showSearch
                    onChange={this.onCompanyChange}
                    placeholder="Please select company"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {companies
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter((x) => x)
                      .filter((value, index, array) => {
                        return index === 0 || value.name !== array[index - 1].name;
                      })
                      .map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="locationId" label="Location" style={{ marginBottom: 16 }} rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Please select location"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {this.state.locations
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((location) => (
                        <Select.Option key={location.id} value={location.id}>
                          {location.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="timePreference" label="Time preference" rules={[{ required: true }]}>
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Row>
                      <Col span={8}>
                        <Checkbox value={"morning"} style={{ lineHeight: "32px" }}>
                          morning
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value={"afternoon"} style={{ lineHeight: "32px" }}>
                          afternoon
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Create
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const PatientsList = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 15px;
`;

const PatientsListItem = styled.div`
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border: ${(props: { isSelected: boolean }) => (props.isSelected ? "1px solid #fff" : "1px solid #424242")};
  background-color: ${(props: { isSelected: boolean }) => (props.isSelected ? "#1890ff" : "#fff")};
  color: ${(props: { isSelected: boolean }) => (props.isSelected ? "#fff" : "#424242")};
`;
