import React from "react";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { otherSectionSelectQuestions, otherSectionTextAreaQuestions, otherSectionYesNoQuestions } from "../questions";
import { QuestionSelectOption } from "../inputs/question-select-option.component";
import { QuestionTextArea } from "../inputs/question-text-area.component";
import { QuestionSelectYesOrNoOption } from "../inputs/question-select-yes-or-no-option.component";

interface IProps {
  other: IState["other"];
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
}

export const OtherSection = (props: IProps) => {
  const { other, updateQuestionnaireField } = props;
  return (
    <>
      {otherSectionTextAreaQuestions.map((q) => (
        <QuestionTextArea
          key={q.key}
          label={q.question}
          defaultValue={other[q.key]}
          value={other[q.key]}
          onChange={(e: any) => {
            updateQuestionnaireField({
              section: "other",
              key: q.key,
              value: e.target.value,
            });
          }}
        />
      ))}

      {otherSectionYesNoQuestions.map((q) => (
        <QuestionSelectYesOrNoOption
          key={q.key}
          question={q.question}
          options={q.options}
          defaultValue={other[q.key]}
          onChange={(e: any) => {
            updateQuestionnaireField({
              section: "other",
              key: q.key,
              value: e.target.value,
            });
          }}
          labelColOptions={{ xs: { span: 24 }, md: { span: 12 } }}
        />
      ))}

      {otherSectionSelectQuestions.map((q) => (
        <QuestionSelectOption
          key={q.question}
          question={q.question}
          options={q.options}
          defaultValue={other[q.key]}
          onChange={(value: string) => {
            updateQuestionnaireField({
              section: "other",
              key: q.key,
              value,
            });
          }}
          labelColOptions={{ xs: { span: 24 }, md: { span: 12 } }}
        />
      ))}
    </>
  );
};
