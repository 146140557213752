import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Form, Input, message, PageHeader, Row, Select } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { AppointmentData, ProductData } from "../../../interfaces/visit.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  appointment: AppointmentData | undefined;
  product: ProductData | undefined;
}

export class EditProductComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    appointment: undefined,
    product: undefined,
  };

  public componentDidMount(): void {
    this.getProduct();
  }

  public getProduct = async () => {
    try {
      this.setState({ isLoading: true });
      const { id, productId } = this.props.match.params;
      const { data: appointment } = await ApiClient.getAppointment(id);

      const product = appointment.products.find((x) => x.id === productId);

      this.setState({ product, appointment, isLoading: false });
    } catch {
      message.error("Product cannot be fetched!");
    }
  };

  private onFinish = async (values: any) => {
    this.setState({ isLoading: true });

    try {
      const { product, appointment } = this.state;

      if (!appointment || !product) {
        throw new Error('No appointment or product found');
      }

      await ApiClient.updateProducts(appointment.timeslot.visit.id, appointment.id, [
        {
          id: product.id,
          type: values.type,
          status: values.status,
          notes: values.notes,
          frameStatus: values.frameStatus,
        },
      ]);

      message.success("Product successfully saved!");
      this.getProduct();
    } catch (e) {
      console.log(e);
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading, product } = this.state;
    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push(`/appointments/${this.props.match.params?.id}/edit`)}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Edit product"
        />
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card title="Product" loading={isLoading}>
              <Form layout="vertical" ref={this.form} name="edit-product" onFinish={this.onFinish}>
                <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={product?.type}>
                  <Select placeholder="Select type">
                    <Select.Option value={"contact_lenses"}>contact_lenses</Select.Option>
                    <Select.Option value={"glasses"}>glasses</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="status" label="Status" rules={[{ required: true }]} initialValue={product?.status}>
                  <Select placeholder="Select status">
                    <Select.Option value={"ordered"}>ordered</Select.Option>
                    <Select.Option value={"purchased"}>purchased</Select.Option>
                    <Select.Option value={"quote"}>quote</Select.Option>
                    <Select.Option value={"remake"}>remake</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="notes" label="Notes" initialValue={product?.notes}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="frameStatus" label="Frame Status" initialValue={product?.frameStatus}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
