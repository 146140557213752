import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import classNames from 'classnames';
import TimeHeader from './TimeHeader';
import TimeBody from './TimeBody';
import { createKeyDownHandler } from '../../utils/uiUtil';

var countBoolean = function countBoolean(boolList) {
  return boolList.filter(function (bool) {
    return bool !== false;
  }).length;
};

function TimePanel(props) {
  var generateConfig = props.generateConfig,
      _props$format = props.format,
      format = _props$format === void 0 ? 'HH:mm:ss' : _props$format,
      prefixCls = props.prefixCls,
      active = props.active,
      operationRef = props.operationRef,
      showHour = props.showHour,
      showMinute = props.showMinute,
      showSecond = props.showSecond,
      _props$use12Hours = props.use12Hours,
      use12Hours = _props$use12Hours === void 0 ? false : _props$use12Hours,
      onSelect = props.onSelect,
      value = props.value;
  var panelPrefixCls = "".concat(prefixCls, "-time-panel");
  var bodyOperationRef = React.useRef(); // ======================= Keyboard =======================

  var _React$useState = React.useState(-1),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeColumnIndex = _React$useState2[0],
      setActiveColumnIndex = _React$useState2[1];

  var columnsCount = countBoolean([showHour, showMinute, showSecond, use12Hours]);
  operationRef.current = {
    onKeyDown: function onKeyDown(event) {
      return createKeyDownHandler(event, {
        onLeftRight: function onLeftRight(diff) {
          setActiveColumnIndex((activeColumnIndex + diff + columnsCount) % columnsCount);
        },
        onUpDown: function onUpDown(diff) {
          if (activeColumnIndex === -1) {
            setActiveColumnIndex(0);
          } else if (bodyOperationRef.current) {
            bodyOperationRef.current.onUpDown(diff);
          }
        },
        onEnter: function onEnter() {
          onSelect(value || generateConfig.getNow(), 'key');
          setActiveColumnIndex(-1);
        }
      });
    },
    onBlur: function onBlur() {
      setActiveColumnIndex(-1);
    }
  };
  return React.createElement("div", {
    className: classNames(panelPrefixCls, _defineProperty({}, "".concat(panelPrefixCls, "-active"), active))
  }, React.createElement(TimeHeader, Object.assign({}, props, {
    format: format,
    prefixCls: prefixCls
  })), React.createElement(TimeBody, Object.assign({}, props, {
    prefixCls: prefixCls,
    activeColumnIndex: activeColumnIndex,
    operationRef: bodyOperationRef
  })));
}

export default TimePanel;