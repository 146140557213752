import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { orderBy } from "lodash";
import styled from "styled-components";
import {
  message,
  Button,
  Form,
  Input,
  PageHeader,
  Card,
  Row,
  Col,
  Select,
  Checkbox,
  Radio,
  DatePicker,
  Divider,
  Typography,
  notification,
  Space,
} from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import moment from "moment-timezone";
import { states } from "../../../helpers/states.helper";
import { RadioChangeEvent } from "antd/lib/radio";
import { VisitData } from "../../../interfaces/visit.interface";
import { sortInsurances } from "../../../helpers/appointment.helper";

interface IProps extends RouteComponentProps {
  isRetailOnly?: boolean;
}

interface IState {
  isLoading: boolean;
  visits: VisitData[];
  companies: any[];
  patients: any[];
  timeslots: any[];
  insurances: any[];
  primaryInsuranceSecondaryInsured: boolean;
  secondaryInsuranceSecondaryInsured: boolean;
  isNewPatient: boolean;
  patientId: string | undefined;
  addAddressDetails: boolean;
  patientType: "routine_optometry" | "life_sciences";
}

export class CreateAppointmentComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();
  private isRetailOnly = this.props.isRetailOnly || false;

  public state: IState = {
    isLoading: false,
    visits: [],
    companies: [],
    patients: [],
    timeslots: [],
    insurances: [],
    primaryInsuranceSecondaryInsured: false,
    secondaryInsuranceSecondaryInsured: false,
    isNewPatient: true,
    patientId: undefined,
    addAddressDetails: true,
    patientType: "routine_optometry",
  };

  public async componentDidMount() {
    const { companyIdParameter, visitIdParameter, timeslotIdParameter } = this.getUrlQueryParams();

    await Promise.all([this.getCompanies(undefined), this.getPatients(), this.getInsurances()]);

    const values: any = {};

    if (companyIdParameter) {
      values.companyId = companyIdParameter;
    }
    if (companyIdParameter && visitIdParameter) {
      values.visitId = visitIdParameter;
    }
    if (visitIdParameter && timeslotIdParameter) {
      values.timeslotId = timeslotIdParameter;
    }

    if (companyIdParameter && visitIdParameter) {
      await Promise.all([this.getVisits(companyIdParameter), this.getTimeslots(visitIdParameter)]);
    }

    if (this.isRetailOnly) {
      values.type = "retail_only";
    }

    this.form.current?.setFieldsValue(values);
    this.setState({ isLoading: false });
  }

  public getUrlQueryParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const companyIdParameter = searchParams.get("companyId");
    const visitIdParameter = searchParams.get("visitId");
    const timeslotIdParameter = searchParams.get("timeslotId");
    const closeTabParameter = searchParams.get("closeTab");

    return {
      companyIdParameter,
      visitIdParameter,
      timeslotIdParameter,
      closeTabParameter,
    };
  };

  private onFinish = async (values: any) => {
    const { isNewPatient, patientId, addAddressDetails, patientType } = this.state;
    this.setState({ isLoading: true });

    try {
      await this.form.current!.validateFields();

      let newPatient;

      if (isNewPatient) {
        const body = {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email,
          gender: values.gender,
          birth: moment(values.birth).format("YYYY-MM-DD"),
          phoneNumber: "1" + values.phoneNumber, // country code
          address: {
            type: values.addressType,
            city: values.addressCity,
            street: values.addressStreet,
            zipCode: values.addressZipCode,
            state: values.addressState,
          },
          ...(patientType === "life_sciences"
            ? {
                caregiver: values.caregiver,
                caregiverRelationship: values.caregiverRelationship,
                qualified: values.qualified === "true",
                consent: values.consent === "true",
                language: values.language,
              }
            : { notes: values.notes, ehrId: values.ehrId }),
        };

        if (!addAddressDetails) {
          delete body.address;
        }

        if (patientType === "life_sciences") {
          newPatient = await ApiClient.createLifeSciencePatient(body);
        } else {
          newPatient = await ApiClient.createPatient(body);
        }
      }

      if (!isNewPatient && !patientId) {
        notification.error({
          message: "Patient not selected",
          description: "Please select the existing patient",
        });
        this.setState({ isLoading: false });
        return;
      }

      const createAppointmentPayload = {
        patientId: isNewPatient ? newPatient.data.id : patientId,
        timeslotId: values.timeslotId,
        type: values.type,
        smsSubscription: values.smsSubscription,
        survey: {
          isEyeExam: false,
          isContactRenewal: values.isContactRenewal,
          isFittedForContactLenses: values.isFittedForContactLenses,
          wearGlasses: values.wearGlasses,
          wearContactLenses: values.wearContactLenses,
          applyInsuranceBenefitsForGlassPurchase: values.applyInsuranceBenefitsForGlassPurchase,
          covidPositive: values.covidPositive,
          covidSymptoms: values.covidSymptoms,
          covidAntibodies: values.covidAntibodies,
          insuranceProvidedByCompany: values.insuranceProvidedByCompany,
          insuranceReminder: values.insuranceReminder,
          marketingOptIn: values.marketingOptIn,
          hipaaAcknowledgement: values.hipaaAcknowledgement,
          noInsuranceCoverage: values.noInsuranceCoverage,
          lastExam: values.lastExam,
          primaryInsurance: {
            insuranceId: values.primaryInsurance || null,
            secondaryInsured: values.primaryInsuranceSecondaryInsured,
            provider: values.primaryInsuranceProvider || null,
            subscriberId: values.primaryInsuranceSubscriberId || null,
            extPolicyHolderDOB: values.primaryInsuranceExtPolicyHolderDOB || null,
            extPolicyHolderName: values.primaryInsuranceExtPolicyHolderName || null,
            noInsurance: values.noMedicalInsurance || false,
            companySponsoredInsurancePlan: values.companySponsoredMedicalInsurancePlan || undefined,
          },
          secondaryInsurance: {
            insuranceId: values.secondaryInsurance || null,
            secondaryInsured: values.secondaryInsuranceSecondaryInsured,
            provider: values.secondaryInsuranceProvider || null,
            subscriberId: values.secondaryInsuranceSubscriberId || null,
            extPolicyHolderDOB: values.secondaryInsuranceExtPolicyHolderDOB || null,
            extPolicyHolderName: values.secondaryInsuranceExtPolicyHolderName || null,
            noInsurance: values.noVisionInsurance || false,
            companySponsoredInsurancePlan: values.companySponsoredVisionInsurancePlan || undefined,
          },
        },
      };

      let response;
      if (this.isRetailOnly) {
        const _response: { data: { appointmentId: string } } = (await ApiClient.createRetailOnlyAppointment(
          values.visitId,
          createAppointmentPayload
        )) as { data: { appointmentId: string } };
        response = _response.data;
      } else {
        const _response = (await ApiClient.createAppointment(values.visitId, createAppointmentPayload)) as {
          data: { appointmentId: string };
        };
        response = _response.data;
      }

      message.success("Appointment successfully created!");
      this.setState({ isLoading: false });
      this.props.history.push(`/appointments/${response.appointmentId}/edit`);

      // Close tab if closeTab == true
      const { closeTabParameter } = this.getUrlQueryParams();
      if (closeTabParameter === "true") {
        if (window.confirm("Appointment successfully added. Do you want to close current tab?")) {
          window.close();
        }
      }
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({ isLoading: false });
    }
  };

  private onFormValuesChange = (values: any) => {
    if (values.companyId) {
      this.form.current?.resetFields(["visitId", "timeslotId"]);
      this.getVisits(values.companyId);
    }

    if (values.visitId) {
      this.form.current?.resetFields(["timeslotId"]);
      this.getTimeslots(values.visitId);
    }

    if (typeof values.primaryInsuranceSecondaryInsured === "boolean") {
      this.setState({
        primaryInsuranceSecondaryInsured: values.primaryInsuranceSecondaryInsured,
      });
      this.form.current?.resetFields(["primaryInsuranceExtPolicyHolderName", "primaryInsuranceExtPolicyHolderDOB"]);
    }

    if (typeof values.secondaryInsuranceSecondaryInsured === "boolean") {
      this.setState({
        secondaryInsuranceSecondaryInsured: values.secondaryInsuranceSecondaryInsured,
      });
      this.form.current?.resetFields(["secondaryInsuranceExtPolicyHolderName", "secondaryInsuranceExtPolicyHolderDOB"]);
    }
  };

  private getCompanies = async (companyName?: string) => {
    const { data: companies } = await ApiClient.findCompanies({
      offset: 0,
      limit: "all",
      name: companyName || undefined,
    });
    this.setState({ companies: companies.data });
  };

  private getPatients = async () => {
    const { finderFirstName, finderLastName, finderEmail } = this.form.current!.getFieldsValue();

    if (finderFirstName || finderLastName || finderEmail) {
      const { data: patients } = await ApiClient.findPatients({
        offset: 0,
        limit: 500,
        firstName: finderFirstName,
        lastName: finderLastName,
        email: finderEmail,
      });
      this.setState({ patients: patients.data });
    }
  };

  private getTimeslots = async (visitId: string) => {
    const { data: timeslots } = await ApiClient.getVisitTimeslots(visitId);
    this.setState({ timeslots });
  };

  private getVisits = async (companyId: string) => {
    const { companies } = this.state;
    const company = companies.find((x) => x.id === companyId);

    if (!company) {
      this.setState({ visits: [] });
    }

    const locationIds = company.locations.map((x) => x.id);

    const { data: visits } = await ApiClient.findVisits({
      offset: 0,
      limit: "all",
      locationIds,
    });

    this.setState({ visits: visits.data });

    if (visits.total === 1) {
      const visitId = visits.data[0].id;
      this.form.current?.setFieldsValue({ visitId });
      this.onFormValuesChange({ visitId });
    }
  };

  private getInsurances = async () => {
    const { data: insurances } = await ApiClient.findInsurances();
    this.setState({ insurances: insurances.sort(sortInsurances) });
  };

  private selectPatient = (patientId: string) => {
    this.setState({ patientId });
  };

  private onChangePatientType = (e: RadioChangeEvent) => {
    this.setState({ patientType: e.target.value });
  };

  public render() {
    const {
      isLoading,
      isNewPatient,
      patientId,
      companies,
      visits,
      patients,
      timeslots,
      insurances,
      primaryInsuranceSecondaryInsured,
      secondaryInsuranceSecondaryInsured,
      addAddressDetails,
      patientType,
    } = this.state;

    const visitId = this.form.current?.getFieldValue("visitId");
    const selectedVisit = visits && visitId ? visits.find((x) => x.id === visitId) : undefined;

    return (
      <>
        <PageHeader
          onBack={() => this.props.history.push("/appointments")}
          ghost={false}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          title="Create appointment"
        />
        <Row>
          <Col xs={24}>
            <Card title="Appointment">
              <Form
                layout="vertical"
                ref={this.form}
                name="create-appointment"
                onFinish={this.onFinish}
                onValuesChange={this.onFormValuesChange}>
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item name="isNewPatient" rules={[{ required: false }]}>
                      <Radio.Group
                        defaultValue={isNewPatient}
                        buttonStyle="solid"
                        onChange={(e) => {
                          this.setState({ isNewPatient: e.target.value });
                        }}>
                        <Radio.Button value={true}>New patient</Radio.Button>
                        <Radio.Button value={false}>Existing patient</Radio.Button>
                      </Radio.Group>
                    </Form.Item>

                    {isNewPatient && (
                      <>
                        <Form.Item
                          name="patientType"
                          label="Patient type"
                          rules={[{ required: true, message: "Please pick type!" }]}
                          initialValue="routine_optometry">
                          <Radio.Group onChange={this.onChangePatientType}>
                            <Radio value="routine_optometry">Routine Optometry</Radio>
                            <Radio value="life_sciences">Life Sciences</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name="firstName" label="First name" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name="lastName" label="Last name" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                          <Select placeholder="Select gender">
                            <Select.Option value={"male"}>Male</Select.Option>
                            <Select.Option value={"female"}>Female</Select.Option>
                            <Select.Option value={"not_specified"}>Not specified</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="birth" label="Birth" rules={[{ required: true }]}>
                          <DatePicker format="l" />
                        </Form.Item>
                        <Form.Item
                          extra="10 digits"
                          name="phoneNumber"
                          label="Phone number"
                          rules={[
                            { required: true },
                            {
                              min: 10,
                              max: 10,
                              message: "Phone number must have 10 digits.",
                            },
                          ]}>
                          <Input />
                        </Form.Item>

                        {patientType === "life_sciences" && (
                          <>
                            <Form.Item name="caregiver" label="Caregiver" rules={[{ required: true }]}>
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="caregiverRelationship"
                              label="Caregiver Relationship"
                              rules={[{ required: true }]}>
                              <Select placeholder="Select gender">
                                <Select.Option value="parent">Parent</Select.Option>
                                <Select.Option value="guardian">Guardian</Select.Option>
                                <Select.Option value="other">Other</Select.Option>
                              </Select>
                            </Form.Item>

                            <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                              <Select placeholder="Select gender">
                                <Select.Option value="english">English</Select.Option>
                                <Select.Option value="spanish">Español</Select.Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="consent"
                              label="I/MY CHILD HAS BEEN PRESCRIBED ONE OF THE FOLLOWING MEDICATIONS: KALYDECO®, ORKAMBI®, SYMDEKO®, OR TRIKAFTA®"
                              rules={[{ required: true, message: "Please pick consent!" }]}>
                              <Space direction={"vertical"}>
                                The patient is currently prescribed one of these medications in accordance with the
                                prescribed medicine's age and prescription label diagnosis requirements
                                <Radio.Group>
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Space>
                            </Form.Item>

                            <Form.Item
                              name="qualified"
                              label="Is caregiver qualified?"
                              rules={[{ required: true, message: "The person needs to be qualified!" }]}>
                              <RadioOptions>
                                <RadioOption value={true}>
                                  I certify that I am 18 years of age or older and have legal authorization as
                                  parent/guardian of the above named patient, and give consent for them to be examined
                                  by 2020 On-site. I understand that if additional testing, invasive or interventional
                                  procedures are recommended, I will be asked to read and sign additional consent forms
                                  prior to the test(s) or procedure(s).
                                </RadioOption>
                              </RadioOptions>
                            </Form.Item>
                          </>
                        )}
                        <Form.Item
                          name="addAddressDetails"
                          valuePropName="checked"
                          initialValue={addAddressDetails}
                          style={{ marginBottom: 5 }}>
                          <Checkbox
                            onChange={(e) =>
                              this.setState({
                                addAddressDetails: e.target.checked,
                              })
                            }>
                            Add address details
                          </Checkbox>
                        </Form.Item>

                        {addAddressDetails ? (
                          <>
                            <Form.Item
                              name="addressType"
                              label="Address type"
                              initialValue={"billing"}
                              rules={[{ required: true }]}>
                              <Input disabled />
                            </Form.Item>
                            <Form.Item name="addressStreet" label="Address street" rules={[{ required: true }]}>
                              <Input />
                            </Form.Item>
                            <Form.Item name="addressCity" label="Address city" rules={[{ required: true }]}>
                              <Input />
                            </Form.Item>
                            <Form.Item name="addressState" label="Address state" rules={[{ required: true }]}>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select state"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {states.map((state) => (
                                  <Select.Option key={state} value={state}>
                                    {state}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="addressZipCode"
                              label="Address zip code"
                              rules={[
                                { required: true },
                                {
                                  min: 5,
                                  max: 5,
                                  message: "Zip code must have 5 digits.",
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </>
                        ) : null}
                        <Divider />
                      </>
                    )}

                    {!isNewPatient && (
                      <>
                        <div style={{ backgroundColor: "#F8F8F8", padding: 10 }}>
                          <Typography.Title level={4}>Finder</Typography.Title>
                          <Form.Item name="finderFirstName" label="First name" rules={[{ required: false }]}>
                            <Input onChange={() => this.setState({ patientId: undefined })} />
                          </Form.Item>
                          <Form.Item name="finderLastName" label="Last name" rules={[{ required: false }]}>
                            <Input onChange={() => this.setState({ patientId: undefined })} />
                          </Form.Item>
                          <Form.Item name="finderEmail" label="Email" rules={[{ required: false }]}>
                            <Input onChange={() => this.setState({ patientId: undefined })} />
                          </Form.Item>
                          <Form.Item>
                            <Button onClick={this.getPatients}>Search</Button>
                          </Form.Item>

                          <PatientsList>
                            {patients.map((patient) => {
                              return (
                                <PatientsListItem
                                  key={patient.id}
                                  isSelected={patientId === patient.id}
                                  onClick={() => this.selectPatient(patient.id)}>
                                  <div>
                                    <strong>
                                      {patient.firstName} {patient.lastName}
                                    </strong>
                                  </div>
                                  <div>
                                    <i>{patient.email}</i>
                                  </div>
                                  <div>
                                    <i>{moment(patient.birth).format("MM/DD/YYYY")}</i>
                                  </div>
                                </PatientsListItem>
                              );
                            })}
                          </PatientsList>
                        </div>
                        <Divider />
                      </>
                    )}

                    <Form.Item name="companyId" label="Company" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        placeholder="Select company"
                        onSearch={this.getCompanies}
                        notFoundContent={"no companies"}
                        disabled={this.isRetailOnly}>
                        {orderBy(companies, [(c) => c.name.toLowerCase()], "asc").map((company) => (
                          <Select.Option value={company.id}>{company.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="visitId" label="Visit" rules={[{ required: true }]}>
                      <Select
                        disabled={!visits.length || this.isRetailOnly}
                        placeholder="Select visit"
                        notFoundContent={"no visits"}>
                        {visits.map((visit) => (
                          <Select.Option value={visit.id}>{visit.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {!this.isRetailOnly && (
                      <Form.Item name="timeslotId" label="Timeslot" rules={[{ required: true }]}>
                        <Select
                          disabled={!selectedVisit}
                          placeholder="Select timeslot"
                          notFoundContent={"no timeslots"}>
                          {timeslots
                            .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.endDate).getTime())
                            .map((timeslot) => (
                              <Select.Option
                                value={timeslot.id}
                                disabled={timeslot.isBooked || timeslot.isDisabled || timeslot.isTemporaryLocked}>
                                {moment.tz(timeslot.startDate, selectedVisit?.timezone).format("LT")}
                                {" - "}
                                {moment.tz(timeslot.endDate, selectedVisit?.timezone).format("LT")}
                                {timeslot.isBooked ? " (booked)" : ""}
                                {timeslot.isDisabled ? " (disabled)" : ""}
                                {timeslot.isTemporaryLocked ? " (temporary locked)" : ""}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                      <Select placeholder="Select appointment type" disabled={this.isRetailOnly}>
                        <Select.Option value={"exam"}>exam</Select.Option>
                        <Select.Option value={"cl_exam"}>cl_exam</Select.Option>
                        <Select.Option value={"retail_only"}>retail_only</Select.Option>
                        <Select.Option value={"f/u"}>f/u</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="lastExam" label="Last Exam" rules={[{ required: true }]}>
                      <Select placeholder="Select last exam note">
                        <Select.Option value="Less than 1 year">Less than 1 year</Select.Option>
                        <Select.Option value="1-2 years">1-2 years</Select.Option>
                        <Select.Option value="2-5 years">2-5 years</Select.Option>
                        <Select.Option value="5-10 years">5-10 years</Select.Option>
                        <Select.Option value="10+ years">10+ years</Select.Option>
                        <Select.Option value={`I've never had an eye exam`}>I've never had an eye exam</Select.Option>
                        <Select.Option value={`I don't know`}>I don't know</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <div className="ant-col ant-form-item-label">
                      <label>Survey</label>
                    </div>
                    <style>
                      {`
                        .ant-checkbox-wrapper { display: flex; }
                        .ant-checkbox { margin-top: 3px; }
                      `}
                    </style>
                    <Form.Item
                      name="wearGlasses"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Do you currently wear glasses?</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="wearContactLenses"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Do you currently wear contact lenses?</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="isContactRenewal"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Do you need to renew your contact lens prescription?</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="isFittedForContactLenses"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Do you want to be fitted for contact lenses?</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="applyInsuranceBenefitsForGlassPurchase"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        Would you like apply existing insurance benefits for a potential glasses purchase?
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="insuranceProvidedByCompany"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Is your insurance provided by company?</Checkbox>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      name="noMedicalInsurance"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>I don't have medical insurance</Checkbox>
                    </Form.Item>
                    <Form.Item name="primaryInsuranceId" label="Primary Insurance" initialValue={""}>
                      <Select placeholder="Select primary insurance">
                        <Select.Option value={""}>none</Select.Option>
                        {insurances.map((insurance) => (
                          <Select.Option value={insurance.id}>{insurance.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="primaryInsuranceProvider" label="Primary Insurance - provider (other)">
                      <Input />
                    </Form.Item>
                    <Form.Item name="primaryInsuranceSubscriberId" label="Primary Insurance - subscriber ID">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="companySponsoredMedicalInsurancePlan"
                      label="Insured under a company-sponsored insurance plan">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="primaryInsuranceSecondaryInsured"
                      label="Primary Insurance - secondary insured"
                      valuePropName="checked"
                      initialValue={false}>
                      <Checkbox />
                    </Form.Item>
                    {primaryInsuranceSecondaryInsured ? (
                      <>
                        <Form.Item
                          name="primaryInsuranceExtPolicyHolderName"
                          label="Primary Insurance - Ext Policy Holder Name">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="primaryInsuranceExtPolicyHolderDOB"
                          label="Primary Insurance - Ext Policy Holder DOB">
                          <Input />
                        </Form.Item>
                      </>
                    ) : null}
                    <Divider />
                    <Form.Item
                      name="noVisionInsurance"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>I don't have vision insurance</Checkbox>
                    </Form.Item>
                    <Form.Item name="secondaryInsuranceId" label="Secondary Insurance" initialValue={""}>
                      <Select placeholder="Select secondary insurance">
                        <Select.Option value={""}>none</Select.Option>
                        {insurances.map((insurance) => (
                          <Select.Option value={insurance.id}>{insurance.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="secondaryInsuranceProvider" label="Secondary Insurance - provider (other)">
                      <Input />
                    </Form.Item>
                    <Form.Item name="secondaryInsuranceSubscriberId" label="Secondary Insurance - subscriber ID">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="companySponsoredVisionInsurancePlan"
                      label="Insured under a company-sponsored insurance plan">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="secondaryInsuranceSecondaryInsured"
                      label="Secondary Insurance - secondary insured"
                      valuePropName="checked"
                      initialValue={false}>
                      <Checkbox />
                    </Form.Item>
                    {secondaryInsuranceSecondaryInsured ? (
                      <>
                        <Form.Item
                          name="secondaryInsuranceExtPolicyHolderName"
                          label="Secondary Insurance - Ext Policy Holder Name">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="secondaryInsuranceExtPolicyHolderDOB"
                          label="Secondary Insurance - Ext Policy Holder DOB">
                          <Input />
                        </Form.Item>
                      </>
                    ) : null}
                    <Divider />
                    <Form.Item
                      name="insuranceReminder"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        I don't have my insurance ID cards right now. Email me a reminder to complete this information
                        when I have my ID card.
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="noInsuranceCoverage"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>I don’t have insurance coverage.</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="marketingOptIn"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        I would like to stay in the loop about 2020 On-site promotions, news, and events.
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="hipaaAcknowledgement"
                      valuePropName="checked"
                      initialValue={false}
                      required={true}
                      rules={[{ required: true }]}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        I acknowledge that I have reviewed and agree to the{" "}
                        <a href="https://www.2020onsite.com/privacypolicy" target="_blank">
                          <u>20/20 Onsite HIPAA Compliance, Financial Responsibility, and Telehealth policies</u>
                        </a>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="covidPositive"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        Have you tested positive for COVID-19 in the past 14 days, or been in contact with anyone who
                        has tested positive for COVID-19 in the past 14 days?
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="covidSymptoms"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>
                        Are you currently experiencing any signs of acute respiratory illness (cough, fever, or
                        difficulty breathing)?
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="covidAntibodies"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: 5 }}>
                      <Checkbox>Have you been tested for COVID-19 antibodies?</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="smsSubscription"
                      initialValue={true}
                      valuePropName="checked"
                      style={{ marginBottom: 5 }}>
                      <Checkbox>SMS texts for the appointment</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                      {this.isRetailOnly ? "Create retail only appointment" : "Create"}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const PatientsList = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 15px;
`;

const PatientsListItem = styled.div`
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border: ${(props: { isSelected: boolean }) => (props.isSelected ? "1px solid #fff" : "1px solid #424242")};
  background-color: ${(props: { isSelected: boolean }) => (props.isSelected ? "#1890ff" : "#fff")};
  color: ${(props: { isSelected: boolean }) => (props.isSelected ? "#fff" : "#424242")};
`;

const RadioOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioOption = styled(Radio)`
  white-space: pre-wrap;
`;
