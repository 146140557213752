import React from "react";
import { Calendar, Badge, Tooltip, Button, Space, ConfigProvider } from "antd";
import en_GB from "antd/lib/locale/en_GB";
import "moment/locale/en-gb";
import styled from "styled-components";
import moment, { Moment } from "moment";
import momentTz from "moment-timezone";
import { orderBy } from "lodash";
import { SyncOutlined } from "@ant-design/icons";

import { colors } from "../../../styles/colors";
import {
  IBookingBoardMonthCalendarVisit,
  IBookingBoardMonthCalendarVisitWithoutRequireTruck,
  IVisitStaff,
} from "../../../interfaces/visit.interface";
import {
  getAppointmentTypeColor,
  getVisitStatusColor,
  getVisitStatusesWhereTruckIsRequire,
} from "../../../helpers/visit.helper";
import { ITruck } from "../../../interfaces/truck.interface";
import { ApiClient } from "../../../api-client/api.client";
import BookingBoardVisitsModal from "./visits-modal.component";
import CreateOpsHoldVisitModal from "./create-ops-hold-visit-modal";

interface IProps {
  selectedMonth: string;
  selectedTrucks: string[];
  visits: IBookingBoardMonthCalendarVisit[];
  visitsWithoutRequireTruck: IBookingBoardMonthCalendarVisitWithoutRequireTruck[];
  trucks: ITruck[];
  excludedDates: string[];
  excludedTrucks: {
    [key: string]: string[];
  };
  isEditExcludedDatesModeOn: boolean;
  isEditExcludedTrucksModeOn: boolean;
  isMarkClinicUnavailableModeOn: boolean;
  toggleEditExcludedDatesMode: (value: boolean) => void;
  toggleEditExcludedTrucksMode: (value: boolean) => void;
  toggleMarkClinicUnavailableMode: (value: boolean) => void;
  updateExcludedDates: (date: string) => void;
  updateExcludedTrucks: (date: string, truckId: string) => void;
  saveExcludedDates: () => void;
  saveExcludedTrucks: () => void;
  refreshVisits: () => void;
  getVisitsInBackground: () => void;
  getVisitsWithoutRequireTruckInBackground: () => void;
}

interface IState {
  additionalSelectedMonth: string;
  selectedMonth: string;
  selectedDay: string;
  isVisitsModalVisible: boolean;
  isCreateOpsHoldVisitModalVisible: boolean;
  visitTimeRange: {
    [key: string]: {
      startDate: string;
      endDate: string;
    };
  };
  assignedStaff: {
    [key: string]: IVisitStaff[];
  };
  selectedTruck: ITruck | undefined;
}

export class BookingBoardCalendar extends React.Component<IProps, IState> {
  public state: IState = {
    additionalSelectedMonth: "",
    selectedMonth: "",
    selectedDay: "",
    isVisitsModalVisible: false,
    isCreateOpsHoldVisitModalVisible: false,
    visitTimeRange: {},
    assignedStaff: {},
    selectedTruck: undefined,
  };

  componentDidMount(): void {
    this.setState({
      selectedMonth: this.props.selectedMonth,
      additionalSelectedMonth: moment(this.props.selectedMonth, "YYYY-MM").add(1, "months").format("YYYY-MM"),
    });
  }

  countDaysToDate = (date: Moment): number => {
    const currentDate = moment();
    return date.diff(currentDate, "days");
  };

  isDayExcluded = (date: Moment) => {
    const { excludedDates } = this.props;
    return excludedDates.includes(date.format("YYYY-MM-DD"));
  };

  getCellBackground = (date: Moment) => {
    switch (true) {
      case this.isDayExcluded(date):
        return colors.greyCell;
      default:
        return "transparent";
    }
  };

  getDayIndicator = (date: Moment) => {
    const numberOfDays = this.countDaysToDate(date);
    switch (true) {
      case numberOfDays > 56:
        return "🟢";
      case 42 <= numberOfDays && numberOfDays <= 56:
        return "🟡";
      case numberOfDays >= 0 && numberOfDays < 42:
        return "🔴";
      default:
        return "";
    }
  };

  getListData = (value: Moment) => {
    const formattedValue = value.format("YYYY-MM-DD");
    return orderBy(
      this.props.visits.filter(
        (visit) => visit.date === formattedValue && getVisitStatusesWhereTruckIsRequire().includes(visit.status)
      ),
      "truckName",
      "asc"
    );
  };

  getListDateWithVisitsWithoutRequireTruck = (value: Moment) => {
    if (!this.props.visitsWithoutRequireTruck) return [];
    const formattedValue = value.format("YYYY-MM-DD");
    return this.props.visitsWithoutRequireTruck.filter(
      (visit) => visit.date === formattedValue && !getVisitStatusesWhereTruckIsRequire().includes(visit.status)
    );
  };

  dateCellRender = (value: Moment) => {
    const {
      selectedTrucks,
      trucks,
      excludedTrucks,
      isEditExcludedTrucksModeOn,
      updateExcludedTrucks,
      isMarkClinicUnavailableModeOn,
    } = this.props;
    const { visitTimeRange, assignedStaff } = this.state;
    const listData = this.getListData(value);
    const listDateWithVisitsWithoutRequireTruck = this.getListDateWithVisitsWithoutRequireTruck(value);
    const availableTruckIds = selectedTrucks.filter((id) => !listData.map((v) => v.truckId).includes(id));
    const availableTrucks = trucks.filter((truck) => availableTruckIds.includes(truck.id));
    const excludedTrucksThisDay = excludedTrucks[value.format("YYYY-MM-DD")] || [];

    let badges: any = [];

    badges = [
      ...badges,
      ...listData.map((item) => {
        const timeRange = visitTimeRange[item.id];
        const staff = assignedStaff[item.id];
        return {
          truck: item.truckName,
          component: (
            <Tooltip
              key={item.id}
              color={getAppointmentTypeColor(item.appointmentType)}
              placement="topLeft"
              title={
                <div style={{ fontSize: 12 }}>
                  <div>
                    <strong>Clinic:</strong> {item.truckName}
                  </div>
                  <div>
                    <strong>Type:</strong> {item.appointmentType}
                  </div>
                  <div>
                    <strong>Account:</strong> {item.companyName}
                  </div>
                  <div>
                    <strong>Location:</strong> {item.companyLocation ? item.companyLocation : "-"}
                  </div>

                  <div>
                    <strong>Hours:</strong>{" "}
                    {timeRange ? (
                      <>
                        {momentTz(timeRange.startDate).locale("en").tz(item.timezone).format("LT")} -{" "}
                        {momentTz(timeRange.endDate).locale("en").tz(item.timezone).format("LT")}
                      </>
                    ) : (
                      "-"
                    )}
                  </div>

                  {staff && staff.length > 0 && (
                    <div style={{ marginTop: 10 }}>
                      <strong>Staff:</strong>
                      {orderBy(staff, "position", "asc").map((p) => (
                        <div style={{ paddingLeft: 5 }}>
                          - [{p.position}] {p.firstName} {p.lastName}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.schedulingNotes && (
                    <div style={{ marginTop: 10 }}>
                      <strong>Scheduling notes:</strong> {item.schedulingNotes}
                    </div>
                  )}
                </div>
              }>
              <StyledBadge
                color={getAppointmentTypeColor(item.appointmentType)}
                backgroundColor={getVisitStatusColor(item.status)}
                text={
                  <strong
                    style={{
                      fontSize: 12,
                      color: colors.white,
                    }}>
                    <span>
                      <span>{item.truckName}:</span>
                      <br />
                      <span style={{ paddingLeft: 15 }}>{item.companyName}</span>
                    </span>
                  </strong>
                }
                //@ts-ignore
                onMouseEnter={() => this.onVisitHover(item.id)}
              />
            </Tooltip>
          ),
        };
      }),
    ];

    if (moment().startOf("day").isSameOrBefore(value)) {
      badges = [
        ...badges,
        ...availableTrucks.map((item) => ({
          truck: item.name,
          component: (
            <StyledBadge
              color={colors.black}
              key={item.id}
              style={{
                border: `1px solid ${colors.lightGrey}`,
                backgroundColor: excludedTrucksThisDay.includes(item.id) ? colors.greyCell : "transparent",
              }}
              onClick={(e) => {
                if (isMarkClinicUnavailableModeOn) {
                  e.stopPropagation();
                }
                isEditExcludedTrucksModeOn ? updateExcludedTrucks(value.format("YYYY-MM-DD"), item.id) : null;
                isMarkClinicUnavailableModeOn
                  ? this.toggleCreateOpsHoldVisitModal(true, item, value.format("YYYY-MM-DD"))
                  : null;
              }}
              text={
                <span style={{ fontSize: 12 }}>
                  <span>{item.name}:</span>
                  <br />
                  {!excludedTrucksThisDay.includes(item.id) && (
                    <div>
                      {item.isDisabled ? (
                        <span style={{ paddingLeft: 15, fontStyle: "italic", color: colors.red }}>Inactive truck</span>
                      ) : (
                        <span
                          style={{ paddingLeft: 15, fontStyle: "italic", color: colors.blue }}
                          onClick={(e) => e.stopPropagation()}>
                          <a href={`/visits/create?truckId=${item.id}&date=${value.format("YYYY-MM-DD")}`}>Add visit</a>
                        </span>
                      )}
                    </div>
                  )}
                  {excludedTrucksThisDay.includes(item.id) && (
                    <div>
                      <span style={{ paddingLeft: 15, fontStyle: "italic", color: colors.red }}>
                        Unavailable clinic
                      </span>
                    </div>
                  )}
                </span>
              }
            />
          ),
        })),
      ];
    }

    const renderVisitsList = (data: IBookingBoardMonthCalendarVisitWithoutRequireTruck[]) => {
      return (
        <CompanyListWrapper>
          {data.map((item) => (
            <CompanyListItem
              key={item.id}
              onClick={(e) => e.stopPropagation()}
              href={`visits/${item.id}/edit`}
              color={item.status.startsWith("cancelled") ? colors.red : colors.black}>
              <Tooltip title={item.companyName} placement="topLeft">
                {item.name as any}
              </Tooltip>
            </CompanyListItem>
          ))}
        </CompanyListWrapper>
      );
    };

    return (
      <div>
        {orderBy(badges, "truck", "asc").map((badge: { truck: string; component: React.ReactNode }) => badge.component)}
        {listDateWithVisitsWithoutRequireTruck.length ? renderVisitsList(listDateWithVisitsWithoutRequireTruck) : null}
      </div>
    );
  };

  dateFullCellRender = (value: Moment) => {
    const { selectedTrucks, isEditExcludedDatesModeOn, isEditExcludedTrucksModeOn, updateExcludedDates } = this.props;
    const { selectedMonth, additionalSelectedMonth } = this.state;
    if ([selectedMonth, additionalSelectedMonth].includes(value.format("YYYY-MM"))) {
      const formattedValue = value.format("YYYY-MM-DD");
      const currentDate = moment().format("YYYY-MM-DD");
      const cellBackgroundColor = this.getCellBackground(value);

      return (
        <CalendarDayWrapper
          isToday={formattedValue === currentDate}
          backgroundColor={cellBackgroundColor}
          onClick={() => {
            if (isEditExcludedDatesModeOn) {
              return updateExcludedDates(formattedValue);
            }

            if (isEditExcludedTrucksModeOn) {
              return null;
            }

            if (this.isDayExcluded(value)) {
              return null;
            } else {
              return this.toggleVisitsModal(true, formattedValue);
            }
          }}>
          <DayCardHeader>
            <div>{this.isDayExcluded(value) ? null : this.getDayIndicator(value)}</div>
            <div>
              <strong>{value.format("dd, DD")}</strong>
            </div>
          </DayCardHeader>
          <CalendarDayEventsWrapper height={selectedTrucks.length > 4 ? `${selectedTrucks.length * 52}px` : `210px`}>
            <div>{this.isDayExcluded(value) ? null : this.dateCellRender(value)}</div>
          </CalendarDayEventsWrapper>
        </CalendarDayWrapper>
      );
    }

    return null;
  };

  toggleVisitsModal = (isVisitsModalVisible: boolean, selectedDay: string) => {
    this.setState({
      isVisitsModalVisible,
      selectedDay,
    });
  };

  toggleCreateOpsHoldVisitModal = (
    isCreateOpsHoldVisitModalVisible: boolean,
    truck: ITruck | undefined,
    selectedDay: string
  ) => {
    this.setState({
      isCreateOpsHoldVisitModalVisible,
      selectedDay,
      selectedTruck: truck,
    });
  };

  onVisitHover = async (id: string) => {
    const { visitTimeRange } = this.state;
    if (!visitTimeRange[id]) {
      try {
        const timeRangeResponse = await ApiClient.getVisitTimeRange(id);
        const assignedStaffResponse = await ApiClient.getVisitStaff(id);

        this.setState({
          visitTimeRange: {
            ...this.state.visitTimeRange,
            [id]: timeRangeResponse.data,
          },
          assignedStaff: {
            ...this.state.assignedStaff,
            [id]: assignedStaffResponse.data.staff,
          },
        });
      } catch (error) {}
    }
  };

  public render() {
    const { selectedMonth, additionalSelectedMonth, selectedDay, isVisitsModalVisible, selectedTruck } = this.state;
    const {
      visits,
      isEditExcludedDatesModeOn,
      isEditExcludedTrucksModeOn,
      isMarkClinicUnavailableModeOn,
      toggleEditExcludedTrucksMode,
      toggleEditExcludedDatesMode,
      toggleMarkClinicUnavailableMode,
      saveExcludedDates,
      saveExcludedTrucks,
      refreshVisits,
      getVisitsInBackground,
      getVisitsWithoutRequireTruckInBackground,
    } = this.props;

    return (
      <Wrapper>
        <Heading>{moment(selectedMonth, "YYYY-MM").format("MMMM YYYY")}</Heading>
        <UpdateExcludedDatesWrapper>
          <div>
            <Button icon={<SyncOutlined />} onClick={refreshVisits}>
              Refresh
            </Button>
          </div>
          <div>
            <Space>
              {!isMarkClinicUnavailableModeOn && !isEditExcludedTrucksModeOn && !isEditExcludedDatesModeOn && (
                <>
                  <Button
                    onClick={() => {
                      toggleEditExcludedDatesMode(true);
                      toggleMarkClinicUnavailableMode(false);
                      toggleEditExcludedTrucksMode(false);
                    }}>
                    Edit available days
                  </Button>
                  <Button
                    onClick={() => {
                      toggleEditExcludedTrucksMode(true);
                      toggleMarkClinicUnavailableMode(false);
                      toggleEditExcludedDatesMode(false);
                    }}>
                    Edit available clinics
                  </Button>
                  <Button
                    onClick={() => {
                      toggleMarkClinicUnavailableMode(true);
                      toggleEditExcludedTrucksMode(false);
                      toggleEditExcludedTrucksMode(false);
                    }}>
                    Mark clinic unavailable
                  </Button>
                </>
              )}
              {isEditExcludedDatesModeOn && (
                <Space>
                  <div style={{ fontStyle: "italic" }}>
                    (You're in editing mode to save changes press on "Save" button)
                  </div>
                  <Button onClick={() => toggleEditExcludedDatesMode(false)}>Cancel</Button>
                  <Button type="primary" onClick={saveExcludedDates}>
                    Save
                  </Button>
                </Space>
              )}
              {isEditExcludedTrucksModeOn && (
                <Space>
                  <div style={{ fontStyle: "italic" }}>
                    (You're in editing mode to save changes press on "Save" button)
                  </div>
                  <Button onClick={() => toggleEditExcludedTrucksMode(false)}>Cancel</Button>
                  <Button type="primary" onClick={saveExcludedTrucks}>
                    Save
                  </Button>
                </Space>
              )}
              {isMarkClinicUnavailableModeOn && (
                <Space>
                  <div style={{ fontStyle: "italic" }}>(You're in editing mode)</div>
                  <Button onClick={() => toggleMarkClinicUnavailableMode(false)}>Cancel</Button>
                </Space>
              )}
            </Space>
          </div>
        </UpdateExcludedDatesWrapper>
        <ConfigProvider locale={en_GB}>
          <Calendar
            value={moment(selectedMonth, "YYYY-MM")}
            dateCellRender={this.dateCellRender}
            dateFullCellRender={this.dateFullCellRender}
            headerRender={() => <></>}
          />
        </ConfigProvider>

        <Heading>{moment(additionalSelectedMonth, "YYYY-MM").format("MMMM YYYY")}</Heading>
        <ConfigProvider locale={en_GB}>
          <Calendar
            value={moment(additionalSelectedMonth, "YYYY-MM")}
            dateCellRender={this.dateCellRender}
            dateFullCellRender={this.dateFullCellRender}
            headerRender={() => <></>}
          />
        </ConfigProvider>
        <BookingBoardVisitsModal
          isVisible={isVisitsModalVisible}
          selectedDay={selectedDay}
          visits={visits}
          getVisitsInBackground={getVisitsInBackground}
          getVisitsWithoutRequireTruckInBackground={getVisitsWithoutRequireTruckInBackground}
          onCancel={() => this.toggleVisitsModal(false, selectedDay)}
        />
        <CreateOpsHoldVisitModal
          isVisible={this.state.isCreateOpsHoldVisitModalVisible}
          selectedDay={selectedDay}
          truck={selectedTruck}
          onFinish={() => refreshVisits()}
          onCancel={() => this.toggleCreateOpsHoldVisitModal(false, undefined, selectedDay)}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  table.ant-picker-content {
    thead tr th {
      padding-right: 0 !important;
      font-weight: bold;
    }
  }

  table.ant-picker-content {
    border-collapse: separate;
  }
`;

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const CalendarDayWrapper = styled.div`
  padding: 5px;
  border: 1px solid
    ${(props: { isToday: boolean; backgroundColor: string }) => (props.isToday ? colors.blue : colors.grey)};
  background-color: ${(props: { isToday: boolean; backgroundColor: string }) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
`;

const CalendarDayEventsWrapper = styled.div`
  height: ${(props: { height: string }) => props.height};
  overflow-y: auto;
  text-align: left;
  font-size: 12px;
`;

const DayCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
//If you need to make a lighter shade add ${props.backgroundColor}4D
const StyledBadge = styled(Badge)`
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  background-color: ${(props: { backgroundColor?: string }) =>
    props.backgroundColor ? `${props.backgroundColor}` : "transparent"};
  padding: 0 5px;
  margin-bottom: 5px;
`;

export const UpdateExcludedDatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyListWrapper = styled.div`
  border: black 1px solid;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
`;

const CompanyListItem = styled.a`
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  color: ${(props: { color?: string }) => (props.color ? `${props.color}` : "black")};
`;
