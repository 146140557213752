import React from "react";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";
import moment from "moment";

import { AutoComplete, Button, Col, Form, InputNumber, Row, Select, Spin, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { ApiClient } from "../../../api-client/api.client";
import { colors } from "../../../styles/colors";
import { ListOfVisitFromMap } from "./list-of-visit-from-map.component";
import { calcHaversineFormula, milesToMetersFormula } from "../../../helpers/distance.helper";
import GoogleMap from "../../../ui/map/GoogleMap.container";
import { geocode, GeocodeAddress } from "../../../helpers/geocode-service.helper";
import { CompanyLocationVisit, VisitWithTimeslotsData } from "../../../interfaces/visit.interface";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: {
    visits: boolean;
    openTimeslots: boolean;
    accountsByPositions: boolean;
  };
  filters: {
    address: string;
    companyType: string;
    radiusOfEarth: number;
  };
  addressCoordinates: {
    longitude: number;
    latitude: number;
  };
  visits: CompanyLocationVisit[];
  visitsWithOpenTimeslots: VisitWithTimeslotsData[];
  addressResult: GeocodeAddress[];
  accountsByPositions: any[];
}

export class MapComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: {
      visits: false,
      openTimeslots: false,
      accountsByPositions: false,
    },
    filters: {
      address: "",
      companyType: "all",
      radiusOfEarth: 5,
    },
    addressCoordinates: {
      longitude: -71.05828,
      latitude: 42.35614,
    },
    visits: [],
    visitsWithOpenTimeslots: [],
    addressResult: [],
    accountsByPositions: [],
  };

  public async componentDidMount(): Promise<void> {
    await this.findFutureVisitsAccordingToCoordinates();
    await this.findOpenTimeslotsInVisits();
    await this.findAccountsByPositions();
  }

  public findFutureVisitsAccordingToCoordinates = async () => {
    this.setState({ isLoading: { ...this.state.isLoading, visits: true } });
    const { filters, addressCoordinates } = this.state;
    const visits = await ApiClient.findFutureVisitsAccordingToCoordinates({
      radiusOfEarth: filters.radiusOfEarth,
      latitude: addressCoordinates.latitude,
      longitude: addressCoordinates.longitude,
      types: filters.companyType === "all" ? ["ro_private", "ro_public"] : [filters.companyType],
      statuses: ["active_booked"],
    });

    this.setState({ visits: visits.data || [], isLoading: { ...this.state.isLoading, visits: false } });
  };

  public findOpenTimeslotsInVisits = async () => {
    this.setState({ isLoading: { ...this.state.isLoading, openTimeslots: true } });
    const { visits } = this.state;
    if (visits && visits.length) {
      const openTimeslots = await ApiClient.findOpenTimeslotsInVisits({
        visitIds: visits.map(({ visit }) => visit.id),
      });
      this.setState({ visitsWithOpenTimeslots: openTimeslots.data || [] });
    }
    this.setState({ isLoading: { ...this.state.isLoading, openTimeslots: false } });
  };

  public findAccountsByPositions = async () => {
    this.setState({ isLoading: { ...this.state.isLoading, accountsByPositions: true } });

    const accounts = await ApiClient.findAccountsByPositions({ positions: ["OD"] });

    this.setState({
      accountsByPositions: accounts.data || [],
      isLoading: { ...this.state.isLoading, accountsByPositions: false },
    });
  };

  public onSearchFormFinish = async (values: any) => {
    this.setState({ filters: values, isLoading: { ...this.state.isLoading, visits: true } });

    const addresses = await geocode(values.address);

    if (addresses && addresses.length) {
      const { coordinates } = addresses[0];
      this.setState({ addressCoordinates: { longitude: coordinates.lng, latitude: coordinates.lat } });
    }

    await this.findFutureVisitsAccordingToCoordinates();
    await this.findOpenTimeslotsInVisits();
    await this.findAccountsByPositions();
  };

  public onFormReset = async () => {
    this.setState({
      isLoading: {
        openTimeslots: false,
        visits: false,
        accountsByPositions: false,
      },
      filters: {
        address: "",
        companyType: "all",
        radiusOfEarth: 5,
      },
      addressCoordinates: {
        longitude: -71.05828,
        latitude: 42.35614,
      },
      visits: [],
      addressResult: [],
    });
    this.searchForm.current!.resetFields();

    await this.findFutureVisitsAccordingToCoordinates();
    await this.findOpenTimeslotsInVisits();
    await this.findAccountsByPositions();
  };

  public handleAddressSearch = async (address: string) => {
    this.setState({ addressResult: [] });
    if (address.length > 3) {
      const results = await geocode(address);
      this.setState({ addressResult: results });
    }
  };

  public redirectToPatientTracker(visitId: string) {
    this.props.history.push({
      pathname: `/patient-tracker`,
      search: `?trackerId=${visitId}`,
    });
  }

  public getListOfVisits = () => {
    const { visits, addressCoordinates, visitsWithOpenTimeslots, accountsByPositions } = this.state;
    const allVisits = visits.map((visit) => visit.visit);
    const allCompanies = visits.map((visit) => visit.company);

    return visits
      .map((visit) => visit.location)
      .filter((location, index, self) => index === self.findIndex((c) => c.id === location.id))
      .map((location) => {
        const matchedVisits = allVisits.filter((visit) => location.id === visit.locationId);
        const matchedCompany = allCompanies.find((company) => company.locations.find((l) => l.id === location.id));
        return {
          companyName: matchedCompany.name,
          address: location.address,
          distance: location.geoLocation
            ? calcHaversineFormula(addressCoordinates, {
                longitude: location.geoLocation.x,
                latitude: location.geoLocation.y,
              })
            : 0,
          visits: matchedVisits.map((matchedVisit) => {
            const visitWithOpenTimeslots = visitsWithOpenTimeslots.find((v) => v.id === matchedVisit.id);
            const employeeWithPositionOd =
              matchedVisit.employees && matchedVisit.employees.length
                ? matchedVisit.employees.find((e) => e.position === "OD")
                : undefined;
            const od = employeeWithPositionOd
              ? accountsByPositions.find((a) => a.id === employeeWithPositionOd.id)
              : undefined;
            return {
              id: matchedVisit.id,
              odName: od ? `${od.firstName} ${od.lastName}` : undefined,
              date: moment(matchedVisit.date).format("ll"),
              openTimeslotsCount: visitWithOpenTimeslots ? visitWithOpenTimeslots.timeslots.length : 0,
            };
          }),
        };
      })
      .sort((a, b) => {
        return a.distance - b.distance;
      });
  };

  public getMarkers = () => {
    const { visits } = this.state;

    return visits.map((visit) => {
      return {
        color: visit.company.isPublic ? "blue" : "red",
        coordinates: {
          lng: visit.location.geoLocation.x,
          lat: visit.location.geoLocation.y,
        },
        popup: (
          <>
            <b>{visit.visit.name}</b>
            <br />
            <b>
              <BlueLink to={`/visits/${visit.visit.id}/edit`} target="_blank">
                Go to Visit
              </BlueLink>
            </b>
            <br />
            <b>
              <BlueTextColor onClick={() => this.redirectToPatientTracker(visit.visit.id)}>Go to Tracker</BlueTextColor>
            </b>
            <b>Comapny: </b>
            {visit.company.name}
            <br />
            <b>Address: </b>
            {visit.location.address}
            <br />
            <b>Date: </b>
            {visit.visit.date}
            <br />
            <b>Type: </b>
            {visit.visit.appointmentType}
            <br />
          </>
        ),
      };
    });
  };

  public render() {
    const { isLoading, addressCoordinates, addressResult, filters, visits } = this.state;

    const markers = this.getMarkers();
    const listOfVisits = this.getListOfVisits();

    return (
      <Wrapper>
        <Row style={{ margin: "24px 0" }}>
          <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
            <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
              <Form.Item
                name="address"
                label="Address"
                style={{ marginBottom: 16 }}
                rules={[{ required: true, message: "Please enter address!" }]}>
                <AutoComplete style={{ width: 450 }} onSearch={this.handleAddressSearch} placeholder="input here">
                  {addressResult.map((result, index) => (
                    <AutoComplete.Option key={`auto-complete-optin-${index}`} value={result.formattedAddress}>
                      {result.formattedAddress}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                name="companyType"
                label="Company type"
                initialValue={filters.companyType}
                style={{ marginBottom: 16 }}
                rules={[{ required: true, message: "Please select company type!" }]}>
                <Select placeholder="Select type" style={{ width: 150 }}>
                  <Select.Option value="ro_public">Public</Select.Option>
                  <Select.Option value="ro_private">Private</Select.Option>
                  <Select.Option value="all">All</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="radiusOfEarth"
                label="Radius range"
                initialValue="5"
                style={{ marginBottom: 16 }}
                rules={[{ required: true, message: "Please enter radius range!" }]}>
                <InputNumber min={0.1} max={100000} precision={1} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button type="primary" loading={isLoading.visits} htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button htmlType="button" onClick={this.onFormReset}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Total: {isLoading.visits ? <strong> Loading ...</strong> : <strong>{visits.length}</strong>}
        </Typography.Text>

        <Row gutter={24} style={{ marginTop: 20 }}>
          <Col xs={24} md={16}>
            <GoogleMap
              styles={{
                height: "600px",
              }}
              markers={markers}
              circle={{
                radius: milesToMetersFormula(filters.radiusOfEarth),
                center:
                  addressCoordinates.longitude && addressCoordinates.latitude
                    ? { lat: addressCoordinates.latitude, lng: addressCoordinates.longitude }
                    : { lat: 0, lng: 0 },
              }}
            />
          </Col>
          <Col xs={24} md={8}>
            {isLoading.visits ? (
              <Spin />
            ) : (
              <InfiniteContainer style={{ height: "600px" }}>
                <ListOfVisitFromMap visits={listOfVisits} onVisitClick={this.redirectToPatientTracker.bind(this)} />
              </InfiniteContainer>
            )}
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const BlueTextColor = styled.div`
  color: ${colors.blue} !important;
  &:hover {
    color: ${colors.darkerBlue} !important;
  }
`;

const BlueLink = styled(Link)`
  color: ${colors.blue} !important;
  &:hover {
    color: ${colors.darkerBlue} !important;
  }
`;

const InfiniteContainer = styled.div`
  height: 600px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;
