import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

import { IState, IUpdateQuestionnaireFieldPayload } from "../questionnaire.interfaces";
import { medicalHistoryQuestions } from "../questions";
import { QuestionCheckbox } from "../inputs/question-checkbox.component";
import { SectionHeader } from "../section-header.component";
import { FamilyQuestionAnswers } from "../../../../../../../patient-app/src/questionnaire/Questionnaire.interfaces";

interface IProps {
  updateQuestionnaireField: (value: IUpdateQuestionnaireFieldPayload) => void;
  medicalHistory: IState["medicalHistory"];
}

export const MedicalHistory = (props: IProps) => {
  const { medicalHistory, updateQuestionnaireField } = props;
  return (
    <>
      <SectionHeader header="Medical History" />
      <Row gutter={16}>
        <CenteredCol span={2} offset={12}>
          <Option>You</Option>
        </CenteredCol>
        <CenteredCol span={2}>
          <Option>Mother</Option>
        </CenteredCol>
        <CenteredCol span={2}>
          <Option>Father</Option>
        </CenteredCol>
        <CenteredCol span={2}>
          <Option>Sister</Option>
        </CenteredCol>
        <CenteredCol span={2}>
          <Option>Brother</Option>
        </CenteredCol>
        <CenteredCol span={2}>
          <Option>No / Unknown</Option>
        </CenteredCol>
      </Row>
      {medicalHistoryQuestions.map((q, i) => (
        <QuestionCheckbox
          key={q.question}
          isRowOdd={!!(i & 1)}
          question={q.question}
          options={q.options}
          defaultValue={medicalHistory[q.key]}
          onChange={(e: any) => {
            const { value } = e.target;
            let answers = medicalHistory[q.key];
            const payload = {
              section: "medicalHistory",
              key: q.key,
            };

            if (value === "no") {
              if (answers.includes("no" as FamilyQuestionAnswers)) {
                updateQuestionnaireField({
                  ...payload,
                  value: [],
                });
              } else {
                updateQuestionnaireField({
                  ...payload,
                  value: [value],
                });
              }
              return;
            } else {
              if (answers.includes("no" as FamilyQuestionAnswers)) {
                answers = answers.filter((answer) => answer !== "no");
                updateQuestionnaireField({
                  ...payload,
                  value: answers,
                });
              }
            }

            if (answers.includes(value)) {
              updateQuestionnaireField({
                ...payload,
                value: answers.filter((answer) => answer !== value),
              });
            } else {
              updateQuestionnaireField({
                ...payload,
                value: [...answers, value],
              });
            }
          }}
        />
      ))}
    </>
  );
};

const Option = styled.div`
  font-size: 13px;
  font-weight: bold;

  @media (max-width: 1100px) {
    font-size: 12px;
    writing-mode: vertical-rl;
  }
`;

const CenteredCol = styled(Col)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
