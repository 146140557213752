import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import moment from "moment";
import momentTz from "moment-timezone";

moment.locale("en");
momentTz.locale("en");

if (window.location === window.parent.location) {
  ReactDOM.render(<App />, document.getElementById("root"));
}
