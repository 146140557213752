import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Typography } from "antd";
import { CreateVisitComponent } from "./components/create-visit.component";
import { VisitsListComponent } from "./components/visits-list.component";
import { EditVisitComponent } from "./components/edit-visit.component";
import { EditTimeslotsComponent } from "./components/edit-timeslots.component";

interface IProps extends RouteComponentProps {}

export class Visits extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <>
        <Typography.Title>Visits</Typography.Title>
        <Route path="/visits/create" component={CreateVisitComponent} />
        <Route path="/visits/:id/edit" component={EditVisitComponent} />
        <Route path="/visits/" exact component={VisitsListComponent} />
        <Route path="/visits/:id/timeslots/edit" component={EditTimeslotsComponent} />
      </>
    );
  }
}
